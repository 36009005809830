import React, { useRef, useState } from "react";
import { Button, Menu, MenuItem, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useNavigate } from "react-router-dom";
import { RoleContext } from "../role-provider";
import { useContext, useMemo } from "react";

type NavMenuItem = {
  shortName: string;
  name: string;
  url: string | null;
  key: string;
  children: NavMenuItem[];
};
const navMenuConfig: NavMenuItem[] = [
  {
    shortName: "Home",
    name: "Home",
    url: "/",
    key: "topnav-item-home",
    children: [],
  },
  {
    shortName: "Categories",
    name: "Categories & Meal Bundles",
    url: null,
    key: "topnav-item-categories",
    children: [
      {
        shortName: "View Categories & Meal Bundles",
        name: "View Categories & Meal Bundle",
        url: "/categories",
        key: "menu-item-list-categories",
        children: [],
      },
      {
        shortName: "Add Categories & Meal Bundles",
        name: "Add Categories & Meal Bundle",
        url: "/categories/create-category",
        key: "menu-item-new-category",
        children: [],
      },
      {
        shortName: "Delete/Restore Archive Categories",
        name: "Delete/Restore Archive Categories",
        url: "/archive-category",
        key: "menu-item-list-archived-categories",
        children: [],
      },
    ],
  },
  {
    shortName: "Items",
    name: "Items",
    url: null,
    key: "topnav-item-items",
    children: [
      {
        shortName: "View Menu Items",
        name: "View Menu Items",
        url: "/items",
        key: "menu-item-list-items",
        children: [],
      },
      {
        shortName: "Add Menu Items",
        name: "Add Menu Items",
        url: "/items/create-item",
        key: "menu-item-new-item",
        children: [],
      },
      {
        shortName: "Delete/Restore Archive Items",
        name: "Delete/Restore Archive Items",
        url: "/archive-item",
        key: "menu-item-list-archived-items",
        children: [],
      },
    ],
  },
  {
    shortName: "Products",
    name: "Products",
    url: null,
    key: "topnav-item-products",
    children: [
      {
        shortName: "View Products",
        name: "View Products",
        url: "/products",
        key: "menu-item-list-products",
        children: [],
      },
      {
        shortName: "Add Products",
        name: "Add Products",
        url: "/products/create-product",
        key: "menu-item-new-product",
        children: [],
      },
    ],
  },
  {
    shortName: "Miscellaneous",
    name: "Miscellaneous",
    url: null,
    key: "topnav-item-misc",
    children: [
      {
        shortName: "View Relationship",
        name: "View Relationship",
        url: "/relationships",
        key: "menu-item-misc-view-relationship",
        children: [],
      },
      {
        shortName: "Element Order",
        name: "Element Order",
        url: null,
        key: "menu-item-misc-element-order",
        children: [],
      },
      {
        shortName: "Custom Footers",
        name: "Custom Footers",
        url: "/custom-footers",
        key: "menu-item-misc-custom-footers",
        children: [],
      },
      {
        shortName: "View Logo",
        name: "View Logo",
        url: "/view-logo",
        key: "menu-item-misc-view-logos",
        children: [],
      },
       {
         shortName: "View Status",
         name: "View Status",
         url: "/view-status",
         key: "menu-item-misc-view-status",
         children: [],
       },
      {
        shortName: "Bulk Upload",
        name: "Bulk Upload",
        url: "/bulk-upload",
        key: "menu-item-misc-bulk-upload",
        children: [],
      },
      {
        shortName: "Exceptions Report",
        name: "Exceptions Report",
        url: "/exceptions-report",
        key: "menu-item-misc-exceptions-report",
        children: [],
      },
      {
        shortName: "Nutrition Facts",
        name: "Nutrition Facts",
        url: "/nutrition-facts",
        key: "menu-item-misc-nutrition-facts",
        children: [],
      },
      {
         shortName: "Unit of Measurement",
         name: "Unit of Measurement",
         url: "/unit-of-measurement",
         key: "menu-item-misc-unit-of-measurement",
         children: [],
      },
      {
         shortName: "Rounding Rule Management",
         name: "Rounding Rule Management",
         url: "/rounding-rules",
         key: "menu-item-misc-rounding-rules",
         children: [],
      },
      {
        shortName: "Element Hierarchy",
        name: "Element Hierarchy",
        url: null,
        key: "menu-item-misc-element-hierarchy",
        children: [],
      },
      {
        shortName: "Create Attribute",
        name: "Create Attribute",
        url: null,
        key: "menu-item-misc-create-attribute",
        children: [],
      },
       {
         shortName: "Manage Common Attributes",
         name: "Manage Common Attributes",
         url: "/manage-common-attributes",
         key: "menu-item-misc-manage-attribute",
         children: [],
       },
      {
        shortName: "Workflows",
        name: "Workflows",
        url: "/workflows",
        key: "menu-item-misc-workflows",
        children: [],
      },
      {
        shortName: "Add User",
        name: "Add User",
        url: "/view-user/add-edit-user",
        key: "menu-item-misc-add-user",
        children: [],
      },
      {
        shortName: "View User",
        name: "View User",
        url: "/view-user",
        key: "menu-item-misc-view-user",
        children: [],
      },
      {
        shortName: "View Role",
        name: "View Role",
        url: "/view-role",
        key: "menu-item-misc-view-role",
        children: [],
      },
    ],
  },
  {
    shortName: "Push Live Console",
    name: "Push Live Console",
    url: "/push-live-detail",
    key: "topnav-item-push-live-console",
    children: [],
  },
  {
    shortName: "Audit Trail",
    name: "Audit Trail",
    url: "/audit-trail",
    key: "topnav-item-audit-trail",
    children: [],
  },
];

type SubNavMenuProps = {
  id: string;
  label: string;
  children: NavMenuItem[];
  open: boolean;
  buttonRef: HTMLButtonElement;
  onMenuClose: () => void;
};
const SubNavMenu = ({
  id,
  label,
  children,
  open,
  buttonRef,
  onMenuClose,
}: SubNavMenuProps) => {
  const navigate = useNavigate();
  const menuItemRefs = useRef<(HTMLElement | null)[]>([]);
  const { visibleMenuItems } = useContext(RoleContext);
  // filter subnav items down to just those allowed for role
  const visibleSubNavItems = children.filter(
    (subNavMenuItem) =>
      subNavMenuItem.url !== null &&
      visibleMenuItems.includes(subNavMenuItem.name.toLowerCase())
  );

  const onMenuItemKeydown = (event: React.KeyboardEvent<HTMLElement>) => {
    const currentFocusedIndex = menuItemRefs.current.findIndex(
      (ref) => ref === document.activeElement
    );
    if (event.key === "Escape") {
      onMenuClose();
    }
    if (event.key === "Tab" && event.shiftKey === false) {
      event.preventDefault();
      if (currentFocusedIndex === menuItemRefs.current.length - 1) {
        // current focused item is last in the menu, close it
        onMenuClose();
      } else if (
        currentFocusedIndex >= 0 &&
        currentFocusedIndex < menuItemRefs.current.length - 1
      ) {
        // Move focus to the next item
        menuItemRefs.current[currentFocusedIndex + 1]?.focus();
      }
    }
  };
  const onRequestMenuClose = (
    event: React.KeyboardEvent<HTMLElement>,
    reason: string
  ) => {
    if (reason === "backdropClick") {
      onMenuClose();
    }
  };

  return (
    <StyledMenu
      id={`${id}-menu`}
      data-testid="subnav-menu"
      anchorEl={buttonRef}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      open={open}
      onKeyDown={onMenuItemKeydown}
      onClose={onRequestMenuClose}
      aria-label={label}
      disableScrollLock
    >
      {visibleSubNavItems &&
        visibleSubNavItems.map((navItem, index) => {
          const { shortName, url, key } = navItem;
          return (
            <MenuItem
              component="button"
              disableRipple
              sx={{
                width: "100%",
              }}
              key={key}
              ref={(el: HTMLButtonElement) =>
                (menuItemRefs.current[index] = el)
              }
              onClick={() => {
                if (url) {
                  onMenuClose();
                  return navigate(url);
                }
              }}
              data-testid="subnav-menu-item"
              aria-label="Subnav Menu Item"
            >
              {shortName}
            </MenuItem>
          );
        })}
    </StyledMenu>
  );
};

export const Navbar = () => {
  const navigate = useNavigate();
  const { visibleMenuItems } = useContext(RoleContext);
  // filter menu items down to just those allowed for role
  const visibleTopNavItems = navMenuConfig.filter((topNavItem) =>
    visibleMenuItems.includes(topNavItem.name.toLowerCase())
  );

  const [openIndex, setOpenIndex] = useState<number | null>(null);
  const topNavButtonRefs = useRef<(HTMLButtonElement | null)[]>([]);
  const { isItemLevelMarket } = useContext(RoleContext);

  const handleTopNavItemClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    index: number
  ) => {
    event.preventDefault();
    setOpenIndex(index);
  };
  const handleMenuClose = () => {
    setOpenIndex(null);
  };
  const filteredNavMenuConfig = useMemo(() => {
    return visibleTopNavItems.filter((item) => {
      if (item.name === "Products" && isItemLevelMarket) {
        return false;
      }
      return true;
    });
  }, [visibleTopNavItems, isItemLevelMarket]);
  const handleNavItemClick = (url: string) => {
    window.location.href = url;
  };
  return (
    <Stack
      direction="row"
      sx={{ justifyContent: "space-between", marginTop: 0 }}
      component="nav"
      role="navigation"
      aria-label="Main Navigation"
      data-testid="main-menu-stack"
    >
      {filteredNavMenuConfig.map((topNavItem, index) => {
        const { shortName, url, key, children } = topNavItem;
        return (
          <div key={key}>
            {shortName === "Home" ? (
              <StyledTopNavButton
                disableRipple
                onClick={(e) => {
                  e.preventDefault();
                  handleNavItemClick(url!);
                }}
                ref={(el: HTMLButtonElement) =>
                  (topNavButtonRefs.current[index] = el)
                }
                selected={openIndex === index}
                aria-label="Nav Menu Item"
                aria-controls={`${key}-menu`}
                aria-haspopup={children.length > 0 ? "true" : "false"}
                aria-expanded={openIndex === index ? "true" : "false"}
                endIcon={
                  children.length > 0 ? <ArrowDropDownIcon /> : undefined
                }
              >
                {shortName}
              </StyledTopNavButton>
            ) : (
              <StyledTopNavButton
                disableRipple
                onClick={(e) => {
                  if (url) {
                    return navigate(url);
                  } else {
                    return handleTopNavItemClick(e, index);
                  }
                }}
                ref={(el: HTMLButtonElement) =>
                  (topNavButtonRefs.current[index] = el)
                }
                selected={openIndex === index}
                aria-label="Nav Menu Item"
                aria-controls={`${key}-menu`}
                aria-haspopup={children.length > 0 ? "true" : "false"}
                aria-expanded={openIndex === index ? "true" : "false"}
                endIcon={
                  children.length > 0 ? <ArrowDropDownIcon /> : undefined
                }
              >
                {shortName}
              </StyledTopNavButton>
            )}
            {topNavButtonRefs.current[index] && children.length > 0 && (
              <SubNavMenu
                id={`${key}-subnav`}
                label={shortName}
                open={openIndex === index}
                buttonRef={topNavButtonRefs.current[index]!}
                onMenuClose={handleMenuClose}
                children={children}
              />
            )}
          </div>
        );
      })}
    </Stack>
  );
};

const StyledTopNavButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "open",
})<{
  selected?: boolean;
}>(({ theme }) => ({
  paddingRight: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  color: theme.palette.text.primary,
  fontFamily: theme.typography.normal.fontFamily,
  fontSize: theme.typography.normal.fontSize,
  textTransform: "none",
  "&:hover": {
    outline: "none",
  },
  "&.Mui-focusVisible": {
    backgroundColor: theme.palette.primary.light,
  },
}));

const StyledMenu = styled(Menu)({
  "& .MuiMenu-list": {
    padding: 0,
  },
});
