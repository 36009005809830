import { Button, TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { styled } from "@mui/material/styles";
import { useForm } from "react-hook-form";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { LanguageSelector } from "./LanguageSelector";
import { useMutation } from "@tanstack/react-query";
import {
  getCategoryMarketingInformation,
  saveCategoryMarketingInformation,
} from "../data/categories";
import { useAxios } from "../axios-provider";
import { RoleContext } from "../role-provider";
import {
  ApiError,
  SANITIZE_OPTS_ALLOW_TAGS,
  SANITIZE_OPTS_NO_TAGS,
  ValidCategoryAttributeWithValue,
  isCategoryMarketingAttribute,
} from "../utils";
import { Loader } from "./loader/Loader";
import { SuccessAlertSnackbar, ErrorAlertSnackbar } from "./AlertSnackbar";
import { useNavigate } from "react-router-dom";
import { StyledSecondaryButton } from "./ItemMarketingForm";
import { UnsavedChangesModal } from "./UnsavedChangesModal";
import DOMPurify from "dompurify";
import { useCustomQuery } from "../hooks/use-custom-query";
import { ResendFormModal } from "./ResendFormModal";
import { AxiosError } from "axios";

type MarketingInfoFormProps = {
  categoryId?: number;
  editable: boolean;
  isLive: boolean;
  saveSuccessMessage: string;
  language: string;
  setLanguage: (language: string) => void;
  onSaveSuccess?: () => void;
};

type CategoryFormFields = {
  category_id: string
  category_type: string;
  category_name: string;
  category_marketing_name: string;
  do_not_show: string;
  category_description: string;
  category_comments: string;
  category_keywords: string;
};
type Attribute = {
  keyName?: string;
  isEditable?: string;
};
export const CategoryMarketingForm = ({
  categoryId,
  editable,
  isLive,
  saveSuccessMessage,
  language,
  setLanguage,
  onSaveSuccess,
}: MarketingInfoFormProps) => {
  const successCallback =
    typeof onSaveSuccess === "function" ? onSaveSuccess : () => { };
  const { apiClient } = useAxios();
  const navigate = useNavigate();
  const { selectedCountry, isReaderRole, selectedRole } =
    useContext(RoleContext);

  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);
  const [isResendModalOpen, setIsResendModalOpen] = useState<boolean>(false);

  const handleSaveSuccess = () => {
    setSuccessMessage(null);
    successCallback();
  };
  const getDefaultValues = (
    attributes: ValidCategoryAttributeWithValue[]
  ): CategoryFormFields => {
    const defaultValues: Partial<CategoryFormFields> = {};
    attributes.forEach((attr) => {
      //change added for platform 9439 start amanjare
      if (attr.keyName === 'do_not_show') {
        defaultValues[attr.keyName as keyof CategoryFormFields] =
          attr?.value && attr.value !== "" ? attr.value : "Do not Show";
      }
      else {
        defaultValues[attr.keyName as keyof CategoryFormFields] =
          attr.value ?? "";
      }
      //change added for platform 9439 end amanjare
    });
    return defaultValues as CategoryFormFields;
  };

  const { data, isFetching } = useCustomQuery(
    [
      "getCategoryMarketingInformation",
      {
        categoryId,
        countryCode: selectedCountry,
        languageCode: language,
      },
    ],
    () =>
      getCategoryMarketingInformation(apiClient)({
        categoryId: categoryId !== undefined ? Number(categoryId) : undefined,
        countryCode: selectedCountry!,
        languageCode: language,
        roleId: selectedRole!,
      })
  );
  const categoryAttributes = useMemo(() => {
    if (data?.data.attributeList === undefined) {
      return [];
    }
    const validAttributes = data?.data.attributeList.filter(
      (maybeAttribute): maybeAttribute is ValidCategoryAttributeWithValue => {
        return isCategoryMarketingAttribute(maybeAttribute) === true;
      }
    );
    return validAttributes.sort((a, b) => a.displayOrder - b.displayOrder);
  }, [data]);
  // Varshitha commented based on PLATFORM 8783: 
  // To loop through all key names in a single useMemo to avoid duplicate useMemo instances.
  // const categoryIdAttribute = useMemo(() => {
  //   return categoryAttributes.find((attr) => attr.keyName === "category_id");
  // }, [categoryAttributes]);
  const attributeKeys: (keyof CategoryFormFields)[] = useMemo(() => [
    "category_id",
    "category_name",
    "category_type",
    "do_not_show",
    "category_description",
    "category_keywords",
    "category_comments",
  ], []);
  const categoryAttributesMap = useMemo(() => {
    const attributes: Partial<{ [key in keyof CategoryFormFields]: any }> = {};

    // Iterate over attributeKeys
    attributeKeys.forEach((key) => {
      const attr = categoryAttributes.find((attr) => attr.keyName === key);
      if (attr) {
        attributes[key] = attr;
      }
    });

    return attributes;
  }, [categoryAttributes, attributeKeys]);

  const categoryIdAttribute = categoryAttributesMap["category_id"];
  const categoryNameAttribute = categoryAttributesMap["category_name"];
  const categoryTypeAttribute = categoryAttributesMap["category_type"];
  const categoryShowAttribute = categoryAttributesMap["do_not_show"];
  const categoryDescriptionAttribute = categoryAttributesMap["category_description"];
  const categoryKeyAttribute = categoryAttributesMap["category_keywords"];
  const categoryCommentAttribute = categoryAttributesMap["category_comments"];

  // Assuming isEditable is initially "Y"
  function isAttributeEditable(attribute: Attribute | undefined): boolean {
    return attribute?.isEditable === "Y" ? false : true;
  }

  const isEditableBoolean = isAttributeEditable(categoryIdAttribute);
  const isEditableName = isAttributeEditable(categoryNameAttribute);
  const isEditableType = isAttributeEditable(categoryTypeAttribute);

  const isEditableShow = isAttributeEditable(categoryShowAttribute);
  const isEditableDesc = isAttributeEditable(categoryDescriptionAttribute);
  const isEditableKey = isAttributeEditable(categoryKeyAttribute);
  const isEditableComment = isAttributeEditable(categoryCommentAttribute);



  const dropDownValueMap = useMemo(() => {
    if (data?.data.dropdownList === undefined) {
      return {};
    }
    return data.data.dropdownList;
  }, [data]);
  const {
    register,
    reset,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<CategoryFormFields>({
    defaultValues: getDefaultValues(categoryAttributes),
  });
  const handleCancel = () => {
    if (isDirty) {
      setShowUnsavedChangesModal(true);
    } else {
      navigate("/categories");
    }
  };
  const handleLeaveNavigation = () => {
    navigate("/categories");
  };
  useEffect(() => {
    // Whenever attributes change, update the form's default values
    const defaultValues = getDefaultValues(categoryAttributes);
    reset(defaultValues);
  }, [categoryAttributes, reset]);

  const getAttributeByKeyName = useCallback(
    (keyName: string): ValidCategoryAttributeWithValue | undefined => {
      return categoryAttributes.find((attr) => attr.keyName === keyName);
    },
    [categoryAttributes]
  );

  const saveCategoryMarketingInformationRequest =
    saveCategoryMarketingInformation(apiClient);
  const { mutate, isLoading } = useMutation(
    (data: CategoryFormFields) => {
      const attributesToSave: ValidCategoryAttributeWithValue[] = [];
      Object.entries(data).forEach(([key, value]) => {
        if (key !== "category_id") {
          const attr = getAttributeByKeyName(key);
          if (attr) {
            attributesToSave.push({
              ...attr,
              value,
            });
          }
        }
      });
      const categoryMarketingFormData = {
        attributeList: attributesToSave,
      };
      const metaData = {
        ...(typeof categoryId !== "undefined"
          ? { categoryId: Number(categoryId) }
          : {}),
        countryCode: selectedCountry!,
        roleId: selectedRole!,
        languageCode: language,
      };
      return saveCategoryMarketingInformationRequest(
        categoryMarketingFormData,
        metaData
      );
    },
    {
      onMutate: () => saveCategoryMarketingInformationRequest,
      onSuccess: () => {
        setSuccessMessage(saveSuccessMessage);
      },
      onError: (error: AxiosError) => {
        if (error?.response?.status === 401) {
          setIsResendModalOpen(true);
        } else if (error.response?.data) {
          const errorMessage: ApiError = error.response.data as ApiError;
          setErrorMessage(errorMessage.message);
        } else {
          setErrorMessage(String(error));
        }
      },
    }
  );

  const handleBlur =
    (fieldName: keyof CategoryFormFields, allowtags: boolean = false) =>
      (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const sanitizedValue = DOMPurify.sanitize(
          event.target.value,
          !allowtags ? SANITIZE_OPTS_NO_TAGS : SANITIZE_OPTS_ALLOW_TAGS
        )
          .toString()
          .trim();
        setValue(fieldName, sanitizedValue);
      };

  const onSubmit = (formData: CategoryFormFields) => {
    mutate(formData);
  };

  const renderMinLengthField = useCallback(
    (
      fieldName: keyof CategoryFormFields,
      fieldId: string,
      required: boolean,
      minLength: number,
      purify: boolean
    ) => {
      const attr = getAttributeByKeyName(fieldName);
      const fieldLabel = attr?.name ?? "";
      return (
        <Grid mobile={12}>
          <StyledTextField
            fullWidth
            InputLabelProps={{ shrink: true }}
            label={required ? fieldLabel + "*" : fieldLabel}
            disabled={!!isReaderRole || !editable || isEditableName}
            {...register(fieldName, {
              required,
              validate: (value) => {
                return (
                  value.trim().length >= minLength ||
                  `This field must be at least ${minLength} alphanumeric characters long.`
                );
              },
            })}
            onBlur={purify ? handleBlur(fieldName) : undefined}
            error={!!errors[fieldName]}
            helperText={errors[fieldName] && errors[fieldName]?.message}
            data-testid={`cy-${fieldId}`}
          />
        </Grid>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errors, isReaderRole, editable, isEditableName]
  );

  return (
    <StyledRelativeContainer container spacing={1}>
      {(isFetching || isLoading) && <Loader />}
      <Grid container spacing={1} sx={{ m: 0 }}>
        <Grid mobile={12}>
          <Grid
            container
            mobile={12}
            sx={{ mb: 6, display: "flex", justifyContent: "space-between" }}
          >
            <Typography variant="h2">Category Marketing Information</Typography>
            <StyledSecondaryButton
              variant="contained"
              disabled={!isLive}
              onClick={() => {
                navigate(`/categories/${categoryId}/compare-with-live`);
              }}
            >
              Compare with live
            </StyledSecondaryButton>
          </Grid>
          <Grid container sx={{ mb: 2, justifyContent: "flex-end" }}>
            <LanguageSelector
              selectedLanguage={language}
              disabled={typeof categoryId === "undefined"}
              onChange={(language) => setLanguage(language)}
            />
          </Grid>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              {/* <Grid mobile={12}>
                <StyledTextField
                  fullWidth
                  disabled={!!isReaderRole || !editable || isEditableType} 
                  label="Category Type*"
                  InputLabelProps={{ shrink: true }}
                  select
                  SelectProps={{ native: true }}
                  {...register("category_type", {
                    required: true,
                  })}
                >
                  {!!dropDownValueMap.category_type &&
                    dropDownValueMap.category_type.map((option) => (
                      <option key={option.id} value={option.value}>
                        {option.value}
                      </option>
                    ))}
                </StyledTextField>
              </Grid>
              {renderMinLengthField(
                "category_name",
                "category-name-input",
                true,
                3,
                true
              )}
              {renderMinLengthField(
                "category_marketing_name",
                "category-marketing-name-input",
                true,
                3,
                true
              )}
              {categoryIdAttribute && (
                <Grid mobile={12}>
                  <StyledTextField
                    fullWidth
                    disabled={isEditableBoolean}
                    label={categoryIdAttribute.name}
                    value={categoryIdAttribute.value}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              )}

              <Grid mobile={12}>
                <StyledTextField
                  fullWidth
                  disabled={!!isReaderRole || !editable || isEditableShow}
                  label="Do Not Show"
                  InputLabelProps={{ shrink: true }}
                  select
                  SelectProps={{ native: true }}
                  {...register("do_not_show")}
                >
                  {!!dropDownValueMap.do_not_show &&
                    dropDownValueMap.do_not_show.map((option) => (
                      <option key={option.id} value={option.value}>
                        {option.value}
                      </option>
                    ))}
                </StyledTextField>
              </Grid>
              <Grid mobile={12}>
                <StyledTextField
                  label="Category Description"
                  InputLabelProps={{ shrink: true }}
                  multiline
                  fullWidth
                  disabled={!!isReaderRole || !editable || isEditableDesc}
                  rows={3}
                  {...register("category_description")}
                  onBlur={handleBlur("category_description", true)}
                />
              </Grid>
              <Grid mobile={12}>
                <StyledTextField
                  label="Category Comments"
                  InputLabelProps={{ shrink: true }}
                  multiline
                  disabled={!!isReaderRole || !editable || isEditableComment}
                  fullWidth
                  rows={3}
                  {...register("category_comments")}
                  onBlur={handleBlur("category_comments", true)}
                />
              </Grid>
              <Grid mobile={12}>
                <StyledTextField
                  label="Category Keywords"
                  InputLabelProps={{ shrink: true }}
                  multiline
                  disabled={!!isReaderRole || !editable || isEditableKey}
                  fullWidth
                  rows={3}
                  {...register("category_keywords")}
                  onBlur={handleBlur("category_keywords")}
                /> */}
              {categoryAttributes.map((attr) => {
                switch (attr.keyName) {
                  case "category_type":
                    return (
                      <Grid mobile={12}>
                        <StyledTextField
                          fullWidth
                          disabled={!!isReaderRole || !editable || isEditableType}
                          label="Category Type*"
                          InputLabelProps={{ shrink: true }}
                          select
                          SelectProps={{ native: true }}
                          {...register("category_type", {
                            required: true,
                          })}
                        >
                          {!!dropDownValueMap.category_type &&
                            dropDownValueMap.category_type.map((option) => (
                              <option key={option.id} value={option.value}>
                                {option.value}
                              </option>
                            ))}
                        </StyledTextField>
                      </Grid>
                    )
                  case "category_name":
                    return renderMinLengthField(
                      "category_name",
                      "category-name-input",
                      true,
                      3,
                      true
                    )
                  case "category_marketing_name":
                    return renderMinLengthField(
                      "category_marketing_name",
                      "category-marketing-name-input",
                      true,
                      3,
                      true
                    )
                  case "category_id":
                    return (categoryIdAttribute && (
                      <Grid mobile={12}>
                        <StyledTextField
                          fullWidth
                          disabled={isEditableBoolean}
                          label={categoryIdAttribute.name}
                          value={categoryIdAttribute.value}
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                    ))
                  case "do_not_show":
                    return (
                      <Grid mobile={12}>
                        <StyledTextField
                          fullWidth
                          disabled={!!isReaderRole || !editable || isEditableShow}
                          label="Do Not Show"
                          InputLabelProps={{ shrink: true }}
                          select
                          SelectProps={{ native: true }}
                          {...register("do_not_show")}
                        >
                          {!!dropDownValueMap.do_not_show &&
                            dropDownValueMap.do_not_show.map((option) => (
                              <option key={option.id} value={option.value}>
                                {option.value}
                              </option>
                            ))}
                        </StyledTextField>
                      </Grid>
                    )
                  case "category_description":
                    return (
                      <Grid mobile={12}>
                        <StyledTextField
                          label="Category Description"
                          InputLabelProps={{ shrink: true }}
                          multiline
                          fullWidth
                          disabled={!!isReaderRole || !editable || isEditableDesc}
                          rows={3}
                          {...register("category_description")}
                          onBlur={handleBlur("category_description", true)}
                        />
                      </Grid>
                    )
                  case "category_comments":
                    return (
                      <Grid mobile={12}>
                        <StyledTextField
                          label="Category Comments"
                          InputLabelProps={{ shrink: true }}
                          multiline
                          disabled={!!isReaderRole || !editable || isEditableComment}
                          fullWidth
                          rows={3}
                          {...register("category_comments")}
                          onBlur={handleBlur("category_comments", true)}
                        />
                      </Grid>
                    )
                  case "category_keywords":
                    return (
                      <Grid mobile={12}>
                        <StyledTextField
                          label="Category Keywords"
                          InputLabelProps={{ shrink: true }}
                          multiline
                          disabled={!!isReaderRole || !editable || isEditableKey}
                          fullWidth
                          rows={3}
                          {...register("category_keywords")}
                          onBlur={handleBlur("category_keywords")}
                        />
                      </Grid>
                    )
                  default:
                    return null;
                }
              })}
            </Grid>
            {!isReaderRole && editable && (
              <Grid
                mobile={12}
                sx={{
                  p: 0,
                  my: 4,
                  justifyContent: "space-between",
                  display: "flex",
                }}
              >
                <StyledSaveButton
                  type="submit"
                  variant="contained"
                  size="large"
                >
                  Save
                </StyledSaveButton>
                {!categoryId ? (
                  <StyledSecondaryButton
                    variant="contained"
                    size="large"
                    aria-label="Reset"
                    disabled={!isDirty}
                    onClick={() => reset()}
                  >
                    Reset
                  </StyledSecondaryButton>
                ) : (
                  <StyledDangerButton
                    variant="contained"
                    size="large"
                    aria-label="cancel"
                    disabled={!isDirty}
                    onClick={handleCancel}
                  >
                    Cancel
                  </StyledDangerButton>
                )}
              </Grid>
            )}
          </form>
        </Grid>
      </Grid>
      <UnsavedChangesModal
        open={showUnsavedChangesModal}
        onClose={() => setShowUnsavedChangesModal(false)}
        onLeave={handleLeaveNavigation}
      />
      <ResendFormModal
        open={isResendModalOpen}
        onResend={() => {
          const formValues = getValues();
          onSubmit(formValues);
          setIsResendModalOpen(false);
        }}
        onCancel={() => setIsResendModalOpen(false)}
      />
      <SuccessAlertSnackbar
        message={successMessage}
        duration={3000}
        onClose={handleSaveSuccess}
      />
      <ErrorAlertSnackbar
        message={errorMessage}
        onClose={() => setErrorMessage(null)}
      />
    </StyledRelativeContainer>
  );
};

const StyledTextField = styled(TextField)(({ theme }) => ({
  "&& .MuiInputLabel-root": {
    color: "#707070",
  },
  "& .MuiInputLabel-shrink": {
    fontSize: theme.typography.large.fontSize,
    fontFamily: theme.typography.large.fontFamily,
    fontWeight: theme.typography.large.fontWeight,
    lineHeight: theme.typography.large.lineHeight,
  },
  "& .MuiOutlinedInput-root legend": {
    fontSize: "0.85em",
  },
}));
const StyledRelativeContainer = styled(Grid)({
  margin: 0,
  position: "relative",
});
const StyledSaveButton = styled(Button)(({ theme }) => ({
  color: "#000000",
  fontSize: theme.typography.largeBold.fontSize,
  fontFamily: theme.typography.largeBold.fontFamily,
  fontWeight: theme.typography.largeBold.fontWeight,
  textTransform: "none",
}));
export const StyledDangerButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#DA291C",
  "&:hover": {
    backgroundColor: "#DA291C",
  },
  "&:focus": {
    backgroundColor: "#DA291C",
  },
  "&:active": {
    backgroundColor: "#DA291C",
  },
  fontSize: theme.typography.largeBold.fontSize,
  fontFamily: theme.typography.largeBold.fontFamily,
  fontWeight: theme.typography.largeBold.fontWeight,
  textTransform: "none",
}));
