import { AxiosHeaders } from "axios";
import {
  realGetViewStatus,
  realBulkFileUpload,
  realDeleteFooterId,
  realDeletePushLive,
  realDeleteUOM,
  realDeleteUser,
  realEditInstantPushLive,
  realEditPushLive,
  realEditSchedulePushLive,
  realGetAllRolesByCountry,
  realGetApprovedChanges,
  realGetCustomFooters,
  realGetPushLiveDetails,
  realGetPushliveEventDetails,
  realGetRolesAndPrivileges,
  realGetTimeZones,
  realGetUOMByCountry,
  realGetUnapprovedChanges,
  realGetUserDetailsById,
  realGetUsersData,
  realInstantPushLive,
  realRevertWorkflow,
  realSaveReadyForBulkPushlive,
  realGetExceptionReportData,
  realSaveUOM,
  realSaveUser,
  realSaveCustomFooters,
  realSchedulePushLive,
  realChangeRoleStatus,
  realChangePrivilegeStatus,
  realSaveRole,
  realGetWorkflowManagementData,
  realSaveAddedStepForWorkflowManagement,
  realDeleteWorkflowStep,
  realSaveExceptionsReportData,
  realGetViewRelationshipDetails,
  realGetEditRelationshipDetails,
  realSaveRelationship,
  realDeleteRelationshipType,
  realDeleteRelationship,
  realGetNutritionFactsByCountry,
  realSaveDisplayOrder,
  realSaveNutrient,
  realDeleteByNutritionFactID,
  realSaveElementDisplayOrder,
  realGetViewLogo,
  realDeleteLogo,
  realSaveLogoDetails,
  realSaveStatusFlag,
  realDeleteStatusFlag,
  realGetManageAttributes,
  realSaveAttributeDisplayOrder,
} from "../api/miscellaneous";
import { delay } from ".";

export const mockGetPushLiveDetails: ReturnType<
  typeof realGetPushLiveDetails
> = async () => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful",
      dataList: [
        {
          pushLiveEventId: "P_CH_1001",
          pushLiveEventName: "CH Push Live Event",
          initiatedBy: "e1598526(Test User)",
          status: "Pending",
          scheduledTime: "2024-01-13 23:15:17.0",
        },
        {
          pushLiveEventId: "P_CH_1000",
          pushLiveEventName: "CH Push Live Event",
          initiatedBy: "em491398(Sunita Kumari)",
          status: "Completed",
          scheduledTime: "2024-01-12 15:07:41.0",
        },
        {
          pushLiveEventId: "P_CH_999",
          pushLiveEventName: "CH Push Live Event",
          initiatedBy: "em491398(Sunita Kumari)",
          status: "Completed",
          scheduledTime: "2024-01-12 14:50:18.0",
        },
        {
          pushLiveEventId: "P_CH_998",
          pushLiveEventName: "CH Push Live Event",
          initiatedBy: "em491398(Sunita Kumari)",
          status: "Completed",
          scheduledTime: "2024-01-12 14:23:44.0",
        },
        {
          pushLiveEventId: "P_CH_997",
          pushLiveEventName: "CH Push Live Event",
          initiatedBy: "em491398(Sunita Kumari)",
          status: "Completed",
          scheduledTime: "2024-01-12 13:02:15.0",
        },
        {
          pushLiveEventId: "P_CH_996",
          pushLiveEventName: "CH Push Live Event",
          initiatedBy: "eg938245(Navya Boddu)",
          status: "Completed",
          scheduledTime: "2024-01-12 12:17:22.0",
        },
      ],
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};
export const mockGetApprovedChanges: ReturnType<
  typeof realGetApprovedChanges
> = async () => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful",
      dataList: {
        Categories_and_Meal_Bundles: [
          {
            elementId: 100003,
            elementName: "Burgers, Wraps & Nuggets",
          },
          {
            elementId: 100004,
            elementName: "Snacks & Sauces",
          },
          {
            elementId: 100005,
            elementName: "McDeal",
          },
          {
            elementId: 100006,
            elementName: "Desserts",
          },
          {
            elementId: 100007,
            elementName: "Happy Meal",
          },
          {
            elementId: 100008,
            elementName: "Salate",
          },
          {
            elementId: 100015,
            elementName: "Beef",
          },
          {
            elementId: 100017,
            elementName: "Fish",
          },
          {
            elementId: 100018,
            elementName: "Veggie",
          },
          {
            elementId: 100019,
            elementName: "Glutenfrei",
          },
          {
            elementId: 100020,
            elementName: "Kalte Getränke",
          },
          {
            elementId: 100021,
            elementName: "Heisse Getränke",
          },
          {
            elementId: 100022,
            elementName: "Menu",
          },
          {
            elementId: 100023,
            elementName: "Chicken and sandwich",
          },
          {
            elementId: 100024,
            elementName: "Burger mocha a",
          },
        ],
        Menu_Item: [
          {
            elementId: 200529,
            elementName: "Lemon Strussel",
          },
        ],
        Products: [
          {
            elementId: 300001,
            elementName: "Testing Mutex Group",
          },
          {
            elementId: 300002,
            elementName: "Testing mutex",
          },
          {
            elementId: 300004,
            elementName: "Test mutex product",
          },
          {
            elementId: 300005,
            elementName: "Test new product 3",
          },
        ],
      },
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};
export const mockGetUnapprovedChanges: ReturnType<
  typeof realGetUnapprovedChanges
> = async () => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful",
      dataList: {
        Categories_and_Meal_Bundles: [],
        Menu_Item: [
          {
            elementId: 200504,
            elementName: "Daim® Donut ",
          },
        ],
        Products: [],
      },
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};
export const mockEditPushLive: ReturnType<
  typeof realEditPushLive
> = async () => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful",
      dataList: {
        Categories_and_Meal_Bundles: [
          {
            elementId: 100009,
            elementName: "McCafé",
          },
          {
            elementId: 100016,
            elementName: "Chicken",
          },
        ],
        Menu_Item: [
          {
            elementId: 200529,
            elementName: "Lemon Strussel",
          },
        ],
        Products: [
          {
            elementId: 300003,
            elementName: "Ice product",
          },
          {
            elementId: 300006,
            elementName: "Test promotional",
          },
        ],
      },
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};
export const mockGetPushliveEventDetails: ReturnType<
  typeof realGetPushliveEventDetails
> = async () => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful",
      dataList: {
        Categories_and_Meal_Bundles: [
          {
            elementId: 100009,
            elementName: "McCafé",
          },
          {
            elementId: 100016,
            elementName: "Chicken",
          },
        ],
        Menu_Item: [
          {
            elementId: 200529,
            elementName: "Lemon Strussel",
          },
        ],
        Products: [
          {
            elementId: 300003,
            elementName: "Ice product",
          },
          {
            elementId: 300006,
            elementName: "Test promotional",
          },
        ],
      },
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockGetTimeZones: ReturnType<
  typeof realGetTimeZones
> = async () => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful.",
      dataList: [
        {
          timeZoneName: "US - Eastern Time (UTC -5:00)",
          timeZoneId: 34,
        },
        {
          timeZoneName: "US - Central Time (UTC -6:00)",
          timeZoneId: 35,
        },
      ],
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};
export const mockDeletePushLive: ReturnType<
  typeof realDeletePushLive
> = async () => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful.",
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};
export const mockSaveReadyForBulkPushlive: ReturnType<
  typeof realSaveReadyForBulkPushlive
> = async (body) => {
  await delay(2);
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      status: "200",
      message: "Save Ready For Bulk Push live was saved successfully",
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockRevertWorkflow: ReturnType<typeof realRevertWorkflow> = async (
  body
) => {
  await delay(2);
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "Push Live was saved successfully",
      approvedFlag: "Y",
      dataList: [100000, 200002],
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};
export const mockSchedulePushLive: ReturnType<
  typeof realSchedulePushLive
> = async (body) => {
  await delay(2);
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "Push Live was saved successfully",
      newEvent: "Y",
      eventType: "scheduledType",
      timeZoneId: 34,
      scheduleTime: "2023-10-11T10:15:00Z",
      reason: "Test Schedule Latest",
      elementList: [200610, 200115],
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};
export const mockEditSchedulePushLive: ReturnType<
  typeof realEditSchedulePushLive
> = async (body) => {
  await delay(2);
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "Push Live was saved successfully",
      pushLiveEventId: "P_US_4383",
      newEvent: "N",
      eventType: "scheduledType",
      timeZoneId: 34,
      scheduleTime: "2023-10-11T10:15:00Z",
      reason: "Test Schedule Latest",
      elementList: [200610, 200115],
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};
export const mockInstantPushLive: ReturnType<
  typeof realInstantPushLive
> = async (body) => {
  await delay(2);
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "Push Live was saved successfully",
      newEvent: "Y",
      eventType: "instantType",
      timeZoneId: 34,
      reason: "Test Instant Latest",
      elementList: [200610, 200115],
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};
export const mockEditInstantPushLive: ReturnType<
  typeof realEditInstantPushLive
> = async (body) => {
  await delay(2);
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "Push Live was saved successfully",
      pushLiveEventId: "P_US_4383",

      newEvent: "N",
      eventType: "instantType",
      timeZoneId: 34,
      reason: "Test Instant Latest",
      elementList: [200610, 200115],
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockGetUOMByCountry: ReturnType<
  typeof realGetUOMByCountry
> = async () => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful.",
      uomlist: {
        EN: [
          {
            uomId: 2,
            uom: "oz",
            uomDesc: "oz",
            measurementType: "metric",
          },
          {
            uomId: 3,
            uom: "fl oz",
            uomDesc: "fl oz",
            measurementType: "metric",
          },
        ],
        FR: [
          {
            uomId: 1,
            uom: "Cals",
            uomDesc: "calories",
            measurementType: "metric",
          },
          {
            uomId: 2,
            uom: "g",
            uomDesc: "gram",
            measurementType: "metric",
          },
        ],
      },
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};
export const mockDeleteUOM: ReturnType<typeof realDeleteUOM> = async () => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful.",
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};
export const mockSaveUOM: ReturnType<typeof realSaveUOM> = async (body) => {
  await delay(2);
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      status: "200",
      message: "UOM was saved successfully",
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};
export const mockBulkFileUpload: ReturnType<typeof realBulkFileUpload> = async (
  body
) => {
  await delay(2);
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "Item file uploaded successfully.",
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockSaveLogoDetails: ReturnType<
  typeof realSaveLogoDetails
> = async () => {
  await delay(2);
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "Logo was saved successfully",
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockDeleleteLogo: ReturnType<typeof realDeleteLogo> = async () => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful.",
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockGetViewLogo: ReturnType<typeof realGetViewLogo> = async () => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful.",
      viewLogo: [
        {
          logoId: 1,
          logoName: "McDonalds",
          logoImage: "McDonalds",
          url: "https://www.mcdonalds.com",
          altText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam",
        },
        {
          logoId: 2,
          logoName: "Mcdonalds 1",
          logoImage: "Mcdonalds 1",
          url: "https://www.mcdonalds.com",
          altText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam",
        },
      ],
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};
export const mockGetCustomFooters: ReturnType<
  typeof realGetCustomFooters
> = async () => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful.",
      footerList: [
        {
          footerText: "US - Eastern Time (UTC -5:00)",
          footerId: 734,
        },
        {
          footerText:
            "Does not include values of optional condiments. Please refer to Condiments & Sauces section.",
          footerId: 735,
        },
      ],
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};
export const mockGetUsersData: ReturnType<
  typeof realGetUsersData
> = async () => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful.",
      userList: {
        existingUserList: [
          {
            eId: "e0018409",
            name: "Bulk Upload Utility",
            email: "dummy@email.com",
            country: "UNITED STATES",
          },
          {
            eId: "e0018413",
            name: "DNA_Business_User_1",
            email: "dummy@email.com",
            country: "UNITED STATES",
          },
        ],
        userPendingForApprovalList: [
          {
            eId: "e7355874",
            name: "Azanza Cristina",
            email: "cristina.azanza@almaad.com",
            country: "UNITED STATES",
          },
          {
            eId: "e7691318",
            name: "Higgins Patrick",
            email: "patrick.higgins@us.mcd.com",
            country: "UNITED STATES",
          },
        ],
      },
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};
export const mockGetAllRolesByCountry: ReturnType<
  typeof realGetAllRolesByCountry
> = async () => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful.",
      role: [
        {
          roleId: 2,
          roleName: "Country Administrator",
        },
        {
          roleId: 3,
          roleName: "Nutritionist",
        },
        {
          roleId: 4,
          roleName: "Marketing",
        },
      ],
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockSaveUser: ReturnType<typeof realSaveUser> = async (body) => {
  await delay(2);
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      status: "200",
      message: "User was saved successfully",
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};
export const mockSaveCustomFooters: ReturnType<
  typeof realSaveCustomFooters
> = async (body) => {
  await delay(2);
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      status: "200",
      message: "Footer was saved successfully",
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockGetUserDetailsById: ReturnType<
  typeof realGetUserDetailsById
> = async ({ countryCode = "US", userId }) => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful.",
      userData: {
        eid: "eg945147",
        firstName: "test",
        lastName: "user",
        email: "test@email.com",
        roleIds: "2,3",
        remarks: "stage user",
      },
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};
export const mockDeleteFooterId: ReturnType<
  typeof realDeleteFooterId
> = async () => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful.",
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};
export const mockDeleteUser: ReturnType<typeof realDeleteUser> = async () => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful.",
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};
export const mockGetExceptionReportData: ReturnType<
  typeof realGetExceptionReportData
> = async () => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful.",
      dataList: [
        {
          action: "Created",
          exceptionId: 123,
          failedRecord: "Broken record",
          reason: "Broken record",
          type: "Product",
          updatedOn: "2024-01-01 00:00:00",
        },
        {
          action: "Archive",
          exceptionId: 123,
          failedRecord: "Broken record",
          reason: "Broken record",
          type: "Product",
          updatedOn: "2024-01-01 00:00:00",
          archiveInfo: {
            archiveBy: "John Smith",
            archiveDate: "2024-01-01 00:00:00",
            archiveReason: "OK",
          },
        },
      ],
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};
export const mockSaveExceptionsReportData: ReturnType<
  typeof realSaveExceptionsReportData
> = async () => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "Information has been saved successfully.",
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockSaveNutrient: ReturnType<
  typeof realSaveNutrient
> = async () => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "Data has been saved successfully.",
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockSaveDisplayOrder: ReturnType<
  typeof realSaveDisplayOrder
> = async () => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "Information has been saved successfully.",
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockDeleteByNutritionFactID: ReturnType<
  typeof realDeleteByNutritionFactID
> = async () => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "Data has been deleted successfully.",
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockGetNutritionFactsByCountry: ReturnType<
  typeof realGetNutritionFactsByCountry
> = async () => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful.",
      nutrientFacts: {
        EN: [
          {
            nutrientFactId: 18,
            nutrientFactName: "Secondary Serving Size",
            displayOrder: 1,
            uom: "g",
            womenGDA: null,
            menGDA: null,
            childGDA: null,
          },
          {
            nutrientFactId: 17,
            nutrientFactName: "Primary Serving Size",
            displayOrder: 2,
            uom: "oz",
            womenGDA: null,
            menGDA: null,
            childGDA: null,
          },
          {
            nutrientFactId: 23,
            nutrientFactName: "Calories From Fat",
            displayOrder: 3,
            uom: "Cal.",
            womenGDA: null,
            menGDA: null,
            childGDA: null,
          },
        ],
        ES: [
          {
            nutrientFactId: 18,
            nutrientFactName: "Secondary Serving Size",
            displayOrder: 1,
            uom: "g",
            womenGDA: null,
            menGDA: null,
            childGDA: null,
          },
          {
            nutrientFactId: 17,
            nutrientFactName: "Primary Serving Size",
            displayOrder: 2,
            uom: "oz",
            womenGDA: null,
            menGDA: null,
            childGDA: null,
          },
          {
            nutrientFactId: 23,
            nutrientFactName: "Calories From Fat",
            displayOrder: 3,
            uom: "Cal.",
            womenGDA: null,
            menGDA: null,
            childGDA: null,
          },
        ],
      },
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockGetRolesAndPrivileges: ReturnType<
  typeof realGetRolesAndPrivileges
> = async () => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful.",
      dataList: [
        {
          roleId: 2,
          roleName: "Country Administrator",
          isEnable: "Y",
          privilegeList: [
            {
              isEnable: "Y",
              privilegeId: 38,
              privilegeName: "Administrative Task",
            },
            {
              isEnable: "Y",
              privilegeId: 49,
              privilegeName: "Audit Trail",
            },
            {
              isEnable: "Y",
              privilegeId: 5,
              privilegeName: "Categories & Meal Bundles",
            },
            {
              isEnable: "N",
              privilegeId: 2,
              privilegeName: "Country Management",
            },
            {
              isEnable: "Y",
              privilegeId: 90,
              privilegeName: "Exceptions Report",
            },
            {
              isEnable: "Y",
              privilegeId: 8,
              privilegeName: "Items",
            },
            {
              isEnable: "Y",
              privilegeId: 32,
              privilegeName: "Marketing task",
            },
            {
              isEnable: "Y",
              privilegeId: 34,
              privilegeName: "Nutritionist Task",
            },
            {
              isEnable: "Y",
              privilegeId: 50,
              privilegeName: "Products",
            },
            {
              isEnable: "Y",
              privilegeId: 48,
              privilegeName: "Push Live Console",
            },
          ],
        },
        {
          roleId: 5,
          roleName: "Legal",
          isEnable: "Y",
          privilegeList: [
            {
              isEnable: "N",
              privilegeId: 38,
              privilegeName: "Administrative Task",
            },
            {
              isEnable: "Y",
              privilegeId: 49,
              privilegeName: "Audit Trail",
            },
            {
              isEnable: "N",
              privilegeId: 5,
              privilegeName: "Categories & Meal Bundles",
            },
            {
              isEnable: "N",
              privilegeId: 2,
              privilegeName: "Country Management",
            },
            {
              isEnable: "N",
              privilegeId: 90,
              privilegeName: "Exceptions Report",
            },
            {
              isEnable: "N",
              privilegeId: 8,
              privilegeName: "Items",
            },
            {
              isEnable: "N",
              privilegeId: 32,
              privilegeName: "Marketing task",
            },
            {
              isEnable: "N",
              privilegeId: 34,
              privilegeName: "Nutritionist Task",
            },
            {
              isEnable: "N",
              privilegeId: 50,
              privilegeName: "Products",
            },
            {
              isEnable: "Y",
              privilegeId: 48,
              privilegeName: "Push Live Console",
            },
          ],
        },
        {
          roleId: 4,
          roleName: "Marketing",
          isEnable: "Y",
          privilegeList: [
            {
              isEnable: "N",
              privilegeId: 38,
              privilegeName: "Administrative Task",
            },
            {
              isEnable: "Y",
              privilegeId: 49,
              privilegeName: "Audit Trail",
            },
            {
              isEnable: "Y",
              privilegeId: 5,
              privilegeName: "Categories & Meal Bundles",
            },
            {
              isEnable: "N",
              privilegeId: 2,
              privilegeName: "Country Management",
            },
            {
              isEnable: "Y",
              privilegeId: 90,
              privilegeName: "Exceptions Report",
            },
            {
              isEnable: "Y",
              privilegeId: 8,
              privilegeName: "Items",
            },
            {
              isEnable: "Y",
              privilegeId: 32,
              privilegeName: "Marketing task",
            },
            {
              isEnable: "N",
              privilegeId: 34,
              privilegeName: "Nutritionist Task",
            },
            {
              isEnable: "Y",
              privilegeId: 50,
              privilegeName: "Products",
            },
            {
              isEnable: "Y",
              privilegeId: 48,
              privilegeName: "Push Live Console",
            },
          ],
        },
        {
          roleId: 3,
          roleName: "Nutritionist",
          isEnable: "Y",
          privilegeList: [
            {
              isEnable: "N",
              privilegeId: 38,
              privilegeName: "Administrative Task",
            },
            {
              isEnable: "Y",
              privilegeId: 49,
              privilegeName: "Audit Trail",
            },
            {
              isEnable: "Y",
              privilegeId: 5,
              privilegeName: "Categories & Meal Bundles",
            },
            {
              isEnable: "N",
              privilegeId: 2,
              privilegeName: "Country Management",
            },
            {
              isEnable: "Y",
              privilegeId: 90,
              privilegeName: "Exceptions Report",
            },
            {
              isEnable: "Y",
              privilegeId: 8,
              privilegeName: "Items",
            },
            {
              isEnable: "N",
              privilegeId: 32,
              privilegeName: "Marketing task",
            },
            {
              isEnable: "Y",
              privilegeId: 34,
              privilegeName: "Nutritionist Task",
            },
            {
              isEnable: "Y",
              privilegeId: 50,
              privilegeName: "Products",
            },
            {
              isEnable: "Y",
              privilegeId: 48,
              privilegeName: "Push Live Console",
            },
          ],
        },
        {
          roleId: 9,
          roleName: "Reader",
          isEnable: "Y",
          privilegeList: [
            {
              isEnable: "N",
              privilegeId: 38,
              privilegeName: "Administrative Task",
            },
            {
              isEnable: "Y",
              privilegeId: 49,
              privilegeName: "Audit Trail",
            },
            {
              isEnable: "N",
              privilegeId: 5,
              privilegeName: "Categories & Meal Bundles",
            },
            {
              isEnable: "N",
              privilegeId: 2,
              privilegeName: "Country Management",
            },
            {
              isEnable: "N",
              privilegeId: 90,
              privilegeName: "Exceptions Report",
            },
            {
              isEnable: "N",
              privilegeId: 8,
              privilegeName: "Items",
            },
            {
              isEnable: "Y",
              privilegeId: 32,
              privilegeName: "Marketing task",
            },
            {
              isEnable: "Y",
              privilegeId: 34,
              privilegeName: "Nutritionist Task",
            },
            {
              isEnable: "N",
              privilegeId: 50,
              privilegeName: "Products",
            },
            {
              isEnable: "Y",
              privilegeId: 48,
              privilegeName: "Push Live Console",
            },
          ],
        },
      ],
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockSaveRole: ReturnType<typeof realSaveRole> = async (body) => {
  await delay(2);
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "Role was saved successfully",
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockChangeRoleStatus: ReturnType<
  typeof realChangeRoleStatus
> = async () => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful.",
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockChangePrivilegeStatus: ReturnType<
  typeof realChangePrivilegeStatus
> = async () => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful.",
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockGetWorkflowManagementData: ReturnType<
  typeof realGetWorkflowManagementData
> = async () => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful.",
      dataList: [
        {
          elementId: 1,
          elementType: "Category",
          workFlowList: [
            {
              workFlowId: 3,
              workFlowName: "Two Workflow",
              workFlowStepsList: [
                {
                  level: 1,
                  stepName: "Marketing ",
                  role: "Marketing",
                  stepDescription: "Marketing Category Step",
                  deleteEnable: "No",
                },
              ],
            },
            {
              workFlowId: 5,
              workFlowName: "All Team Workflow",
              workFlowStepsList: [
                {
                  level: 1,
                  stepName: "Marketing",
                  role: "Marketing",
                  stepDescription: "Marketing Category Step",
                  deleteEnable: "No",
                },
                {
                  level: 2,
                  stepName: "Nutritionist",
                  role: "Nutritionist",
                  stepDescription: "Nutritionist Category Step",
                  deleteEnable: "No",
                },
                {
                  level: 3,
                  stepName: "Country Administrator",
                  role: "Country Administrator",
                  stepDescription: "Step Description data ",
                  deleteEnable: "No",
                },
              ],
            },
          ],
        },
        {
          elementId: 2,
          elementType: "Item",
          workFlowList: [
            {
              workFlowId: 7,
              workFlowName: "Single Workflow",
              workFlowStepsList: [
                {
                  level: 1,
                  stepName: "Marketing",
                  role: "Marketing",
                  stepDescription: "Marketing Item ",
                  deleteEnable: "No",
                },
              ],
            },
            {
              workFlowId: 8,
              workFlowName: "Two Workflow",
              workFlowStepsList: [
                {
                  level: 1,
                  stepName: "Marketing",
                  role: "Marketing",
                  stepDescription: "Marketing Item Step",
                  deleteEnable: "No",
                },
              ],
            },
            {
              workFlowId: 9,
              workFlowName: "Multiple workflow",
              workFlowStepsList: [
                {
                  level: 1,
                  stepName: "Marketing",
                  role: "Marketing",
                  stepDescription: "Marketing Item Step",
                  deleteEnable: "No",
                },
                {
                  level: 2,
                  stepName: "Nutritionist",
                  role: "Nutritionist",
                  stepDescription: "Nutritionist Item Step",
                  deleteEnable: "No",
                },
              ],
            },
            {
              workFlowId: 10,
              workFlowName: "All Team Workflow",
              workFlowStepsList: [
                {
                  level: 2,
                  stepName: "Nutritionist",
                  role: "Nutritionist",
                  stepDescription: "Nutritionist Item Step",
                  deleteEnable: "No",
                },
                {
                  level: 3,
                  stepName: "Country Administrator",
                  role: "Country Administrator",
                  stepDescription: "Step Description data ",
                  deleteEnable: "No",
                },
              ],
            },
          ],
        },
        {
          elementId: 3,
          elementType: "Product",
          workFlowList: [
            {
              workFlowId: 12,
              workFlowName: "Single Workflow",
              workFlowStepsList: [
                {
                  level: 1,
                  stepName: "Marketing",
                  role: "Marketing",
                  stepDescription: "Marketing Product Step",
                  deleteEnable: "No",
                },
              ],
            },
            {
              workFlowId: 13,
              workFlowName: "Two Workflow",
              workFlowStepsList: [
                {
                  level: 1,
                  stepName: "Marketing",
                  role: "Marketing",
                  stepDescription: "Marketing Product Step",
                  deleteEnable: "Yes",
                },
                {
                  level: 2,
                  stepName: "Nutritionist",
                  role: "Nutritionist",
                  stepDescription: "Nutritionist Product Step",
                  deleteEnable: "Yes",
                },
                {
                  level: 3,
                  stepName: "Country Administrator",
                  role: "Country Administrator",
                  stepDescription: "Step Description data ",
                  deleteEnable: "Yes",
                },
              ],
            },
            {
              workFlowId: 14,
              workFlowName: "Multiple workflow",
              workFlowStepsList: [
                {
                  level: 2,
                  stepName: "Nutritionist",
                  role: "Nutritionist",
                  stepDescription: "Nutritionist Product Step",
                  deleteEnable: "No",
                },
              ],
            },
            {
              workFlowId: 15,
              workFlowName: "All Team Workflow",
              workFlowStepsList: [
                {
                  level: 1,
                  stepName: "Marketing",
                  role: "Marketing",
                  stepDescription: "Marketing Product Step",
                  deleteEnable: "No",
                },
                {
                  level: 2,
                  stepName: "Nutritionist",
                  role: "Nutritionist",
                  stepDescription: "Nutritionist Product Step",
                  deleteEnable: "No",
                },
                {
                  level: 3,
                  stepName: "Country Administrator",
                  role: "Country Administrator",
                  stepDescription: "Step Description data ",
                  deleteEnable: "No",
                },
              ],
            },
          ],
        },
      ],
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockSaveAddedStepForWorkflowManagement: ReturnType<
  typeof realSaveAddedStepForWorkflowManagement
> = async (body) => {
  await delay(2);
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "Workflow Steps are saved successfully.",
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};
export const mockDeleteWorkflowStep: ReturnType<
  typeof realDeleteWorkflowStep
> = async () => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "Workflow step is deleted successfully.",
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockGetViewRelationshipDetails: ReturnType<
  typeof realGetViewRelationshipDetails
> = async () => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful.",
      relationshipList: [
        {
          relationTypeId: 101,
          relationTypeName: "Coca-Cola Size",
          laguageRelationList: {
            DE: "Mini,Small,Medium",
            EN: "Mini,Small,Medium",
            IT: "Mini,Small,Medium",
            FR: "Mini,Small,Medium",
          },
        },
        {
          relationTypeId: 102,
          relationTypeName: "McFlurry Size",
          laguageRelationList: {
            DE: "Regular,Maxi",
            EN: "Regular,Maxi",
            IT: "Regular,Maxi",
            FR: "Regular,Maxi",
          },
        },
        {
          relationTypeId: 103,
          relationTypeName: "size",
          laguageRelationList: {
            DE: "Mini,Small,Medium,Regular,Maxi,Stück,Stück,Stück,Stück,Doppio,Stück ,Stück ,Stück ",
            EN: "Mini,Small,Medium,Regular,Maxi,Pieces,Pieces,Pieces,Pieces,Doppio,Pieces ,Pieces ,Pieces ",
            IT: "Mini,Small,Medium,Regular,Maxi,Pezzi,Pezzi,Pezzi,Pezzi,Doppio,Pezzi ,Pezzi ,Pezzi ",
            FR: "Mini,Small,Medium,Regular,Maxi,Pièces,Pièces,Pièces,Pièces,Doppio,Pièces ,Pièces ,Pièces ",
          },
        },
      ],
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockGetEditRelationshipDetails: ReturnType<
  typeof realGetEditRelationshipDetails
> = async () => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful.",
      relationTypeName: "size",
      dataList: [
        {
          relationId: 692,
          languageData: {
            DE: {
              value: "Mini",
              abbreviationValue: "Min",
            },
            EN: {
              value: "Mini",
              abbreviationValue: "Min",
            },
            IT: {
              value: "Mini",
              abbreviationValue: "Min",
            },
            FR: {
              value: "Mini",
              abbreviationValue: "Min",
            },
          },
        },
        {
          relationId: 694,
          languageData: {
            DE: {
              value: "Small",
              abbreviationValue: "Sma",
            },
            EN: {
              value: "Small",
              abbreviationValue: "Sma",
            },
            IT: {
              value: "Small",
              abbreviationValue: "Sma",
            },
            FR: {
              value: "Small",
              abbreviationValue: "Sma",
            },
          },
        },
        {
          relationId: 696,
          languageData: {
            DE: {
              value: "Medium",
              abbreviationValue: "Med",
            },
            EN: {
              value: "Medium",
              abbreviationValue: "Med",
            },
            IT: {
              value: "Medium",
              abbreviationValue: "Med",
            },
            FR: {
              value: "Medium",
              abbreviationValue: "Med",
            },
          },
        },
        {
          relationId: 698,
          languageData: {
            DE: {
              value: "Regular",
              abbreviationValue: "Reg",
            },
            EN: {
              value: "Regular",
              abbreviationValue: "Reg",
            },
            IT: {
              value: "Regular",
              abbreviationValue: "Reg",
            },
            FR: {
              value: "Regular",
              abbreviationValue: "Reg",
            },
          },
        },
        {
          relationId: 699,
          languageData: {
            DE: {
              value: "Maxi",
              abbreviationValue: "Max",
            },
            EN: {
              value: "Maxi",
              abbreviationValue: "Max",
            },
            IT: {
              value: "Maxi",
              abbreviationValue: "Max",
            },
            FR: {
              value: "Maxi",
              abbreviationValue: "Max",
            },
          },
        },
        {
          relationId: 701,
          languageData: {
            DE: {
              value: "Stück",
              abbreviationValue: "4",
            },
            EN: {
              value: "Pieces",
              abbreviationValue: "4",
            },
            IT: {
              value: "Pezzi",
              abbreviationValue: "4",
            },
            FR: {
              value: "Pièces",
              abbreviationValue: "4",
            },
          },
        },
      ],
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockSaveRelationship: ReturnType<
  typeof realSaveRelationship
> = async (body) => {
  await delay(2);
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "Data has been updated successfully.",
      relationshipTypeId: 131,
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockDeleteRelationshipType: ReturnType<
  typeof realDeleteRelationshipType
> = async () => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "Relationship deleted successfully.",
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockDeleteRelationship: ReturnType<
  typeof realDeleteRelationship
> = async () => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "Data has been deleted successfully.",
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockSaveElementDisplayOrder: ReturnType<
  typeof realSaveElementDisplayOrder
> = async (body) => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "Data has been saved successfully.",
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockGetViewStatus: ReturnType<
  typeof realGetViewStatus
> = async () => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful.",
      dataList: [
        {
          id: 1,
          value: "Status 1",
        },
        {
          id: 2,
          value: "Status 2",
        },
      ],
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockSaveStatusFlag: ReturnType<
  typeof realSaveStatusFlag
> = async () => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "Data has been saved successfully.",
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockDeleteStatusFlag: ReturnType<
  typeof realDeleteStatusFlag
> = async () => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "Data has been deleted successfully.",
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockGetManageAttributes: ReturnType<
  typeof realGetManageAttributes
> = async () => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful.",
      dataList: {
        category: [
          {
            id: 1,
            displayOrder: "1",
            attributeName: "Category Type",
            type: "Single",
            uiType: "Dropdown",
            system: "Yes",
            common: "No",
            translatable: "No",
          },
          {
            id: 2,
            displayOrder: "2",
            attributeName: "Category Name",
            type: "Single",
            uiType: "Textbox",
            system: "Yes",
            common: "No",
            translatable: "Yes",
          },
          {
            id: 3,
            displayOrder: "3",
            attributeName: "Category Marketing Name",
            type: "Single",
            uiType: "Textbox",
            system: "Yes",
            common: "No",
            translatable: "Yes",
          },
          {
            id: 5,
            displayOrder: "4",
            attributeName: "Category ID",
            type: "Single",
            uiType: "Textbox",
            system: "Yes",
            common: "No",
            translatable: "No",
          },
          {
            id: 57,
            displayOrder: "6",
            attributeName: "Do Not Show",
            type: "Single",
            uiType: "Dropdown",
            system: "Yes",
            common: "Yes",
            translatable: "No",
          },
          {
            id: 4,
            displayOrder: "7",
            attributeName: "Category Description",
            type: "Single",
            uiType: "Textarea",
            system: "Yes",
            common: "No",
            translatable: "Yes",
          },
          {
            id: 10,
            displayOrder: "8",
            attributeName: "Category Comments",
            type: "Single",
            uiType: "Textarea",
            system: "Yes",
            common: "No",
            translatable: "Yes",
          },
          {
            id: 13,
            displayOrder: "9",
            attributeName: "Category Keywords",
            type: "Single",
            uiType: "Textarea",
            system: "Yes",
            common: "No",
            translatable: "Yes",
          },
        ],
        item: [
          {
            id: 15,
            displayOrder: "1",
            attributeName: "Item Type",
            type: "Single",
            uiType: "Dropdown",
            system: "Yes",
            common: "No",
            translatable: "No",
          },
          {
            id: 16,
            displayOrder: "2",
            attributeName: "Time Of Day",
            type: "Single",
            uiType: "Dropdown",
            system: "Yes",
            common: "No",
            translatable: "No",
          },
          {
            id: 17,
            displayOrder: "3",
            attributeName: "Item Name",
            type: "Single",
            uiType: "Textbox",
            system: "Yes",
            common: "No",
            translatable: "Yes",
          },
          {
            id: 18,
            displayOrder: "4",
            attributeName: "Item Marketing Name",
            type: "Single",
            uiType: "Textbox",
            system: "Yes",
            common: "No",
            translatable: "Yes",
          },
          {
            id: 439,
            displayOrder: "5",
            attributeName: "Short Name",
            type: "Single",
            uiType: "Textbox",
            system: "Yes",
            common: "No",
            translatable: "No",
          },
          {
            id: 201,
            displayOrder: "6",
            attributeName: "Genesis Item Name",
            type: "Single",
            uiType: "Textbox",
            system: "Yes",
            common: "No",
            translatable: "Yes",
          },
          {
            id: 1816,
            displayOrder: "7",
            attributeName: "Is Fortified Menu Item",
            type: "Single",
            uiType: "Checkbox",
            system: "No",
            common: "No",
            translatable: "No",
          },
          {
            id: 21,
            displayOrder: "7",
            attributeName: "Item ID",
            type: "Single",
            uiType: "Textbox",
            system: "Yes",
            common: "No",
            translatable: "No",
          },
          {
            id: 22,
            displayOrder: "8",
            attributeName: "RFM Id / Menu Item No / External Id / Import Id",
            type: "Single",
            uiType: "Textbox",
            system: "Yes",
            common: "No",
            translatable: "No",
          },
          {
            id: 203,
            displayOrder: "9",
            attributeName: "Genesis Menu Item No.",
            type: "Single",
            uiType: "Textbox",
            system: "Yes",
            common: "No",
            translatable: "No",
          },
          {
            id: 58,
            displayOrder: "10",
            attributeName: "Do Not Show",
            type: "Single",
            uiType: "Dropdown",
            system: "Yes",
            common: "Yes",
            translatable: "No",
          },
          {
            id: 200,
            displayOrder: "11",
            attributeName: "Serves",
            type: "Single",
            uiType: "Textbox",
            system: "Yes",
            common: "No",
            translatable: "Yes",
          },
          {
            id: 19,
            displayOrder: "12",
            attributeName: "Item Name Special Symbols",
            type: "Single",
            uiType: "Dropdown",
            system: "Yes",
            common: "No",
            translatable: "No",
          },
          {
            id: 20,
            displayOrder: "13",
            attributeName: "Description",
            type: "Single",
            uiType: "Textarea",
            system: "Yes",
            common: "No",
            translatable: "Yes",
          },
          {
            id: 31,
            displayOrder: "14",
            attributeName: "Item Comments",
            type: "Single",
            uiType: "Textarea",
            system: "Yes",
            common: "No",
            translatable: "Yes",
          },
          {
            id: 28,
            displayOrder: "15",
            attributeName: "Keywords",
            type: "Single",
            uiType: "Textarea",
            system: "Yes",
            common: "No",
            translatable: "Yes",
          },
          {
            id: 60,
            displayOrder: "17",
            attributeName: "Ingredient Statement",
            type: "Single",
            uiType: "Textarea",
            system: "Yes",
            common: "No",
            translatable: "No",
          },
          {
            id: 12,
            displayOrder: "18",
            attributeName: "Additional Text For Ingredient Statement",
            type: "Single",
            uiType: "Textarea",
            system: "Yes",
            common: "No",
            translatable: "No",
          },
          {
            id: 40,
            displayOrder: "19",
            attributeName: "Item Allergen",
            type: "Single",
            uiType: "Textarea",
            system: "Yes",
            common: "No",
            translatable: "Yes",
          },
          {
            id: 30,
            displayOrder: "20",
            attributeName: "Additional Allergen",
            type: "Single",
            uiType: "Textarea",
            system: "Yes",
            common: "No",
            translatable: "No",
          },
          {
            id: 26,
            displayOrder: "22",
            attributeName: "Nutrition Quality Statement Text",
            type: "Single",
            uiType: "Textarea",
            system: "Yes",
            common: "No",
            translatable: "Yes",
          },
          {
            id: 627,
            displayOrder: "25",
            attributeName: "Item Meta Title",
            type: "Single",
            uiType: "Textbox",
            system: "Yes",
            common: "No",
            translatable: "Yes",
          },
          {
            id: 633,
            displayOrder: "26",
            attributeName: "Item Meta Description",
            type: "Single",
            uiType: "Textarea",
            system: "Yes",
            common: "No",
            translatable: "Yes",
          },
          {
            id: 628,
            displayOrder: "27",
            attributeName: "Customization Label",
            type: "Single",
            uiType: "Textbox",
            system: "Yes",
            common: "No",
            translatable: "Yes",
          },
          {
            id: 629,
            displayOrder: "28",
            attributeName: "Customization Button",
            type: "Single",
            uiType: "Textbox",
            system: "Yes",
            common: "No",
            translatable: "Yes",
          },
          {
            id: 630,
            displayOrder: "29",
            attributeName: "Show Product Images?",
            type: "Single",
            uiType: "Checkbox",
            system: "Yes",
            common: "No",
            translatable: "Yes",
          },
          {
            id: 1631,
            displayOrder: "29",
            attributeName: "Status Flag",
            type: "Single",
            uiType: "Dropdown",
            system: "Yes",
            common: "No",
            translatable: "No",
          },
          {
            id: 1566,
            displayOrder: "29",
            attributeName: "Logo 3",
            type: "Single",
            uiType: "Dropdown",
            system: "Yes",
            common: "No",
            translatable: "No",
          },
          {
            id: 1565,
            displayOrder: "29",
            attributeName: "Logo 2",
            type: "Single",
            uiType: "Dropdown",
            system: "Yes",
            common: "No",
            translatable: "No",
          },
          {
            id: 1564,
            displayOrder: "29",
            attributeName: "Logo 1",
            type: "Single",
            uiType: "Dropdown",
            system: "Yes",
            common: "No",
            translatable: "No",
          },
          {
            id: 631,
            displayOrder: "30",
            attributeName: "Specialization Text 1",
            type: "Single",
            uiType: "Textbox",
            system: "Yes",
            common: "No",
            translatable: "Yes",
          },
          {
            id: 632,
            displayOrder: "30",
            attributeName: "Specialization Text 2",
            type: "Single",
            uiType: "Textbox",
            system: "Yes",
            common: "No",
            translatable: "Yes",
          },
          {
            id: 1437,
            displayOrder: "31",
            attributeName: "SiteMap",
            type: "Single",
            uiType: "Checkbox",
            system: "Yes",
            common: "No",
            translatable: "Yes",
          },
          {
            id: 1438,
            displayOrder: "31",
            attributeName: "Frequency",
            type: "Single",
            uiType: "Dropdown",
            system: "Yes",
            common: "No",
            translatable: "Yes",
          },
          {
            id: 1439,
            displayOrder: "31",
            attributeName: "Priority",
            type: "Single",
            uiType: "Dropdown",
            system: "Yes",
            common: "No",
            translatable: "Yes",
          },
          {
            id: 33,
            displayOrder: "32",
            attributeName: "Attach Item Thumbnail Image",
            type: "Multiple",
            uiType: "Image",
            system: "Yes",
            common: "No",
            translatable: "No",
          },
          {
            id: 34,
            displayOrder: "33",
            attributeName: "Attach Item Hero Image",
            type: "Multiple",
            uiType: "Image",
            system: "Yes",
            common: "No",
            translatable: "No",
          },
          {
            id: 634,
            displayOrder: "34",
            attributeName: "Attach Transparent Icon Image",
            type: "Multiple",
            uiType: "Image",
            system: "Yes",
            common: "No",
            translatable: "No",
          },
          {
            id: 38,
            displayOrder: "37",
            attributeName: "Attach Meal Bundle Image ",
            type: "Multiple",
            uiType: "Image",
            system: "Yes",
            common: "No",
            translatable: "No",
          },
          {
            id: 23,
            displayOrder: "38",
            attributeName: "Has Components",
            type: "Single",
            uiType: "Radio Button",
            system: "Yes",
            common: "No",
            translatable: "No",
          },
          {
            id: 41,
            displayOrder: "38",
            attributeName: "Footer",
            type: "Multiple",
            uiType: "Link",
            system: "No",
            common: "No",
            translatable: "Yes",
          },
          {
            id: 24,
            displayOrder: "39",
            attributeName: "Serving Size Imperial",
            type: "Single",
            uiType: "Dropdown",
            system: "Yes",
            common: "No",
            translatable: "No",
          },
          {
            id: 25,
            displayOrder: "40",
            attributeName: "Serving Size Metric",
            type: "Single",
            uiType: "Dropdown",
            system: "Yes",
            common: "No",
            translatable: "No",
          },
        ],
        product: [
          {
            id: 42,
            displayOrder: "1",
            attributeName: "Product Type",
            type: "Single",
            uiType: "Dropdown",
            system: "Yes",
            common: "No",
            translatable: "No",
          },
          {
            id: 43,
            displayOrder: "2",
            attributeName: "Product Name",
            type: "Single",
            uiType: "Textbox",
            system: "Yes",
            common: "No",
            translatable: "Yes",
          },
          {
            id: 44,
            displayOrder: "3",
            attributeName: "Product Marketing Name",
            type: "Single",
            uiType: "Textbox",
            system: "Yes",
            common: "No",
            translatable: "Yes",
          },
          {
            id: 202,
            displayOrder: "4",
            attributeName: "Genesis Product Name",
            type: "Single",
            uiType: "Textbox",
            system: "Yes",
            common: "No",
            translatable: "Yes",
          },
          {
            id: 46,
            displayOrder: "5",
            attributeName: "Product ID",
            type: "Single",
            uiType: "Textbox",
            system: "Yes",
            common: "No",
            translatable: "No",
          },
          {
            id: 47,
            displayOrder: "6",
            attributeName: "Product No.",
            type: "Single",
            uiType: "Textbox",
            system: "Yes",
            common: "No",
            translatable: "No",
          },
          {
            id: 199,
            displayOrder: "7",
            attributeName: "WRIN #",
            type: "Single",
            uiType: "Textbox",
            system: "Yes",
            common: "No",
            translatable: "Yes",
          },
          {
            id: 45,
            displayOrder: "10",
            attributeName: "Ingredient Statement",
            type: "Single",
            uiType: "Textarea",
            system: "Yes",
            common: "No",
            translatable: "Yes",
          },
          {
            id: 50,
            displayOrder: "11",
            attributeName: "Product Allergen",
            type: "Single",
            uiType: "Textarea",
            system: "Yes",
            common: "No",
            translatable: "Yes",
          },
          {
            id: 53,
            displayOrder: "12",
            attributeName: "Additional Text For Ingredient Statement",
            type: "Single",
            uiType: "Textbox",
            system: "Yes",
            common: "No",
            translatable: "Yes",
          },
          {
            id: 59,
            displayOrder: "13",
            attributeName: "Additional Allergen",
            type: "Single",
            uiType: "Textbox",
            system: "Yes",
            common: "No",
            translatable: "Yes",
          },
          {
            id: 51,
            displayOrder: "14",
            attributeName: "Product Comments",
            type: "Single",
            uiType: "Textarea",
            system: "Yes",
            common: "No",
            translatable: "Yes",
          },
          {
            id: 54,
            displayOrder: "16",
            attributeName: "Product Keywords",
            type: "Single",
            uiType: "Textarea",
            system: "Yes",
            common: "No",
            translatable: "Yes",
          },
          {
            id: 55,
            displayOrder: "17",
            attributeName: "Attach Product Thumbnail Image",
            type: "Multiple",
            uiType: "Checkbox",
            system: "Yes",
            common: "No",
            translatable: "No",
          },
        ],
      },
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockSaveAttributeDisplayOrder: ReturnType<
  typeof realSaveAttributeDisplayOrder
> = async (body) => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "Data has been updated successfully.",
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};
