import {
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  TextFieldProps,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useForm } from "react-hook-form";
import { components } from "../data/miscellaneous.types";
import { forwardRef, useCallback, useEffect, useId, useState } from "react";
import { ConfirmActionModal } from "./ConfirmActionModal";

type AddEditLogoFormModalProps = {
  isOpen: boolean;
  onCancel: () => void;
  onSave: (formData: AddEditLogoFormDataType) => void;
  logo?: components["schemas"]["Logo"];
};

export type AddEditLogoFormDataType = Partial<
  components["schemas"]["LogoRequestBody"] & { logoId: number }
>;

export const AddEditLogoFormModal = ({
  isOpen,
  onCancel,
  onSave,
  logo,
}: AddEditLogoFormModalProps) => {
  const formId = useId();
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isDirty },
  } = useForm<AddEditLogoFormDataType>({
    defaultValues: logo || {
      altText: "",
      description: "",
      logoImage: "",
      logoName: "",
      url: "",
    },
  });

  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  useEffect(() => {
    if (logo) {
      reset({ ...logo, logoImage: logo.logoImage });
    } else {
      reset({
        altText: "",
        description: "",
        logoImage: "",
        logoName: "",
        url: "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(logo), reset]);

  const onSubmit = useCallback(
    (data: AddEditLogoFormDataType) => {
      reset();
      onSave(data);
    },
    [onSave, reset]
  );

  const onCancelClick = useCallback(() => {
    if (
      isDirty &&
      (watch("logoName") ||
        watch("logoImage") ||
        watch("url") ||
        watch("altText") ||
        watch("description"))
    ) {
      setIsConfirmOpen(true);
    } else {
      reset();
      onCancel();
    }
  }, [isDirty, onCancel, reset, watch]);

  return (
    <form id={formId}>
      {isConfirmOpen && (
        <ConfirmActionModal
          open={isConfirmOpen}
          loading={false}
          message={
            "Are you sure you want to cancel? Your changes will be lost."
          }
          onCancel={() => setIsConfirmOpen(false)}
          onConfirm={() => {
            setIsConfirmOpen(false);
            reset();
            onCancel();
          }}
        />
      )}
      <Dialog open={isOpen}>
        <DialogTitle>{logo ? "Edit Logo" : "Add Logo"}</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid mobile={12}>
              <Stack
                spacing={2}
                sx={{ width: 360 }}
                style={{ paddingTop: 5, paddingBottom: 5 }}
                component="div"
              >
                <StyledTextField
                  autoFocus
                  {...register("logoName", {
                    required: "Logo Name is required",
                  })}
                  label="Logo Name"
                  error={!!errors.logoName}
                  helperText={errors.logoName?.message}
                  required
                />
                <StyledTextField
                  {...register("logoImage", {
                    required: "Logo Image is required",
                  })}
                  label="Logo Image"
                  error={!!errors.logoImage}
                  helperText={errors.logoImage?.message}
                  required
                />
                <StyledTextField
                  {...register("url", { required: "URL is required" })}
                  type="url"
                  label="URL"
                  error={!!errors.url}
                  helperText={errors.url?.message}
                  required
                />
                <StyledTextField
                  {...register("altText")}
                  label="Alt Text"
                  error={!!errors.altText}
                  helperText={errors.altText?.message}
                  multiline
                />
                <StyledTextField
                  {...register("description")}
                  label="Description"
                  error={!!errors.description}
                  helperText={errors.description?.message}
                  multiline
                />
              </Stack>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <StyledButton color="error" onClick={onCancelClick}>
            Cancel
          </StyledButton>
          <StyledButton type="submit" onClick={handleSubmit(onSubmit)}>
            Submit
          </StyledButton>
        </DialogActions>
      </Dialog>
    </form>
  );
};

const StyledTextField = forwardRef<HTMLDivElement, TextFieldProps>(
  ({ children, ...args }, ref) => (
    <TextField fullWidth size="small" {...args} ref={ref}>
      {children}
    </TextField>
  )
);

const StyledButton = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, ...args }, ref) => {
    return (
      <Button variant="contained" disableRipple {...args} ref={ref}>
        {children}
      </Button>
    );
  }
);
