import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  FormControlLabel,
  TableHead,
  TableRow,
  Typography,
  styled,
  Checkbox,
  TextField,
  TablePagination,
  FormControl,
  FormGroup,
} from "@mui/material";
import { withLayout } from "../hoc/with-layout";
import { useAxios } from "../axios-provider";
import {
  AuditSearchElement,
  AuditType,
  AuditUser,
  createSortByDateFn,
  createSortByStringFn,
  isAuditSearchData,
  isAuditTypeList,
  isAuditUsersList,
} from "../utils";
import { useCallback, useContext, useMemo, useState } from "react";
import { RoleContext } from "../role-provider";
import { Controller, useForm } from "react-hook-form";
import { Loader } from "./loader/Loader";
import {
  getAuditTrailData,
  getSearchedAuditTrailData,
} from "../data/audittrail";
import dayjs, { Dayjs } from "dayjs";
import { DateTimePicker } from "@mui/x-date-pickers";
import { StyledSecondaryButton } from "./ItemMarketingForm";
import { SearchInput } from "./SearchInput";
import { useUrlSearchPageSort } from "../hooks/use-url-search-page-sort";
import TableSortIcon from "./TableSortIcon";
import { useCustomQuery } from "../hooks/use-custom-query";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

type AuditSearchParams = {
  auditTypeId: string;
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  userId: string;
};
type AuditSearchParamsTest = {
  auditTypeId: string;
  startDate: string | null;
  endDate: string | null;
  userId?: string;
};
type AuditSearchResultsTableProps = {
  search: boolean;
  searchParams: AuditSearchParamsTest | null;
};
const AuditSearchResultsTable = ({
  searchParams,
}: AuditSearchResultsTableProps) => {
  const { apiClient } = useAxios();
  const { selectedCountry } = useContext(RoleContext);

  const auditTrailSearchResultsQuery = useCustomQuery(
    [
      "getSearchedAuditTrailData",
      { countryCode: selectedCountry, searchParams },
    ],
    () =>
      getSearchedAuditTrailData(apiClient)({
        countryCode: selectedCountry!,
        auditTypeId: searchParams?.auditTypeId!,
        startDate: searchParams?.startDate!,
        endDate: searchParams?.endDate!,
        userId: searchParams?.userId,
      }),
    !!searchParams
  );
  const auditSearchResults = useMemo(() => {
    if (auditTrailSearchResultsQuery.data?.data.dataList === undefined) {
      return [];
    }
    const validAuditSearchResults =
      auditTrailSearchResultsQuery.data?.data.dataList.filter(
        (
          maybeAuditSearchResults
        ): maybeAuditSearchResults is AuditSearchElement => {
          return isAuditSearchData(maybeAuditSearchResults) === true;
        }
      );
    return validAuditSearchResults;
  }, [auditTrailSearchResultsQuery.data?.data.dataList]);

  const {
    page,
    pageSize,
    sortField,
    sortOrder,
    search,
    navigateToPage,
    setPageSize,
    setSorting,
    setSearchQuery,
    currentSort,
  } = useUrlSearchPageSort<AuditSearchElement>("audit-trail", {
    page: 0,
    pageSize: 10,
    sortField: "dateOfAction",
    sortOrder: "desc",
    search: "",
  });

  const sortByAction = createSortByStringFn<AuditSearchElement>("auditAction");
  const sortByDate = createSortByDateFn<AuditSearchElement>("dateOfAction");
  const sortByType = createSortByStringFn<AuditSearchElement>("auditType");
  const sortBySubtype =
    createSortByStringFn<AuditSearchElement>("auditSubtype");
  const sortByStatement =
    createSortByStringFn<AuditSearchElement>("actionStatement");
  const sortByUser = createSortByStringFn<AuditSearchElement>("username");
  const sortByRole = createSortByStringFn<AuditSearchElement>("role");
  const filteredAndSortedItems = useMemo(() => {
    const searchLower = search.toLowerCase();
    const filteredItems =
      search.length > 0
        ? auditSearchResults?.filter((item) => {
            return (
              item.auditAction.toLowerCase().includes(searchLower) ||
              item.dateOfAction.toLowerCase().includes(searchLower)
            );
          })
        : auditSearchResults;
    if (sortField === "auditAction") {
      return sortByAction(filteredItems, sortOrder);
    } else if (sortField === "dateOfAction") {
      return sortByDate(filteredItems, sortOrder);
    } else if (sortField === "auditType") {
      return sortByType(filteredItems, sortOrder);
    } else if (sortField === "auditSubtype") {
      return sortBySubtype(filteredItems, sortOrder);
    } else if (sortField === "actionStatement") {
      return sortByStatement(filteredItems, sortOrder);
    } else if (sortField === "username") {
      return sortByUser(filteredItems, sortOrder);
    } else if (sortField === "role") {
      return sortByRole(filteredItems, sortOrder);
    }
    return sortByDate(filteredItems, sortOrder);
  }, [
    search,
    auditSearchResults,
    sortField,
    sortByDate,
    sortOrder,
    sortByAction,
    sortByType,
    sortBySubtype,
    sortByStatement,
    sortByUser,
    sortByRole,
  ]);

  const handlePageChange = useCallback(
    (_: React.MouseEvent<HTMLButtonElement> | null, value: number) => {
      navigateToPage(value);
    },
    [navigateToPage]
  );
  const handleRowsPerPageChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setPageSize(Number(event.target.value));
    },
    [setPageSize]
  );

  const getRow = useCallback(
    (index: number) => {
      if (!filteredAndSortedItems || !filteredAndSortedItems[index]) {
        return (
          <TableRow
            sx={{
              height: 48,
              "&:last-child td, &:last-child th": { border: 0 },
            }}
            data-testid="items-placeholder-tr"
          >
            <TableCell
              component="th"
              scope="row"
              data-testid="items-td-name"
            ></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        );
      }
      const item = filteredAndSortedItems[index];
      return (
        <StyledTableRow
          key={index}
          sx={{
            height: 48,
            "&:last-child td, &:last-child th": { border: 0 },
          }}
          data-testid="item-tr"
        >
          <TableCell
            component="th"
            scope="row"
            data-testid="item-td-name"
            width="7%"
          >
            {item.auditType}
          </TableCell>
          <TableCell width="10%">{item.auditSubtype}</TableCell>
          <TableCell width="12%">{item.auditAction}</TableCell>
          <TableCell width="30%">{item.actionStatement}</TableCell>
          <TableCell width="10%">{item.username}</TableCell>
          <TableCell width="10%">{item.role}</TableCell>
          <TableCell align="right" width="10%">
            {item.dateOfAction}
          </TableCell>
        </StyledTableRow>
      );
    },
    [filteredAndSortedItems]
  );
  const isEmpty = useMemo(() => {
    return (
      !auditTrailSearchResultsQuery.isFetching &&
      auditTrailSearchResultsQuery.isSuccess &&
      auditSearchResults.length === 0
    );
  }, [
    auditTrailSearchResultsQuery.isFetching,
    auditTrailSearchResultsQuery.isSuccess,
    auditSearchResults.length,
  ]);

  return (
    <>
      <Grid sx={{ pl: 0, pr: 0, mt: 4 }}>
        {!!searchParams && auditTrailSearchResultsQuery.isFetching && (
          <Loader />
        )}
        {auditTrailSearchResultsQuery.isSuccess && (
          <>
            <Grid
              container
              sx={{ display: "flex", justifyContent: "flex-end", mb: 1 }}
            >
              <SearchInput
                value={search}
                onChange={(e) => setSearchQuery(e.target.value)}
                aria-label="Search items"
                placeholder="Search Results"
                testId="item-search"
              />
            </Grid>
            <TableContainer component={Paper} aria-label="Audit Table">
              <Table aria-label="simple table">
                <StyledDashboardTableHead>
                  <TableRow sx={{ borderBottom: "unset" }} role="row">
                    <TableCell
                      component="th"
                      scope="col"
                      role="columnheader"
                      id="name-header"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      <TableSortIcon
                        currentSort={currentSort}
                        setSorting={setSorting}
                        field="auditType"
                        label="Audit Type"
                        aria-label="Sort by type"
                        testId="sort-by-type"
                      />
                    </TableCell>
                    <TableCell style={{ whiteSpace: "nowrap" }}>
                      <TableSortIcon
                        currentSort={currentSort}
                        setSorting={setSorting}
                        field="auditSubtype"
                        label="Audit SubType"
                        aria-label="Sort by subtype"
                        testId="sort-by-subtype"
                      />
                    </TableCell>
                    <TableCell style={{ whiteSpace: "nowrap" }}>
                      <TableSortIcon
                        currentSort={currentSort}
                        setSorting={setSorting}
                        field="auditAction"
                        label="Audit Action"
                        aria-label="Sort by action"
                        testId="sort-by-action"
                      />
                    </TableCell>
                    <TableCell style={{ whiteSpace: "nowrap" }}>
                      <TableSortIcon
                        currentSort={currentSort}
                        setSorting={setSorting}
                        field="actionStatement"
                        label="Action Statement"
                        aria-label="Sort by statement"
                        testId="sort-by-statement"
                      />
                    </TableCell>
                    <TableCell style={{ whiteSpace: "nowrap" }}>
                      <TableSortIcon
                        currentSort={currentSort}
                        setSorting={setSorting}
                        field="username"
                        label="Username"
                        aria-label="Sort by user"
                        testId="sort-by-user"
                      />
                    </TableCell>
                    <TableCell style={{ whiteSpace: "nowrap" }}>
                      <TableSortIcon
                        currentSort={currentSort}
                        setSorting={setSorting}
                        field="role"
                        label="Role"
                        aria-label="Sort by role"
                        testId="sort-by-role"
                      />
                    </TableCell>
                    <TableCell align="right" style={{ whiteSpace: "nowrap" }}>
                      <TableSortIcon
                        currentSort={currentSort}
                        setSorting={setSorting}
                        field="dateOfAction"
                        label="Date of Action"
                        aria-label="Sort by date"
                        testId="sort-by-date"
                      />
                    </TableCell>
                  </TableRow>
                </StyledDashboardTableHead>
                {!isEmpty && (
                  <TableBody>
                    {Array.from({ length: pageSize }, (_, i) => i).map((i) =>
                      getRow(page * pageSize + i)
                    )}
                  </TableBody>
                )}
              </Table>
              {auditTrailSearchResultsQuery.isSuccess &&
                !auditTrailSearchResultsQuery.data.data.dataList?.length && (
                  <Grid
                    container
                    mobile={12}
                    sx={{
                      justifyContent: "center",
                      alignItems: "center",
                      height: "50px",
                    }}
                  >
                    <Typography>No results found.</Typography>
                  </Grid>
                )}
            </TableContainer>
            <StyledActionPanel container>
              <TablePagination
                component="div"
                count={filteredAndSortedItems.length}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
                page={page}
                rowsPerPage={pageSize}
                rowsPerPageOptions={[5, 10, 25]}
                aria-label="Pagination"
                data-testid="audit-trail-pagination"
                SelectProps={{
                  MenuProps: {
                    classes: { list: "audit-trail-pagination-list" },
                  },
                }}
              />
            </StyledActionPanel>
          </>
        )}
      </Grid>
    </>
  );
};

export const AuditTrail = withLayout(() => {
  const { apiClient } = useAxios();
  const { selectedCountry } = useContext(RoleContext);
  const {
    control,
    handleSubmit,
    register,
    getValues,
    setValue,
    reset,
    watch,
    setError,
    clearErrors,
    formState: { isDirty, errors },
  } = useForm<AuditSearchParams>({
    defaultValues: {
      auditTypeId: "",
      userId: "",
      startDate: dayjs().utc().subtract(6, "month"),
      endDate: dayjs().utc(),
    },
  });
  const auditTrailDataQuery = useCustomQuery(
    ["getAuditTrailData", { countryCode: selectedCountry }],
    () =>
      getAuditTrailData(apiClient)({
        countryCode: selectedCountry!,
      })
  );

  const fieldsToExclude = useMemo(() => {
    return [
      "Attributes",
      "Element Hierarchy",
      "Country Management",
      "Common Attributes",
    ];
  }, []);

  const memoizedFieldsToExclude = useMemo(
    () => fieldsToExclude,
    [fieldsToExclude]
  );

  const auditTrailTypeList = useMemo(() => {
    if (auditTrailDataQuery.data?.data.auditTypeList === undefined) {
      return [];
    }
    const validAuditTrailType =
      auditTrailDataQuery.data?.data.auditTypeList.filter(
        (maybeAuditTrailType): maybeAuditTrailType is AuditType => {
          return (
            isAuditTypeList(maybeAuditTrailType) === true &&
            maybeAuditTrailType.auditTypeName !== undefined &&
            !memoizedFieldsToExclude.includes(maybeAuditTrailType.auditTypeName)
          ); // excluding the audittype
        }
      );
    return validAuditTrailType;
  }, [auditTrailDataQuery.data?.data.auditTypeList, memoizedFieldsToExclude]);
  const auditTrailUserList = useMemo(() => {
    if (auditTrailDataQuery.data?.data.usersList === undefined) {
      return [];
    }
    const validAuditTrailUserList =
      auditTrailDataQuery.data?.data.usersList.filter(
        (maybeAuditTrailUser): maybeAuditTrailUser is AuditUser => {
          return isAuditUsersList(maybeAuditTrailUser) === true;
        }
      );
    return validAuditTrailUserList;
  }, [auditTrailDataQuery.data?.data.usersList]);

  const [searchParams, setSearchParams] =
    useState<AuditSearchParamsTest | null>(null);
  const onSubmitSearch = (formData: AuditSearchParams) => {
    setSearchParams(null);
    if (selectedTypes.length === 0) {
      setError("auditTypeId", {
        type: "manual",
        message: "Selecting at least one type is mandatory",
      });
    }
    if (selectedTypes.length > 0 && !dateRangeError) {
      const formattedSearchParams: AuditSearchParamsTest = {
        auditTypeId: formData.auditTypeId,
        startDate: formData.startDate
          ? formData.startDate.format("YYYY-MM-DDTHH:mm:ss[Z]")
          : null,
        endDate: formData.endDate
          ? formData.endDate.format("YYYY-MM-DDTHH:mm:ss[Z]")
          : null,
        ...(formData.userId ? { userId: formData.userId } : {}),
      };
      clearErrors("auditTypeId");
      setSearchParams(formattedSearchParams);
    }
  };
  const [dateRangeError, setDateRangeError] = useState("");
  const checkDateRange = useCallback((start: Dayjs, end: Dayjs) => {
    const sixMonthsLater = dayjs(start).utc().add(6, "months");
    if (end.isAfter(sixMonthsLater)) {
      setDateRangeError("The date range should not exceed 6 months.");
    } else {
      setDateRangeError("");
    }
  }, []);
  const startDate = watch("startDate");
  const endDate = watch("endDate");

  const [selectedTypes, setSelectedTypes] = useState<number[]>([]);

  const handleCheckboxChange = (typeId: number) => {
    setSelectedTypes((prevSelectedType) => {
      const newSelectedType = prevSelectedType.includes(typeId)
        ? prevSelectedType.filter((id) => id !== typeId)
        : [...prevSelectedType, typeId];
      setValue("auditTypeId", newSelectedType.sort((a, b) => a - b).join(","), {
        shouldDirty: true,
      });
      return newSelectedType;
    });
    if (selectedTypes.length === 0) {
      clearErrors("auditTypeId");
    }
  };
  return (
    <StyledRelativeContainer container spacing={1}>
      <Grid container sx={{ m: 0 }} spacing={1}>
        <Grid mobile={12} container sx={{ mb: 6 }}>
          <Typography variant="h1">Audit Trail</Typography>
        </Grid>

        {auditTrailDataQuery.isFetching && <Loader />}
        <Paper elevation={4} sx={{ minWidth: "1100px" }}>
          <Grid mobile={12} sx={{ padding: 4 }}>
            {!auditTrailDataQuery.isFetching && (
              <form onSubmit={handleSubmit(onSubmitSearch)}>
                <Grid container sx={{ mb: 2 }}>
                  <Grid mobile={2}>
                    <Typography variant="h6">Audit Types</Typography>
                  </Grid>
                  <Grid mobile={10}>
                    <Grid
                      spacing={0}
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      {errors.auditTypeId && (
                        <Typography color="error">
                          {errors.auditTypeId.message}
                        </Typography>
                      )}
                    </Grid>
                    <Grid
                      mobile={12}
                      sx={{
                        mb: 3,
                        p: 2,
                        border: "2px rgba(0, 0, 0, 0.1) solid",
                        borderRadius: "4px",
                      }}
                    >
                      <FormControl component="fieldset" variant="standard">
                        <FormGroup row>
                          <Grid container spacing={0}>
                            {auditTrailTypeList.map((role) => (
                              <Grid mobile={3}>
                                <FormControlLabel
                                  key={role.auditTypeId}
                                  control={
                                    <Checkbox
                                      value={role.auditTypeId}
                                      onChange={() =>
                                        handleCheckboxChange(role.auditTypeId)
                                      }
                                      checked={selectedTypes.includes(
                                        role.auditTypeId
                                      )}
                                    />
                                  }
                                  label={role.auditTypeName}
                                />
                              </Grid>
                            ))}
                          </Grid>
                        </FormGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container sx={{ mb: 2 }}>
                  <Grid mobile={2}>
                    <Typography variant="h6">User Name</Typography>
                  </Grid>
                  <Grid mobile={10}>
                    <TextField
                      fullWidth
                      select
                      label="User Name"
                      InputLabelProps={{ shrink: true }}
                      {...register("userId")}
                      onChange={(event) => {
                        setValue("userId", event.target.value, {
                          shouldDirty: true,
                        });
                      }}
                      aria-labelledby="audit-user-label"
                      SelectProps={{ native: true }}
                      data-testid="audit-user-selector"
                    >
                      {auditTrailUserList.map((option) => (
                        <option key={option.userId} value={option.userId}>
                          <Typography variant="body1">
                            {option.userName}
                          </Typography>
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid mobile={2}>
                    <Typography variant="h6">Action Date Range</Typography>
                  </Grid>
                  <Grid mobile={10} container>
                    <Grid mobile={6}>
                      <Controller
                        name={"startDate"}
                        control={control}
                        render={() => (
                          <DateTimePicker
                            sx={{ width: "100%" }}
                            label="Start"
                            value={getValues("startDate")}
                            onChange={(newValue) => {
                              const updatedStartDate = newValue;
                              setValue("startDate", newValue, {
                                shouldDirty: true,
                              });
                              checkDateRange(updatedStartDate!, endDate!);
                            }}
                            slotProps={{
                              actionBar: { actions: ["clear", "accept"] },
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid mobile={6}>
                      <Controller
                        name={"endDate"}
                        control={control}
                        render={() => (
                          <DateTimePicker
                            sx={{ width: "100%" }}
                            label="End"
                            value={getValues("endDate")}
                            onChange={(newValue) => {
                              const updatedEndDate = newValue;
                              setValue("endDate", newValue, {
                                shouldDirty: true,
                              });
                              checkDateRange(startDate!, updatedEndDate!);
                            }}
                            slotProps={{
                              actionBar: { actions: ["clear", "accept"] },
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  {dateRangeError && (
                    <Grid
                      mobile={12}
                      container
                      mobileOffset={2}
                      justifyContent="center"
                    >
                      <Typography
                        color="error"
                        variant="body2"
                        style={{ marginTop: 8 }}
                      >
                        {dateRangeError}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
                <Grid container sx={{ mt: 4, justifyContent: "space-between" }}>
                  <StyledSecondaryButton
                    variant="contained"
                    size="large"
                    aria-label="Reset"
                    disabled={!isDirty}
                    onClick={() => {
                      reset({
                        userId: "",
                        startDate: dayjs().utc().subtract(6, "month"),
                        endDate: dayjs().utc(),
                      });
                      setDateRangeError("");
                      setSelectedTypes([]);
                    }}
                  >
                    Reset
                  </StyledSecondaryButton>
                  <StyledButton
                    type="submit"
                    variant="contained"
                    size="large"
                    disabled={!!dateRangeError}
                    aria-label="Search"
                    data-testid="save-item-submit-button"
                  >
                    Search
                  </StyledButton>
                </Grid>
              </form>
            )}
          </Grid>
        </Paper>
        <Grid mobile={12}>
          <AuditSearchResultsTable search={true} searchParams={searchParams!} />
        </Grid>
      </Grid>
    </StyledRelativeContainer>
  );
}, "Audit Trail");

export const StyledDashboardTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  ...theme.typography.normalBold,
}));
const StyledTableRow = styled(TableRow)({
  borderBottom: "unset",
  backgroundColor: "white",
  height: 48,
});
const StyledRelativeContainer = styled(Grid)({
  margin: 0,
  position: "relative",
});
const StyledButton = styled(Button)(({ theme }) => ({
  color: "#000000",
  fontSize: theme.typography.largeBold.fontSize,
  fontFamily: theme.typography.largeBold.fontFamily,
  fontWeight: theme.typography.largeBold.fontWeight,
  textTransform: "none",
}));
const StyledActionPanel = styled(Grid)({
  display: "flex",
  flexDirection: "row",
  gap: "10px",
  justifyContent: "flex-end",
  alignItems: "center",
});
