import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
  Button,
  Collapse,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { withLayout } from "../hoc/with-layout";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAxios } from "../axios-provider";
import { useCallback, useContext, useEffect, useState } from "react";
import { RoleContext } from "../role-provider";
import { Loader } from "../components/loader/Loader";
import {
  ErrorAlertSnackbar,
  SuccessAlertSnackbar,
} from "../components/AlertSnackbar";
import {
  getManageAttributes,
  saveAttributeDisplayOrder,
} from "../data/miscellaneous";
import { ApiError } from "../utils";
import { useCustomQuery } from "../hooks/use-custom-query";
import { ResendFormModal } from "../components/ResendFormModal";
import { AxiosError } from "axios";
import {
  CommonAttributeMap,
  FormattedElementAttribute,
  formatCommonAttributeMap,
} from "../util/attribute-display-order";
import {
  ArrowDownward,
  ArrowUpward,
  LowPriority,
  Save,
} from "@mui/icons-material";
import { useForm } from "react-hook-form";
import { ConfirmNavigateAway } from "../components/ConfirmNavigateAway";

export const ManageCommonAttributes = withLayout(() => {
  const { apiClient } = useAxios();
  const queryClient = useQueryClient();
  const { selectedCountry } = useContext(RoleContext);

  const [commonAttributeMap, setCommonAttributeMap] =
    useState<CommonAttributeMap | null>(null);
  const [isCategoryDisplayOrderChanged, setIsCategoryDisplayOrderChanged] =
    useState<boolean>(false);
  const [isItemDisplayOrderChanged, setIsItemDisplayOrderChanged] =
    useState<boolean>(false);
  const [isProductDisplayOrderChanged, setIsProductDisplayOrderChanged] =
    useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const commonAttributesQuery = useCustomQuery(
    ["getManageAttributes", { selectedCountry }],
    () =>
      getManageAttributes(apiClient)({
        countryCode: selectedCountry!,
      })
  );

  useEffect(() => {
    if (commonAttributesQuery.data?.data.dataList) {
      setCommonAttributeMap(
        formatCommonAttributeMap(commonAttributesQuery.data.data.dataList)
      );
    }
  }, [commonAttributesQuery.data]);

  const onSaveDisplayOrderSuccess = useCallback(() => {
    setSuccessMessage("Display order was saved successfully");
    queryClient.invalidateQueries(["getManageAttributes", { selectedCountry }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onSaveDisplayOrderError = useCallback((error: string) => {
    setErrorMessage(error);
  }, []);

  return (
    <>
      <StyledRelativeContainer container spacing={1}>
        <Grid
          mobile={12}
          container
          sx={{ mb: 6, justifyContent: "space-between" }}
        >
          <Typography variant="h1">Manage Attributes</Typography>
        </Grid>

        {commonAttributesQuery.isFetching && <Loader />}
        {commonAttributeMap !== null && (
          <Grid mobile={12}>
            <CommonAttributesTable
              elementName="Category"
              attributes={commonAttributeMap.category}
              onSaveSuccess={onSaveDisplayOrderSuccess}
              onSaveError={onSaveDisplayOrderError}
              onDirtyStatusChange={setIsCategoryDisplayOrderChanged}
            />
            <CommonAttributesTable
              elementName="Item"
              attributes={commonAttributeMap.item}
              onSaveSuccess={onSaveDisplayOrderSuccess}
              onSaveError={onSaveDisplayOrderError}
              onDirtyStatusChange={setIsItemDisplayOrderChanged}
            />
            <CommonAttributesTable
              elementName="Product"
              attributes={commonAttributeMap.product}
              onSaveSuccess={onSaveDisplayOrderSuccess}
              onSaveError={onSaveDisplayOrderError}
              onDirtyStatusChange={setIsProductDisplayOrderChanged}
            />
          </Grid>
        )}
      </StyledRelativeContainer>
      <ConfirmNavigateAway
        isDirty={
          isCategoryDisplayOrderChanged ||
          isItemDisplayOrderChanged ||
          isProductDisplayOrderChanged
        }
      />
      <SuccessAlertSnackbar
        message={successMessage}
        onClose={() => setSuccessMessage(null)}
      />
      <ErrorAlertSnackbar
        message={errorMessage}
        onClose={() => setErrorMessage(null)}
      />
    </>
  );
}, "Manage Attributes");

type CommonAttributesTableProps = {
  elementName: "Category" | "Item" | "Product";
  attributes: FormattedElementAttribute[];
  onSaveSuccess: () => void;
  onSaveError: (error: string) => void;
  onDirtyStatusChange: (isDirty: boolean) => void;
};

const CommonAttributesTable = ({
  elementName,
  attributes,
  onSaveSuccess,
  onSaveError,
  onDirtyStatusChange,
}: CommonAttributesTableProps) => {
  const { apiClient } = useAxios();
  const { selectedCountry, selectedRole } = useContext(RoleContext);

  const [open, setOpen] = useState(false);
  const [isResendModalOpen, setIsResendModalOpen] = useState<boolean>(false);

  const {
    watch,
    setValue,
    reset,
    formState: { isDirty },
  } = useForm<{
    attributeList: FormattedElementAttribute[];
  }>({
    defaultValues: {
      attributeList: attributes,
    },
  });
  const attributeListWithEditableDisplayOrder = watch("attributeList");

  useEffect(() => {
    onDirtyStatusChange(isDirty);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  useEffect(() => {
    setValue("attributeList", attributes, { shouldDirty: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attributes]);

  const saveAttributeDisplayOrderRequest = saveAttributeDisplayOrder(apiClient);
  const { mutate: doSaveDisplayOrder, isLoading: isDisplayOrderSaving } =
    useMutation(
      () => {
        return saveAttributeDisplayOrderRequest(
          {
            attributeList: attributeListWithEditableDisplayOrder.map(
              (attribute, index) => ({
                attributeId: attribute.id,
                displayOrder: index + 1,
              })
            ),
          },
          {
            countryCode: selectedCountry!,
            roleId: String(selectedRole)!,
            elementType: elementName,
          }
        );
      },
      {
        onMutate: () => saveAttributeDisplayOrderRequest,
        onSuccess: () => {
          reset();
          onSaveSuccess();
        },
        onError: (error: AxiosError) => {
          if (error?.response?.status === 401) {
            setIsResendModalOpen(true);
          } else if (error.response?.data) {
            const errorMessage: ApiError = error.response.data as ApiError;
            onSaveError(String(errorMessage.message));
          } else {
            onSaveError(String(error));
          }
        },
      }
    );

  const handleMoveItem = useCallback(
    (index: number, direction: string) => {
      const newIndex = direction === "up" ? index - 1 : index + 1;
      const updatedAttributes = [...attributeListWithEditableDisplayOrder];
      [updatedAttributes[index], updatedAttributes[newIndex]] = [
        updatedAttributes[newIndex],
        updatedAttributes[index],
      ];
      setValue("attributeList", updatedAttributes, { shouldDirty: true });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [attributeListWithEditableDisplayOrder]
  );

  const handleMoveToTop = useCallback(
    (index: number) => {
      const updatedAttributes = [...attributeListWithEditableDisplayOrder];
      const [attributeToMove] = updatedAttributes.splice(index, 1);
      updatedAttributes.unshift(attributeToMove);
      setValue("attributeList", updatedAttributes, { shouldDirty: true });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [attributeListWithEditableDisplayOrder]
  );

  return (
    <Stack marginBottom={3}>
      <StyledTableControls>
        <Grid container mobile={12} justifyContent="space-between">
          <Grid container alignItems="flex-start" gap={2}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
              data-testid="attribute-table-expand-collapse"
              role="button"
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
            <Typography variant="h4">{elementName}</Typography>
          </Grid>
          <Grid container>
            <StyledButton
              color="primary"
              variant="contained"
              startIcon={<Save />}
              onClick={() => doSaveDisplayOrder()}
              disabled={!isDirty || isDisplayOrderSaving}
            >
              Save Display Order
            </StyledButton>
          </Grid>
        </Grid>
      </StyledTableControls>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {isDisplayOrderSaving && <Loader />}
        <TableContainer>
          <Table aria-label={`${elementName} attributes`}>
            <StyledDashboardTableHead>
              <TableRow role="row">
                <TableCell role="columnheader" align="right">
                  ID
                </TableCell>
                <TableCell role="columnheader" align="right">
                  Attribute Name
                </TableCell>
                <TableCell role="columnheader" align="right">
                  Type
                </TableCell>
                <TableCell role="columnheader" align="right">
                  UI Type
                </TableCell>
                <TableCell role="columnheader" align="right">
                  System
                </TableCell>
                <TableCell role="columnheader" align="right">
                  Common
                </TableCell>
                <TableCell role="columnheader" align="right">
                  Translatable
                </TableCell>
                <TableCell role="columnheader" align="right">
                  Display Order
                </TableCell>
                <TableCell role="columnheader" align="right">
                  Action
                </TableCell>
              </TableRow>
            </StyledDashboardTableHead>
            <TableBody>
              {attributeListWithEditableDisplayOrder.map((attribute, index) => (
                <StyledTableRow
                  key={attribute.id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell role="cell" align="right">
                    {attribute.id}
                  </TableCell>
                  <TableCell role="cell" align="right">
                    {attribute.attributeName}
                  </TableCell>
                  <TableCell role="cell" align="right">
                    {attribute.type}
                  </TableCell>
                  <TableCell role="cell" align="right">
                    {attribute.uiType}
                  </TableCell>
                  <TableCell role="cell" align="right">
                    {attribute.system}
                  </TableCell>
                  <TableCell role="cell" align="right">
                    {attribute.common}
                  </TableCell>
                  <TableCell role="cell" align="right">
                    {attribute.translatable}
                  </TableCell>
                  <TableCell role="cell" align="center">
                    {attribute.displayOrder}
                  </TableCell>
                  <TableCell role="cell" align="right">
                    {index !== 0 && (
                      <IconButton
                        color="primary"
                        onClick={() => handleMoveItem(index, "up")}
                        aria-label={`Move ${attribute.attributeName} up`}
                      >
                        <ArrowUpward />
                      </IconButton>
                    )}
                    {index !==
                      attributeListWithEditableDisplayOrder.length - 1 && (
                      <IconButton
                        color="primary"
                        onClick={() => handleMoveItem(index, "down")}
                        aria-label={`Move ${attribute.attributeName} down`}
                      >
                        <ArrowDownward />
                      </IconButton>
                    )}
                    {index !== 0 && (
                      <Tooltip title="Move to Top">
                        <IconButton
                          color="primary"
                          onClick={() => handleMoveToTop(index)}
                          aria-label={`Move ${attribute.attributeName} to top`}
                          style={{ transform: "rotate(180deg)" }}
                        >
                          <LowPriority />
                        </IconButton>
                      </Tooltip>
                    )}
                  </TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Collapse>
      <ResendFormModal
        open={isResendModalOpen}
        onResend={() => {
          setIsResendModalOpen(false);
          doSaveDisplayOrder();
        }}
        onCancel={() => {
          setIsResendModalOpen(false);
        }}
        description="An error occurred updating the display order"
      />
    </Stack>
  );
};



export const StyledDashboardTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  ...theme.typography.normalBold,
}));
const StyledTableControls = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  paddingTop: theme.spacing(2),
  paddingRight: 0,
  paddingBottom: theme.spacing(1),
}));
const StyledTableRow = styled(TableRow)({
  borderBottom: "unset",
  backgroundColor: "white",
  height: 48,
});
const StyledRelativeContainer = styled(Grid)({
  margin: 0,
  position: "relative",
});
const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.primary,
}));
