import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { withLayout } from "../hoc/with-layout";
import { ItemMarketingForm } from "../components/ItemMarketingForm";
import { useQueryClient } from "@tanstack/react-query";
import { useAxios } from "../axios-provider";
import { getItemMarketingInformation } from "../data/items";
import { useContext, useMemo, useState } from "react";
import { RoleContext } from "../role-provider";
import { ValidItemAttribute, isDropDownList, isItemAttribute } from "../utils";
import { Loader } from "../components/loader/Loader";
import { ErrorAlertSnackbar } from "../components/AlertSnackbar";
import { useCustomQuery } from "../hooks/use-custom-query";

export const CreateItem = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { apiClient } = useAxios();
  const { selectedCountry, defaultLanguage, selectedRole } =
    useContext(RoleContext);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { data, isFetching, isSuccess } = useCustomQuery(
    ["getItemMarketingInformation"],
    () =>
      getItemMarketingInformation(apiClient)({
        countryCode: selectedCountry!,
        roleId: selectedRole!,
      })
  );

  const itemAttributes = useMemo(() => {
    if (data?.data.attributeList === undefined) {
      return [];
    }
    const validAttributes = data?.data.attributeList.filter(
      (maybeAttribute): maybeAttribute is ValidItemAttribute => {
        return isItemAttribute(maybeAttribute) === true;
      }
    );
    return validAttributes;
  }, [data]);

  const dropDownList = useMemo(() => {
    if (isDropDownList(data?.data.dropdownList)) {
      return data?.data.dropdownList;
    }
    return {};
  }, [data]);

  const CreateItemWithLayout = useMemo(() => {
    return withLayout(() => {
      return (
        <StyledRelativeContainer>
          {isFetching && <Loader />}
          {!isFetching && isSuccess && (
            <Box sx={{ flexGrow: 1, px: 0 }}>
              <Grid container>
                <Grid container mobile={9} sx={{ alignItems: "center", mb: 4 }}>
                  <Grid>
                    <Typography variant="h2">New Item</Typography>
                  </Grid>
                </Grid>

                <Grid mobile={12}>
                  <ItemMarketingForm
                    editable={true}
                    isLive={false}
                    attributes={itemAttributes}
                    collectionItems={[]}
                    dropDownList={dropDownList!}
                    footers={[]}
                    saveSuccessMessage="Item was created successfully"
                    language={
                      typeof defaultLanguage === "string"
                        ? defaultLanguage
                        : "EN"
                    }
                    setLanguage={() => {}}
                    onSaveSuccess={() => {
                      queryClient.invalidateQueries(["getItemsList"]);
                      navigate(`/items`);
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          )}
          <ErrorAlertSnackbar
            message={errorMessage}
            onClose={() => setErrorMessage(null)}
          />
        </StyledRelativeContainer>
      );
    }, "New Item");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    defaultLanguage,
    dropDownList,
    errorMessage,
    isFetching,
    isSuccess,
    itemAttributes,
  ]);

  return <CreateItemWithLayout />;
};

const StyledRelativeContainer = styled(Grid)({
  margin: 0,
  position: "relative",
});
