import styled from "@emotion/styled";
import { Box, Chip, Tab, Tabs, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
  Link,
  Outlet,
  useLocation,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { withLayout } from "../hoc/with-layout";
import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DataContext } from "../data-provider";
import {
  getElementLiveStatus,
  getItemMarketingInformation,
} from "../data/items";
import { useAxios } from "../axios-provider";
import { RoleContext } from "../role-provider";
import {
  DropDownList,
  Footer,
  ValidItemAttribute,
  ValidItemCollectionItem,
  createSortByNumberFn,
  isDropDownList,
  isFooter,
  isItemAttribute,
  isItemCollectionItem,
} from "../utils";
import { Loader } from "../components/loader/Loader";
import { useCustomQuery } from "../hooks/use-custom-query";

type ItemURI = {
  title: string;
  path: string;
  tabIndex?: number;
};
type ItemTab = {
  title: string;
  path: string;
  tabIndex: number;
};
const pageURIs: ItemURI[] = [
  {
    title: "Marketing Information",
    path: "item-marketing-information",
    tabIndex: 0,
  },
  {
    title: "Item Product Mapping",
    path: "product-mapping",
    tabIndex: 1,
  },
  {
    title: "Nutrition Information",
    path: "nutrition",
    tabIndex: 2,
  },
  {
    title: "Category Mapping",
    path: "category-mapping",
    tabIndex: 3,
  },
  {
    title: "Relationship Mapping",
    path: "relationship-mapping",
    tabIndex: 4,
  },
  {
    title: "Goes Well With",
    path: "goes-well-with",
    tabIndex: 5,
  },
  {
    title: "Co-Op Mapping",
    path: "co-op-mapping",
    tabIndex: 6,
  },
  {
    title: "Review and Submit",
    path: "item-review-and-submit",
    tabIndex: 7,
  },
  {
    title: "Compare With Live",
    path: "compare-with-live",
  },
];
const allTabs = pageURIs.filter(
  (uri) => typeof uri.tabIndex === "number"
) as ItemTab[];

type ItemContextType = {
  itemId: number;
  itemName: string;
  editable: boolean;
  attributes: ValidItemAttribute[];
  hasComponents: boolean;
  collectionItems: ValidItemCollectionItem[];
  dropDownList: DropDownList;
  footers: Footer[];
  isLive: boolean;
  language: string;
  setLanguage: (language: string) => void;
};
export function useItem() {
  return useOutletContext<ItemContextType>();
}

const sortByIndex = createSortByNumberFn<ItemTab>("tabIndex");

const ItemComponent = () => {
  const navigate = useNavigate();
  const { itemId } = useParams();
  const { apiClient } = useAxios();
  const { pathname } = useLocation();
  const pathParts = pathname.split("/");
  const activeURI = pathParts[pathParts.length - 1];
  const activePage = pageURIs.find((uri) => uri.path === activeURI);
  const activeTab = allTabs.find((tab) => tab.path === activePage?.path);

  const [isLive, setIsLive] = useState(false);

  if (!itemId) {
    throw new Error("itemId is required");
  }
  if (!activePage) {
    throw new Error("Invalid URI");
  }
  const { items } = useContext(DataContext);
  const { selectedCountry, isItemLevelMarket, defaultLanguage, selectedRole } =
    useContext(RoleContext);
  const itemName = useMemo(() => {
    if (items.length > 0) {
      const item = items.find((p) => p.itemId === Number(itemId));
      if (!item) {
        const errorMessage = `No item found matching id ${itemId}`;
        navigate(`/error?message=${errorMessage}`);
        return;
      }
      return item.itemName;
    }
    return "";
  }, [items, itemId, navigate]);

  const title = itemName + " - " + activePage?.title;

  const editable = useMemo(() => {
    const item = items.find((p) => p.itemId === Number(itemId));
    if (item?.showEdit === "Y") {
      return true;
    } else {
      return false;
    }
  }, [items, itemId]);

  const [tabs, setTabs] = useState<ItemTab[]>([]);
  const [language, setLanguage] = useState<string>(
    typeof defaultLanguage === "string" ? defaultLanguage : "EN"
  );

  const getElementLiveStatusQuery = useCustomQuery(
    [
      "getElementLiveStatus",
      {
        itemId,
        countryCode: selectedCountry,
      },
    ],
    () =>
      getElementLiveStatus(apiClient)({
        elementId: itemId,
        countryCode: selectedCountry!,
      })
  );
  useEffect(() => {
    if (
      getElementLiveStatusQuery.isSuccess &&
      typeof getElementLiveStatusQuery.data.data.live === "string"
    ) {
      setIsLive(getElementLiveStatusQuery.data.data.live === "Y");
    }
  }, [getElementLiveStatusQuery.isSuccess, getElementLiveStatusQuery.data]);

  const { data, isFetching, isSuccess } = useCustomQuery(
    [
      "getItemMarketingInformation",
      {
        itemId,
        countryCode: selectedCountry,
        languageCode: language,
        roleId: selectedRole,
      },
    ],
    () =>
      getItemMarketingInformation(apiClient)({
        itemId: itemId !== undefined ? Number(itemId) : undefined,
        countryCode: selectedCountry!,
        languageCode: language!,
        roleId: selectedRole!,
      }),
    typeof language === "string"
  );

  const itemAttributes = useMemo(() => {
    if (data?.data.attributeList === undefined) {
      return [];
    }
    const validAttributes = data?.data.attributeList.filter(
      (maybeAttribute): maybeAttribute is ValidItemAttribute => {
        return isItemAttribute(maybeAttribute) === true;
      }
    );
    return validAttributes;
  }, [data]);
  const hasComponents = useMemo(() => {
    if (!itemAttributes.length) {
      return false;
    }
    const hasComponentsAttribute = itemAttributes.find(
      (attr) => attr.keyName === "has_components"
    );
    if (hasComponentsAttribute) {
      return hasComponentsAttribute.value === "Yes";
    }
    return false;
  }, [itemAttributes]);
  const itemCollectionList = useMemo(() => {
    if (typeof data?.data.itemCollectionList === "undefined") {
      return [];
    }
    const validCollectionList = data?.data.itemCollectionList.filter(
      (maybeAttribute): maybeAttribute is ValidItemCollectionItem => {
        return isItemCollectionItem(maybeAttribute) === true;
      }
    );
    return validCollectionList;
  }, [data]);
  const dropDownList = useMemo(() => {
    if (typeof data?.data.dropdownList === "undefined") {
      return {};
    }
    if (isDropDownList(data?.data.dropdownList)) {
      return data.data.dropdownList;
    }
  }, [data]);
  const footers = useMemo(() => {
    if (typeof data?.data.footerList === "undefined") {
      return [];
    }
    const validFooters = data?.data.footerList.filter(
      (maybeFooter): maybeFooter is Footer => {
        return isFooter(maybeFooter) === true;
      }
    );
    return validFooters;
  }, [data]);

  useEffect(() => {
    if (itemAttributes.length > 0) {
      let filteredTabs = [...allTabs];
      const typeAttribute = itemAttributes.find(
        (attribute) => attribute.keyName === "item_type"
      );
      if (isItemLevelMarket) {
        // Exclude Goes Well With tab if we're in an item-level market
        filteredTabs = filteredTabs.filter(
          (tab) => tab.path !== "goes-well-with"
        );
      }
      if (selectedCountry !== "US") {
        // Exclude Co-Op Mapping tab for all non-US markets
        filteredTabs = filteredTabs.filter(
          (tab) => tab.path !== "co-op-mapping"
        );
      }
      if (typeAttribute && typeAttribute.value === "Item Collection") {
        // Remove Item-Product mapping tab if Item type = "Item Collection"
        filteredTabs = filteredTabs.filter(
          (tab) => !["nutrition", "product-mapping"].includes(tab.path)
        );
      }
      setTabs(sortByIndex(filteredTabs, "asc"));
    }
  }, [itemAttributes, selectedCountry, isItemLevelMarket]);

  const ItemWithLayout = useMemo(() => {
    return withLayout(() => {
      return (
        <Box sx={{ flexGrow: 1, px: 0 }}>
          <StyledRelativeContainer container>
            {isFetching && <Loader />}
            {!isFetching && isSuccess && (
              <>
                {activeTab && (
                  <>
                    <Grid
                      container
                      mobile={9}
                      sx={{ alignItems: "center", mb: 4 }}
                    >
                      <Grid>
                        <Typography variant="h2">{itemName}</Typography>
                      </Grid>
                      <Grid>
                        {isLive ? (
                          <Chip label="Live" color="success" />
                        ) : (
                          <Chip label="Not Live" />
                        )}
                      </Grid>
                    </Grid>

                    <Grid
                      mobile={12}
                      sx={{
                        justifyContent: "center",
                        display: "flex",
                        width: "100%",
                        borderBottom: "1px solid #F6F6F6",
                        mb: 4,
                      }}
                    >
                      <Tabs
                        value={activeTab.path}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="tab buttons"
                        data-testid="item-tab-menu"
                        TabIndicatorProps={{
                          sx: { backgroundColor: "#FFBC0D" },
                        }}
                        sx={{
                          alignItems: "center",
                          justifyContent: "space-between",
                          borderBottom: "1px solid #F6F6F6",
                          px: 2,
                        }}
                      >
                        {tabs.map((tab) => (
                          <StyledTab
                            key={tab.path}
                            sx={{ fontSize: 16, px: 2 }}
                            label={tab.title}
                            aria-label={tab.title}
                            component={Link}
                            to={`/items/${itemId}/${tab.path}`}
                            value={tab.path}
                            role="tab"
                            data-testid={`cy-item-tab-${tab.path}`}
                          />
                        ))}
                      </Tabs>
                    </Grid>
                  </>
                )}

                <Grid mobile={12} data-testid="item-tab-content-wrapper">
                  <Outlet
                    context={{
                      itemId,
                      itemName,
                      editable,
                      attributes: itemAttributes,
                      hasComponents,
                      collectionItems: itemCollectionList,
                      dropDownList,
                      footers,
                      isLive,
                      language,
                      setLanguage,
                    }}
                  />
                </Grid>
              </>
            )}
          </StyledRelativeContainer>
        </Box>
      );
    }, title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeTab,
    dropDownList,
    editable,
    footers,
    hasComponents,
    isFetching,
    isLive,
    isSuccess,
    itemAttributes,
    itemCollectionList,
    itemId,
    itemName,
    language,
    tabs,
    title,
  ]);

  return <ItemWithLayout />;
};

export const Item = ItemComponent;

const StyledRelativeContainer = styled(Grid)({
  margin: 0,
  position: "relative",
});
const StyledTab = styled(Tab)({
  color: "black",
  fontFamily: "Speedee-Bold, Arial, Helvetica, sans-serif",
  letterSpacing: "-0.15px",
  textTransform: "initial",
  lineHeight: "28px",
  borderBottom: "2px solid #F6F6F6",
  "&.Mui-selected": {
    backgroundColor: "#F6F6F6",
    borderWidth: "4px",
    color: "black",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
}) as typeof Tab;
