import * as React from "react";
import {
  Typography,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  IconButton,
  Box,
  Collapse,
  Stack,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { styled } from "@mui/material/styles";
import { useCallback, useContext, useMemo, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { withLayout } from "../hoc/with-layout";
import { isDefined } from "../utils";
import { RoleContext } from "../role-provider";
import { useAxios } from "../axios-provider";
import { useCustomQuery } from "../hooks/use-custom-query";
import { AddRowLink } from "../components/AddRowLink";
import {
  AddCircle,
  ArrowDropDown,
  ArrowRight,
  DeleteForever,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Loader } from "../components/loader/Loader";
import { AxiosError } from "axios";
import { ApiError } from "../utils";
import {
  ErrorAlertSnackbar,
  SuccessAlertSnackbar,
} from "../components/AlertSnackbar";
import { ResendFormModal } from "../components/ResendFormModal";
import { ConfirmActionModal } from "../components/ConfirmActionModal";
import {
  deleteRoundingRule,
  deleteRoundingRuleMapping,
  getRoundingRuleManagementData,
  getRoundingRuleMapping,
} from "../data/roundingrules";
import {
  NutrientRoundingRuleMappingConfig,
  NutrientRoundingRuleMappingForEdit,
  RoundingRule,
  formatNutrientRoundingRuleMappingConfig,
  formatRoundingRule,
} from "../util/rounding-rules";
import { NutrientRoundingRuleMappingModal } from "../components/NutrientRoundingRuleMappingModal";
import { localizedNumStringOrNA } from "../util/number-localization";

export const RoundingRules = withLayout(() => {
  const { apiClient } = useAxios();
  const queryClient = useQueryClient();
  const { selectedCountry, isReaderRole } = useContext(RoleContext);
  const navigate = useNavigate();

  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [nutrientRoundingRuleForEdit, setNutrientRoundingRuleForEdit] =
    useState<NutrientRoundingRuleMappingForEdit | null>(null);
  const [roundingRuleToDelete, setRoundingRuleToDelete] = useState<
    number | null
  >(null);
  const [roundingRuleMappingToDelete, setRoundingRuleMappingToDelete] =
    useState<{
      roundingRuleMappingId: number;
      roundingRuleTypeId: number;
    } | null>(null);
  const [
    isRetryDeleteRoundingRuleModalOpen,
    setIsRetryDeleteRoundingRuleModalOpen,
  ] = useState<boolean>(false);
  const [
    isRetryDeleteRoundingRuleMappingModalOpen,
    setIsRetryDeleteRoundingRuleMappingModalOpen,
  ] = useState<boolean>(false);

  const getRoundingRulesQuery = useCustomQuery(
    ["getRoundingRuleManagementData", { selectedCountry }],
    () =>
      getRoundingRuleManagementData(apiClient)({
        countryCode: selectedCountry!,
      })
  );

  const roundingRules: RoundingRule[] = useMemo(() => {
    if (getRoundingRulesQuery.data?.data.dataList === undefined) {
      return [];
    }
    return getRoundingRulesQuery.data.data.dataList
      .map((rawRoundingRule) =>
        formatRoundingRule(rawRoundingRule, selectedCountry!)
      )
      .filter(isDefined);
  }, [getRoundingRulesQuery.data, selectedCountry]);

  const getRoundingRuleMappingQuery = useCustomQuery(
    ["getRoundingRuleMapping", { selectedCountry }],
    () =>
      getRoundingRuleMapping(apiClient)({
        countryCode: selectedCountry!,
      })
  );
  const roundingRuleMappings: NutrientRoundingRuleMappingConfig[] =
    useMemo(() => {
      if (getRoundingRuleMappingQuery.data?.data.dataList === undefined) {
        return [];
      }
      return getRoundingRuleMappingQuery.data.data.dataList
        .map((rawRoundingRuleMapping) =>
          formatNutrientRoundingRuleMappingConfig(rawRoundingRuleMapping)
        )
        .filter(isDefined);
    }, [getRoundingRuleMappingQuery.data]);

  const deleteRoundingRuleRequest = deleteRoundingRule(apiClient);
  const { mutate: doDeleteRoundingRule, isLoading: isRoundingRuleDeleting } =
    useMutation(
      () => {
        return deleteRoundingRuleRequest({
          countryCode: selectedCountry!,
          roundingRuleId: roundingRuleToDelete!,
        });
      },
      {
        onSuccess: () => {
          setSuccessMessage(`Rounding rule was successfully deleted`);
          setRoundingRuleToDelete(null);
          setIsRetryDeleteRoundingRuleModalOpen(false);
          queryClient.invalidateQueries([
            "getRoundingRuleManagementData",
            { selectedCountry },
          ]);
          queryClient.invalidateQueries([
            "getRoundingRuleMapping",
            { selectedCountry },
          ]);
        },
        onError: (error: AxiosError) => {
          if (error?.response?.status === 401) {
            setIsRetryDeleteRoundingRuleModalOpen(true);
          } else if (error.response?.data) {
            const errorMessage: ApiError = error.response.data as ApiError;
            setErrorMessage(String(errorMessage.message));
          } else {
            setErrorMessage(String(error));
          }
          setRoundingRuleToDelete(null);
        },
      }
    );
  const deleteRoundingRuleMappingRequest = deleteRoundingRuleMapping(apiClient);
  const {
    mutate: doDeleteRoundingRuleMapping,
    isLoading: isRoundingRuleMappingDeleting,
  } = useMutation(
    () => {
      return deleteRoundingRuleMappingRequest({
        countryCode: selectedCountry!,
        roundingRuleMappingId:
          roundingRuleMappingToDelete?.roundingRuleMappingId!,
        roundingRuleTypeId: roundingRuleMappingToDelete?.roundingRuleTypeId!,
      });
    },
    {
      onSuccess: () => {
        setSuccessMessage(`Nutrient rounding rule was successfully deleted`);
        setRoundingRuleMappingToDelete(null);
        queryClient.invalidateQueries([
          "getRoundingRuleManagementData",
          { selectedCountry },
        ]);
        queryClient.invalidateQueries([
          "getRoundingRuleMapping",
          { selectedCountry },
        ]);
      },
      onError: (error: AxiosError) => {
        if (error?.response?.status === 401) {
          setIsRetryDeleteRoundingRuleMappingModalOpen(true);
        } else if (error.response?.data) {
          const errorMessage: ApiError = error.response.data as ApiError;
          setErrorMessage(String(errorMessage.message));
        } else {
          setErrorMessage(String(error));
        }
        setRoundingRuleMappingToDelete(null);
      },
    }
  );

  const handleClickDeleteRoundingRuleMapping = useCallback(
    (roundingRuleMappingId: number, roundingRuleTypeId: number) =>
      setRoundingRuleMappingToDelete({
        roundingRuleMappingId,
        roundingRuleTypeId,
      }),
    []
  );
  const handleClickCreateRoundingRuleMapping = useCallback(
    (nutrientFactId: number) => {
      setNutrientRoundingRuleForEdit({
        nutrientFactId,
        roundingRuleId: null,
        roundingRuleTypeId: null,
        minValue: "",
        maxValue: "",
      });
    },
    []
  );
  const handleClickEditRoundingRuleMapping = useCallback(
    (roundingRuleMapping: NutrientRoundingRuleMappingForEdit) =>
      setNutrientRoundingRuleForEdit(roundingRuleMapping),
    []
  );
  const handleRoundingRuleMappingSaveSuccess = useCallback(() => {
    setNutrientRoundingRuleForEdit(null);
    setSuccessMessage("Nutrient rounding rule was saved successfully");
    queryClient.invalidateQueries([
      "getRoundingRuleManagementData",
      { selectedCountry },
    ]);
    queryClient.invalidateQueries([
      "getRoundingRuleMapping",
      { selectedCountry },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleCancelEditRoundingRuleMapping = useCallback(
    () => setNutrientRoundingRuleForEdit(null),
    []
  );

  const handleRetryDeleteRoundingRule = useCallback(() => {
    setIsRetryDeleteRoundingRuleModalOpen(false);
    doDeleteRoundingRule();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleRetryDeleteRoundingRuleCancel = useCallback(() => {
    setRoundingRuleToDelete(null);
    setIsRetryDeleteRoundingRuleModalOpen(false);
  }, []);
  const handleRetryDeleteRoundingRuleMapping = useCallback(() => {
    setIsRetryDeleteRoundingRuleMappingModalOpen(false);
    doDeleteRoundingRuleMapping();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleRetryDeleteRoundingRuleMappingCancel = useCallback(() => {
    setRoundingRuleMappingToDelete(null);
    setIsRetryDeleteRoundingRuleMappingModalOpen(false);
  }, []);

  const isWorking = useMemo(
    () =>
      getRoundingRulesQuery.isFetching ||
      getRoundingRuleMappingQuery.isFetching,
    [getRoundingRulesQuery.isFetching, getRoundingRuleMappingQuery.isFetching]
  );

  return (
    <StyledRelativeContainer>
      <Grid
        mobile={12}
        container
        sx={{ mb: 6, justifyContent: "space-between" }}
      >
        <Typography variant="h1">Rounding Rule Management</Typography>
        {!isReaderRole && (
          <AddRowLink
            onClick={() => {
              navigate(`/rounding-rules/create-rounding-rule`);
            }}
            data-testid="add-new-rounding-rule-button"
          >
            <AddCircle aria-label="add new rounding rule button" />
            <Typography>Add New Rounding Rule</Typography>
          </AddRowLink>
        )}
      </Grid>
      {isWorking && <Loader />}
      <Grid
        container
        mobile={12}
        marginBottom={4}
        data-testid="rounding-rules-list"
      >
        <TableContainer component={Paper}>
          <Table aria-label="rounding rules table">
            <StyledDashboardTableHead>
              <TableRow>
                <StyledNameTableCell>Rounding Rule Name</StyledNameTableCell>
                <StyledNameTableCell>Rounding Mode</StyledNameTableCell>
                <StyledNameTableCell>Rounding Constant</StyledNameTableCell>
                {!isReaderRole && (
                  <StyledActionsTableCell align="right">
                    Action
                  </StyledActionsTableCell>
                )}
              </TableRow>
            </StyledDashboardTableHead>
            {roundingRules.length > 0 && (
              <TableBody>
                {roundingRules.map((roundingRule) => {
                  return (
                    <StyledTableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                      data-testid="cy-rounding-rule-tr"
                    >
                      <StyledNameTableCell>
                        {roundingRule.roundingRuleName}
                      </StyledNameTableCell>
                      <StyledNameTableCell>
                        {roundingRule.roundingMode}
                      </StyledNameTableCell>
                      <StyledNameTableCell>
                        {roundingRule.roundingConstant}
                      </StyledNameTableCell>
                      {!isReaderRole && (
                        <StyledActionsTableCell align="right">
                          <>
                            <IconButton
                              disabled={false}
                              onClick={() => {
                                navigate(
                                  `/rounding-rules/${roundingRule.roundingRuleId}/edit-rounding-rule`
                                );
                              }}
                              data-testid="cy-edit-rounding-rule-button"
                            >
                              <EditIcon
                                aria-label={`Edit rounding rule ${roundingRule.roundingRuleId}`}
                              />
                            </IconButton>
                            <IconButton
                              sx={{ color: "#DA291C" }}
                              onClick={() =>
                                setRoundingRuleToDelete(
                                  roundingRule.roundingRuleId
                                )
                              }
                              aria-label={`Delete rounding rule ${roundingRule.roundingRuleId}`}
                              data-testid="cy-delete-rounding-rule-button"
                            >
                              <DeleteForever />
                            </IconButton>
                          </>
                        </StyledActionsTableCell>
                      )}
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
          {roundingRules.length === 0 && (
            <StyledEmptyBox>
              <Typography variant="h6">{`No rounding rules defined in this market`}</Typography>
            </StyledEmptyBox>
          )}
        </TableContainer>
      </Grid>
      {roundingRuleMappings.map((roundingRuleMapping) => (
        <NutrientRoundingRuleMapping
          roundingRuleMapping={roundingRuleMapping}
          allRoundingRules={roundingRules}
          onClickDelete={handleClickDeleteRoundingRuleMapping}
          onClickCreate={handleClickCreateRoundingRuleMapping}
          onClickEdit={handleClickEditRoundingRuleMapping}
          onError={(message) => setErrorMessage(message)}
        />
      ))}
      {nutrientRoundingRuleForEdit !== null && (
        <NutrientRoundingRuleMappingModal
          initialData={nutrientRoundingRuleForEdit}
          onSaveSuccess={handleRoundingRuleMappingSaveSuccess}
          onCancel={handleCancelEditRoundingRuleMapping}
        />
      )}
      {typeof roundingRuleToDelete === "number" && (
        <ConfirmActionModal
          open={!isRetryDeleteRoundingRuleModalOpen}
          loading={isRoundingRuleDeleting}
          message="Are you sure you want to delete this rounding rule?"
          onConfirm={() => doDeleteRoundingRule()}
          onCancel={() => setRoundingRuleToDelete(null)}
        />
      )}
      {roundingRuleMappingToDelete !== null && (
        <ConfirmActionModal
          open={!isRetryDeleteRoundingRuleMappingModalOpen}
          loading={isRoundingRuleMappingDeleting}
          message="Are you sure you want to delete this nutrient rounding rule?"
          onConfirm={() => doDeleteRoundingRuleMapping()}
          onCancel={() => setRoundingRuleMappingToDelete(null)}
        />
      )}
      <ResendFormModal
        open={isRetryDeleteRoundingRuleModalOpen}
        onResend={handleRetryDeleteRoundingRule}
        onCancel={handleRetryDeleteRoundingRuleCancel}
        description="An error occurred while deleting the rounding rule"
      />
      <ResendFormModal
        open={isRetryDeleteRoundingRuleMappingModalOpen}
        onResend={handleRetryDeleteRoundingRuleMapping}
        onCancel={handleRetryDeleteRoundingRuleMappingCancel}
        description="An error occurred while deleting the nutrient rounding rule"
      />
      <SuccessAlertSnackbar
        message={successMessage}
        onClose={() => setSuccessMessage(null)}
      />
      <ErrorAlertSnackbar
        message={errorMessage}
        onClose={() => setErrorMessage(null)}
      />
    </StyledRelativeContainer>
  );
}, "Rounding Rule Management");

type NutrientRoundingRuleMappingProps = {
  roundingRuleMapping: NutrientRoundingRuleMappingConfig;
  allRoundingRules: RoundingRule[];
  onClickDelete: (
    roundingRuleMappingId: number,
    roundingRuleTypeId: number
  ) => void;
  onClickCreate: (nutrientFactId: number) => void;
  onClickEdit: (
    roundingRuleMapping: NutrientRoundingRuleMappingForEdit
  ) => void;
  onError: (message: string) => void;
};
const NutrientRoundingRuleMapping = ({
  roundingRuleMapping,
  allRoundingRules,
  onClickDelete,
  onClickCreate,
  onClickEdit,
  onError,
}: NutrientRoundingRuleMappingProps) => {
  const { isReaderRole, selectedCountry } = useContext(RoleContext);
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const getRoundingRuleIdByName = useCallback(
    (name: string): number | undefined => {
      const roundingRule = allRoundingRules.find(
        (rr) => rr.roundingRuleName === name
      );
      if (typeof roundingRule !== "undefined") {
        return roundingRule.roundingRuleId;
      }
    },
    [allRoundingRules]
  );

  return (
    <Stack
      spacing={1}
      marginBottom={2}
      data-testid="cy-nutrient-for-rounding-rule-mapping"
    >
      <Grid container mobile={12}>
        <Grid container justifyContent="space-between" padding={1}>
          <Grid item>
            <Grid container>
              <IconButton
                onClick={() => {
                  setIsOpen(!isOpen);
                }}
                aria-label="expand"
              >
                {!isOpen ? (
                  <ArrowRight fontSize="large" />
                ) : (
                  <ArrowDropDown fontSize="large" />
                )}
              </IconButton>
              <Typography variant="h4" padding={1}>
                {roundingRuleMapping.nutrientFactName}
              </Typography>
            </Grid>
          </Grid>
          {!isReaderRole && (
            <AddRowLink
              onClick={() => onClickCreate(roundingRuleMapping.nutrientFactId)}
              data-testid="add-new-rounding-rule-mapping-button"
            >
              <AddCircle aria-label="add new rounding rule mapping button" />
              <Typography>Add Nutrient-Rounding Rule Mapping</Typography>
            </AddRowLink>
          )}
        </Grid>
      </Grid>
      <Collapse in={isOpen}>
        <Grid container mobile={12}>
          <Grid mobile={6} padding={1}>
            <Grid>
              <Typography variant="h5" paddingBottom={1}>
                Value
              </Typography>
            </Grid>
            <TableContainer component={Paper}>
              <Table
                aria-label={`${roundingRuleMapping.nutrientFactName} value rounding rule mappings`}
              >
                <StyledDashboardTableHead>
                  <TableRow>
                    <TableCell>Rule Name</TableCell>
                    <TableCell>Min Value </TableCell>
                    <TableCell>Max Value </TableCell>
                    {!isReaderRole && (
                      <TableCell align="right">Action</TableCell>
                    )}
                  </TableRow>
                </StyledDashboardTableHead>
                {roundingRuleMapping.roundingRuleValue &&
                  roundingRuleMapping.roundingRuleValue.length > 0 && (
                    <TableBody>
                      {roundingRuleMapping.roundingRuleValue.map(
                        (roundingRuleMappingValue, index) => (
                          <StyledTableRow
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                            data-testid="cy-rounding-rule-mapping-tr"
                          >
                            <TableCell>
                              {roundingRuleMappingValue.ruleName}
                            </TableCell>
                            <TableCell>
                              {localizedNumStringOrNA(
                                roundingRuleMappingValue.minVal,
                                selectedCountry!
                              )}
                            </TableCell>
                            <TableCell>
                              {localizedNumStringOrNA(
                                roundingRuleMappingValue.maxVal,
                                selectedCountry!
                              )}
                            </TableCell>
                            {!isReaderRole && (
                              <TableCell align="right">
                                <IconButton
                                  onClick={() => {
                                    const roundingRuleId =
                                      getRoundingRuleIdByName(
                                        roundingRuleMappingValue.ruleName
                                      );
                                    if (typeof roundingRuleId === "number") {
                                      onClickEdit({
                                        roundingRuleMappingId:
                                          roundingRuleMappingValue.roundingRuleMappingId,
                                        nutrientFactId:
                                          roundingRuleMapping.nutrientFactId,
                                        roundingRuleId,
                                        roundingRuleTypeId: 1,
                                        minValue:
                                          roundingRuleMappingValue.minVal,
                                        maxValue:
                                          roundingRuleMappingValue.maxVal,
                                      });
                                    } else {
                                      onError(
                                        "There was an error opening this nutrient rounding rule for edit"
                                      );
                                    }
                                  }}
                                  data-testid="cy-edit-rounding-rule-mapping-button"
                                >
                                  <EditIcon
                                    aria-label={`Edit nutrient rounding rule ${roundingRuleMappingValue.ruleName} (Value)`}
                                  />
                                </IconButton>
                                <IconButton
                                  sx={{ color: "#DA291C" }}
                                  onClick={() =>
                                    onClickDelete(
                                      roundingRuleMappingValue.roundingRuleMappingId,
                                      1
                                    )
                                  }
                                  data-testid="cy-delete-rounding-rule-mapping-button"
                                  aria-label={`Delete nutrient rounding rule ${roundingRuleMappingValue.ruleName} (Value)`}
                                >
                                  <DeleteForever />
                                </IconButton>
                              </TableCell>
                            )}
                          </StyledTableRow>
                        )
                      )}
                    </TableBody>
                  )}
              </Table>
              {(!roundingRuleMapping.roundingRuleValue ||
                roundingRuleMapping.roundingRuleValue.length === 0) && (
                <StyledEmptyBox>
                  <Typography variant="h6">{`No value rounding rules mapped to this nutrient`}</Typography>
                </StyledEmptyBox>
              )}
            </TableContainer>
          </Grid>
          <Grid mobile={6} padding={1}>
            <Grid>
              <Typography variant="h5" paddingBottom={1}>
                Daily Value
              </Typography>
            </Grid>
            <TableContainer component={Paper}>
              <Table
                aria-label={`${roundingRuleMapping.nutrientFactName} daily value rounding rule mappings`}
              >
                <StyledDashboardTableHead>
                  <TableRow>
                    <TableCell>Rule Name</TableCell>
                    <TableCell>Min Value </TableCell>
                    <TableCell>Max Value </TableCell>
                    {!isReaderRole && (
                      <TableCell align="right">Action</TableCell>
                    )}
                  </TableRow>
                </StyledDashboardTableHead>
                {roundingRuleMapping.roundingRuleDailyValue &&
                  roundingRuleMapping.roundingRuleDailyValue.length > 0 && (
                    <TableBody>
                      {roundingRuleMapping.roundingRuleDailyValue.map(
                        (roundingRuleMappingDailyValue, index) => (
                          <StyledTableRow
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                            data-testid="cy-rounding-rule-mapping-dv-tr"
                          >
                            <TableCell>
                              {roundingRuleMappingDailyValue.ruleName}
                            </TableCell>
                            <TableCell>
                              {localizedNumStringOrNA(
                                roundingRuleMappingDailyValue.minVal,
                                selectedCountry!
                              )}
                            </TableCell>
                            <TableCell>
                              {localizedNumStringOrNA(
                                roundingRuleMappingDailyValue.maxVal,
                                selectedCountry!
                              )}
                            </TableCell>
                            {!isReaderRole && (
                              <TableCell align="right">
                                <IconButton
                                  onClick={() => {
                                    const roundingRuleId =
                                      getRoundingRuleIdByName(
                                        roundingRuleMappingDailyValue.ruleName
                                      );
                                    if (typeof roundingRuleId === "number") {
                                      onClickEdit({
                                        roundingRuleMappingId:
                                          roundingRuleMappingDailyValue.roundingRuleMappingId,
                                        nutrientFactId:
                                          roundingRuleMapping.nutrientFactId,
                                        roundingRuleId,
                                        roundingRuleTypeId: 2,
                                        minValue:
                                          roundingRuleMappingDailyValue.minVal,
                                        maxValue:
                                          roundingRuleMappingDailyValue.maxVal,
                                      });
                                    } else {
                                      onError(
                                        "There was an error opening this nutrient rounding rule for edit"
                                      );
                                    }
                                  }}
                                  data-testid="cy-edit-dv-rounding-rule-mapping-button"
                                >
                                  <EditIcon
                                    aria-label={`Edit rounding rule ${roundingRuleMappingDailyValue.ruleName} (Daily Value)`}
                                  />
                                </IconButton>
                                <IconButton
                                  sx={{ color: "#DA291C" }}
                                  onClick={() =>
                                    onClickDelete(
                                      roundingRuleMappingDailyValue.roundingRuleMappingId,
                                      2
                                    )
                                  }
                                  data-testid="cy-delete-dv-rounding-rule-mapping-button"
                                  aria-label={`Delete nutrient rounding rule ${roundingRuleMappingDailyValue.ruleName} (Daily Value)`}
                                >
                                  <DeleteForever />
                                </IconButton>
                              </TableCell>
                            )}
                          </StyledTableRow>
                        )
                      )}
                    </TableBody>
                  )}
              </Table>
              {(!roundingRuleMapping.roundingRuleDailyValue ||
                roundingRuleMapping.roundingRuleDailyValue.length === 0) && (
                <StyledEmptyBox>
                  <Typography variant="h6">{`No daily value rounding rules mapped to this nutrient`}</Typography>
                </StyledEmptyBox>
              )}
            </TableContainer>
          </Grid>
        </Grid>
      </Collapse>
    </Stack>
  );
};

const StyledRelativeContainer = styled(Grid)({
  margin: 0,
  position: "relative",
});
const StyledEmptyBox = styled(Box)(({ theme }) => ({
  display: "flex",
  height: 40,
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: theme.palette.secondary.main,
}));
export const StyledDashboardTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  fontSize: theme.typography.small.fontSize,
}));
const StyledTableRow = styled(TableRow)({
  borderBottom: "unset",
  backgroundColor: "white",
  height: 36,
});
const StyledNameTableCell = styled(TableCell)({
  width: "20%",
});
const StyledActionsTableCell = styled(TableCell)({
  width: "10%",
});
