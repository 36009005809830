import {
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  TextFieldProps,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useForm } from "react-hook-form";
import { components } from "../data/miscellaneous.types";
import { forwardRef, useCallback, useId, useState } from "react";
import { ConfirmActionModal } from "./ConfirmActionModal";

type AddStatusFormModalProps = {
  isOpen: boolean;
  onCancel: () => void;
  onSave: (formData: AddStatusFormDataType) => void;
};

export type AddStatusFormDataType = Partial<
  components["schemas"]["StatusFlagRequestBody"]
>;

export const AddStatusFormModal = ({
  isOpen,
  onCancel,
  onSave,
}: AddStatusFormModalProps) => {
  const formId = useId();
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isDirty },
  } = useForm<AddStatusFormDataType>({
    defaultValues: {},
  });

  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  const onSubmit = useCallback(
    (data: AddStatusFormDataType) => {
      onSave(data);
      reset({ statusFlagName: "" });
    },
    [onSave, reset]
  );

  const onCancelClick = useCallback(() => {
    if (isDirty && watch("statusFlagName")) {
      setIsConfirmOpen(true);
    } else {
      reset({ statusFlagName: "" });
      onCancel();
    }
  }, [isDirty, onCancel, reset, watch]);

  return (
    <form id={formId}>
      <ConfirmActionModal
        open={isConfirmOpen}
        loading={false}
        message={"Are you sure you want to cancel? Your changes will be lost."}
        onCancel={() => setIsConfirmOpen(false)}
        onConfirm={() => {
          setIsConfirmOpen(false);
          reset();
          onCancel();
        }}
      />
      <Dialog open={isOpen}>
        <DialogTitle>Add Status</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid mobile={12}>
              <Stack
                spacing={2}
                sx={{ width: 360 }}
                style={{ paddingTop: 5, paddingBottom: 5 }}
                component="div"
              >
                <StyledTextField
                  autoFocus
                  {...register("statusFlagName", {
                    required: "Name is required",
                  })}
                  label="Name"
                  error={!!errors.statusFlagName}
                  helperText={errors.statusFlagName?.message?.toString()}
                  required
                />
              </Stack>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <StyledButton color="error" onClick={onCancelClick}>
            Cancel
          </StyledButton>
          <StyledButton type="submit" onClick={handleSubmit(onSubmit)}>
            Submit
          </StyledButton>
        </DialogActions>
      </Dialog>
    </form>
  );
};

const StyledTextField = forwardRef<HTMLDivElement, TextFieldProps>(
  ({ children, ...args }, ref) => (
    <TextField fullWidth size="small" {...args} ref={ref}>
      {children}
    </TextField>
  )
);

const StyledButton = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, ...args }, ref) => {
    return (
      <Button variant="contained" disableRipple {...args} ref={ref}>
        {children}
      </Button>
    );
  }
);
