import { components } from "../data/miscellaneous.types";
import { isDefined } from "../utils";

type ElementAttribute = components["schemas"]["Attribute"];
export type FormattedElementAttribute = Omit<
  ElementAttribute,
  "displayOrder"
> & {
  displayOrder: number;
};

export type CommonAttributeMap = {
  category: FormattedElementAttribute[];
  item: FormattedElementAttribute[];
  product: FormattedElementAttribute[];
};

export type AttributeDisplayOrder =
  components["schemas"]["AttributeDisplayOrder"];

export function formatElementAttribute(
  rawElementAttribute: any
): FormattedElementAttribute | undefined {
  const {
    id,
    displayOrder,
    attributeName,
    type,
    uiType,
    system,
    common,
    translatable,
  } = rawElementAttribute;
  if (typeof id === "number" && !isNaN(Number(displayOrder))) {
    return {
      id,
      displayOrder: Number(displayOrder),
      attributeName: typeof attributeName === "string" ? attributeName : "",
      type: typeof type === "string" ? type : "",
      uiType: typeof uiType === "string" ? uiType : "",
      system: typeof system === "string" ? system : "",
      common: typeof common === "string" ? common : "",
      translatable: typeof translatable === "string" ? translatable : "",
    };
  }
}

export function formatCommonAttributeMap(
  rawCommonAttributeMap: any
): CommonAttributeMap {
  const { category, item, product } = rawCommonAttributeMap;
  return {
    category: Array.isArray(category)
      ? category.map(formatElementAttribute).filter(isDefined)
      : [],
    item: Array.isArray(item)
      ? item.map(formatElementAttribute).filter(isDefined)
      : [],
    product: Array.isArray(product)
      ? product.map(formatElementAttribute).filter(isDefined)
      : [],
  };
}
