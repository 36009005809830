import { AxiosHeaders } from "axios";
import items from "./json/item-list.json";
import optionalItems from "./json/optional-items.json";
import mutexGroups from "./json/mutex-groups.json";
import {
  realGetIngredientsByItemAndProductIds,
  realGetMarketingInformation,
  realGetProductsByItemId,
  realGetNutritionFactsByProductIds,
  realGetItemCategoryMapping,
  realSaveItemMarketingInformation,
  realSaveItemCategoryMapping,
  realGetSelectedItemsGoesWellWith,
  realSaveItemGoesWellWith,
  realGetRegionsList,
  realGetCoopMappedDataByItemID,
  realSaveItemCoopMapping,
  realGetCompareLive,
  realGetCompareCurrent,
  realGetItemList,
  realGetItemRelationshipMapping,
  realGetRelationTypes,
  realGetSelectedItemRelationship,
  realSaveItemRelationship,
  realSaveItemProductMapping,
  realGetAllOptionalItems,
  realGetAllMutexGroups,
  realGetSelectedElementsMapping,
  realItemGetReviewAndSubmit,
  realSaveItemReviewAndSubmit,
  realGetItemServingSize,
  realGetUomDetails,
  realRecalculateServingSize,
  realSaveItemServingSize,
  realGetProductDetailsByMutexId,
  realGetOptionalItemDetailsByMutexId,
  realSaveMutexGroup,
  realGetArchiveItem,
  realHardDeleteItem,
  realRestoreItem,
  realSoftDeleteItem,
  realSaveNutritionInformationByItemId,
  realDeleteSelectedItemRelationship,
  realSaveIsFortifiedApiRequest,
  realGetIsFortifiedItemValueQuery,
  realGetFortifiedItemsList,
  realUpdateFortifiedItems,
  realGetElementLiveStatus,
} from "../api/items";
import { delay } from ".";

export const mockGetItemList: ReturnType<typeof realGetItemList> = async () => {
  await delay(1.5);
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful.",
      dataList: items,
      totalRecordCount: items.length,
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockGetMarketingInformation: ReturnType<
  typeof realGetMarketingInformation
> = async () => {
  await delay(1.5);
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful",
      dropDownList: {
        item_type: [
          {
            value: "Core Item",
            id: 5,
          },
          {
            value: "Promotional Item",
            id: 6,
          },
          {
            value: "Set Item",
            id: 7,
          },
          {
            value: "Item Collection",
            id: 8,
          },
        ],
        do_not_show: [
          {
            value: "Core",
            id: 317,
          },
          {
            value: "Promotional",
            id: 318,
          },
          {
            value: "Do not Show",
            id: 319,
          },
        ],
        time_of_day: [
          {
            value: "Whole Day",
            id: 1,
          },
          {
            value: "Rest Of Day",
            id: 2,
          },
          {
            value: "Breakfast",
            id: 3,
          },
        ],
        priority: [
          {
            value: "0.6",
            id: 904,
          },
          {
            value: "0.7",
            id: 905,
          },
          {
            value: "0.8",
            id: 906,
          },
          {
            value: "0.9",
            id: 907,
          },
        ],
        item_status_flag: [
          {
            value: "None",
            id: 7,
          },
          {
            value: "New",
            id: 8,
          },
          {
            value: "Limited Time Only",
            id: 9,
          },
          {
            value: "Introducing",
            id: 10,
          },
          {
            value: "Now Including",
            id: 11,
          },
          {
            value: "Now Included",
            id: 12,
          },
          {
            value: "Its Back",
            id: 151,
          },
          {
            value: "They are Back",
            id: 153,
          },
        ],
        logoList: [],
        frequency: [
          {
            value: "Daily",
            id: 900,
          },
          {
            value: "Weekly",
            id: 901,
          },
          {
            value: "Monthly",
            id: 902,
          },
          {
            value: "Yearly",
            id: 903,
          },
        ],
      },
      attributeList: [
        {
          keyName: "item_type",
          name: "Item Type",
          value: "Core Item",
          id: 15,
        },
        {
          keyName: "time_of_day",
          name: "Time Of Day",
          value: "",
          id: 16,
        },
        {
          keyName: "item_name",
          name: "Item Name",
          value: "Small French Fries ",
          id: 17,
        },
        {
          keyName: "item_marketing_name",
          name: "Item Marketing Name",
          value: "World Famous Fries® ",
          id: 18,
        },
        {
          keyName: "short_name",
          name: "Short Name",
          value: "small-french-fries",
          id: 439,
        },
        {
          keyName: "genesis_item_name",
          name: "Genesis Item Name",
          value: "French Fries (Small)",
          id: 201,
        },
        {
          keyName: "item_id",
          name: "Item ID",
          value: "200066",
          id: 21,
        },
        {
          keyName: "menu_item_no",
          name: "RFM Id / Menu Item No / External Id / Import Id",
          value: "6050",
          id: 22,
        },
        {
          keyName: "genesis_menu_item_no",
          name: "Genesis Menu Item No.",
          value: "6050-000",
          id: 203,
        },
        {
          keyName: "do_not_show",
          name: "Do Not Show",
          value: "Core",
          id: 58,
        },
        {
          keyName: "serves",
          name: "Serves",
          value: "",
          id: 200,
        },
        {
          keyName: "item_name_special_symbols",
          name: "Item Name Special Symbols",
          value: "",
          id: 19,
        },
        {
          keyName: "description",
          name: "Description",
          value:
            'McDonald\'s World Famous Fries® are made with premium potatoes such as the Russet Burbank and the Shepody. With 0g of trans fat per labeled serving, these epic fries are crispy and golden on the outside and fluffy on the inside. Best of all, you can get medium Fries for free every Friday with any $1 purchase, exclusively in the <a href="https://www.mcdonalds.com/us/en-us/download-app.html">McDonald’s App</a>. Grab our World Famous Fries® and pair with one of our classic <a href="https://www.mcdonalds.com/us/en-us/full-menu/burgers.html">McDonald’s Burgers</a> today! There are 220 calories in a small McDonald\'s fries. Order your fries today with <a href="https://www.mcdonalds.com/us/en-us/mcdelivery.html">McDelivery®</a> or get them with curbside pickup using <a href="https://www.mcdonalds.com/us/en-us/mobile-order-and-pay.html">Mobile Order & Pay</a>!<br><br>Download the <a href="https://mcdonalds.smart.link/yotm502tc?site_id=pdp&creative_id=text-publication">McDonald’s app</a> and earn points on every order with <a href="https://www.mcdonalds.com/us/en-us/mymcdonalds.html">MyMcDonald\'s Rewards</a> to redeem for a free World Famous Fries®.^<br><br><small>^Refer to <a href="https://www.mcdonalds.com/us/en-us/terms-and-conditions.html#rewards-anchor"> MyMcDonald’s Program Terms </a> for details. Excludes delivery. McD app download and registration required.</small><br><small>Offer valid 1x use each Friday thru 3/31/22 with minimum purchase of $1 (excluding tax). McD App download and registration required. Offer only available through Mobile Order & Pay. Mobile Order & Pay at participating McDonald\'s. © 2022 McDonald\'s</small>',
          id: 20,
        },
        {
          keyName: "item_comments",
          name: "Item Comments",
          value: "",
          id: 31,
        },
        {
          keyName: "keywords",
          name: "Keywords",
          value:
            "french fries, snack, sides, potato, zero trans fat, trans fat, canola oil, favorite, best fries, best french fries ",
          id: 28,
        },
        {
          keyName: "item_ingredient_statement",
          name: "Ingredient Statement",
          value:
            "Ingredients: Potatoes, Vegetable Oil (canola Oil, Corn Oil, Soybean Oil, Hydrogenated Soybean Oil, Natural Beef Flavor [wheat And Milk Derivatives]), Dextrose, Sodium Acid Pyrophosphate (maintain Color), Salt. natural Beef Flavor Contains Hydrolyzed Wheat And Hydrolyzed Milk As Starting Ingredients.",
          id: 60,
        },
        {
          keyName: "item_additional_text_ingredient_statement",
          name: "Additional Text For Ingredient Statement",
          value: "",
          id: 12,
        },
        {
          keyName: "item_allergen",
          name: "Item Allergen",
          value: "Wheat, Milk..",
          id: 40,
        },
        {
          keyName: "item_additional_allergen",
          name: "Additional Allergen",
          value: "",
          id: 30,
        },
        {
          keyName: "text",
          name: "Nutrition Quality Statement Text",
          value: "",
          id: 26,
        },
        {
          keyName: "item_meta_title",
          name: "Item Meta Title",
          value: "World Famous Fries®: Calories & Nutrition | McDonald’s",
          id: 627,
        },
        {
          keyName: "item_meta_description",
          name: "Item Meta Description",
          value:
            "Our World Famous Fries® at McDonald’s are the perfect snack or addition to any meal. Get your McDonald's fries with curbside pickup when you Mobile Order & Pay!",
          id: 633,
        },
        {
          keyName: "customization_label",
          name: "Customization Label",
          value: "",
          id: 628,
        },
        {
          keyName: "customization_button",
          name: "Customization Button",
          value: "",
          id: 629,
        },
        {
          keyName: "show_product_images",
          name: "Show Product Images?",
          value: "No",
          id: 630,
        },
        {
          keyName: "logo_1",
          name: "Logo 1",
          value: "No",
          id: 1564,
        },
        {
          keyName: "logo_2",
          name: "Logo 2",
          value: "No",
          id: 1565,
        },
        {
          keyName: "logo_3",
          name: "Logo 3",
          value: "No",
          id: 1566,
        },
        {
          keyName: "item_status_flag",
          name: "Status Flag",
          value: "None",
          id: 1631,
        },
        {
          keyName: "specialization_text1",
          name: "Specialization Text 1",
          value: "",
          id: 631,
        },
        {
          keyName: "specialization_text2",
          name: "Specialization Text 2",
          value: "",
          id: 632,
        },
        {
          keyName: "sitemap",
          name: "SiteMap",
          value: "No",
          id: 1437,
        },
        {
          keyName: "frequency",
          name: "Frequency",
          value: "",
          id: 1438,
        },
        {
          keyName: "priority",
          name: "Priority",
          value: "",
          id: 1439,
        },
        {
          keyName: "attach_item_thumbnail_image",
          name: "Thumbnail Image",
          value: "t-mcdonalds-fries-small.jpg",
          id: 33,
        },
        {
          keyName: "attach_item_hero_image",
          name: "Hero Image",
          value: "t-mcdonalds-fries-small.jpg",
          id: 34,
        },
        {
          keyName: "attach_transparent_icon_image",
          name: "Transparent Icon Image",
          value: "t-mcdonalds-fries-small.png",
          id: 634,
        },
        {
          keyName: "attach_meal_bundle_image",
          name: "Meal Bundle Image",
          value: "",
          id: 38,
        },
        {
          keyName: "has_components",
          name: "Has Components",
          value: "Yes",
          id: 23,
        },
        {
          keyName: "footer",
          name: "Footer",
          value: "Footer",
          id: 41,
        },
      ],
      footerList: [],
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockGetProductsByItemId: ReturnType<
  typeof realGetProductsByItemId
> = async () => {
  await delay(1.5);
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful",
      itemName: "Biscuit",
      products: [
        {
          productId: 300131,
          productName: "Biscuit",
        },
        {
          productId: 300310,
          productName: "Salted Butter",
        },
      ],
      optionalItems: [
        {
          optionalItemId: 300131,
          optionalItemName: "Biscuit",
        },
      ],
      mutexGroup: [
        {
          elementName: "test mutex item",
          elementId: 329974,
          mutexId: 74,
          elementType: "Optional Item",
        },
        {
          elementName: "test mutex item two",
          elementId: 329975,
          mutexId: 75,
          elementType: "Optional Item",
        },
        {
          elementName: "test mutex item three",
          elementId: 329976,
          mutexId: 76,
          elementType: "Optional Item",
        },
      ],
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockGetIngredientsByItemAndProductIds: ReturnType<
  typeof realGetIngredientsByItemAndProductIds
> = async ({ itemId, productId }) => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful",
      ingredients: [
        {
          productName: "Shredded Lettuce",
          ingredients: "Ingredients: Lettuce.",
          contains: "",
          productAdditionalText: "",
          displayOrder: 1,
        },
        {
          productName: "Mayonnaise",
          ingredients:
            "Ingredients: Soybean Oil, Egg Yolk, Water, Distilled Vinegar, Salt, Sugar, Spice, Lemon Juice Concentrate.",
          contains: "Egg.",
          productAdditionalText: "",
          displayOrder: 2,
        },
        {
          productName: "McChicken Patty",
          ingredients:
            "Ingredients: Boneless Chicken, Bleached Wheat Flour, Water, Vegetable Oil (canola Oil, Corn Oil, Soybean Oil, Hydrogenated Soybean Oil), Wheat Flour, Modified Corn Starch, Sea Salt, Spice, Potassium Chloride, Salt, Paprika, Dextrose, Sodium Phosphates, Leavening (ammonium Bicarbonate, Sodium Acid Pyrophosphate, Baking Soda, Monocalcium Phosphate), Wheat Gluten, Natural Flavors With Extractives Of Paprika, Yeast, Corn Starch, Garlic Powder.",
          contains: "Wheat.",
          productAdditionalText: "",
          displayOrder: 3,
        },
        {
          productName: "Regular Bun",
          ingredients:
            "Ingredients: Enriched Flour (wheat Flour, Malted Barley Flour, Niacin, Iron, Thiamine Mononitrate, Riboflavin, Folic Acid), Water, Sugar, Yeast, Soybean Oil, Contains 2% Or Less: Salt, Wheat Gluten, Potato Flour, Vinegar, Dextrose, Corn Starch, Modified Food Starch, Vegetable Proteins (pea, Potato, Rice And/or Faba Bean), Sunflower And/or Canola Oil, Maltodextrin, Natural Flavors, May Contain One Or More Dough Conditioners (datem, Ascorbic Acid, Mono And Diglycerides, Enzymes).",
          contains: "Wheat.",
          productAdditionalText: "May Contain: Sesame.",
          displayOrder: 4,
        },
      ],
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockGetNutritionFactsByProductIds: ReturnType<
  typeof realGetNutritionFactsByProductIds
> = async ({ itemId, productCombinationId }) => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful",
      nutrientFacts: [
        {
          nutrientFactId: 2,
          nutrientFactName: "Calories",
          nutrientValue: "280",
          uomName: "Cal.",
          dvValue: "",
          hundredGramPerProduct: "415.2",
        },
        {
          nutrientFactId: 23,
          nutrientFactName: "Calories From Fat",
          nutrientValue: "120",
          uomName: "Cal.",
          dvValue: "",
          hundredGramPerProduct: "178",
        },
        {
          nutrientFactId: 30,
          nutrientFactName: "Energy kJ",
          nutrientValue: "1170",
          uomName: "kJ",
          dvValue: "",
          hundredGramPerProduct: "1735.1",
        },
        {
          nutrientFactId: 11,
          nutrientFactName: "Protein",
          nutrientValue: "6",
          uomName: "g",
          dvValue: "",
          hundredGramPerProduct: "8.9",
        },
        {
          nutrientFactId: 22,
          nutrientFactName: "Carbohydrates",
          nutrientValue: "34",
          uomName: "g",
          dvValue: "12",
          hundredGramPerProduct: "50.4",
        },
        {
          nutrientFactId: 9,
          nutrientFactName: "Dietary Fiber 2015",
          nutrientValue: "1",
          uomName: "g",
          dvValue: "3",
          hundredGramPerProduct: "1.5",
        },
        {
          nutrientFactId: 10,
          nutrientFactName: "Total Sugars",
          nutrientValue: "9",
          uomName: "g",
          dvValue: "",
          hundredGramPerProduct: "13.3",
        },
        {
          nutrientFactId: 21,
          nutrientFactName: "Total Fat",
          nutrientValue: "13",
          uomName: "g",
          dvValue: "17",
          hundredGramPerProduct: "19.3",
        },
        {
          nutrientFactId: 31,
          nutrientFactName: "Added Sugars",
          nutrientValue: "8",
          uomName: "g",
          dvValue: "16",
          hundredGramPerProduct: "11.9",
        },
        {
          nutrientFactId: 4,
          nutrientFactName: "Saturated Fat",
          nutrientValue: "4.5",
          uomName: "g",
          dvValue: "23",
          hundredGramPerProduct: "6.7",
        },
        {
          nutrientFactId: 29,
          nutrientFactName: "Trans Fat",
          nutrientValue: "0",
          uomName: "g",
          dvValue: "",
          hundredGramPerProduct: "0",
        },
        {
          nutrientFactId: 6,
          nutrientFactName: "Cholesterol",
          nutrientValue: "15",
          uomName: "mg",
          dvValue: "5",
          hundredGramPerProduct: "22.2",
        },
        {
          nutrientFactId: 28,
          nutrientFactName: "Vitamin B6",
          nutrientValue: "0",
          uomName: "mg",
          dvValue: "0",
          hundredGramPerProduct: "0",
        },
        {
          nutrientFactId: 27,
          nutrientFactName: "Vitamin D",
          nutrientValue: "0",
          uomName: "mcg",
          dvValue: "0",
          hundredGramPerProduct: "0",
        },
        {
          nutrientFactId: 14,
          nutrientFactName: "Calcium",
          nutrientValue: "20",
          uomName: "mg",
          dvValue: "2",
          hundredGramPerProduct: "29.7",
        },
        {
          nutrientFactId: 15,
          nutrientFactName: "Iron",
          nutrientValue: "1.5",
          uomName: "mg",
          dvValue: "8",
          hundredGramPerProduct: "2.2",
        },
        {
          nutrientFactId: 24,
          nutrientFactName: "Phosphorus",
          nutrientValue: "0",
          uomName: "mg",
          dvValue: "0",
          hundredGramPerProduct: "0",
        },
        {
          nutrientFactId: 25,
          nutrientFactName: "Potassium",
          nutrientValue: "55",
          uomName: "mg",
          dvValue: "2",
          hundredGramPerProduct: "81.6",
        },
        {
          nutrientFactId: 7,
          nutrientFactName: "Sodium",
          nutrientValue: "260",
          uomName: "mg",
          dvValue: "11",
          hundredGramPerProduct: "385.6",
        },
        {
          nutrientFactId: 26,
          nutrientFactName: "Caffeine",
          nutrientValue: "0",
          uomName: "mg",
          dvValue: "",
          hundredGramPerProduct: "0",
        },
        {
          nutrientFactId: 32,
          nutrientFactName: "Dietary Fiber",
          nutrientValue: "0",
          uomName: "{}",
          dvValue: "0",
          hundredGramPerProduct: "0",
        },
      ],
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};
export const mockGetItemCategoryMapping: ReturnType<
  typeof realGetItemCategoryMapping
> = async () => {
  await delay(1.5);
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful.",
      itemName: "Sausage Biscuit ",
      dataList: [
        {
          categoryId: 100002,
          defaultCategory: "Y",
          categoryName: "Breakfast",
        },
        {
          categoryId: 100009,
          defaultCategory: "N",
          categoryName: "Dollar Menu",
        },
        {
          categoryId: 100015,
          defaultCategory: "N",
          categoryName: "All Day Breakfast",
        },
        {
          categoryId: 100018,
          defaultCategory: "N",
          categoryName: "ADB Biscuit",
        },
        {
          categoryId: 100040,
          defaultCategory: "N",
          categoryName: "AM D123 Menu",
        },
        {
          categoryId: 100042,
          defaultCategory: "N",
          categoryName: "D123 Menu",
        },
      ],
      footerList: [],
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const  mockSaveIsFortifiedApiRequest: ReturnType<
  typeof realSaveIsFortifiedApiRequest
> = async (body) => {
  await delay(2);
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "Data has been saved successfully.",
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};
export const mockSaveItemMarketingInformation: ReturnType<
  typeof realSaveItemMarketingInformation
> = async (body) => {
  await delay(2);
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "Data has been updated successfully.",
      itemId: 200734,
      attributeList: [
        {
          id: 15,
          keyName: "item_type",
          name: "Item Type",
          value: "Item Collection",
        },
        {
          id: 16,
          keyName: "time_of_day",
          name: "Time Of Day",
          value: "Whole Day",
        },
        {
          id: 17,
          keyName: "item_name",
          name: "Item Name",
          value: "10 piece Chicken McNuggets® Meal",
        },
        {
          id: 18,
          keyName: "item_marketing_name",
          name: "Item Marketing Name",
          value: "10 piece Chicken McNuggets® Meal",
        },
        {
          id: 439,
          keyName: "short_name",
          name: "Short Name",
          value: "chicken-mcnuggets-10-piece-meal",
        },
        {
          id: 201,
          keyName: "genesis_item_name",
          name: "Genesis Item Name",
          value: "",
        },
        {
          id: 21,
          keyName: "item_id",
          name: "Item ID",
          value: "200734",
        },
        {
          id: 22,
          keyName: "menu_item_no",
          name: "RFM Id / Menu Item No / External Id / Import Id",
          value: "200734",
        },
        {
          id: 203,
          keyName: "genesis_menu_item_no",
          name: "Genesis Menu Item No.",
          value: "",
        },
        {
          id: 58,
          keyName: "do_not_show",
          name: "Do Not Show",
          value: "Core",
        },
        {
          id: 200,
          keyName: "serves",
          name: "Serves",
          value: "",
        },
        {
          id: 19,
          keyName: "item_name_special_symbols",
          name: "Item Name Special Symbols",
          value: "",
        },
        {
          id: 20,
          keyName: "description",
          name: "Description",
          value:
            'The McDonald\'s 10 piece Chicken McNuggets® Combo Meal features 10 tender and delicious Chicken McNuggets® made with all white meat chicken—plus our World Famous Fries® and your choice of a Medium McDonald’s drink. There are 950 calories in a Chicken McNuggets® Combo Meal with medium Fries and a medium Coca-Cola®. Get it today when you <a href="https://www.mcdonalds.com/us/en-us/mobile-order-and-pay.html">Mobile Order & Pay</a> or get it delivered with <a href="https://www.mcdonalds.com/us/en-us/mcdelivery.html">McDelivery®</a>.',
        },
        {
          id: 31,
          keyName: "item_comments",
          name: "Item Comments",
          value: "",
        },
        {
          id: 28,
          keyName: "keywords",
          name: "Keywords",
          value: "10 piece mcnuggets, mcnuggets meal",
        },
        {
          id: 60,
          keyName: "item_ingredient_statement",
          name: "Ingredient Statement",
          value: "",
        },
        {
          id: 12,
          keyName: "item_additional_text_ingredient_statement",
          name: "Additional Text For Ingredient Statement",
          value: "",
        },
        {
          id: 40,
          keyName: "item_allergen",
          name: "Item Allergen",
          value: "",
        },
        {
          id: 30,
          keyName: "item_additional_allergen",
          name: "Additional Allergen",
          value: "",
        },
        {
          id: 26,
          keyName: "text",
          name: "Nutrition Quality Statement Text",
          value: "",
        },
        {
          id: 627,
          keyName: "item_meta_title",
          name: "Item Meta Title",
          value: "10 Piece Chicken McNuggets® Meal | McDonald's",
        },
        {
          id: 633,
          keyName: "item_meta_description",
          name: "Item Meta Description",
          value:
            "Enjoy crispy Chicken McNuggets® made with all white-meat chicken in our 10 pc McNuggets Combo Meal with fries and a drink-- get it today with McDelivery®!",
        },
        {
          id: 628,
          keyName: "customization_label",
          name: "Customization Label",
          value: "",
        },
        {
          id: 629,
          keyName: "customization_button",
          name: "Customization Button",
          value: "",
        },
        {
          id: 630,
          keyName: "show_product_images",
          name: "Show Product Images?",
          value: "No",
        },
        {
          id: 1564,
          keyName: "logo_1",
          name: "Logo 1",
          value: "No",
        },
        {
          id: 1565,
          keyName: "logo_2",
          name: "Logo 2",
          value: "No",
        },
        {
          id: 1566,
          keyName: "logo_3",
          name: "Logo 3",
          value: "No",
        },
        {
          id: 1631,
          keyName: "item_status_flag",
          name: "Status Flag",
          value: "None",
        },
        {
          id: 631,
          keyName: "specialization_text1",
          name: "Specialization Text 1",
          value: "",
        },
        {
          id: 632,
          keyName: "specialization_text2",
          name: "Specialization Text 2",
          value: "",
        },
        {
          id: 1437,
          keyName: "sitemap",
          name: "SiteMap",
          value: "No",
        },
        {
          id: 1438,
          keyName: "frequency",
          name: "Frequency",
          value: "",
        },
        {
          id: 1439,
          keyName: "priority",
          name: "Priority",
          value: "",
        },
        {
          id: 33,
          keyName: "attach_item_thumbnail_image",
          name: "Thumbnail Image",
          value: "",
        },
        {
          id: 34,
          keyName: "attach_item_hero_image",
          name: "Hero Image",
          value: "h-mcdonalds-Chicken-McNuggets-10-piece-Extra-Value-Meals.jpg",
        },
        {
          id: 634,
          keyName: "attach_transparent_icon_image",
          name: "Transparent Icon Image",
          value: "",
        },
        {
          id: 38,
          keyName: "attach_meal_bundle_image",
          name: "Meal Bundle Image",
          value: "",
        },
        {
          id: 23,
          keyName: "has_components",
          name: "Has Components",
          value: "No",
        },
        {
          id: 41,
          keyName: "footer",
          name: "Footer",
          value: "Footer",
        },
      ],
      footerList: [],
      itemCollectionList: [
        {
          associatedItemId: 203633,
          isChangeable: "Y",
          associatedCategoryId: 100000,
          displayOrder: 1,
          associatedItemName: "Hamburger Happy Meal",
          associatedCategoryName: "EN Menu Hacks1",
        },
      ],
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};
export const mockSaveItemCategoryMapping: ReturnType<
  typeof realSaveItemCategoryMapping
> = async (body) => {
  await delay(2);
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      status: "200",
      message: "Item Category Mapping was saved successfully",
      itemId: 200000,
      dataList: [
        {
          categoryId: 100002,
          defaultCategory: "Y",
        },
        {
          categoryId: 100000,
          defaultCategory: "N",
        },
        {
          categoryId: 100001,
          defaultCategory: "N",
        },
      ],
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};
export const mockSaveItemGoesWellWith: ReturnType<
  typeof realSaveItemGoesWellWith
> = async (body) => {
  await delay(2);
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "Item Category Mapping was saved successfully",
      itemId: 200000,
      dataList: [
        {
          itemId: 200011,
        },
      ],
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};
export const mockSaveItemCoopMapping: ReturnType<
  typeof realSaveItemCoopMapping
> = async (body) => {
  await delay(2);
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "Selected Co-Ops have been mapped successfully.",
      itemId: 200000,
      coopList: [
        {
          coopId: 215,
        },
        {
          coopId: 230,
        },
        {
          coopId: 254,
        },
      ],
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};
export const mockGetSelectedItemsGoesWellWith: ReturnType<
  typeof realGetSelectedItemsGoesWellWith
> = async ({ itemId, countryCode = "US" }) => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful",
      itemName: "Big Mac",
      dataList: [
        {
          itemId: 200011,
          menuItemName: "Coca Cola (Small)",
        },
      ],
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};
export const mockGetCompareLive: ReturnType<
  typeof realGetCompareLive
> = async ({ countryCode = "US", itemId, languageCode = "EN" }) => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      item: {
        attachItemHeroImage: {
          altText: "",
          description: "",
          imageName: "h-mcdonalds-Bacon-Egg-Cheese-McGriddles.jpg",
          url: "http://s3.amazonaws.com/us-east-dna-staging-dep-share-s3/dna/images/",
        },
        attachItemThumbnailImage: {
          altText: "",
          description: "",
          imageName: "t-mcdonalds-Bacon-Egg-Cheese-McGriddles.jpg",
          url: "http://s3.amazonaws.com/us-east-dna-staging-dep-share-s3/dna/images/",
        },
        attachTransparentIconImage: {
          altText: "",
          description: "",
          imageName: "t-mcdonalds-Bacon-Egg-Cheese-McGriddles.png",
          url: "http://s3.amazonaws.com/us-east-dna-staging-dep-share-s3/dna/images/",
        },
        goesWellWith: {
          goesWell: [
            {
              id: 200340,
              itemName: "Hash Browns",
            },
            {
              id: 200267,
              itemName: "Sausage Burrito",
            },
            {
              id: 200628,
              itemName: "Minute Maid Orange Juice (Medium)",
            },
            {
              id: 200676,
              itemName: "Sweet Tea (Large)",
            },
            {
              id: 200611,
              itemName: "Coca-Cola (Small)",
            },
            {
              id: 200543,
              itemName: "Coffee (Medium)",
            },
          ],
        },
        components: {
          component: [
            {
              quantity: "0.920325",
              productName: "Griddle Cakes",
              ingredient:
                "Ingredients: Water, Enriched Flour (bleached Wheat Flour, Niacin, Iron, Thiamin Mononitrate, Riboflavin, Folic Acid), Sugar, Dextrose, Palm Oil, Contains 2% Or Less: Brown Sugar, Baking Powder (baking Soda, Sodium Acid Pyrophosphate, Monocalcium Phosphate), Modified Tapioca Starch, Rice Flour, Whey Powder, Salt, Natural Flavors, Buttermilk Powder, Soybean Oil, Caramel Color, Soy Lecithin.",
              isDefault: 1,
              type: "Core Product",
              unit: "3.5273961980687005 oz",
              productAllergen: "Wheat, Milk, Soy.",
            },
            {
              quantity: "0.4629",
              productName: "Folded Egg",
              ingredient:
                "Ingredients: Eggs, Nonfat Milk, Modified Food Starch, Salt, Citric Acid.",
              isDefault: 1,
              type: "Core Product",
              unit: "3.5273961980687005 oz",
              productAllergen: "Egg, Milk.",
            },
            {
              quantity: "0.141965",
              productName: "Pasteurized Process American Cheese",
              ingredient:
                "Ingredients: Milk, Cream, Water, Sodium Citrate, Salt, Cheese Cultures, Citric Acid, Enzymes, Soy Lecithin, Color Added.",
              isDefault: 1,
              type: "Core Product",
              unit: "3.5273961980687005 oz",
              productAllergen: "Milk, Soy.",
            },
            {
              quantity: "0.13712562",
              productName: "Thick Cut Applewood Smoked Bacon",
              ingredient:
                "Ingredients: Pork Bellies Cured With Water, Salt, Sugar, Natural Smoke Flavor, Sodium Phosphate, Sodium Erythorbate, Sodium Nitrite.",
              isDefault: 1,
              type: "Core Product",
              unit: "3.5273961980687005 oz",
              productAllergen: "",
            },
            {
              quantity: "0.0019",
              productName: "Clarified Butter",
              ingredient: "Ingredients: Pasteurized Cream (butterfat).",
              isDefault: 1,
              type: "Core Product",
              unit: "3.5273961980687005 oz",
              productAllergen: "Milk.",
            },
          ],
        },
        relationTypes: {
          relationType: [],
        },
        itemAdditionalTextIngredientStatement: "",
        itemType: "Core Item",
        timeOfDay: "Whole Day",
        itemName: "Bacon, Egg & Cheese McGriddles",
        itemMarketingName: "Bacon, Egg & Cheese McGriddles®",
        description:
          "Bacon, Egg & Cheese McGriddles® feature soft, warm griddle cakes with the sweet taste of maple. McDonald's McGriddles® recipe features thick-cut Applewood smoked bacon, a fluffy folded egg, and a slice of melty American cheese. McGriddles® cakes have no artificial preservatives or flavors and no colors from artificial sources. The Bacon, Egg, & Cheese McGriddles® is 430 calories. Pick up on your terms through the drive thru or with McDonald's curbside pickup when you <a href=\"https://www.mcdonalds.com/us/en-us/mobile-order-and-pay.html\">Mobile Order & Pay</a>! McDonald's App download and registration required.",
        itemId: 200304,
        hasComponents: "Yes",
        keywords:
          "bacon egg and cheese mcgriddle, bacon egg and cheese sandwich",
        itemAdditionalAllergen: "",
        itemComments:
          "Bacon, Egg & Cheese McGriddles® feature soft, warm griddle cakes with the sweet taste of maple, holding our thick-cut Applewood smoked bacon, a fluffy folded egg, and a slice of melty American cheese. McGriddles cakes have no artificial preservatives or flavors and no colors from artificial sources.",
        itemAllergen:
          "Wheat, Milk, Soy., Folded Egg Ingredients: Eggs, Nonfat Milk, Modified Food Starch, Salt, Citric Acid. Contains: Egg, Milk., Pasteurized Process American Cheese Ingredients: Milk, Cream, Water, Sodium Citrate, Salt, Cheese Cultures, Citric Acid, Enzymes, Soy Lecithin, Color Added. Contains: Milk, Soy., Thick Cut Applewood Smoked Bacon Ingredients: Pork Bellies Cured With Water, Salt, Sugar, Natural Smoke Flavor, Sodium Phosphate, Sodium Erythorbate, Sodium Nitrite., Clarified Butter Ingredients: Pasteurized Cream (butterfat). Contains: Milk..",
        doNotShow: "Core",
        itemIngredientStatement:
          "Ingredients: Water, Enriched Flour (bleached Wheat Flour, Niacin, Iron, Thiamin Mononitrate, Riboflavin, Folic Acid), Sugar, Dextrose, Palm Oil, Contains 2% Or Less: Brown Sugar, Baking Powder (baking Soda, Sodium Acid Pyrophosphate, Monocalcium Phosphate), Modified Tapioca Starch, Rice Flour, Whey Powder, Salt, Natural Flavors, Buttermilk Powder, Soybean Oil, Caramel Color, Soy Lecithin. Contains: Wheat, Milk, Soy., Folded Egg Ingredients: Eggs, Nonfat Milk, Modified Food Starch, Salt, Citric Acid. Contains: Egg, Milk., Pasteurized Process American Cheese Ingredients: Milk, Cream, Water, Sodium Citrate, Salt, Cheese Cultures, Citric Acid, Enzymes, Soy Lecithin, Color Added. Contains: Milk, Soy., Thick Cut Applewood Smoked Bacon Ingredients: Pork Bellies Cured With Water, Salt, Sugar, Natural Smoke Flavor, Sodium Phosphate, Sodium Erythorbate, Sodium Nitrite., Clarified Butter Ingredients: Pasteurized Cream (butterfat).",
        shortName: "bacon-egg-cheese-mcgriddles",
        serves: "",
        showProductImages: "Yes",
        externalId: "9839",
        genesisMenuItemNo: "9839-000",
        itemMetaTitle:
          "Bacon, Egg & Cheese McGriddles® Breakfast Sandwich | McDonald's",
        customizationLabel: "",
        customizationButton: "",
        itemMetaDescription:
          "Try the McDonald's Bacon, Egg & Cheese McGriddles® breakfast sandwich, made with Applewood smoked bacon, a fluffy folded egg, and melty American cheese.",
        frequency: "",
        priority: "",
        logo1: {
          url: "",
          altText: "",
        },
        logo2: {
          url: "",
          altText: "",
        },
        logo_3: {
          url: "",
          altText: "",
        },
        itemStatusFlag: "None",
        nutrientFacts: {
          nutrient: [
            {
              uom: " Cal.",
              name: "Calories",
              id: 2,
              value: "430",
              hundredGPerProduct: "258.0",
              uomDescription: "Cal.",
              adultDv: "",
              childDv: "",
              womanDv: "",
            },
            {
              uom: "kJ",
              name: "Energy kJ",
              id: 30,
              value: "1800.0",
              hundredGPerProduct: "1081.0",
              uomDescription: "kilojoules",
              adultDv: "",
              childDv: "",
              womanDv: "",
            },
            {
              uom: " Cal.",
              name: "Calories From Fat",
              id: 23,
              value: "190",
              hundredGPerProduct: "114.2",
              uomDescription: "Cal.",
              adultDv: "",
              childDv: "",
              womanDv: "",
            },
            {
              uom: "g",
              name: "Protein",
              id: 11,
              value: "17",
              hundredGPerProduct: "10.2",
              uomDescription: "grams",
              adultDv: "",
              childDv: "",
              womanDv: "",
            },
            {
              uom: "g",
              name: "Carbohydrates",
              id: 22,
              value: "44",
              hundredGPerProduct: "26.4",
              uomDescription: "grams",
              adultDv: "16",
              childDv: "",
              womanDv: "",
            },
            {
              uom: "g",
              name: "Dietary Fiber 2015",
              id: 9,
              value: "2",
              hundredGPerProduct: "1.2",
              uomDescription: "grams",
              adultDv: "7",
              childDv: "",
              womanDv: "",
            },
            {
              uom: "g",
              name: "Total Sugars",
              id: 10,
              value: "15",
              hundredGPerProduct: "9",
              uomDescription: "grams",
              adultDv: "",
              childDv: "",
              womanDv: "",
            },
            {
              uom: "g",
              name: "Added Sugars",
              id: 31,
              value: "13",
              hundredGPerProduct: "7.8",
              uomDescription: "grams",
              adultDv: "25",
              childDv: "",
              womanDv: "",
            },
            {
              uom: "g",
              name: "Total Fat",
              id: 21,
              value: "21",
              hundredGPerProduct: "12.6",
              uomDescription: "grams",
              adultDv: "27",
              childDv: "",
              womanDv: "",
            },
            {
              uom: "g",
              name: "Saturated Fat",
              id: 4,
              value: "9",
              hundredGPerProduct: "5.4",
              uomDescription: "grams",
              adultDv: "46",
              childDv: "",
              womanDv: "",
            },
            {
              uom: "g",
              name: "Trans Fat",
              id: 29,
              value: "0",
              hundredGPerProduct: "0",
              uomDescription: "grams",
              adultDv: "",
              childDv: "",
              womanDv: "",
            },
            {
              uom: "mg",
              name: "Cholesterol",
              id: 6,
              value: "215",
              hundredGPerProduct: "129.2",
              uomDescription: "milligrams",
              adultDv: "71",
              childDv: "",
              womanDv: "",
            },
            {
              uom: "mg",
              name: "Vitamin B6",
              id: 28,
              value: "0",
              hundredGPerProduct: "0",
              uomDescription: "milligrams",
              adultDv: "0",
              childDv: "",
              womanDv: "",
            },
            {
              uom: "mcg",
              name: "Vitamin D",
              id: 27,
              value: "0",
              hundredGPerProduct: "0",
              uomDescription: "microgram",
              adultDv: "6",
              childDv: "",
              womanDv: "",
            },
            {
              uom: "mg",
              name: "Calcium",
              id: 14,
              value: "190",
              hundredGPerProduct: "114.2",
              uomDescription: "milligrams",
              adultDv: "15",
              childDv: "",
              womanDv: "",
            },
            {
              uom: "mg",
              name: "Iron",
              id: 15,
              value: "3",
              hundredGPerProduct: "1.8",
              uomDescription: "milligrams",
              adultDv: "15",
              childDv: "",
              womanDv: "",
            },
            {
              uom: "mg",
              name: "Phosphorus",
              id: 24,
              value: "0",
              hundredGPerProduct: "0",
              uomDescription: "milligrams",
              adultDv: "0",
              childDv: "",
              womanDv: "",
            },
            {
              uom: "mg",
              name: "Potassium",
              id: 25,
              value: "220",
              hundredGPerProduct: "132.2",
              uomDescription: "milligrams",
              adultDv: "4",
              childDv: "",
              womanDv: "",
            },
            {
              uom: "mg",
              name: "Sodium",
              id: 7,
              value: "1230",
              hundredGPerProduct: "739.1",
              uomDescription: "milligrams",
              adultDv: "54",
              childDv: "",
              womanDv: "",
            },
            {
              uom: "mg",
              name: "Caffeine",
              id: 26,
              value: "0",
              hundredGPerProduct: "0",
              uomDescription: "milligrams",
              adultDv: "",
              childDv: "",
              womanDv: "",
            },
          ],
        },
        categories: {
          categories: [
            {
              name: "Breakfast",
              id: 100002,
            },
            {
              name: "All Day Breakfast",
              id: 100015,
            },
            {
              name: "ADB Biscuit",
              id: 100018,
            },
          ],
        },
        mutexGroups: {
          mutexGroup: [],
        },
        coops: {
          coop: [],
        },
        "RFM Id / Menu Item No / External Id / Import Id": "9839",
        nutritionQualityStatementText: "Bacon, eggs and sweetness.",
        specializationText1: "",
        specializationText2: "",
      },
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};
export const mockGetCompareCurrent: ReturnType<
  typeof realGetCompareCurrent
> = async ({ countryCode = "US", itemId, languageCode = "EN" }) => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      item: {
        attachItemHeroImage: {
          imageName: "h-mcdonalds-Bacon-Egg-Cheese-McGriddles.jpg",
        },
        attachItemThumbnailImage: {
          imageName: "t-mcdonalds-Bacon-Egg-Cheese-McGriddles.jpg",
        },
        attachTransparentIconImage: {
          imageName: "t-mcdonalds-Bacon-Egg-Cheese-McGriddles.png",
        },
        attachMealBundleImage: {
          imageName: "t-mcdonalds-Bacon-Egg-Cheese-McGriddles.png",
        },
        goesWellWith: {
          goesWell: [
            {
              id: 200340,
              itemName: "Hash Browns",
            },
            {
              id: 200267,
              itemName: "Sausage Burrito",
            },
            {
              id: 200628,
              itemName: "Minute Maid Orange Juice (Medium)",
            },
            {
              id: 200676,
              itemName: "Sweet Tea (Large)",
            },
            {
              id: 200611,
              itemName: "Coca-Cola (Small)",
            },
            {
              id: 200543,
              itemName: "Coffee (Medium)",
            },
          ],
        },
        components: {
          component: [
            {
              quantity: "0.920325",
              productName: "Griddle Cakes",
              ingredient:
                "Ingredients: Water, Enriched Flour (bleached Wheat Flour, Niacin, Iron, Thiamin Mononitrate, Riboflavin, Folic Acid), Sugar, Dextrose, Palm Oil, Contains 2% Or Less: Brown Sugar, Baking Powder (baking Soda, Sodium Acid Pyrophosphate, Monocalcium Phosphate), Modified Tapioca Starch, Rice Flour, Whey Powder, Salt, Natural Flavors, Buttermilk Powder, Soybean Oil, Caramel Color, Soy Lecithin.",
              isDefault: 1,
              type: "Core Product",
              unit: "3.5273961980687005 oz",
              productAllergen: "Wheat, Milk, Soy.",
            },
            {
              quantity: "0.4629",
              productName: "Folded Egg",
              ingredient:
                "Ingredients: Eggs, Nonfat Milk, Modified Food Starch, Salt, Citric Acid.",
              isDefault: 1,
              type: "Core Product",
              unit: "3.5273961980687005 oz",
              productAllergen: "Egg, Milk.",
            },
            {
              quantity: "0.141965",
              productName: "Pasteurized Process American Cheese",
              ingredient:
                "Ingredients: Milk, Cream, Water, Sodium Citrate, Salt, Cheese Cultures, Citric Acid, Enzymes, Soy Lecithin, Color Added.",
              isDefault: 1,
              type: "Core Product",
              unit: "3.5273961980687005 oz",
              productAllergen: "Milk, Soy.",
            },
            {
              quantity: "0.13712562",
              productName: "Thick Cut Applewood Smoked Bacon",
              ingredient:
                "Ingredients: Pork Bellies Cured With Water, Salt, Sugar, Natural Smoke Flavor, Sodium Phosphate, Sodium Erythorbate, Sodium Nitrite.",
              isDefault: 1,
              type: "Core Product",
              unit: "3.5273961980687005 oz",
              productAllergen: "",
            },
            {
              quantity: "0.0019",
              productName: "Clarified Butter",
              ingredient: "Ingredients: Pasteurized Cream (butterfat).",
              isDefault: 1,
              type: "Core Product",
              unit: "3.5273961980687005 oz",
              productAllergen: "Milk.",
            },
          ],
        },
        relationTypes: {
          relationType: [
            {
              relatedItems: {
                relatedItem: [
                  {
                    id: 200506,
                    label: "Sesame Seed Bun",
                    abbrLabel: "",
                    displayOrder: 1,
                    externalId: "80010",
                    isDefault: true,
                    itemName: "McCafé Cappuccino (Small)",
                    shortName: "mccaf-cappuccino-small",
                  },
                  {
                    id: 200304,
                    label: "Artisan Roll",
                    abbrLabel: "",
                    displayOrder: 2,
                    externalId: "9839",
                    isDefault: false,
                    itemName: "Bacon, Egg & Cheese McGriddles",
                    shortName: "bacon-egg-cheese-mcgriddles",
                  },
                ],
              },
            },
            {
              relatedItems: {
                relatedItem: [
                  {
                    id: 200304,
                    label: "Small",
                    abbrLabel: "",
                    displayOrder: 1,
                    externalId: "9839",
                    isDefault: false,
                    itemName: "Bacon, Egg & Cheese McGriddles",
                    shortName: "bacon-egg-cheese-mcgriddles",
                  },
                  {
                    id: 200506,
                    label: "Large",
                    abbrLabel: "",
                    displayOrder: 2,
                    externalId: "80010",
                    isDefault: true,
                    itemName: "McCafé Cappuccino (Small)",
                    shortName: "mccaf-cappuccino-small",
                  },
                ],
              },
            },
            {
              relatedItems: {
                relatedItem: [
                  {
                    id: 200632,
                    label: "Master",
                    abbrLabel: "",
                    displayOrder: 1,
                    externalId: "522",
                    isDefault: true,
                    itemName: "Coca-Cola (Large)",
                    shortName: "coca-cola-large",
                  },
                  {
                    id: 200200,
                    label: "Master",
                    abbrLabel: "",
                    displayOrder: 1,
                    externalId: "10087",
                    isDefault: true,
                    itemName: "Bacon & Sausage Bagel",
                    shortName: "bacon-sausage-bagel",
                  },
                  {
                    id: 200304,
                    label: "Child",
                    abbrLabel: "",
                    displayOrder: 2,
                    externalId: "9839",
                    isDefault: false,
                    itemName: "Bacon, Egg & Cheese McGriddles",
                    shortName: "bacon-egg-cheese-mcgriddles",
                  },
                  {
                    id: 200304,
                    label: "Child",
                    abbrLabel: "",
                    displayOrder: 2,
                    externalId: "9839",
                    isDefault: false,
                    itemName: "Bacon, Egg & Cheese McGriddles",
                    shortName: "bacon-egg-cheese-mcgriddles",
                  },
                ],
              },
            },
            {
              relatedItems: {
                relatedItem: [
                  {
                    id: 200506,
                    label: "12 fl oz cup",
                    abbrLabel: "",
                    displayOrder: 1,
                    externalId: "80010",
                    isDefault: true,
                    itemName: "McCafé Cappuccino (Small)",
                    shortName: "mccaf-cappuccino-small",
                  },
                  {
                    id: 200300,
                    label: "16 fl oz cup",
                    abbrLabel: "",
                    displayOrder: 2,
                    externalId: "85",
                    isDefault: false,
                    itemName: "Bacon, Egg  & Cheese Biscuit",
                    shortName: "bacon-egg-cheese-biscuit-200300",
                  },
                  {
                    id: 200304,
                    label: "21 fl oz cup",
                    abbrLabel: "",
                    displayOrder: 3,
                    externalId: "9839",
                    isDefault: false,
                    itemName: "Bacon, Egg & Cheese McGriddles",
                    shortName: "bacon-egg-cheese-mcgriddles",
                  },
                ],
              },
            },
            {
              relatedItems: {
                relatedItem: [
                  {
                    id: 200506,
                    label: "5 piece",
                    abbrLabel: "5",
                    displayOrder: 1,
                    externalId: "80010",
                    isDefault: true,
                    itemName: "McCafé Cappuccino (Small)",
                    shortName: "mccaf-cappuccino-small",
                  },
                  {
                    id: 200300,
                    label: "2 piece",
                    abbrLabel: "2",
                    displayOrder: 2,
                    externalId: "85",
                    isDefault: false,
                    itemName: "Bacon, Egg  & Cheese Biscuit",
                    shortName: "bacon-egg-cheese-biscuit-200300",
                  },
                  {
                    id: 200304,
                    label: "12 piece",
                    abbrLabel: "12",
                    displayOrder: 3,
                    externalId: "9839",
                    isDefault: false,
                    itemName: "Bacon, Egg & Cheese McGriddles",
                    shortName: "bacon-egg-cheese-mcgriddles",
                  },
                ],
              },
            },
          ],
        },
        itemAdditionalTextIngredientStatement: "",
        itemType: "Core Item",
        timeOfDay: "Whole Day",
        itemName: "Bacon, Egg & Cheese McGriddles",
        itemMarketingName: "Bacon, Egg & Cheese McGriddles®",
        description:
          "Bacon, Egg & Cheese McGriddles® feature soft, warm griddle cakes with the sweet taste of maple. McDonald's McGriddles® recipe features thick-cut Applewood smoked bacon, a fluffy folded egg, and a slice of melty American cheese. McGriddles® cakes have no artificial preservatives or flavors and no colors from artificial sources. The Bacon, Egg, & Cheese McGriddles® is 430 calories. Pick up on your terms through the drive thru or with McDonald's curbside pickup when you <a href=\"https://www.mcdonalds.com/us/en-us/mobile-order-and-pay.html\">Mobile Order & Pay</a>! McDonald's App download and registration required.",
        itemId: 200304,
        hasComponents: "Yes",
        keywords:
          "bacon egg and cheese mcgriddle, bacon egg and cheese sandwich",
        itemAdditionalAllergen: "",
        itemComments:
          "Bacon, Egg & Cheese McGriddles® feature soft, warm griddle cakes with the sweet taste of maple, holding our thick-cut Applewood smoked bacon, a fluffy folded egg, and a slice of melty American cheese. McGriddles cakes have no artificial preservatives or flavors and no colors from artificial sources.",
        itemAllergen:
          "Wheat, Milk, Soy., Folded Egg Ingredients: Eggs, Nonfat Milk, Modified Food Starch, Salt, Citric Acid. Contains: Egg, Milk., Pasteurized Process American Cheese Ingredients: Milk, Cream, Water, Sodium Citrate, Salt, Cheese Cultures, Citric Acid, Enzymes, Soy Lecithin, Color Added. Contains: Milk, Soy., Thick Cut Applewood Smoked Bacon Ingredients: Pork Bellies Cured With Water, Salt, Sugar, Natural Smoke Flavor, Sodium Phosphate, Sodium Erythorbate, Sodium Nitrite., Clarified Butter Ingredients: Pasteurized Cream (butterfat). Contains: Milk..",
        doNotShow: "Core",
        itemIngredientStatement:
          "Ingredients: Water, Enriched Flour (bleached Wheat Flour, Niacin, Iron, Thiamin Mononitrate, Riboflavin, Folic Acid), Sugar, Dextrose, Palm Oil, Contains 2% Or Less: Brown Sugar, Baking Powder (baking Soda, Sodium Acid Pyrophosphate, Monocalcium Phosphate), Modified Tapioca Starch, Rice Flour, Whey Powder, Salt, Natural Flavors, Buttermilk Powder, Soybean Oil, Caramel Color, Soy Lecithin. Contains: Wheat, Milk, Soy., Folded Egg Ingredients: Eggs, Nonfat Milk, Modified Food Starch, Salt, Citric Acid. Contains: Egg, Milk., Pasteurized Process American Cheese Ingredients: Milk, Cream, Water, Sodium Citrate, Salt, Cheese Cultures, Citric Acid, Enzymes, Soy Lecithin, Color Added. Contains: Milk, Soy., Thick Cut Applewood Smoked Bacon Ingredients: Pork Bellies Cured With Water, Salt, Sugar, Natural Smoke Flavor, Sodium Phosphate, Sodium Erythorbate, Sodium Nitrite., Clarified Butter Ingredients: Pasteurized Cream (butterfat).",
        shortName: "bacon-egg-cheese-mcgriddles",
        serves: "",
        showProductImages: "Yes",
        externalId: "9839",
        genesisMenuItemNo: "9839-000",
        itemMetaTitle:
          "Bacon, Egg & Cheese McGriddles® Breakfast Sandwich | McDonald's",
        customizationLabel: "",
        customizationButton: "",
        itemMetaDescription:
          "Try the McDonald's Bacon, Egg & Cheese McGriddles® breakfast sandwich, made with Applewood smoked bacon, a fluffy folded egg, and melty American cheese.",
        frequency: "",
        priority: "",
        logo1: {
          url: "",
          altText: "",
        },
        logo2: {
          url: "",
          altText: "",
        },
        logo3: {
          url: "",
          altText: "",
        },
        itemStatusFlag: "None",
        nutrientFacts: {
          nutrient: [
            {
              uom: "g",
              name: "Secondary Serving Size",
              id: 18,
              value: "166",
              hundredGPerProduct: "99.7",
              uomDescription: "grams",
              adultDv: "",
              childDv: "",
              womanDv: "",
            },
            {
              uom: "oz",
              name: "Primary Serving Size",
              id: 17,
              value: "5",
              hundredGPerProduct: "3",
              uomDescription: "oz",
              adultDv: "",
              childDv: "",
              womanDv: "",
            },
            {
              uom: " Cal.",
              name: "Calories",
              id: 2,
              value: "430",
              hundredGPerProduct: "258.4",
              uomDescription: "Cal.",
              adultDv: "",
              childDv: "",
              womanDv: "",
            },
            {
              uom: " Cal.",
              name: "Calories From Fat",
              id: 23,
              value: "190",
              hundredGPerProduct: "114.2",
              uomDescription: "Cal.",
              adultDv: "",
              childDv: "",
              womanDv: "",
            },
            {
              uom: "kJ",
              name: "Energy kJ",
              id: 30,
              value: "1800",
              hundredGPerProduct: "1081",
              uomDescription: "kilojoules",
              adultDv: "",
              childDv: "",
              womanDv: "",
            },
            {
              uom: "g",
              name: "Protein",
              id: 11,
              value: "17",
              hundredGPerProduct: "10.2",
              uomDescription: "grams",
              adultDv: "",
              childDv: "",
              womanDv: "",
            },
            {
              uom: "g",
              name: "Carbohydrates",
              id: 22,
              value: "44",
              hundredGPerProduct: "26.4",
              uomDescription: "grams",
              adultDv: "16",
              childDv: "",
              womanDv: "",
            },
            {
              uom: "g",
              name: "Dietary Fiber 2015",
              id: 9,
              value: "2",
              hundredGPerProduct: "1.2",
              uomDescription: "grams",
              adultDv: "7",
              childDv: "",
              womanDv: "",
            },
            {
              uom: "g",
              name: "Total Sugars",
              id: 10,
              value: "15",
              hundredGPerProduct: "9",
              uomDescription: "grams",
              adultDv: "",
              childDv: "",
              womanDv: "",
            },
            {
              uom: "g",
              name: "Total Fat",
              id: 21,
              value: "21",
              hundredGPerProduct: "12.6",
              uomDescription: "grams",
              adultDv: "27",
              childDv: "",
              womanDv: "",
            },
            {
              uom: "g",
              name: "Added Sugars",
              id: 31,
              value: "13",
              hundredGPerProduct: "7.8",
              uomDescription: "grams",
              adultDv: "25",
              childDv: "",
              womanDv: "",
            },
            {
              uom: "g",
              name: "Saturated Fat",
              id: 4,
              value: "9",
              hundredGPerProduct: "5.4",
              uomDescription: "grams",
              adultDv: "46",
              childDv: "",
              womanDv: "",
            },
            {
              uom: "g",
              name: "Trans Fat",
              id: 29,
              value: "0",
              hundredGPerProduct: "0",
              uomDescription: "grams",
              adultDv: "",
              childDv: "",
              womanDv: "",
            },
            {
              uom: "mg",
              name: "Cholesterol",
              id: 6,
              value: "215",
              hundredGPerProduct: "129.2",
              uomDescription: "milligrams",
              adultDv: "71",
              childDv: "",
              womanDv: "",
            },
            {
              uom: "mg",
              name: "Vitamin B6",
              id: 28,
              value: "0",
              hundredGPerProduct: "0",
              uomDescription: "milligrams",
              adultDv: "0",
              childDv: "",
              womanDv: "",
            },
            {
              uom: "mcg",
              name: "Vitamin D",
              id: 27,
              value: "0",
              hundredGPerProduct: "0",
              uomDescription: "microgram",
              adultDv: "6",
              childDv: "",
              womanDv: "",
            },
            {
              uom: "mg",
              name: "Calcium",
              id: 14,
              value: "190",
              hundredGPerProduct: "114.2",
              uomDescription: "milligrams",
              adultDv: "15",
              childDv: "",
              womanDv: "",
            },
            {
              uom: "mg",
              name: "Iron",
              id: 15,
              value: "3",
              hundredGPerProduct: "1.8",
              uomDescription: "milligrams",
              adultDv: "15",
              childDv: "",
              womanDv: "",
            },
            {
              uom: "mg",
              name: "Phosphorus",
              id: 24,
              value: "0",
              hundredGPerProduct: "0",
              uomDescription: "milligrams",
              adultDv: "0",
              childDv: "",
              womanDv: "",
            },
            {
              uom: "mg",
              name: "Potassium",
              id: 25,
              value: "220",
              hundredGPerProduct: "132.2",
              uomDescription: "milligrams",
              adultDv: "4",
              childDv: "",
              womanDv: "",
            },
            {
              uom: "mg",
              name: "Sodium",
              id: 7,
              value: "1230",
              hundredGPerProduct: "739.1",
              uomDescription: "milligrams",
              adultDv: "54",
              childDv: "",
              womanDv: "",
            },
            {
              uom: "mg",
              name: "Caffeine",
              id: 26,
              value: "0",
              hundredGPerProduct: "0",
              uomDescription: "milligrams",
              adultDv: "",
              childDv: "",
              womanDv: "",
            },
            {
              uom: "g",
              name: "Dietary Fiber",
              id: 32,
              value: "2",
              hundredGPerProduct: "1.2",
              uomDescription: "grams",
              adultDv: "7",
              childDv: "",
              womanDv: "",
            },
          ],
        },
        categories: {
          categories: [
            {
              name: "Breakfast",
              id: 100002,
            },
            {
              name: "All Day Breakfast",
              id: 100015,
            },
            {
              name: "ADB Biscuit",
              id: 100018,
            },
          ],
        },
        mutexGroups: {
          mutexGroup: [],
        },
        coops: {
          coop: [],
        },
        "RFM Id / Menu Item No / External Id / Import Id": "9839",
        nutritionQualityStatementText: "Bacon, eggs and sweetness.",
        specializationText1: "",
        specializationText2: "",
      },
    },

    headers: {},
    status: 200,
    statusText: "OK",
  };
};
export const mockGetRegionsList: ReturnType<
  typeof realGetRegionsList
> = async ({ countryCode = "US" }) => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful.",
      regionList: {
        "ATLANTA FIELD OFFICE": [
          {
            coopId: 200,
            coopName: "ATLANTA",
          },
          {
            coopId: 201,
            coopName: "FIELD",
          },
          {
            coopId: 202,
            coopName: "OFFICE",
          },
        ],
        "BOSTON FIELD OFFICE": [
          {
            coopId: 300,
            coopName: "BOSTON",
          },
          {
            coopId: 301,
            coopName: "FIELD",
          },
          {
            coopId: 302,
            coopName: "OFFICE",
          },
        ],
      },
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};
export const mockUpdateFortifiedItems: ReturnType<
  typeof realUpdateFortifiedItems
> = async (body) => {
  await delay(2);
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "Data has been updated successfully.",
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockGetFortifiedItemsList
: ReturnType<
  typeof realGetFortifiedItemsList
> = async ({ countryCode = "US", itemId}) => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful",
      dataList: [
        {
            "nutrientFactId": 1,
            "display": "Yes",
            "name": "Serving Size"
        },
        {
            "nutrientFactId": 2,
            "display": "Yes",
            "name": "Calories"
        },
        {
            "nutrientFactId": 3,
            "display": "Yes",
            "name": "Fat"
        },
        {
            "nutrientFactId": 4,
            "display": "Yes",
            "name": "Saturated Fat"
        },
        {
            "nutrientFactId": 5,
            "display": "Yes",
            "name": "Trans Fat"
        },
        {
            "nutrientFactId": 6,
            "display": "Yes",
            "name": "Saturated and Trans Fat"
        },
        {
            "nutrientFactId": 7,
            "display": "Yes",
            "name": "Cholesterol"
        },
        {
            "nutrientFactId": 8,
            "display": "Yes",
            "name": "Sodium"
        },
        {
            "nutrientFactId": 9,
            "display": "Yes",
            "name": "Carbohydrates"
        },
        {
            "nutrientFactId": 10,
            "display": "Yes",
            "name": "Fibre"
        },
        {
            "nutrientFactId": 11,
            "display": "Yes",
            "name": "Sugar"
        },
        {
            "nutrientFactId": 12,
            "display": "Yes",
            "name": "Protein"
        },
        {
            "nutrientFactId": 15,
            "display": "Yes",
            "name": "Calcium"
        },
        {
            "nutrientFactId": 16,
            "display": "Yes",
            "name": "Iron"
        },
        {
            "nutrientFactId": 17,
            "display": "Yes",
            "name": "Energy kJ"
        },
        {
            "nutrientFactId": 18,
            "display": "Yes",
            "name": "Potassium"
        },
        {
            "nutrientFactId": 19,
            "display": "Yes",
            "name": "Vitamin D"
        },
        {
            "nutrientFactId": 20,
            "display": "No",
            "name": "Vitamin Sample"
        }
    ]
  },
  headers: {},
  status: 200,
  statusText: "OK",
};
};

export const mockGetIsFortifiedItemValueQuery:  ReturnType<
typeof realGetIsFortifiedItemValueQuery
> = async ({ countryCode = "CA", itemId }) => {
return {
  config: { headers: new AxiosHeaders() },
  data: {
    message: "The call was successful.",
    dataList: [
      {
          "name": "Is Fortified Menu Item",
          "value": "Yes",
          "id": 1792,
          "keyName": "is_fortified_menu_item"
      }
  ]
},
headers: {},
status: 200,
statusText: "OK",
};
};

export const mockGetItemRelationshipMapping: ReturnType<
  typeof realGetItemRelationshipMapping
> = async ({ countryCode = "US", itemId }) => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful.",
      defaultItemList: [
        {
          defaultItemId: 200632,
          defaultItem: "Coca-Cola (Large)",
          relationShipNameId: 6,
          relationShipName: "egg white",
          groupedItems:
            "Chicken McNuggets (20 piece) (20 piece),Chicken McNuggets (40 piece)(40 piece),Chicken McNuggets 10 piece(10 piece),Chicken McNuggets® (4 piece) (4 piece),Chicken McNuggets® (6 piece) (6 piece)",
        },
      ],
      mappedItemList: [
        {
          alsoMappedToId: 200006,
          alsoMapped: "Mocha (Small) old ",
          mappedRelationShipNameId: 1,
          mappedRelationShipName: "size",
          mappedGroupItems:
            "Mocha (Large) (Large),Mocha (Medium)(Medium),Mocha (Small) old (Small)",
        },
      ],
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};
export const mockGetCoopMappedDataByItemID: ReturnType<
  typeof realGetCoopMappedDataByItemID
> = async ({ countryCode = "US", itemId }) => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful.",
      itemName: "Coca Cola",
      nationalLevelFlag: "N",
      coopList: [
        {
          coopId: 215,
          coopName: "DES MOINES QUAD CITIES PEORIA CENT IL",
        },
        {
          coopId: 230,
          coopName: "MICHIGAN",
        },
        {
          coopId: 254,
          coopName: "GREATER INDIANA",
        },
      ],
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};
export const mockGetSelectedItemRelationship: ReturnType<
  typeof realGetSelectedItemRelationship
> = async ({ countryCode = "US", selectedRelationId, defaultItemId }) => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful.",
      dataList: [
        {
          displayOrder: 1,
          selectedItemName: "Coca-Cola (Large)",
          selectedItemId: 200632,
          variantId: 6,
          isDefault: "Y",
        },
        {
          displayOrder: 2,
          selectedItemName: "Bacon, Egg & Cheese McGriddles ",
          selectedItemId: 200304,
          variantId: 5,
          isDefault: "N",
        },
      ],
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};
export const mockGetSelectedElementsMapping: ReturnType<
  typeof realGetSelectedElementsMapping
> = async ({ countryCode = "US", itemId }) => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful.",
      itemName: "Chicken McNuggets® (6 piece) ",
      elementList: [
        {
          elementName: "Hazelnut Creamer",
          qty: 0.7864483,
          importId: "",
          elementId: 202369,
          unit: "",
          elementType: "Optional Item",
        },
        {
          elementName: "Chicken McNuggets®",
          qty: "0.9156",
          importId: "WRIN 00407-756",
          elementId: 300701,
          unit: "3.5273961980687005 oz(100 g)",
          elementType: "Product",
        },
        {
          elementName: "Select Sauce",
          qty: "1.0",
          importId: "",
          elementId: 300728,
          unit: "N/A oz(N/A g)",
          elementType: "Mutex",
        },
      ],
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};
export const mockSaveItemProductMapping: ReturnType<
  typeof realSaveItemProductMapping
> = async (body) => {
  await delay(2);
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "Mapping saved successfully.",
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};
export const mockGetAllOptionalItems: ReturnType<
  typeof realGetAllOptionalItems
> = async ({ countryCode = "US", itemId }) => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful.",
      dataList: optionalItems,
      totalRecordCount: optionalItems.length,
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};
export const mockGetAllMutexGroups: ReturnType<
  typeof realGetAllMutexGroups
> = async ({ countryCode = "US", itemId }) => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful.",
      dataList: mutexGroups,
      totalRecordCount: mutexGroups.length,
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockGetRelationTypes: ReturnType<
  typeof realGetRelationTypes
> = async ({ countryCode = "US" }) => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful.",
      relationList: [
        {
          relationId: 1,
          relationName: "size",
          variantList: [
            {
              variantId: 1,
              variantName: "Kids",
            },
            {
              variantId: 2,
              variantName: "Small",
            },
            {
              variantId: 3,
              variantName: "Medium",
            },
            {
              variantId: 4,
              variantName: "Large",
            },
            {
              variantId: 9,
              variantName: "Extra Large",
            },
            {
              variantId: 10,
              variantName: "Large Size",
            },
            {
              variantId: 11,
              variantName: "Regular Size",
            },
            {
              variantId: 12,
              variantName: "Shareable Size",
            },
            {
              variantId: 13,
              variantName: "Snack Size",
            },
            {
              variantId: 14,
              variantName: "Happy Meal Size",
            },
            {
              variantId: 32,
              variantName: "Extra Small",
            },
            {
              variantId: 646,
              variantName: "2 piece",
            },
            {
              variantId: 647,
              variantName: "4 piece",
            },
            {
              variantId: 648,
              variantName: "6 piece",
            },
            {
              variantId: 650,
              variantName: "10 piece",
            },
            {
              variantId: 651,
              variantName: "12 piece",
            },
            {
              variantId: 652,
              variantName: "20 piece",
            },
            {
              variantId: 653,
              variantName: "40 piece",
            },
          ],
        },
        {
          relationId: 2,
          relationName: "master child",
          variantList: [
            {
              variantId: 5,
              variantName: "Child",
            },
            {
              variantId: 6,
              variantName: "Master",
            },
          ],
        },
        {
          relationId: 5,
          relationName: "piece",
          variantList: [
            {
              variantId: 20,
              variantName: "5 piece",
            },
            {
              variantId: 21,
              variantName: "20 piece",
            },
            {
              variantId: 22,
              variantName: "40 piece",
            },
            {
              variantId: 23,
              variantName: "3 piece",
            },
            {
              variantId: 24,
              variantName: "4 piece",
            },
            {
              variantId: 25,
              variantName: "6 piece",
            },
            {
              variantId: 26,
              variantName: "10 piece",
            },
            {
              variantId: 126,
              variantName: "2 piece",
            },
            {
              variantId: 128,
              variantName: "12 piece",
            },
          ],
        },
        {
          relationId: 6,
          relationName: "oz cup",
          variantList: [
            {
              variantId: 27,
              variantName: "12 fl oz cup",
            },
            {
              variantId: 28,
              variantName: "16 fl oz cup",
            },
            {
              variantId: 29,
              variantName: "21 fl oz cup",
            },
            {
              variantId: 30,
              variantName: "32 fl oz cup",
            },
            {
              variantId: 31,
              variantName: "22 fl oz cup",
            },
            {
              variantId: 33,
              variantName: "Snack Size",
            },
          ],
        },
        {
          relationId: 125,
          relationName: "Testing reslation",
          variantList: [
            {
              variantId: 1028,
              variantName: "Test1",
            },
            {
              variantId: 1030,
              variantName: "test2",
            },
          ],
        },
        {
          relationId: 15,
          relationName: "bun",
          variantList: [
            {
              variantId: 121,
              variantName: "Sesame Seed Bun",
            },
            {
              variantId: 122,
              variantName: "Artisan Roll",
            },
            {
              variantId: 1025,
              variantName: "Sesame Seed Bun",
            },
            {
              variantId: 1027,
              variantName: "Artisan Roll",
            },
          ],
        },
      ],
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockSaveItemRelationship: ReturnType<
  typeof realSaveItemRelationship
> = async (body) => {
  await delay(2);
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      status: "200",
      message: "Item Category Mapping was saved successfully",
      itemId: 200000,
      dataList: [
        {
          selectedItemId: 201376,
          variantId: 121,
          isDefault: "Y",
          selectedItemName: "Regular Bun",
          displayOrder: 1,
        },
        {
          selectedItemId: 201431,
          variantId: 122,
          isDefault: "N",
          selectedItemName: "Big Mac Bun",
          displayOrder: 2,
        },
        {
          selectedItemId: 203490,
          variantId: 1025,
          isDefault: "N",
          selectedItemName: "Homestyle Bun",
          displayOrder: 3,
        },
      ],
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockItemGetReviewAndSubmit: ReturnType<
  typeof realItemGetReviewAndSubmit
> = async ({ countryCode = "US" }) => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful.",
      appliedWorkFlowId: 10,
      currentStep: "",
      previousStep: "",
      nextStep: "",
      itemName: "Equal 0 Calorie Sweetener ",
      previousComments: [
        {
          userName: "User,Test",
          date: "10/12/2023",
          comments: "Auto Approved",
          role: "Marketing",
        },
        {
          userName: "User,Test",
          date: "10/12/2023",
          comments: "Submitted by Marketing",
          role: "Marketing",
        },
        {
          userName: "User,Test",
          date: "10/12/2023",
          comments: "Approved by Nutritionist",
          role: "Nutritionist",
        },
      ],
      workFlowList: [
        {
          id: 6,
          name: "No Workflow",
          description: "No Workflow for Item",
        },
        {
          id: 7,
          name: "Single Workflow",
          description: "Only one step for Item",
        },
        {
          id: 8,
          name: "Two Workflow",
          description: "Two step for Item",
        },
        {
          id: 9,
          name: "Multiple workflow",
          description: "Multiple step for Item",
        },
        {
          id: 10,
          name: "All Team Workflow",
          description: "All step for Item",
        },
      ],
      checkListAndAckList: [
        {
          checkList: "Marketing Information",
          ack: "Marketing Information has been validated.",
        },
        {
          checkList: "Category Mapping",
          ack: "Category Mapping has been validated.",
        },
        {
          checkList: "Item Product Mapping",
          ack: "Item Product Mapping has been validated.",
        },
        {
          checkList: "Nutrition Information",
          ack: "Nutrition Information has been validated.",
        },
        {
          checkList: "Relationship Mapping",
          ack: "Relationship mapping has been validated.",
        },
      ],
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockSaveItemReviewAndSubmit: ReturnType<
  typeof realSaveItemReviewAndSubmit
> = async (body) => {
  await delay(2);
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "Data has been updated successfully.",
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockGetItemServingSize: ReturnType<
  typeof realGetItemServingSize
> = async ({ countryCode = "US", itemId }) => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful.",
      itemName: "Quarter Pounder with Cheese",
      servingList: [
        {
          nutrientId: 18,
          nutrientName: "Secondary Serving Size",
          nutrientValue: "201",
          uomName: "g",
          uomId: 9,
        },
        {
          nutrientId: 17,
          nutrientName: "Primary Serving Size",
          nutrientValue: "7",
          uomName: "oz",
          uomId: 2,
        },
      ],
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockGetUomDetails: ReturnType<typeof realGetUomDetails> = async ({
  countryCode = "US",
  itemId,
}) => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful.",
      primaryServingSize: [
        {
          uomId: 0,
          uomName: "No unit of measurement",
        },
        {
          uomId: 2,
          uomName: "oz",
        },
        {
          uomId: 3,
          uomName: "fl oz",
        },
        {
          uomId: 4,
          uomName: "carton",
        },
        {
          uomId: 5,
          uomName: "pkg",
        },
        {
          uomId: 6,
          uomName: "fl. oz. cup",
        },
        {
          uomId: 7,
          uomName: "cookie",
        },
        {
          uomId: 8,
          uomName: "piece",
        },
        {
          uomId: 11,
          uomName: "mg",
        },
        {
          uomId: 12,
          uomName: "IU",
        },
        {
          uomId: 13,
          uomName: "kJ",
        },
        {
          uomId: 14,
          uomName: " Cal.",
        },
        {
          uomId: 15,
          uomName: "mcg",
        },
      ],
      secondaryServingSize: [
        {
          uomId: 0,
          uomName: "No unit of measurement",
        },
        {
          uomId: 9,
          uomName: "g",
        },
        {
          uomId: 10,
          uomName: "ml",
        },
      ],
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockRecalculateServingSize: ReturnType<
  typeof realRecalculateServingSize
> = async ({ countryCode = "US", itemId }) => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful.",
      itemName: "Quarter Pounder with Cheese",
      nutritionFactNamesList: [
        {
          uom: "g",
          nutritionFactName: "Secondary Serving Size",
          value: "201",
        },
        {
          uom: "oz",
          nutritionFactName: "Primary Serving Size",
          value: "7",
        },
      ],
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockSaveItemServingSize: ReturnType<
  typeof realSaveItemServingSize
> = async (body) => {
  await delay(2);
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "Nutrient facts have been saved successfully.",
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockGetProductDetailsByMutexId: ReturnType<
  typeof realGetProductDetailsByMutexId
> = async ({ countryCode = "US", mutexId }) => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful.",
      mutexName: "mixed_mutex",
      dataList: [
        {
          productId: 301545,
          productName: "10 Inch Tortilla",
          qty: 3.5,
          defaultProduct: "N",
          displayOrder: 1,
        },
      ],
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockGetOptionalItemDetailsByMutexId: ReturnType<
  typeof realGetOptionalItemDetailsByMutexId
> = async ({ countryCode = "US", mutexId }) => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful.",
      mutexName: "mixed_mutex",
      dataList: [
        {
          defaultItem: "N",
          qty: 5.6,
          optionalItemId: 200582,
          displayOrder: 2,
          optionalItemName: "Chipotle Barbeque Sauce ",
        },
        {
          defaultItem: "N",
          qty: 2.6,
          optionalItemId: 200295,
          displayOrder: 3,
          optionalItemName: "Spicy Buffalo Sauce ",
        },
      ],
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockSaveMutexGroup: ReturnType<typeof realSaveMutexGroup> = async (
  body
) => {
  await delay(2);
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "Data has been updated successfully.",
      mutexId: 300569,
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockGetArchiveItem: ReturnType<typeof realGetArchiveItem> = async (
  body
) => {
  await delay(2);
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful.",
      dataList: [
        {
          id: 100001,
          name: "Chicken and Sandwiches",
        },
      ],
      totalRecordCount: 1,
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockHardDeleteItem: ReturnType<
  typeof realHardDeleteItem
> = async () => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "Data has been deleted successfully.",
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockRestoreItem: ReturnType<typeof realRestoreItem> = async () => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful.",
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockSoftDeleteItem: ReturnType<
  typeof realSoftDeleteItem
> = async () => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful.",
      dataList: {
        deleteReason: "Removing seasonal category",
      },
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockSaveNutritionInformationByItemId: ReturnType<
  typeof realSaveNutritionInformationByItemId
> = async (body) => {
  await delay(2);
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      status: "200",
      nutritionList: [
        {
          nutrientFactId: 18,
          nutrientFactName: "Secondary Serving Size",
          nutrientValue: "100",
          uomName: "g",
          dvValue: "N/A",
          hundredGramPerProduct: "N/A",
        },
        {
          nutrientFactId: 17,
          nutrientFactName: "Primary Serving Size",
          nutrientValue: "3.5273961980687005",
          uomName: "oz",
          dvValue: "N/A",
          hundredGramPerProduct: "N/A",
        },
        {
          nutrientFactId: 2,
          nutrientFactName: "Calories",
          nutrientValue: "0.269",
          uomName: " Cal.",
          dvValue: "N/A",
          hundredGramPerProduct: "N/A",
        },
        {
          nutrientFactId: 23,
          nutrientFactName: "Calories From Fat",
          nutrientValue: "0",
          uomName: " Cal.",
          dvValue: "N/A",
          hundredGramPerProduct: "N/A",
        },
        {
          nutrientFactId: 30,
          nutrientFactName: "Energy kJ",
          nutrientValue: "1.1255",
          uomName: "kJ",
          dvValue: "N/A",
          hundredGramPerProduct: "N/A",
        },
        {
          nutrientFactId: 11,
          nutrientFactName: "Protein",
          nutrientValue: "0.009",
          uomName: "g",
          dvValue: "N/A",
          hundredGramPerProduct: "N/A",
        },
        {
          nutrientFactId: 22,
          nutrientFactName: "Carbohydrates",
          nutrientValue: "0.084",
          uomName: "g",
          dvValue: "N/A",
          hundredGramPerProduct: "N/A",
        },
        {
          nutrientFactId: 9,
          nutrientFactName: "Dietary Fiber 2015",
          nutrientValue: "0.023",
          uomName: "g",
          dvValue: "N/A",
          hundredGramPerProduct: "N/A",
        },
        {
          nutrientFactId: 10,
          nutrientFactName: "Total Sugars",
          nutrientValue: "0.006",
          uomName: "g",
          dvValue: "N/A",
          hundredGramPerProduct: "N/A",
        },
        {
          nutrientFactId: 21,
          nutrientFactName: "Total Fat",
          nutrientValue: "0",
          uomName: "g",
          dvValue: "N/A",
          hundredGramPerProduct: "N/A",
        },
        {
          nutrientFactId: 31,
          nutrientFactName: "Added Sugars",
          nutrientValue: "0.006",
          uomName: "g",
          dvValue: "N/A",
          hundredGramPerProduct: "N/A",
        },
        {
          nutrientFactId: 4,
          nutrientFactName: "Saturated Fat",
          nutrientValue: "0",
          uomName: "g",
          dvValue: "N/A",
          hundredGramPerProduct: "N/A",
        },
        {
          nutrientFactId: 29,
          nutrientFactName: "Trans Fat",
          nutrientValue: "0",
          uomName: "g",
          dvValue: "N/A",
          hundredGramPerProduct: "N/A",
        },
        {
          nutrientFactId: 6,
          nutrientFactName: "Cholesterol",
          nutrientValue: "0",
          uomName: "mg",
          dvValue: "N/A",
          hundredGramPerProduct: "N/A",
        },
        {
          nutrientFactId: 28,
          nutrientFactName: "Vitamin B6",
          nutrientValue: "N/A",
          uomName: "mg",
          dvValue: "N/A",
          hundredGramPerProduct: "N/A",
        },
        {
          nutrientFactId: 27,
          nutrientFactName: "Vitamin D",
          nutrientValue: "0",
          uomName: "mcg",
          dvValue: "N/A",
          hundredGramPerProduct: "N/A",
        },
        {
          nutrientFactId: 14,
          nutrientFactName: "Calcium",
          nutrientValue: "0",
          uomName: "mg",
          dvValue: "N/A",
          hundredGramPerProduct: "N/A",
        },
        {
          nutrientFactId: 15,
          nutrientFactName: "Iron",
          nutrientValue: "0",
          uomName: "mg",
          dvValue: "N/A",
          hundredGramPerProduct: "N/A",
        },
        {
          nutrientFactId: 24,
          nutrientFactName: "Phosphorus",
          nutrientValue: "N/A",
          uomName: "mg",
          dvValue: "N/A",
          hundredGramPerProduct: "N/A",
        },
        {
          nutrientFactId: 25,
          nutrientFactName: "Potassium",
          nutrientValue: "12.239",
          uomName: "mg",
          dvValue: "N/A",
          hundredGramPerProduct: "N/A",
        },
        {
          nutrientFactId: 7,
          nutrientFactName: "Sodium",
          nutrientValue: "13.983",
          uomName: "mg",
          dvValue: "N/A",
          hundredGramPerProduct: "N/A",
        },
        {
          nutrientFactId: 26,
          nutrientFactName: "Caffeine",
          nutrientValue: "N/A",
          uomName: "mg",
          dvValue: "N/A",
          hundredGramPerProduct: "N/A",
        },
        {
          nutrientFactId: 32,
          nutrientFactName: "Dietary Fiber",
          nutrientValue: "0.023",
          uomName: "g",
          dvValue: "N/A",
          hundredGramPerProduct: "N/A",
        },
      ],
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockDeleteSelectedItemRelationship: ReturnType<
  typeof realDeleteSelectedItemRelationship
> = async () => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "Data has been deleted successfully.",
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockGetElementLiveStatus: ReturnType<
  typeof realGetElementLiveStatus
> = async () => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful.",
      live: "Y",
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};