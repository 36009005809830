import React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Link from "@mui/material/Link";

type UserManualProps = {
  domain: string | null;
};

export function UserManual({ domain }: UserManualProps) {
  const userManualLink = `${domain}dna/help/User_Manual.pdf`;

  return (
    <StyledBox component="span">
      <Tooltip title="User Manual">
        <StyledLink
          href={userManualLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          ?
        </StyledLink>
      </Tooltip>
    </StyledBox>
  );
}

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  padding: theme.spacing(1),
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  backgroundColor: theme.palette.background.paper,
  borderRadius: "4px",
}));

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.text.primary,
}));
