import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  TablePagination,
  IconButton,
  Tab,
  Tabs,
  Button,
} from "@mui/material";
import AddCircle from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { EmptyState } from "../components/EmptyState";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { withLayout } from "../hoc/with-layout";
import { useAxios } from "../axios-provider";
import {
  ApiError,
  ValidUser,
  createSortByStringFn,
  isUserData,
} from "../utils";
import { useCallback, useContext, useMemo, useState } from "react";
import { RoleContext } from "../role-provider";
import { Loader } from "./loader/Loader";
import { ErrorAlertSnackbar, SuccessAlertSnackbar } from "./AlertSnackbar";
import { SearchInput } from "./SearchInput";
import { deleteUser, getUsersData } from "../data/miscellaneous";
import { useUrlSearchPageSort } from "../hooks/use-url-search-page-sort";
import TableSortIcon from "./TableSortIcon";
import { FixedHeightTableLoader } from "./FixedHeightLoader";
import { useNavigate } from "react-router-dom";
import { useCustomQuery } from "../hooks/use-custom-query";
import { AddRowLink } from "./AddRowLink";
import { ResendFormModal } from "./ResendFormModal";
import { AxiosError } from "axios";

const UsersTable = ({
  users,
  title,
  tableKey,
  isLoading,
  onDelete,
}: {
  users: ValidUser[];
  title: string;
  tableKey: string;
  isLoading: boolean;
  onDelete: (userId: string) => void;
}) => {
  const navigate = useNavigate();
  const {
    page,
    pageSize,
    sortField,
    sortOrder,
    search,
    navigateToPage,
    setPageSize,
    setSorting,
    setSearchQuery,
    currentSort,
  } = useUrlSearchPageSort<ValidUser>(tableKey, {
    page: 0,
    pageSize: 10,
    sortField: "eId",
    sortOrder: "asc",
    search: "",
  });
  const sortByName = createSortByStringFn<ValidUser>("name");
  const sortByEid = createSortByStringFn<ValidUser>("eId");
  const sortByCountry = createSortByStringFn<ValidUser>("country");
  const sortByEmail = createSortByStringFn<ValidUser>("email");
  const filteredAndSortedUsers = useMemo(() => {
    const searchLower = search.toLowerCase();
    const filteredUsers =
      search.length > 0
        ? users?.filter((user) => {
            return (
              user.name?.toLowerCase().includes(searchLower) ||
              user.eId?.toLowerCase().includes(searchLower) ||
              user.email?.toLowerCase().includes(searchLower) ||
              user.country?.toLowerCase().includes(searchLower)
            );
          })
        : users;
    if (sortField === "name") {
      return sortByName(filteredUsers, sortOrder);
    } else if (sortField === "eId") {
      return sortByEid(filteredUsers, sortOrder);
    } else if (sortField === "email") {
      return sortByEmail(filteredUsers, sortOrder);
    } else if (sortField === "country") {
      return sortByCountry(filteredUsers, sortOrder);
    }
    return sortByEid(filteredUsers, sortOrder);
  }, [
    search,
    users,
    sortField,
    sortByEid,
    sortOrder,
    sortByName,
    sortByEmail,
    sortByCountry,
  ]);
  const handleRowsPerPageChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setPageSize(Number(event.target.value));
    },
    [setPageSize]
  );
  const handlePageChange = useCallback(
    (_: React.MouseEvent<HTMLButtonElement> | null, value: number) => {
      navigateToPage(value);
    },
    [navigateToPage]
  );

  const getRow = useCallback(
    (index: number) => {
      if (!filteredAndSortedUsers[index]) {
        return (
          <TableRow
            sx={{
              height: 48,
              "&:last-child td, &:last-child th": { border: 0 },
            }}
            data-testid="users-placeholder-tr"
          >
            <TableCell
              component="th"
              scope="row"
              data-testid="users-td-name"
            ></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        );
      }
      const user = filteredAndSortedUsers[index];
      return (
        <StyledTableRow
          key={`${JSON.stringify(user)}`}
          sx={{
            height: 48,
            "&:last-child td, &:last-child th": { border: 0 },
          }}
          data-testid="user-tr"
        >
          <TableCell component="th" scope="row" data-testid="user-td-name">
            {user.eId}
          </TableCell>
          <TableCell>{user.name}</TableCell>
          <TableCell>{user.email}</TableCell>
          <TableCell>{user.country}</TableCell>
          <TableCell align="right">
            <IconButton
              role="button"
              aria-label="edit button"
              onClick={() => {
                navigate(`/view-user/${user.eId}/user-form`);
              }}
              data-testid="cy-view-user-button"
            >
              <EditIcon />
            </IconButton>
            <IconButton
              aria-label="delete button"
              role="button"
              data-testid="cy-delete-user-button"
              sx={{ color: "#DA291C" }}
              onClick={() => onDelete(user.eId)}
            >
              <DeleteForeverIcon />
            </IconButton>
          </TableCell>
        </StyledTableRow>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filteredAndSortedUsers]
  );

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="flex-end">
        <Grid>
          <Typography variant="h2">{title}</Typography>
        </Grid>
        <Grid>
          <SearchInput
            value={search}
            onChange={(e) => setSearchQuery(e.target.value)}
            aria-label="Search users"
            placeholder="Search Users"
            testId="cy-user-search"
          />
        </Grid>
      </Grid>
      <Grid sx={{ pl: 0, pr: 0, mt: 1 }}>
        <FixedHeightTableLoader pageSize={pageSize}>
          {isLoading && <Loader />}
          <TableContainer component={Paper} aria-label="Table">
            <Table aria-label="simple table" role="table">
              <StyledDashboardTableHead>
                <TableRow>
                  <TableCell
                    component="th"
                    scope="col"
                    role="columnheader"
                    id="name-header"
                  >
                    <TableSortIcon
                      currentSort={currentSort}
                      setSorting={setSorting}
                      field="eId"
                      label="EID"
                      aria-label="Sort by eid"
                      testId="sort-by-eid"
                    />
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="col"
                    role="columnheader"
                    id="id-header"
                  >
                    <TableSortIcon
                      currentSort={currentSort}
                      setSorting={setSorting}
                      field="name"
                      label="Name"
                      aria-label="Sort by name"
                      testId="sort-by-name"
                    />
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="col"
                    role="columnheader"
                    id="id-header"
                  >
                    <TableSortIcon
                      currentSort={currentSort}
                      setSorting={setSorting}
                      field="email"
                      label="Email"
                      aria-label="Sort by email"
                      testId="sort-by-email"
                    />
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="col"
                    role="columnheader"
                    id="id-header"
                  >
                    Country
                  </TableCell>
                  <TableCell
                    align="right"
                    component="th"
                    scope="col"
                    role="columnheader"
                    id="add-header"
                  >
                    Action
                  </TableCell>
                </TableRow>
              </StyledDashboardTableHead>
              <TableBody>
                {filteredAndSortedUsers.length > 0
                  ? Array.from({ length: pageSize }, (_, i) => i).map((i) =>
                      getRow(page * pageSize + i)
                    )
                  : !isLoading && <EmptyState>No records found</EmptyState>}
              </TableBody>
            </Table>
          </TableContainer>
          <StyledActionPanel container>
            <TablePagination
              component="div"
              count={filteredAndSortedUsers.length}
              onPageChange={handlePageChange}
              page={page}
              rowsPerPage={pageSize}
              rowsPerPageOptions={[5, 10, 25]}
              onRowsPerPageChange={handleRowsPerPageChange}
              aria-label="Pagination"
              data-testid="user-pagination"
              SelectProps={{
                MenuProps: {
                  classes: { list: "user-pagination-list" },
                },
              }}
            />
          </StyledActionPanel>
        </FixedHeightTableLoader>
      </Grid>
    </>
  );
};

export const ViewUser = withLayout(() => {
  const { apiClient } = useAxios();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { selectedCountry, selectedRole } = useContext(RoleContext);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState<number>(0);
  const handleTabChange = (event: React.ChangeEvent<{}>, newTab: number) => {
    setActiveTab(newTab);
  };
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const userDataQuery = useCustomQuery(
    ["getUsersData", { countryCode: selectedCountry }],
    () =>
      getUsersData(apiClient)({
        countryCode: selectedCountry!,
      })
  );
  const existingUser = useMemo(() => {
    if (userDataQuery.data?.data.userList?.existingUserList === undefined) {
      return [];
    }
    const validExistingUser =
      userDataQuery.data.data.userList.existingUserList.filter(
        (maybeExistingUser): maybeExistingUser is ValidUser => {
          return isUserData(maybeExistingUser) === true;
        }
      );
    return validExistingUser;
  }, [userDataQuery.data?.data.userList?.existingUserList]);
  
  const loading = useMemo(
    () => (userDataQuery.isLoading ? true : false),
    [userDataQuery.isLoading]
  );
  const [deletingUserId, setDeletingUserId] = useState<string | null>(null);

  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const [isResendModalOpen, setIsResendModalOpen] = useState<boolean>(false);

  const handleOpenDeleteConfirmation = (userId: string) => {
    setIsDeleteConfirmationOpen(true);
    setDeletingUserId(userId!);
  };
  const handleCloseDeleteConfirmation = () => {
    setIsDeleteConfirmationOpen(false);
    setDeletingUserId(null);
  };
  const deleteUserRequest = deleteUser(apiClient);
  const deleteMutation = useMutation(
    (userId: string) => {
      return deleteUserRequest({
        userId: userId,
        roleId: selectedRole!,
        countryCode: selectedCountry!,
      });
    },
    {
      onMutate: () => deleteUserRequest,
      onSuccess: () => {
        queryClient.invalidateQueries(["getUsersData"]);
        setSuccessMessage("User deleted successfully");
        handleCloseDeleteConfirmation();
      },
      onError: (error: AxiosError) => {
        if (error?.response?.status === 401) {
          setIsDeleteConfirmationOpen(false);
          setIsResendModalOpen(true);
        } else if (error.response?.data) {
          const errorMessage: ApiError = error.response.data as ApiError;
          setErrorMessage(String(errorMessage.message));
        } else {
          setErrorMessage(String(error));
        }
      },
    }
  );

  const handleDelete = () => {
    if (deletingUserId) {
      return deleteMutation.mutate(deletingUserId);
    }
  };

  return (
    <StyledRelativeContainer container spacing={1}>
      <Grid
        mobile={12}
        container
        sx={{ mb: 6, justifyContent: "space-between" }}
      >
        <Typography variant="h1">View User</Typography>
        <AddRowLink
          onClick={() => navigate(`/view-user/add-edit-user`)}
          data-testid="cy-add-new-user-button"
        >
          <AddCircle aria-label="add new user button" />
          <Typography>Add New User</Typography>
        </AddRowLink>
      </Grid>
      <Grid mobile={12} container justifyContent="center">
        <Tabs
          TabIndicatorProps={{
            sx: { backgroundColor: "#FFBC0D" },
          }}
          value={activeTab}
          onChange={handleTabChange}
          role="tablist"
        >
          <StyledTab
            label="Existing User List"
            data-testid="existing-users"
            value={0}
          />
        </Tabs>
      </Grid>
      {activeTab === 0 && (
        <Grid mobile={12}>
          <UsersTable
            users={existingUser}
            title={`Existing User List`}
            tableKey={"existing-users"}
            isLoading={loading}
            onDelete={handleOpenDeleteConfirmation}
          />
        </Grid>
      )}
      
      <Dialog
        open={isDeleteConfirmationOpen}
        onClose={handleCloseDeleteConfirmation}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete user?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-evenly" }}>
          <StyledButton
            variant="contained"
            size="large"
            onClick={handleCloseDeleteConfirmation}
          >
            Cancel
          </StyledButton>
          <StyledDangerButton
            variant="contained"
            size="large"
            color="error"
            onClick={handleDelete}
            autoFocus
            data-testid="cy-user-confirm-delete-button"
          >
            Delete
          </StyledDangerButton>
        </DialogActions>
      </Dialog>
      <ResendFormModal
        open={isResendModalOpen}
        onResend={() => {
          handleDelete();
          setIsResendModalOpen(false);
        }}
        onCancel={() => setIsResendModalOpen(false)}
      />
      <SuccessAlertSnackbar
        message={successMessage}
        onClose={() => setSuccessMessage(null)}
      />
      <ErrorAlertSnackbar
        message={errorMessage}
        onClose={() => setErrorMessage(null)}
      />
    </StyledRelativeContainer>
  );
}, "View User");

export const StyledDashboardTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  ...theme.typography.normalBold,
}));
const StyledTableRow = styled(TableRow)({
  borderBottom: "unset",
  backgroundColor: "white",
  height: 48,
});
const StyledRelativeContainer = styled(Grid)({
  margin: 0,
  position: "relative",
});
const StyledActionPanel = styled(Grid)({
  display: "flex",
  flexDirection: "row",
  gap: "10px",
  justifyContent: "flex-end",
  alignItems: "center",
});

const StyledButton = styled(Button)(({ theme }) => ({
  color: "#000000",
  fontSize: theme.typography.largeBold.fontSize,
  fontFamily: theme.typography.largeBold.fontFamily,
  fontWeight: theme.typography.largeBold.fontWeight,
  textTransform: "none",
}));
export const StyledDangerButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#DA291C",
  "&:hover": {
    backgroundColor: "#DA291C",
  },
  "&:focus": {
    backgroundColor: "#DA291C",
  },
  "&:active": {
    backgroundColor: "#DA291C",
  },
  fontSize: theme.typography.largeBold.fontSize,
  fontFamily: theme.typography.largeBold.fontFamily,
  fontWeight: theme.typography.largeBold.fontWeight,
  textTransform: "none",
}));
const StyledTab = styled(Tab)({
  color: "black",
  fontFamily: "Speedee-Bold, Arial, Helvetica, sans-serif",
  letterSpacing: "-0.15px",
  textTransform: "initial",
  lineHeight: "28px",
  fontSize: "18px",
  borderBottom: "2px solid #F6F6F6",
  "&.Mui-selected": {
    backgroundColor: "#F6F6F6",
    borderWidth: "4px",
    color: "black",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
});
