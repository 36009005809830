import { ReactNode, useContext } from "react";
import { styled } from "@mui/material/styles";
import { Box, Button, Chip, Link, Stack } from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import { ProfileMenu } from "../components/ProfileMenu";
import { Helmet } from "react-helmet";
import { RoleContext } from "../role-provider";
import { UniversalSearchBar } from "../components/UniversalSearchBar";
import { SkipToMainContent } from "../components/SkipToMain";
import { Navbar } from "../components/Navbar";
import packageInfo from "../../package.json";
import { BreadcrumbComponent } from "../components/BreadcrumbComponent";
import { AuthContext } from "../auth-provider";
import { UserManual } from "../components/UserManual";

type LayoutProps = {
  children: ReactNode;
  title?: string;
};

export const Layout = ({ children, title }: LayoutProps) => {
  const { defaultLanguage } = useContext(RoleContext);
  const version = packageInfo.version;
  const { invalidateAccessToken, domain } = useContext(AuthContext);
  return (
    <>
      <Helmet
        title={`${title} - Digital Nutrition Application`}
        htmlAttributes={{ lang: defaultLanguage! }}
      >
        <meta
          http-equiv="Cache-Control"
          content="no-store, no-cache, must-revalidate"
        />
        <meta http-equiv="Pragma" content="no-cache" />
        <meta http-equiv="Expires" content="0" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
      </Helmet>
      <Box
        id="root"
        sx={{
          width: "100%",
          height: "100vh",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Box
          id="body"
          sx={{
            flexDirection: "column",
            flexGrow: 1,
            justifyContent: "flex-start",
          }}
        >
          <SkipToMainContent />
          <MuiAppBar
            role="banner"
            position="absolute"
            sx={{ boxShadow: "none", backgroundColor: "white" }}
            aria-label="App Bar"
          >
            <Stack direction="row" sx={{ width: 1170, margin: "0 auto" }}>
              <Stack direction="column" sx={{ justifyContent: "center" }}>
                <Link href="/">
                  <img
                    src="/mcdonalds-golden-arches.png"
                    alt="DNA Home"
                    width="86px"
                  />
                </Link>
              </Stack>
              <Stack
                role="navigation"
                aria-label="Secondary"
                direction="column"
                sx={{ flexGrow: 1, paddingLeft: 4 }}
              >
                <Stack
                  direction="row"
                  sx={{ alignItems: "center", justifyContent: "space-between" }}
                >
                  <UniversalSearchBar />
                  <UserManual domain={domain!} />
                  <ProfileMenu />
                  <Chip
                    label={`Version: ${version}`}
                    sx={{ backgroundColor: "#FFBC0D" }}
                  />
                  {typeof process.env.REACT_APP_DEBUG !== "undefined" &&
                    process.env.REACT_APP_DEBUG && (
                      <Button onClick={invalidateAccessToken}>
                        Invalidate Token
                      </Button>
                    )}
                </Stack>
                <Navbar />
              </Stack>
            </Stack>
          </MuiAppBar>
          <StyledRelativeContainer>
            <Main id="content" role="main" aria-labelledby="content-header">
              <BreadcrumbComponent />
              {children}
            </Main>
          </StyledRelativeContainer>
        </Box>
      </Box>
    </>
  );
};

const Main = styled("main")(({ theme }) => ({
  width: 1170,
  padding: theme.spacing(3),
  marginTop: 100,
  marginLeft: "auto",
  marginRight: "auto",
}));
const StyledRelativeContainer = styled(Box)({
  margin: 0,
  position: "relative",
});
