import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Controller, useForm } from "react-hook-form";
import { ApiError } from "../utils";
import { useMutation } from "@tanstack/react-query";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { AxiosError } from "axios";
import { RoleContext } from "../role-provider";
import { useAxios } from "../axios-provider";
import { Loader } from "./loader/Loader";
import {
  NutrientRoundingRuleMappingForEdit,
  NutrientRoundingRuleMappingFormData,
  formatNutrientRoundingRuleMappingFormData,
} from "../util/rounding-rules";
import { useCustomQuery } from "../hooks/use-custom-query";
import {
  getNutrientRoundingMappingForAdd,
  saveRoundingRuleMapping,
} from "../data/roundingrules";
import {
  localizedNumStringOrEmpty,
  localizedStringValueToFloat,
  standardizedNumStringOrEmpty,
  standardizedNumStringOrNA,
  standardizedNumStringOrPlaceholder,
} from "../util/number-localization";

function formatRoundingRuleMappingLocalized(
  data: NutrientRoundingRuleMappingForEdit,
  marketCode: string
): NutrientRoundingRuleMappingForEdit {
  const { minValue, maxValue, ...otherFields } = data;
  return {
    ...otherFields,
    minValue: localizedNumStringOrEmpty(minValue, marketCode),
    maxValue: localizedNumStringOrEmpty(maxValue, marketCode),
  };
}

type ValidNutrientRoundingRuleMappingForEdit = {
  [P in keyof NutrientRoundingRuleMappingForEdit]: NonNullable<
    NutrientRoundingRuleMappingForEdit[P]
  >;
};

type NutrientRoundingRuleMappingModalProps = {
  initialData: NutrientRoundingRuleMappingForEdit;
  onSaveSuccess: () => void;
  onCancel: () => void;
};
export type RoleForm = {
  roleName: string;
};
export const NutrientRoundingRuleMappingModal = ({
  initialData,
  onSaveSuccess,
  onCancel,
}: NutrientRoundingRuleMappingModalProps) => {
  const { apiClient } = useAxios();
  const { selectedCountry, selectedRole } =
    useContext(RoleContext);
  const [customError, setCustomError] = useState<string | null>(null);
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm<NutrientRoundingRuleMappingForEdit>();

  useEffect(() => {
    reset(formatRoundingRuleMappingLocalized(initialData, selectedCountry!));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialData]);

  const getRoundingRuleMappingForAddQuery = useCustomQuery(
    ["getNutrientRoundingMappingForAdd", { selectedCountry }],
    () =>
      getNutrientRoundingMappingForAdd(apiClient)({
        countryCode: selectedCountry!,
      })
  );
  const {
    nutrient,
    roundingRule,
    roundingRuleType,
  }: NutrientRoundingRuleMappingFormData = useMemo(() => {
    if (
      typeof getRoundingRuleMappingForAddQuery.data?.data
        .nutrientRoundingRuleData !== "undefined"
    ) {
      return formatNutrientRoundingRuleMappingFormData(
        getRoundingRuleMappingForAddQuery.data?.data.nutrientRoundingRuleData
      );
    }
    return {
      nutrient: [],
      roundingRule: [],
      roundingRuleType: [],
    };
  }, [getRoundingRuleMappingForAddQuery.data]);

  const saveRoundingRuleMappingRequest = saveRoundingRuleMapping(apiClient);
  const { mutate: doSaveRoundingRuleMapping, isLoading } = useMutation(
    (data: ValidNutrientRoundingRuleMappingForEdit) => {
      const metaData = {
        ...(typeof initialData.roundingRuleMappingId !== "undefined"
          ? { roundingRuleMappingId: initialData.roundingRuleMappingId }
          : {}),
        countryCode: selectedCountry!,
        roleId: String(selectedRole!),
      };
      const { minValue, maxValue, ...otherFields } = data;
      return saveRoundingRuleMappingRequest(
        {
          ...otherFields,
          minValue: standardizedNumStringOrNA(minValue, selectedCountry!),
          maxValue: standardizedNumStringOrNA(maxValue, selectedCountry!),
        },
        metaData
      );
    },
    {
      onMutate: () => saveRoundingRuleMappingRequest,
      onSuccess: () => onSaveSuccess(),
      onError: (error: AxiosError) => {
        if (error?.response?.status === 401) {
          setCustomError(
            "There was an error saving the nutrient rounding rule. Please try again."
          );
        } else if (error.response?.data) {
          const errorMessage: ApiError = error.response.data as ApiError;
          setCustomError(errorMessage.message);
        } else {
          setCustomError(String(error));
        }
      },
    }
  );

  const onSave = useCallback((data: NutrientRoundingRuleMappingForEdit) => {
    setCustomError(null);
    const {
      nutrientFactId,
      roundingRuleId,
      roundingRuleTypeId,
      minValue,
      maxValue,
    } = data;
    if (
      nutrientFactId !== null &&
      roundingRuleId !== null &&
      roundingRuleTypeId !== null &&
      minValue !== null &&
      maxValue !== null
    ) {
      // all fields are valid. save to API
      doSaveRoundingRuleMapping({
        nutrientFactId: Number(nutrientFactId),
        roundingRuleId: Number(roundingRuleId),
        roundingRuleTypeId: Number(roundingRuleTypeId),
        minValue,
        maxValue,
      });
    } else {
      setCustomError("Please fill out all fields");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog open={true} onClose={onCancel}>
      <form
        onSubmit={handleSubmit(onSave)}
        data-testid="cy-rounding-rule-mapping-form"
      >
        {isLoading && <Loader />}
        <DialogTitle id="create-edit-nutrient-rounding-rule-mapping-dialog-title">
          Nutrient Rounding Rule
        </DialogTitle>
        <DialogContent>
          <StyledMaxWidthGrid
            container
            spacing={2}
            marginLeft={0}
            justifyContent={"center"}
          >
            {customError && (
              <Grid marginLeft={3} marginTop={3}>
                <Alert variant="outlined" severity="error">
                  {customError}
                </Alert>
              </Grid>
            )}
            <Grid mobile={8} margin={2}>
              <Controller
                name={`nutrientFactId`}
                control={control}
                render={() => (
                  <TextField
                    fullWidth
                    label="Nutrient*"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={getValues("nutrientFactId")}
                    aria-labelledby="nutrient-fact-id"
                    error={!!errors.nutrientFactId}
                    select
                    disabled
                    SelectProps={{ native: true }}
                    data-testid="nutrient-selector"
                  >
                    {nutrient.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.value}
                      </option>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid mobile={8} margin={2}>
              <Controller
                name={`roundingRuleTypeId`}
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    label="Rounding Rule Type*"
                    {...field}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={getValues("roundingRuleTypeId")}
                    aria-labelledby="rounding-rule-type-id"
                    error={!!errors.roundingRuleTypeId}
                    helperText={
                      errors.roundingRuleTypeId &&
                      "Please select a rounding rule type"
                    }
                    select
                    disabled={
                      typeof initialData.roundingRuleMappingId !== "undefined"
                    }
                    SelectProps={{ native: true }}
                    data-testid="cy-rounding-rule-type-selector"
                  >
                    <option key="" value="">
                      Select Rounding Rule Type
                    </option>
                    {roundingRuleType.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.value}
                      </option>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid mobile={8} margin={2}>
              <Controller
                name={`roundingRuleId`}
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    label="Rounding Rule*"
                    {...field}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={getValues("roundingRuleId")}
                    aria-labelledby="rounding-rule-id"
                    error={!!errors.roundingRuleId}
                    helperText={
                      errors.roundingRuleId && "Please select a rounding rule"
                    }
                    select
                    SelectProps={{ native: true }}
                    data-testid="cy-rounding-rule-selector"
                  >
                    <option key="" value="">
                      Select Rounding Rule
                    </option>
                    {roundingRule.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.value}
                      </option>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid mobile={8}>
              <Controller
                name={`minValue`}
                control={control}
                rules={{
                  required: "Please enter a min value",
                  validate: (value) => {
                    const floatVal = localizedStringValueToFloat(
                      String(value),
                      selectedCountry!
                    );
                    return !isNaN(floatVal) || "Min value must be numeric";
                  },
                }}
                render={({ field }) => (
                  <StyledTextField
                    label="Min Value*"
                    {...field}
                    value={getValues("minValue")}
                    onFocus={() => {
                      setValue(
                        `minValue`,
                        standardizedNumStringOrPlaceholder(
                          getValues(`minValue`),
                          selectedCountry!,
                          ""
                        )
                      );
                    }}
                    onBlur={(e) => {
                      const formattedValue = localizedNumStringOrEmpty(
                        e.target.value,
                        selectedCountry!
                      );
                      setValue(
                        `minValue`,
                        formattedValue !== "" ? formattedValue : e.target.value
                      );
                      field.onBlur();
                    }}
                    data-testid="cy-rounding-rule-mapping-minval"
                    error={!!errors.minValue}
                    helperText={!!errors.minValue && errors.minValue.message}
                  />
                )}
              />
            </Grid>
            <Grid mobile={8}>
              <Controller
                name={`maxValue`}
                control={control}
                rules={{
                  required: "Please enter a max value",
                  validate: (value) => {
                    const floatVal = localizedStringValueToFloat(
                      String(value),
                      selectedCountry!
                    );
                    return !isNaN(floatVal) || "Max value must be numeric";
                  },
                }}
                render={({ field }) => (
                  <StyledTextField
                    label="Max Value*"
                    {...field}
                    value={getValues("maxValue")}
                    onFocus={() => {
                      setValue(
                        `maxValue`,
                        standardizedNumStringOrEmpty(
                          getValues(`maxValue`),
                          selectedCountry!
                        )
                      );
                    }}
                    onBlur={(e) => {
                      const formattedValue = localizedNumStringOrEmpty(
                        e.target.value,
                        selectedCountry!
                      );
                      setValue(
                        `maxValue`,
                        formattedValue !== "" ? formattedValue : e.target.value
                      );
                      field.onBlur();
                    }}
                    data-testid="cy-rounding-rule-mapping-maxval"
                    error={!!errors.maxValue}
                    helperText={!!errors.maxValue && errors.maxValue.message}
                  />
                )}
              />
            </Grid>
          </StyledMaxWidthGrid>
        </DialogContent>
        <DialogActions>
          <StyledActionButton
            variant="contained"
            onClick={onCancel}
            data-testid="cy-rounding-rule-mapping-cancel-button"
          >
            Cancel
          </StyledActionButton>
          <Button
            type="submit"
            variant="contained"
            data-testid="cy-rounding-rule-mapping-save-button"
          >
            <Typography>Save</Typography>
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
const StyledActionButton = styled(Button)({
  backgroundColor: "#DA291C",
});
const StyledTextField = styled(TextField)(({ theme }) => ({
  marginTop: theme.spacing(3),
  width: "100%",
  "&& .MuiInputLabel-root": {
    color: "#707070",
  },
  "& .MuiInputLabel-shrink": {
    fontSize: theme.typography.large.fontSize,
    fontFamily: theme.typography.large.fontFamily,
    fontWeight: theme.typography.large.fontWeight,
    lineHeight: theme.typography.large.lineHeight,
  },
  "& .MuiOutlinedInput-root legend": {
    fontSize: "0.85em",
  },
}));
const StyledMaxWidthGrid = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(1),
  maxWidth: 720,
}));
