import { AxiosHeaders } from "axios";
import {
  realGetNutrientRoundingMappingForAdd,
  realGetRoundingRuleManagementData,
  realGetRoundingRuleById,
  realSaveRoundingRule,
  realSaveRoundingRuleMapping,
  realDeleteRoundingRule,
  realDeleteRoundingRuleMapping,
  realGetRoundingRuleMapping,
} from "../api/roundingrules";
import { delay } from ".";

export const mockGetNutrientRoundingMappingForAdd: ReturnType<
  typeof realGetNutrientRoundingMappingForAdd
> = async () => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful",
      nutrientRoundingRuleData: {
        nutrient: [
          {
            id: 1,
            value: "Energy kcal",
          },
          {
            id: 9,
            value: "Energy kJ",
          },
          {
            id: 2,
            value: "protein",
          },
          {
            id: 3,
            value: "carbohydrates",
          },
          {
            id: 4,
            value: "sugars",
          },
          {
            id: 5,
            value: "fat",
          },
          {
            id: 6,
            value: "saturated fat",
          },
          {
            id: 7,
            value: "fibre",
          },
          {
            id: 8,
            value: "salt",
          },
          {
            id: 10,
            value: "Serving Size",
          },
        ],
        roundingRuleType: [
          {
            id: 1,
            value: "Value",
          },
          {
            id: 2,
            value: "Daily Value",
          },
        ],
        roundingRule: [
          {
            id: 27,
            value: "Round_to_1",
          },
          {
            id: 28,
            value: "Round_to_10",
          },
          {
            id: 31,
            value: "No_Rounding",
          },
          {
            id: 33,
            value: "Round_to_0.1",
          },
          {
            id: 34,
            value: "Round_to_0.01",
          },
        ],
      },
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockGetRoundingRuleManagementData: ReturnType<
  typeof realGetRoundingRuleManagementData
> = async () => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful",
      dataList: [
        {
          roundingRuleId: 27,
          roundingRuleName: "Round_to_1",
          roundingMode: "ROUND_TO_V",
          roundingConstant: "1",
        },
        {
          roundingRuleId: 28,
          roundingRuleName: "Round_to_10",
          roundingMode: "ROUND_TO_V",
          roundingConstant: "0.1",
        },
        {
          roundingRuleId: 33,
          roundingRuleName: "Round_to_0.1",
          roundingMode: "ROUND_TO_V",
          roundingConstant: "10",
        },
        {
          roundingRuleId: 34,
          roundingRuleName: "Round_to_0.01",
          roundingMode: "ROUND_TO_V",
          roundingConstant: "100",
        },
        {
          roundingRuleId: 31,
          roundingRuleName: "No_Rounding",
          roundingMode: "NO_ROUNDING",
          roundingConstant: "0",
        },
      ],
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockGetRoundingRuleMapping: ReturnType<
  typeof realGetRoundingRuleMapping
> = async () => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful",
      dataList: [
        {
          nutrientFactId: 1,
          nutrientFactName: "Energy kcal",
          roundingRuleValue: [
            {
              roundingRuleMappingId: 124,
              ruleName: "Round_to_1",
              minVal: "0",
              maxVal: "1000000",
            },
          ],
          roundingRuleDailyValue: [
            {
              roundingRuleMappingId: 84,
              ruleName: "Round_to_1",
              minVal: "0",
              maxVal: "1000000",
            },
          ],
        },
        {
          nutrientFactId: 9,
          nutrientFactName: "Energy kJ",
          roundingRuleValue: [
            {
              roundingRuleMappingId: 92,
              ruleName: "Round_to_1",
              minVal: "0",
              maxVal: "1000000",
            },
          ],
          roundingRuleDailyValue: [
            {
              roundingRuleMappingId: 92,
              ruleName: "Round_to_1",
              minVal: "0",
              maxVal: "1000000",
            },
          ],
        },
        {
          nutrientFactId: 2,
          nutrientFactName: "protein",
          roundingRuleValue: [
            {
              roundingRuleMappingId: 125,
              ruleName: "Round_to_1",
              minVal: "10",
              maxVal: "1000000",
            },
            {
              roundingRuleMappingId: 142,
              ruleName: "Round_to_0.1",
              minVal: "0",
              maxVal: "9.99999",
            },
          ],
          roundingRuleDailyValue: [
            {
              roundingRuleMappingId: 85,
              ruleName: "No_Rounding",
              minVal: "0",
              maxVal: "1000000",
            },
          ],
        },
        {
          nutrientFactId: 3,
          nutrientFactName: "carbohydrates",
          roundingRuleValue: [
            {
              roundingRuleMappingId: 126,
              ruleName: "Round_to_1",
              minVal: "10",
              maxVal: "1000000",
            },
            {
              roundingRuleMappingId: 143,
              ruleName: "Round_to_0.1",
              minVal: "0",
              maxVal: "9.99999",
            },
          ],
          roundingRuleDailyValue: [
            {
              roundingRuleMappingId: 86,
              ruleName: "No_Rounding",
              minVal: "0",
              maxVal: "1000000",
            },
          ],
        },
        {
          nutrientFactId: 4,
          nutrientFactName: "sugars",
          roundingRuleValue: [
            {
              roundingRuleMappingId: 127,
              ruleName: "Round_to_1",
              minVal: "10",
              maxVal: "1000000",
            },
            {
              roundingRuleMappingId: 146,
              ruleName: "Round_to_0.1",
              minVal: "0",
              maxVal: "9.99999",
            },
          ],
          roundingRuleDailyValue: [
            {
              roundingRuleMappingId: 87,
              ruleName: "No_Rounding",
              minVal: "0",
              maxVal: "1000000",
            },
          ],
        },
        {
          nutrientFactId: 5,
          nutrientFactName: "fat",
          roundingRuleValue: [
            {
              roundingRuleMappingId: 128,
              ruleName: "Round_to_1",
              minVal: "10",
              maxVal: "1000000",
            },
            {
              roundingRuleMappingId: 147,
              ruleName: "Round_to_0.1",
              minVal: "0",
              maxVal: "9.99999",
            },
          ],
          roundingRuleDailyValue: [
            {
              roundingRuleMappingId: 88,
              ruleName: "No_Rounding",
              minVal: "0",
              maxVal: "1000000",
            },
          ],
        },
        {
          nutrientFactId: 6,
          nutrientFactName: "saturated fat",
          roundingRuleValue: [
            {
              roundingRuleMappingId: 129,
              ruleName: "Round_to_1",
              minVal: "10",
              maxVal: "1000000",
            },
            {
              roundingRuleMappingId: 148,
              ruleName: "Round_to_0.1",
              minVal: "0",
              maxVal: "9.99999",
            },
          ],
          roundingRuleDailyValue: [
            {
              roundingRuleMappingId: 89,
              ruleName: "No_Rounding",
              minVal: "0",
              maxVal: "1000000",
            },
          ],
        },
        {
          nutrientFactId: 7,
          nutrientFactName: "fibre",
          roundingRuleValue: [
            {
              roundingRuleMappingId: 130,
              ruleName: "Round_to_1",
              minVal: "10",
              maxVal: "1000000",
            },
            {
              roundingRuleMappingId: 149,
              ruleName: "Round_to_0.1",
              minVal: "0",
              maxVal: "9.99999",
            },
          ],
          roundingRuleDailyValue: [
            {
              roundingRuleMappingId: 90,
              ruleName: "No_Rounding",
              minVal: "0",
              maxVal: "1000000",
            },
          ],
        },
        {
          nutrientFactId: 8,
          nutrientFactName: "salt",
          roundingRuleValue: [
            {
              roundingRuleMappingId: 144,
              ruleName: "Round_to_0.01",
              minVal: "0",
              maxVal: "0.999999",
            },
            {
              roundingRuleMappingId: 145,
              ruleName: "Round_to_0.1",
              minVal: "1.0",
              maxVal: "10",
            },
          ],
          roundingRuleDailyValue: [
            {
              roundingRuleMappingId: 91,
              ruleName: "No_Rounding",
              minVal: "0",
              maxVal: "1000000",
            },
          ],
        },
        {
          nutrientFactId: 10,
          nutrientFactName: "Serving Size",
          roundingRuleValue: [],
          roundingRuleDailyValue: [],
        },
      ],
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockGetRoundingRuleById: ReturnType<
  typeof realGetRoundingRuleById
> = async () => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful",
      dataList: {
        roundingModeId: 1,
        roundingMode: "ROUND TO CONSTANT",
        roundingRuleName: "Round_to_1",
        description: "Round_to_1",
        increment: "1",
      },
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockSaveRoundingRule: ReturnType<
  typeof realSaveRoundingRule
> = async (body) => {
  await delay(2);
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "Data has been updated successfully.",
      roundingRuleId: 33,
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockSaveRoundingRuleMapping: ReturnType<
  typeof realSaveRoundingRuleMapping
> = async (body) => {
  await delay(2);
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "Data has been updated successfully.",
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockDeleteRoundingRule: ReturnType<
  typeof realDeleteRoundingRule
> = async () => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "Rounding Rule Deleted Successfully!",
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockDeleteRoundingRuleMapping: ReturnType<
  typeof realDeleteRoundingRuleMapping
> = async () => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "Nutrient Fact rounding rule deleted.",
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};
