import {
  createRealDeleteRequest,
  createRealGetRequest,
  createRealPostRequest,
} from ".";
import { paths } from "../uom.types";

export const realGetNutrientRoundingMappingForAdd = createRealGetRequest<
  paths["/exp/v1/dna/miscellaneous/getNutrientRoundingMappingForAdd"]
>("/miscellaneous/getNutrientRoundingMappingForAdd");

export const realGetRoundingRuleManagementData = createRealGetRequest<
  paths["/exp/v1/dna/miscellaneous/getRoundingRuleManagementData"]
>("/miscellaneous/getRoundingRuleManagementData");

export const realGetRoundingRuleById = createRealGetRequest<
  paths["/exp/v1/dna/miscellaneous/getRoundingRuleDataById"]
>("/miscellaneous/getRoundingRuleDataById");

export const realSaveRoundingRule = createRealPostRequest<
  paths["/exp/v1/dna/miscellaneous/saveRoundingRule"]
>("/miscellaneous/saveRoundingRule");

export const realSaveRoundingRuleMapping = createRealPostRequest<
  paths["/exp/v1/dna/miscellaneous/saveRoundingRuleMapping"]
>("/miscellaneous/saveRoundingRuleMapping");

export const realDeleteRoundingRule = createRealDeleteRequest<
  paths["/exp/v1/dna/miscellaneous/deleteRoundingRule"]
>("/miscellaneous/deleteRoundingRule");

export const realDeleteRoundingRuleMapping = createRealDeleteRequest<
  paths["/exp/v1/dna/miscellaneous/deleteRoundingRuleMapping"]
>("/miscellaneous/deleteRoundingRuleMapping");

export const realGetRoundingRuleMapping = createRealGetRequest<
  paths["/exp/v1/dna/miscellaneous/getRoundingRuleMapping"]
>("/miscellaneous/getRoundingRuleMapping");
