import { paths } from "./uom.types";
import {
  mockableDeleteRequest,
  mockableGetRequest,
  mockablePostRequest,
} from "./mockable-api-request";
import {
  realDeleteRoundingRule,
  realDeleteRoundingRuleMapping,
  realGetNutrientRoundingMappingForAdd,
  realGetRoundingRuleById,
  realGetRoundingRuleManagementData,
  realGetRoundingRuleMapping,
  realSaveRoundingRule,
  realSaveRoundingRuleMapping,
} from "./api/roundingrules";
import {
  mockDeleteRoundingRule,
  mockDeleteRoundingRuleMapping,
  mockGetNutrientRoundingMappingForAdd,
  mockGetRoundingRuleById,
  mockGetRoundingRuleManagementData,
  mockGetRoundingRuleMapping,
  mockSaveRoundingRule,
  mockSaveRoundingRuleMapping,
} from "./mock/roundingrules";

export const getNutrientRoundingMappingForAdd = mockableGetRequest<
  paths["/exp/v1/dna/miscellaneous/getNutrientRoundingMappingForAdd"]
>(realGetNutrientRoundingMappingForAdd, mockGetNutrientRoundingMappingForAdd);

export const getRoundingRuleManagementData = mockableGetRequest<
  paths["/exp/v1/dna/miscellaneous/getRoundingRuleManagementData"]
>(realGetRoundingRuleManagementData, mockGetRoundingRuleManagementData);

export const getRoundingRuleMapping = mockableGetRequest<
  paths["/exp/v1/dna/miscellaneous/getRoundingRuleMapping"]
>(realGetRoundingRuleMapping, mockGetRoundingRuleMapping);

export const getRoundingRuleById = mockableGetRequest<
  paths["/exp/v1/dna/miscellaneous/getRoundingRuleDataById"]
>(realGetRoundingRuleById, mockGetRoundingRuleById);

export const saveRoundingRule = mockablePostRequest<
  paths["/exp/v1/dna/miscellaneous/saveRoundingRule"]
>(realSaveRoundingRule, mockSaveRoundingRule);

export const saveRoundingRuleMapping = mockablePostRequest<
  paths["/exp/v1/dna/miscellaneous/saveRoundingRuleMapping"]
>(realSaveRoundingRuleMapping, mockSaveRoundingRuleMapping);

export const deleteRoundingRule = mockableDeleteRequest<
  paths["/exp/v1/dna/miscellaneous/deleteRoundingRule"]
>(realDeleteRoundingRule, mockDeleteRoundingRule);

export const deleteRoundingRuleMapping = mockableDeleteRequest<
  paths["/exp/v1/dna/miscellaneous/deleteRoundingRuleMapping"]
>(realDeleteRoundingRuleMapping, mockDeleteRoundingRuleMapping);
