import { Category, SortOrder } from "./data/mock/types";
import { components as productComponents } from "./data/products.types";
import { components as auditComponents } from "./data/auditTrail.types";
import { components as itemComponents } from "./data/items-v2.types";
import { components as roleCountryComponents } from "./data/rolecountry.types";
import { components as categoryComponents } from "./data/categories.types";
import { components as miscellaneousComponents } from "./data/miscellaneous.types";
import type { Config } from "dompurify";
import {
  formatLocalized,
  localizedNumStringOrEmpty,
} from "./util/number-localization";
import { components as navComponents } from "./data/navmenu.types";

export const SANITIZE_OPTS_NO_TAGS: Config = { ALLOWED_TAGS: [] };
export const SANITIZE_OPTS_ALLOW_TAGS: Config = {};

export function applyPagination(
  documents: Category[],
  page: number,
  rowsPerPage: number
) {
  return documents.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
}
type PaginationOptions = {
  page: number;
  rowsPerPage: number;
};

export type RequireFields<T> = {
  [K in keyof T]-?: T[K];
};

export type ValidProductAttributeWithValue = RequireFields<
  productComponents["schemas"]["elementDetails"]
>;
export type ValidProductAttribute = Omit<
  ValidProductAttributeWithValue,
  "value"
> & {
  value?: string;
};
export type ValidProductType = RequireFields<
  productComponents["schemas"]["ProductTypeResponse"]
>;
export type ValidProductNutrients = RequireFields<
  productComponents["schemas"]["nutritionResponse"]
>;
export type ValidProductNutrition = RequireFields<
  productComponents["schemas"]["nutrition"]
>;

export function isProductAttribute(
  maybeProductAttribute: any
): maybeProductAttribute is ValidProductAttribute {
  if (
    typeof maybeProductAttribute !== "object" ||
    maybeProductAttribute === null
  ) {
    return false;
  }

  const { id, name, value, keyName } = maybeProductAttribute;

  return (
    typeof id === "number" &&
    typeof name === "string" &&
    typeof keyName === "string" &&
    (typeof value === "string" || typeof value === "undefined")
  );
}
export function isProductNutrients(
  maybeProductNutrients: any
): maybeProductNutrients is ValidProductNutrients {
  if (
    typeof maybeProductNutrients !== "object" ||
    maybeProductNutrients === null
  ) {
    return false;
  }

  const { message, productName, nutrientList } = maybeProductNutrients;

  return (
    typeof message === "string" &&
    typeof productName === "string" &&
    typeof nutrientList === "object"
  );
}

export type ValidNutrient = RequireFields<
  productComponents["schemas"]["Nutrient"]
>;
export type ValidProductImage = RequireFields<
  productComponents["schemas"]["Image"]
>;

function formatNutrient(rawNutrient: any): ValidNutrient | undefined {
  const {
    uom,
    name,
    id,
    value,
    hundredGPerProduct,
    uomDescription,
    adultDv,
    childDv,
    womanDv,
    isDisplay,
  } = rawNutrient;
  if (typeof id === "number") {
    return {
      id,
      uom: typeof uom === "string" ? uom : "",
      name: typeof name === "string" ? name : "",
      value: typeof value === "string" ? value : "",
      hundredGPerProduct:
        typeof hundredGPerProduct === "string" ? hundredGPerProduct : "",
      uomDescription: typeof uomDescription === "string" ? uomDescription : "",
      adultDv: typeof adultDv === "string" ? adultDv : "",
      childDv: typeof childDv === "string" ? childDv : "",
      womanDv: typeof womanDv === "string" ? womanDv : "",
      isDisplay: typeof isDisplay === "string" ? isDisplay : "",
    };
  }
}

function formatProductImage(rawImage: any): ValidProductImage {
  const { imageName } = rawImage ?? {};
  return {
    imageName: typeof imageName === "string" ? imageName : "",
  };
}

export function isItemCategory(
  maybeItemCategory: any
): maybeItemCategory is ValidItemCategory {
  if (typeof maybeItemCategory !== "object" || maybeItemCategory === null) {
    return false;
  }

  const { id, name } = maybeItemCategory;

  return typeof id === "number" && typeof name === "string";
}

type RawProductCompareDetails = {
  productComments?: string;
  productAllergen?: string;
  ingredientStatement?: string;
  productMarketingName?: string;
  productName?: string;
  productAdditionalTextIngredientStatement?: string;
  productWrinNo?: string;
  productAdditionalAllergen?: string;
  productType?: string;
  product_keywords?: string;
  productNo?: string;
  productId: number;
  id?: number;
  nutrientFacts?: {
    nutrient?: productComponents["schemas"]["Nutrient"][];
  };
  attachProductThumbnailImage?: productComponents["schemas"]["Image"];
};
type RequiredProductCompareDetails = RequireFields<RawProductCompareDetails>;

export type FormattedProductCompareDetails = Omit<
  RequiredProductCompareDetails,
  "nutrientFacts" | "attachProductThumbnailImage"
> & {
  nutrientFacts: {
    nutrient: RequireFields<productComponents["schemas"]["Nutrient"]>[];
  };
  attachProductThumbnailImage: ValidProductImage | null;
};

export function formatProductCompare(
  rawProductCompare: any
): FormattedProductCompareDetails | undefined {
  const {
    productComments,
    productAllergen,
    ingredientStatement,
    productMarketingName,
    productName,
    productAdditionalTextIngredientStatement,
    productWrinNo,
    productAdditionalAllergen,
    productType,
    productId,
    product_keywords,
    productNo,
    id,
    nutrientFacts,
    attachProductThumbnailImage,
  } = rawProductCompare;

  if (!isNaN(Number(id)) && !isNaN(Number(productId))) {
    const { nutrient = [] } = nutrientFacts ?? {};
    const validNutrientFacts: ValidNutrient[] = nutrient
      ? nutrient.map((nf: any) => formatNutrient(nf!))
      : [];

    return {
      id: Number(id),
      productComments:
        typeof productComments === "string" ? productComments : "",
      productAllergen:
        typeof productAllergen === "string" ? productAllergen : "",
      ingredientStatement:
        typeof ingredientStatement === "string" ? ingredientStatement : "",
      productMarketingName:
        typeof productMarketingName === "string" ? productMarketingName : "",
      productName: typeof productName === "string" ? productName : "",
      productAdditionalTextIngredientStatement:
        typeof productAdditionalTextIngredientStatement === "string"
          ? productAdditionalTextIngredientStatement
          : "",
      productWrinNo: typeof productWrinNo === "string" ? productWrinNo : "",
      productAdditionalAllergen:
        typeof productAdditionalAllergen === "string"
          ? productAdditionalAllergen
          : "",
      productType: typeof productType === "string" ? productType : "",
      productId: Number(productId),
      product_keywords:
        typeof product_keywords === "string" ? product_keywords : "",
      productNo: typeof productNo === "string" ? productNo : "",
      nutrientFacts: {
        nutrient: validNutrientFacts,
      },
      attachProductThumbnailImage: formatProductImage(
        attachProductThumbnailImage
      ),
    };
  }
}

export function isProductType(
  maybeProductAttribute: any
): maybeProductAttribute is ValidProductType {
  if (
    typeof maybeProductAttribute !== "object" ||
    maybeProductAttribute === null
  ) {
    return false;
  }

  const { id, value } = maybeProductAttribute;

  return typeof id === "number" && typeof value === "string";
}

type ValidItemImage = RequireFields<itemComponents["schemas"]["Image"]>;
type ValidItemImageSimple = RequireFields<itemComponents["schemas"]["Image1"]>;
type ValidItemCategory = RequireFields<itemComponents["schemas"]["Category"]>;
type ValidItemGoesWellWithForItemCompare = RequireFields<
  itemComponents["schemas"]["MenuItemRelation"]
>;
type ValidRelatedItem = Omit<
  RequireFields<itemComponents["schemas"]["RelatedItem"]>,
  "displayOrder" | "abbrLabel" | "externalId" | "isDefault" | "shortName"
>;
type ValidRelationType = {
  type: string;
  relatedItems: string[];
};
type ValidCoOp = RequireFields<itemComponents["schemas"]["MappedCoop"]>;

type RequiredItemCompareDetails = RequireFields<
  itemComponents["schemas"]["MenuItem"]
>;

export type ItemCompareLiveMappedProduct = {
  type: "Product";
  quantity: string;
  productName: string;
  ingredient: string;
  unit: string;
  productAllergen: string;
};
export type ItemCompareLiveMappedItem = {
  type: string;
  quantity: string;
  itemName: string;
  unit: string;
};
export type ItemCompareLiveMappedMutex = {
  itemName: string;
  item_allergen: string;
  product_allergen: string;
  name: string;
  product_type_name: string;
  ingredientStatement: string;
  item_ingredient_statement: string;
  quantity?: string;
  unit?: string;
};
export type FormattedItemCompareDetails = Omit<
  RequiredItemCompareDetails,
  | "attachItemHeroImage"
  | "attachItemThumbnailImage"
  | "attachTransparentIconImage"
  | "attachMealBundleImage"
  | "categories"
  | "goesWellWith"
  | "coops"
  | "components"
  | "relationTypes"
  | "logo1"
  | "logo2"
  | "logo3"
  | "nutrientFacts"
  | "mutexGroups"
  | "itemMultiValue"
  | "pimId"
  | "pimItemName"
  | "importId"
  | "importItemName"
  // | "genesisId"
  // |  "genesisItemName"
> & {
  attachItemHeroImage: ValidItemImage | null;
  attachItemThumbnailImage: ValidItemImage | null;
  attachTransparentIconImage: ValidItemImage | null;
  attachMealBundleImage: ValidItemImage | null;
  categories: {
    categories: ValidItemCategory[];
  };
  goesWellWith: {
    goesWell: ValidItemGoesWellWithForItemCompare[];
  };
  coops: {
    coop: ValidCoOp[];
  };
  mappedProducts: ItemCompareLiveMappedProduct[];
  mappedItems: ItemCompareLiveMappedItem[];
  mappedMutexGroups: ItemCompareLiveMappedMutex[];
  relationTypes: {
    relationType: ValidRelationType[];
  };
  logo1: ValidItemImageSimple | null;
  logo2: ValidItemImageSimple | null;
  logo3: ValidItemImageSimple | null;
  nutrientFacts: {
    nutrient: RequireFields<productComponents["schemas"]["Nutrient"]>[];
  };
  footers: string[];
  pimId: string;
  pimItemName: string;
  importId: string;
  importedItemName: string;
};

export type DropDownItem = itemComponents["schemas"]["DropDownItem"];
export type DropDownList = itemComponents["schemas"]["DropDownList"];

export type Footer = {
  id: number;
  value: string;
};
export type FooterType = {
  footerText: string;
  footerId: number;
};
function formatItemImage(rawImage: any): ValidItemImage {
  const { imageName } = rawImage;
  return {
    imageName: typeof imageName === "string" ? imageName : "",
  };
}

function formatItemImageSimple(rawImageSimple: any): ValidItemImageSimple {
  const { altText, url } = rawImageSimple;
  return {
    altText: typeof altText === "string" ? altText : "",
    url: typeof url === "string" ? url : "",
  };
}

function formatCompareLiveMappedProduct(
  rawComponent: any
): ItemCompareLiveMappedProduct {
  const { productName, ingredient, quantity, unit, productAllergen } =
    rawComponent;

  return {
    type: "Product",
    productName: typeof productName === "string" ? productName : "",
    ingredient: typeof ingredient === "string" ? ingredient : "",
    quantity:
      typeof quantity === "number" || typeof quantity === "string"
        ? String(quantity)
        : "",
    unit: typeof unit === "string" ? unit : "",
    productAllergen: typeof productAllergen === "string" ? productAllergen : "",
  };
}

function formatCompareLiveMappedItem(
  rawComponent: any
): ItemCompareLiveMappedItem {
  const { quantity, unit, itemName, type } = rawComponent;
  return {
    type: typeof type === "string" ? type : "",
    itemName: typeof itemName === "string" ? itemName : "",
    quantity:
      typeof quantity === "number" || typeof quantity === "string"
        ? String(quantity)
        : "",
    unit: typeof unit === "string" ? unit : "",
  };
}

function formatCompareLiveMappedMutexGroup(
  rawComponent: any
): ItemCompareLiveMappedMutex {
  const {
    product_name,
    productName,
    item_name,
    item_allergen,
    product_allergen,
    productAllergen,
    ingredientStatement,
    ingredient,
    product_type_name,
    item_ingredient_statement,
    quantity,
    unit,
  } = rawComponent;

  return {
    name:
      typeof productName === "string"
        ? productName
        : typeof product_name === "string"
        ? product_name
        : "",
    product_type_name:
      typeof product_type_name === "string" ? product_type_name : "",
    itemName: typeof item_name === "string" ? item_name : "",
    item_allergen: typeof item_allergen === "string" ? item_allergen : "",
    product_allergen:
      typeof productAllergen === "string"
        ? productAllergen
        : typeof product_allergen === "string"
        ? product_allergen
        : "",
    ingredientStatement:
      typeof ingredient === "string"
        ? ingredient
        : typeof ingredientStatement === "string"
        ? ingredientStatement
        : "",
    item_ingredient_statement:
      typeof item_ingredient_statement === "string"
        ? item_ingredient_statement
        : "",
    quantity,
    unit,
  };
}

function formatItemGoesWellWithForItemCompare(
  rawGoesWellWith: any
): ValidItemGoesWellWithForItemCompare | undefined {
  const { id, itemName } = rawGoesWellWith;
  if (typeof id === "number") {
    return {
      id,
      itemName: typeof itemName === "string" ? itemName : "",
    };
  }
}

export type ValidGoesWellWith = RequireFields<
  itemComponents["schemas"]["ItemMappingItem"]
>;
export function isGoesWellWith(
  maybeGoesWellWith: any
): maybeGoesWellWith is ValidGoesWellWith {
  const { itemId, menuItemName } = maybeGoesWellWith;
  return typeof itemId === "number" && typeof menuItemName === "string";
}

function formatRelatedItem(rawRelatedItem: any): ValidRelatedItem | undefined {
  const { id, itemName, label } = rawRelatedItem;
  if (typeof id === "number") {
    return {
      id,
      itemName: typeof itemName === "string" ? itemName : "",
      label: typeof label === "string" ? label : "",
    };
  }
}

function formatItemRelationType(
  rawRelationType: any
): ValidRelationType | undefined {
  const { type, relatedItems } = rawRelationType;
  const { relatedItem: rawRelatedItems = [] } = relatedItems ?? {};

  const validRelatedItems: ValidRelatedItem[] = rawRelatedItems
    ? rawRelatedItems
        .map((maybeRelatedItem: any) => formatRelatedItem(maybeRelatedItem))
        .filter(isDefined)
    : [];
  const relatedItemsString = validRelatedItems.map(
    (vri) => `${vri.itemName} (${vri.label})`
  );
  return {
    type: typeof type === "string" ? type : "",
    relatedItems: relatedItemsString,
  };
}

function formatCoOp(rawCoOp: any): ValidCoOp | undefined {
  const { name, coopId } = rawCoOp;
  if (typeof coopId === "number") {
    return {
      coopId,
      name: typeof name === "string" ? name : "",
    };
  }
}

export function formatItemCompare(
  rawItemCompare: any
): FormattedItemCompareDetails | undefined {
  const {
    attachItemHeroImage,
    attachItemThumbnailImage,
    attachTransparentIconImage,
    attachMealBundleImage,
    categories,
    goesWellWith,
    coops,
    components,
    relationTypes,
    itemAdditionalTextIngredientStatement,
    itemType,
    timeOfDay,
    itemName,
    itemMarketingName,
    description,
    mutexGroups,
    hasComponents,
    isFortifiedMenuItem,
    servingSizeImperial,
    servingSizeMetric,
    nutritionQualityStatementText,
    keywords,
    itemAdditionalAllergen,
    itemComments,
    itemAllergen,
    doNotShow,
    itemIngredientStatement,
    shortName,
    itemId,
    serves,
    showProductImages,
    specializationText1,
    specializationText2,
    siteMap,
    externalId,
    genesisMenuItemNo,
    itemMetaTitle,
    customizationLabel,
    customizationButton,
    itemMetaDescription,
    itemMultiValue,
    frequency,
    pimId,
    pimItemName,
    importId,
    importedItemName,
    priority,
    logo1,
    logo2,
    logo3,
    itemStatusFlag,
    nutrientFacts,
    genesisId,
    genesisItemName,
  } = rawItemCompare;
  // deconstruct API response
  const { categories: rawCategories = [] } = categories ?? {};
  const { goesWell: rawGoesWellWith = [] } = goesWellWith ?? {};
  const { coop: rawCoOps = [] } = coops ?? {};
  const { component: rawComponents = [] } = components ?? {};
  const { mutexGroup: rawMutexGroups = [] } = mutexGroups ?? {};
  const { relationType: rawRelationTypes = [] } = relationTypes ?? {};
  const { nutrient: rawNutrients = [] } = nutrientFacts ?? {};

  // massage into type-safe structure
  const validCategories: ValidItemCategory[] = rawCategories
    ? rawCategories.filter(
        (maybeCategory: any): maybeCategory is ValidItemCategory => {
          return isItemCategory(maybeCategory);
        }
      )
    : [];
  const validGoesWellWith: ValidItemGoesWellWithForItemCompare[] =
    rawGoesWellWith
      ? rawGoesWellWith.map((maybeGoesWellWith: any) =>
          formatItemGoesWellWithForItemCompare(maybeGoesWellWith!)
        )
      : [];
  const validCoOps: ValidCoOp[] = rawCoOps
    ? rawCoOps.map((maybeCoOp: any) => formatCoOp(maybeCoOp!))
    : [];
  const maybeMappedProducts = rawComponents
    ? rawComponents.filter((rawComponent: any) => {
        return rawComponent?.type.includes("Product");
      })
    : [];
  const validMappedProducts = maybeMappedProducts.map(
    formatCompareLiveMappedProduct
  );
  const maybeMappedItems = rawComponents
    ? rawComponents.filter((rawComponent: any) => {
        return rawComponent?.type.includes("Item");
      })
    : [];
  const validMappedItems = maybeMappedItems.map(formatCompareLiveMappedItem);

  const validMappedMutexGroups = getMutextGroups(rawComponents, rawMutexGroups);

  const validRelationTypes: ValidRelationType[] = rawRelationTypes
    ? rawRelationTypes.map((maybeRelationType: any) =>
        formatItemRelationType(maybeRelationType)
      )
    : [];
  const validNutrientFacts: ValidNutrient[] = rawNutrients
    ? rawNutrients.map((maybeNutrient: any) => formatNutrient(maybeNutrient!))
    : [];

  if (!isNaN(Number(itemId))) {
    return {
      itemId: Number(itemId),
      genesisId: genesisId !== undefined ? genesisId : "",
      genesisItemName: genesisItemName !== undefined ? genesisItemName : "",
      attachItemHeroImage: attachItemHeroImage
        ? formatItemImage(attachItemHeroImage)
        : null,
      attachItemThumbnailImage: attachItemThumbnailImage
        ? formatItemImage(attachItemThumbnailImage)
        : null,
      attachTransparentIconImage: attachTransparentIconImage
        ? formatItemImage(attachTransparentIconImage)
        : null,
      attachMealBundleImage: attachMealBundleImage
        ? formatItemImage(attachMealBundleImage)
        : null,
      categories: { categories: validCategories },
      goesWellWith: { goesWell: validGoesWellWith },
      coops: { coop: validCoOps },
      mappedProducts: validMappedProducts,
      mappedItems: validMappedItems,
      mappedMutexGroups: validMappedMutexGroups,
      relationTypes: { relationType: validRelationTypes },
      itemAdditionalTextIngredientStatement:
        typeof itemAdditionalTextIngredientStatement === "string"
          ? itemAdditionalTextIngredientStatement
          : "",
      itemType: typeof itemType === "string" ? itemType : "",
      timeOfDay: typeof timeOfDay === "string" ? timeOfDay : "",
      itemName: typeof itemName === "string" ? itemName : "",
      itemMarketingName:
        typeof itemMarketingName === "string" ? itemMarketingName : "",
      description: typeof description === "string" ? description : "",
      hasComponents: typeof hasComponents === "string" ? hasComponents : "",
      isFortifiedMenuItem:
        typeof isFortifiedMenuItem === "string" ? isFortifiedMenuItem : "",
      servingSizeImperial:
        typeof servingSizeImperial === "string" ? servingSizeImperial : "",
      servingSizeMetric:
        typeof servingSizeMetric === "string" ? servingSizeMetric : "",
      nutritionQualityStatementText:
        typeof nutritionQualityStatementText === "string"
          ? nutritionQualityStatementText
          : "",
      keywords: typeof keywords === "string" ? keywords : "",
      itemAdditionalAllergen:
        typeof itemAdditionalAllergen === "string"
          ? itemAdditionalAllergen
          : "",
      itemComments: typeof itemComments === "string" ? itemComments : "",
      itemAllergen: typeof itemAllergen === "string" ? itemAllergen : "",
      doNotShow: typeof doNotShow === "string" ? doNotShow : "",
      itemIngredientStatement:
        typeof itemIngredientStatement === "string"
          ? itemIngredientStatement
          : "",
      shortName: typeof shortName === "string" ? shortName : "",
      serves:
        typeof serves === "number" || typeof serves === "string"
          ? String(serves)
          : "",
      showProductImages:
        typeof showProductImages === "string" ? showProductImages : "",
      specializationText1:
        typeof specializationText1 === "string" ? specializationText1 : "",
      specializationText2:
        typeof specializationText2 === "string" ? specializationText2 : "",
      sitemap: typeof siteMap === "string" ? siteMap : "",
      externalId: typeof externalId === "string" ? externalId : "",
      genesisMenuItemNo:
        typeof genesisMenuItemNo === "string" ? genesisMenuItemNo : "",
      itemMetaTitle: typeof itemMetaTitle === "string" ? itemMetaTitle : "",
      customizationLabel:
        typeof customizationLabel === "string" ? customizationLabel : "",
      customizationButton:
        typeof customizationButton === "string" ? customizationButton : "",
      itemMetaDescription:
        typeof itemMetaDescription === "string" ? itemMetaDescription : "",
      frequency: typeof frequency === "string" ? frequency : "",
      // pimId: typeof pimId !== "undefined" ? String(pimId) : "",
      pimId:
        typeof pimId !== "undefined" &&
        pimId !== null &&
        pimId !== "" &&
        Object.keys(pimId).length !== 0
          ? String(pimId)
          : "",
      pimItemName: typeof pimItemName === "string" ? pimItemName : "",
      importId: typeof importId === "string" ? importId : "",
      importedItemName:
        typeof importedItemName === "string" ? importedItemName : "",
      priority:
        typeof priority === "number" || typeof priority === "string"
          ? String(priority)
          : "",
      logo1: logo1 ? formatItemImageSimple(logo1) : null,
      logo2: logo2 ? formatItemImageSimple(logo2) : null,
      logo3: logo3 ? formatItemImageSimple(logo3) : null,
      itemStatusFlag: typeof itemStatusFlag === "string" ? itemStatusFlag : "",
      nutrientFacts: { nutrient: validNutrientFacts },
      footers: formatCompareLiveFooters(itemMultiValue),
    };
  }
}

function formatCompareLiveFooters(rawItemMultiValue: any): string[] {
  const footers: string[] = [];

  if (typeof rawItemMultiValue !== "object") {
    return [];
  }

  // check for old (v1) structure
  const { Footer: v1Footers = [] } = rawItemMultiValue;
  // check for new (v2) structure
  const { footers: v2Footers = [] } = rawItemMultiValue;

  if (v1Footers.length) {
    // format according to v1 structure
    v1Footers.forEach((v1Footer: any) => {
      Object.keys(v1Footer)
        // they value we want comes from key beginning with "footer_name"
        .filter((key) => key.startsWith("footer_name"))
        .forEach((key) => footers.push(v1Footer[key]));
    });
  }

  if (v2Footers.length) {
    // format according to v2 structure
    v2Footers.forEach((v2Footer: any) => {
      const { footer: footerName } = v2Footer;
      if (typeof footerName === "string") {
        footers.push(footerName);
      }
    });
  }

  return footers;
}

function getMutextGroups(rawComponents: unknown[], rawMutexGroups?: unknown[]) {
  const maybeMappedMutexGroups = rawComponents
    ? rawComponents.filter((rawComponent: any) => {
        return rawComponent?.type.includes("Mutex");
      })
    : [];
  if (maybeMappedMutexGroups.length) {
    return maybeMappedMutexGroups.map(formatCompareLiveMappedMutexGroup);
  } else {
    return rawMutexGroups?.map(formatCompareLiveMappedMutexGroup) || [];
  }
}

export function isDropDownItem(
  maybeDropDownItem: any
): maybeDropDownItem is DropDownItem {
  if (typeof maybeDropDownItem !== "object" || maybeDropDownItem === null) {
    return false;
  }
  const { id, value } = maybeDropDownItem;
  return typeof id === "number" && typeof value === "string";
}

export function isFooter(maybeFooter: any): maybeFooter is Footer {
  if (typeof maybeFooter !== "object" || maybeFooter === null) {
    return false;
  }
  const { id, value } = maybeFooter;
  return typeof id === "number" && typeof value === "string";
}
export function isMasterFooter(
  maybeMasterFooter: any
): maybeMasterFooter is FooterType {
  if (typeof maybeMasterFooter !== "object" || maybeMasterFooter === null) {
    return false;
  }
  const { footerId, footerText } = maybeMasterFooter;
  return typeof footerId === "number" && typeof footerText === "string";
}

export function isDropDownList(
  maybeDropDownList: any
): maybeDropDownList is DropDownList {
  if (typeof maybeDropDownList !== "object" || maybeDropDownList === null) {
    return false;
  }
  for (const key in maybeDropDownList) {
    if (!Array.isArray(maybeDropDownList[key])) {
      return false;
    }

    for (const maybeDropDownItem of maybeDropDownList[key]) {
      if (!isDropDownItem(maybeDropDownItem)) {
        return false;
      }
    }
  }
  return true;
}

export type ValidItemResponse = RequireFields<
  itemComponents["schemas"]["ItemResponse"]
>;
export type ValidItemAttributeWithValue = RequireFields<
  itemComponents["schemas"]["elementDetails"]
>;
export type ValidItemAttribute = Omit<ValidItemAttributeWithValue, "value"> & {
  value?: string;
};
export type ValidItemCollectionItem = RequireFields<
  itemComponents["schemas"]["ItemCollection"]
>;
export type ValidNutritionFact = RequireFields<
  itemComponents["schemas"]["nutrition"]
>;
export type ValidCategoryMapping = RequireFields<
  itemComponents["schemas"]["CategoryMapping"]
>;

export type ValidCoOpLocation = RequireFields<
  itemComponents["schemas"]["coopList"]
>;
export type ValidRegionList = {
  [regionName: string]: ValidCoOpLocation[];
};
export type ValidSelectedElementMapping = RequireFields<
  Omit<itemComponents["schemas"]["SelectedElementMapping"], "importId" | "unit">
>;
export type DisplayableElementMapping = Omit<
  ValidSelectedElementMapping,
  "qty"
> & {
  qty: string;
};
export type ValidMutexGroup = RequireFields<itemComponents["schemas"]["Mutex"]>;
export type ValidOptionalItem = RequireFields<
  itemComponents["schemas"]["SelectableOptionalItem"]
>;
export type ValidOptionalItemWithNumericId = Omit<
  ValidOptionalItem,
  "itemId"
> & {
  itemId: number;
};
export type ValidElementMappingUpdate = RequireFields<
  itemComponents["schemas"]["DataListItem"]
>;

export function isItemAttribute(
  maybeItemAttribute: any
): maybeItemAttribute is ValidItemAttribute {
  if (typeof maybeItemAttribute !== "object" || maybeItemAttribute === null) {
    return false;
  }

  const { id, name, value, keyName } = maybeItemAttribute;

  return (
    typeof id === "number" &&
    typeof name === "string" &&
    typeof keyName === "string" &&
    (typeof value === "string" || typeof value === "undefined")
  );
}
export function isItemCollectionItem(
  maybeItemAttribute: any
): maybeItemAttribute is ValidItemCollectionItem {
  if (typeof maybeItemAttribute !== "object" || maybeItemAttribute === null) {
    return false;
  }

  const {
    associatedItemId,
    associatedItemName,
    associatedCategoryId,
    associatedCategoryName,
    isChangeable,
    displayOrder,
  } = maybeItemAttribute;

  return (
    typeof associatedItemId === "number" &&
    typeof associatedCategoryId === "number" &&
    typeof associatedItemName === "string" &&
    typeof associatedCategoryName === "string" &&
    typeof isChangeable === "string" &&
    typeof displayOrder === "number"
  );
}
export function isCategoryMapping(
  maybeCategoryMapping: any
): maybeCategoryMapping is ValidCategoryMapping {
  if (
    typeof maybeCategoryMapping !== "object" ||
    maybeCategoryMapping === null
  ) {
    return false;
  }

  const { categoryName, categoryId, defaultCategory } = maybeCategoryMapping;

  return (
    typeof categoryName === "string" &&
    typeof categoryId === "number" &&
    typeof defaultCategory === "string"
  );
}

export type ItemCoopRegion = itemComponents["schemas"]["coopList"][];

export function isItemCoopRegion(
  maybeItemMappableElement: any
): maybeItemMappableElement is ItemCoopRegion {
  const { coopId, coopName } = maybeItemMappableElement;
  return typeof coopId === "number" && typeof coopName === "string";
}

export type ProductIngredient =
  itemComponents["schemas"]["ProductIngredientData"];

export function formatProductIngredient(rawIngredient: any): ProductIngredient {
  const { productName, ingredients, contains, productAdditionalText,  displayOrder} =
    rawIngredient;
  return {
    productName: typeof productName === "string" ? productName : "",
    ingredients: typeof ingredients === "string" ? ingredients : "",
    contains: typeof contains === "string" ? contains : "",
    productAdditionalText:
      typeof productAdditionalText === "string" ? productAdditionalText : "",
      displayOrder: typeof displayOrder === "number" ?  displayOrder : 0,
  };
}

function sanitizeStringValue(maybeString: any): string {
  return typeof maybeString === "string" ? maybeString : "";
}

export function formatNutritionFact(
  rawNutritionFact: any
): ValidNutritionFact | undefined {
  const {
    nutrientFactId,
    nutrientFactName,
    nutrientValue,
    uomName,
    dvValue,
    hundredGramPerProduct,
  } = rawNutritionFact;
  if (typeof nutrientFactId === "number") {
    return {
      nutrientFactId,
      nutrientFactName: sanitizeStringValue(nutrientFactName),
      nutrientValue: sanitizeStringValue(nutrientValue),
      uomName: sanitizeStringValue(uomName),
      dvValue:
        typeof dvValue === "number" || typeof dvValue === "string"
          ? sanitizeStringValue(String(dvValue))
          : "",
      hundredGramPerProduct:
        typeof hundredGramPerProduct === "number" ||
        typeof hundredGramPerProduct === "string"
          ? sanitizeStringValue(String(hundredGramPerProduct))
          : "",
    };
  }
}

export function formatAndFilterNutritionFacts(
  maybeNutritionFacts: any[],
  removeSizes: boolean
): ValidNutritionFact[] {
  const formattedNutritionFacts = maybeNutritionFacts.map(formatNutritionFact);
  const validNutritionFacts = formattedNutritionFacts.filter(
    (
      nutritionFactOrUndefined
    ): nutritionFactOrUndefined is ValidNutritionFact =>
      nutritionFactOrUndefined !== undefined
  );
  if (!removeSizes) {
    return validNutritionFacts;
  }
  return validNutritionFacts.filter(
    (nutritionFact) =>
      ![
        "Primary Serving Size",
        "Secondary Serving Size",
        "Serving Size",
      ].includes(nutritionFact.nutrientFactName)
  );
}

export function isElementMapping(
  maybeElementMapping: any
): maybeElementMapping is ValidSelectedElementMapping {
  const { elementId, elementName, qty, elementType } = maybeElementMapping;
  return (
    typeof elementId === "number" &&
    typeof elementName === "string" &&
    (typeof qty === "number" || typeof qty === "string") &&
    typeof elementType === "string"
  );
}

export function formatElementMappingForDisplay(
  elementMapping: ValidSelectedElementMapping,
  marketCode: string,
): DisplayableElementMapping {
  const { qty, ...otherProps } = elementMapping;

  return {
    qty: formatLocalized(String(qty), marketCode),
    ...otherProps,
  };
}

export function isMutexGroup(
  maybeMutexGroup: any
): maybeMutexGroup is ValidMutexGroup {
  const { elementName, mutexId, mutexName } = maybeMutexGroup;
  return (
    typeof elementName === "string" &&
    typeof mutexId === "number" &&
    typeof mutexName === "string"
  );
}

export function isOptionalItem(
  maybeOptionalItem: any
): maybeOptionalItem is ValidOptionalItem {
  const { itemId, itemName, unit } = maybeOptionalItem;
  return (
    typeof itemId === "string" &&
    typeof itemName === "string" &&
    typeof unit === "string"
  );
}

export function formatOptionalItemWithNumericId(
  optionalItem: ValidOptionalItem
): ValidOptionalItemWithNumericId {
  const { itemId, ...otherFields } = optionalItem;
  return {
    ...otherFields,
    itemId: Number(itemId),
  };
}

export type ValidReviewComment = RequireFields<
  itemComponents["schemas"]["previousComments"]
>;
export type ValidReviewWorkflow = RequireFields<
  itemComponents["schemas"]["workFlow"]
>;
export type ValidReviewCheckAndAckList = RequireFields<
  itemComponents["schemas"]["checkListAndAck"]
>;
export type ValidWorkflowStep = Omit<
  RequireFields<itemComponents["schemas"]["workflowStep"]>,
  "level"
>;
export type FormattedItemReviewSubmitData = Omit<
  RequireFields<itemComponents["schemas"]["reviewAndSubResponse"]>,
  | "message"
  | "previousComments"
  | "workFlowList"
  | "checkListAndAckList"
  | "workFlowStepList"
> & {
  comments: ValidReviewComment[];
  workflows: ValidReviewWorkflow[];
  checkListAndAckList: ValidReviewCheckAndAckList[];
  workFlowStepList: ValidWorkflowStep[];
};
export type FormattedProductReviewSubmitData = Omit<
  FormattedItemReviewSubmitData,
  "itemName"
> & {
  productName: string;
};
export type FormattedCategoryReviewSubmitData = Omit<
  FormattedItemReviewSubmitData,
  "itemName"
> & {
  categoryName: string;
};

function formatReviewComment(rawComment: any): ValidReviewComment {
  const { userName, date, comments, role } = rawComment;
  return {
    userName: typeof userName === "string" ? userName : "",
    date: typeof date === "string" ? date : "",
    comments: typeof comments === "string" ? comments : "",
    role: typeof role === "string" ? role : "",
  };
}

function isValidReviewWorkflow(
  rawWorkflow: any
): rawWorkflow is ValidReviewWorkflow {
  const { id, name, description } = rawWorkflow;
  if (typeof id !== "number") {
    return false;
  }
  return typeof name === "string" && typeof description === "string";
}

function formatCheckAndAckList(
  rawCheckAndAckList: any
): ValidReviewCheckAndAckList {
  const { checkList, ack } = rawCheckAndAckList;
  return {
    checkList: typeof checkList === "string" ? checkList : "",
    ack: typeof ack === "string" ? ack : "",
  };
}

function formatWorkflowStep(rawWorkflowStep: any): ValidWorkflowStep {
  const { stepName, role } = rawWorkflowStep;
  return {
    stepName: typeof stepName === "string" ? stepName.trim() : "",
    role: typeof role === "string" ? role.trim() : "",
  };
}

export function formatItemReviewSubmitData(
  rawData: any
): FormattedItemReviewSubmitData | undefined {
  const {
    appliedWorkFlowId,
    currentStep,
    previousStep,
    nextStep,
    itemName,
    previousComments,
    workFlowList,
    checkListAndAckList,
    workFlowStepList,
  } = rawData;
  if (typeof appliedWorkFlowId === "number") {
    const validComments: ValidReviewComment[] = previousComments
      ? previousComments.map((pc: any) => formatReviewComment(pc))
      : [];
    const validWorkflows: ValidReviewWorkflow[] = workFlowList
      ? workFlowList.filter(
          (maybeWorkflow: any): maybeWorkflow is ValidReviewWorkflow => {
            return isValidReviewWorkflow(maybeWorkflow);
          }
        )
      : [];
    const validCheckAndAckList: ValidReviewCheckAndAckList[] =
      checkListAndAckList.map((clal: any) => formatCheckAndAckList(clal));
    const workflowSteps: ValidWorkflowStep[] =
      workFlowStepList.map(formatWorkflowStep);

    return {
      appliedWorkFlowId,
      currentStep: typeof currentStep === "string" ? currentStep.trim() : "",
      previousStep: typeof previousStep === "string" ? previousStep.trim() : "",
      nextStep: typeof nextStep === "string" ? nextStep.trim() : "",
      itemName: typeof itemName === "string" ? itemName : "",
      comments: validComments,
      workflows: validWorkflows,
      checkListAndAckList: validCheckAndAckList,
      workFlowStepList: workflowSteps,
    };
  }
}

export function formatProductReviewSubmitData(
  rawData: any
): FormattedProductReviewSubmitData | undefined {
  const {
    appliedWorkFlowId,
    currentStep,
    previousStep,
    nextStep,
    productName,
    previousComments,
    workFlowList,
    checkListAndAckList,
    workFlowStepList,
  } = rawData;
  if (typeof appliedWorkFlowId === "number") {
    const validComments: ValidReviewComment[] = previousComments
      ? previousComments.map((pc: any) => formatReviewComment(pc))
      : [];
    const validWorkflows: ValidReviewWorkflow[] = workFlowList
      ? workFlowList.filter(
          (maybeWorkflow: any): maybeWorkflow is ValidReviewWorkflow => {
            return isValidReviewWorkflow(maybeWorkflow);
          }
        )
      : [];
    const validCheckAndAckList: ValidReviewCheckAndAckList[] =
      checkListAndAckList.map((clal: any) => formatCheckAndAckList(clal));
    const workflowSteps: ValidWorkflowStep[] =
      workFlowStepList.map(formatWorkflowStep);
    return {
      appliedWorkFlowId,
      currentStep: typeof currentStep === "string" ? currentStep.trim() : "",
      previousStep: typeof previousStep === "string" ? previousStep.trim() : "",
      nextStep: typeof nextStep === "string" ? nextStep.trim() : "",
      productName: typeof productName === "string" ? productName : "",
      comments: validComments,
      workflows: validWorkflows,
      checkListAndAckList: validCheckAndAckList,
      workFlowStepList: workflowSteps,
    };
  }
}

export function formatCategoryReviewSubmitData(
  rawData: any
): FormattedCategoryReviewSubmitData | undefined {
  const {
    appliedWorkFlowId,
    currentStep,
    previousStep,
    nextStep,
    categoryName,
    previousComments,
    workFlowList,
    checkListAndAckList,
    workFlowStepList,
  } = rawData;
  if (typeof appliedWorkFlowId === "number") {
    const validComments: ValidReviewComment[] = previousComments
      ? previousComments.map((pc: any) => formatReviewComment(pc))
      : [];
    const validWorkflows: ValidReviewWorkflow[] = workFlowList
      ? workFlowList.filter(
          (maybeWorkflow: any): maybeWorkflow is ValidReviewWorkflow => {
            return isValidReviewWorkflow(maybeWorkflow);
          }
        )
      : [];
    const validCheckAndAckList: ValidReviewCheckAndAckList[] =
      checkListAndAckList.map((clal: any) => formatCheckAndAckList(clal));
    const workflowSteps: ValidWorkflowStep[] =
      workFlowStepList.map(formatWorkflowStep);
    return {
      appliedWorkFlowId,
      currentStep: typeof currentStep === "string" ? currentStep.trim() : "",
      previousStep: typeof previousStep === "string" ? previousStep.trim() : "",
      nextStep: typeof nextStep === "string" ? nextStep.trim() : "",
      categoryName: typeof categoryName === "string" ? categoryName : "",
      comments: validComments,
      workflows: validWorkflows,
      checkListAndAckList: validCheckAndAckList,
      workFlowStepList: workflowSteps,
    };
  }
}

export type ValidLanguage = RequireFields<
  roleCountryComponents["schemas"]["UserLanguage"]
>;

export function isLanguage(maybeLanguage: any): maybeLanguage is ValidLanguage {
  const { languageCode, languageName, isDefaultLang } = maybeLanguage;
  return (
    typeof languageCode === "string" &&
    typeof languageName === "string" &&
    typeof isDefaultLang === "string"
  );
}

export function isCoOpLocation(
  maybeCoOpLocation: any
): maybeCoOpLocation is ValidCoOpLocation {
  if (typeof maybeCoOpLocation !== "object" || maybeCoOpLocation === null) {
    return false;
  }
  const { coopId, coopName } = maybeCoOpLocation;
  return typeof coopId === "number" && typeof coopName === "string";
}

export function isRegionList(
  maybeRegionList: any
): maybeRegionList is ValidRegionList {
  if (typeof maybeRegionList !== "object" || maybeRegionList === null) {
    return false;
  }

  for (const key in maybeRegionList) {
    if (!Array.isArray(maybeRegionList[key])) {
      return false;
    }

    for (const location of maybeRegionList[key]) {
      if (!isCoOpLocation(location)) {
        return false;
      }
    }
  }

  return true;
}

export type DefaultRelationshipMappingType = RequireFields<
  itemComponents["schemas"]["DefaultItem"]
>;

export type MappedRelationshipMappingType = RequireFields<
  itemComponents["schemas"]["MappedItem"]
>;
export type RelationType = RequireFields<
  itemComponents["schemas"]["ItemRelationMapping"]
>;
export type ItemRelationMappingType = RequireFields<
  itemComponents["schemas"]["ItemRelationMap"]
>;
export type EditableItemRelationMappingType = Omit<
  ItemRelationMappingType,
  "variantId"
> & {
  variantId?: number;
};
export type ItemRelationsMapping = RequireFields<
  itemComponents["schemas"]["ItemRelationsMappingResponse"]
>;
export type RelationTypeVariant = RequireFields<
  itemComponents["schemas"]["ItemVariantMapping"]
>;

export function isDefaultRelationshipMapping(
  maybeDefaultItemList: any
): maybeDefaultItemList is DefaultRelationshipMappingType {
  if (
    typeof maybeDefaultItemList !== "object" ||
    maybeDefaultItemList === null
  ) {
    return false;
  }

  const {
    defaultItemId,
    defaultItem,
    relationShipNameId,
    relationShipName,
    groupedItems,
  } = maybeDefaultItemList;

  return (
    typeof defaultItemId === "number" &&
    typeof defaultItem === "string" &&
    typeof relationShipName === "string" &&
    typeof relationShipNameId === "number" &&
    typeof groupedItems === "string"
  );
}

export function isMappedItemRelationshipMapping(
  maybeMappedItem: any
): maybeMappedItem is MappedRelationshipMappingType {
  if (typeof maybeMappedItem !== "object" || maybeMappedItem === null) {
    return false;
  }

  const {
    alsoMappedToId,
    alsoMapped,
    mappedRelationShipNameId,
    mappedRelationShipName,
    mappedGroupItems,
  } = maybeMappedItem;

  return (
    typeof alsoMappedToId === "number" &&
    typeof alsoMapped === "string" &&
    typeof mappedRelationShipName === "string" &&
    typeof mappedRelationShipNameId === "number" &&
    typeof mappedGroupItems === "string"
  );
}

export function isSelectedRelationshipMapping(
  maybeRelationTypes: any
): maybeRelationTypes is EditableItemRelationMappingType {
  if (typeof maybeRelationTypes !== "object" || maybeRelationTypes === null) {
    return false;
  }
  const {
    displayOrder,
    selectedItemName,
    selectedItemId,
    isDefault,
    variantId,
  } = maybeRelationTypes;

  return (
    typeof displayOrder === "number" &&
    typeof selectedItemId === "number" &&
    typeof selectedItemName === "string" &&
    typeof isDefault === "string" &&
    (typeof variantId === "number" || typeof variantId === "undefined")
  );
}

export function isRelationshipTypeVariant(
  maybeRelationshipTypeVariant: any
): maybeRelationshipTypeVariant is RelationTypeVariant {
  if (
    typeof maybeRelationshipTypeVariant !== "object" ||
    maybeRelationshipTypeVariant === null
  ) {
    return false;
  }
  const { variantId, variantName } = maybeRelationshipTypeVariant;
  return typeof variantId === "number" && typeof variantName === "string";
}
export function isRelationType(
  maybeRelationType: any
): maybeRelationType is RelationType {
  if (typeof maybeRelationType !== "object" || maybeRelationType === null) {
    return false;
  }
  const { relationId, relationName, variantList } = maybeRelationType;
  return (
    typeof relationId === "number" &&
    typeof relationName === "string" &&
    Array.isArray(variantList) &&
    variantList.every(isRelationshipTypeVariant)
  );
}

export type ServingSizeNutritionData = RequireFields<
  itemComponents["schemas"]["ServingSizeNutritionData"]
>;
export type UomData = RequireFields<itemComponents["schemas"]["UomData"]>;

export function isServingSize(
  maybeServingSize: any
): maybeServingSize is ServingSizeNutritionData {
  if (typeof maybeServingSize !== "object") {
    return false;
  }
  const { nutrientId, nutrientName, nutrientValue, uomId, uomName } =
    maybeServingSize;
  return (
    typeof nutrientId === "number" &&
    typeof nutrientName === "string" &&
    typeof nutrientValue === "string" &&
    typeof uomId === "number" &&
    typeof uomName === "string"
  );
}

export function formatServingSize(
  rawServingSize: any,
  marketCode: string
): ServingSizeNutritionData | undefined {
  const { nutrientId, nutrientName, nutrientValue, uomId, uomName } =
    rawServingSize;
  if (!isNaN(Number(nutrientId)) && !isNaN(Number(uomId))) {
    return {
      nutrientId: Number(nutrientId),
      nutrientName: typeof nutrientName === "string" ? nutrientName : "",
      nutrientValue:
        typeof nutrientValue === "string"
          ? localizedNumStringOrEmpty(nutrientValue, marketCode)
          : "",
      uomId: Number(uomId),
      uomName: typeof uomName === "string" ? uomName : "",
    };
  }
}

export function isUom(maybeUomData: any): maybeUomData is UomData {
  if (typeof maybeUomData !== "object") {
    return false;
  }
  const { uomId, uomName } = maybeUomData;
  return typeof uomId === "number" && typeof uomName === "string";
}

export type MappedProduct = RequireFields<
  itemComponents["schemas"]["ProductDetails"]
>;
export type MappedOptionalItem = RequireFields<
  itemComponents["schemas"]["OptionalItemDetails"]
>;
export type SelectableMutexOption = {
  elementId: number;
  elementName: string;
  isDefault: boolean;
};
export type MappedMutexElement = {
  id: number;
  name: string;
  qty: number;
  isDefault: boolean;
  displayOrder: number;
  type: "Optional Item" | "Product";
};
export type DisplayableMappedMutexElement = Omit<MappedMutexElement, "qty"> & {
  qty: string;
};
export type ProductType = RequireFields<
  itemComponents["schemas"]["ProductType"]
>;
export type OptionalType = RequireFields<
  itemComponents["schemas"]["OptionalItem"]
>;
export type MutexType = RequireFields<
  itemComponents["schemas"]["MutexElemement"]
>;
export type SelectableItemOrProduct = {
  id: number;
  name: string;
  displayOrder: number;
};
export type MutexOption = {
  elementName: string;
  elementType: string;
  elementId: number;
  mutexId: number;
  displayOrder: number;
  isDefault: string;
  mutexName: string;
  mutexDisplayOrder: number;
  
};
export type SelectableMutexGroup = {
  id: number;
  name: string;
  options: MutexOption[];
};

export function formatSelectableItem(
  rawItem: any
): SelectableItemOrProduct | undefined {
  const { optionalItemName, optionalItemId, displayOrder } = rawItem;
  if (typeof optionalItemId === "number") {
    return {
      id: optionalItemId,
      name: typeof optionalItemName === "string" ? optionalItemName : "",
      displayOrder : displayOrder,

    }; 
  }
}

export function formatSelectableProduct(
  rawProduct: any
): SelectableItemOrProduct | undefined {
  const { productName, productId, displayOrder } = rawProduct;
  if (typeof productId === "number") {
    return {
      id: productId,
      name: typeof productName === "string" ? productName : "",
      displayOrder: displayOrder,
    };
  }
}

export function formatMutexOption(
  rawMutexOption: any
): MutexOption | undefined {

  const { elementName, mutexId, elementId, elementType, isDefault, displayOrder, mutexName, mutexDisplayOrder } = rawMutexOption;

  if (typeof mutexId === "number" && typeof elementId === "number") {
    return {
      elementName: typeof elementName === "string" ? elementName : "",
      elementId,
      mutexId,
      displayOrder,
      isDefault,
      mutexName,
      mutexDisplayOrder,
      elementType: typeof elementType === "string" ? elementType : "",
    };
  }
}

export function isDefined<T>(value: T | undefined): value is T {
  return value !== undefined;
}

export interface ApiError {
  message: string;
}

export function isMappedProduct(
  maybeProductMapping: any
): maybeProductMapping is MappedProduct {
  const { productId, productName, qty, defaultProduct, displayOrder } =
    maybeProductMapping;
  return (
    typeof productId === "number" &&
    typeof productName === "string" &&
    typeof qty === "number" &&
    typeof defaultProduct === "string" &&
    typeof displayOrder === "number"
  );
}

export function isMappedOptionalItem(
  maybeOptionalItemMapping: any
): maybeOptionalItemMapping is MappedOptionalItem {
  const { optionalItemId, optionalItemName, qty, defaultItem, displayOrder } =
    maybeOptionalItemMapping;
  return (
    typeof optionalItemId === "number" &&
    typeof optionalItemName === "string" &&
    typeof qty === "number" &&
    typeof defaultItem === "string" &&
    typeof displayOrder === "number"
  );
}

export function formatMappedProductAsSelectableMutexOption(
  mappedProduct: MappedProduct
): SelectableMutexOption {
  return {
    elementId: mappedProduct.productId,
    elementName: mappedProduct.productName,
    isDefault: mappedProduct.defaultProduct === "Y",
  };
}

export function formatMappedOptionalItemAsSelectableMutexOption(
  mappedOptionalItem: MappedOptionalItem
): SelectableMutexOption {
  return {
    elementId: mappedOptionalItem.optionalItemId,
    elementName: mappedOptionalItem.optionalItemName,
    isDefault: mappedOptionalItem.defaultItem === "Y",
  };
}

type CategoryItem = Omit<
  categoryComponents["schemas"]["CategoryItemData"],
  "displayOrder"
>;
type ValidCategoryItem = RequireFields<CategoryItem>;
type RequiredCategoryCompareDetails = RequireFields<
  categoryComponents["schemas"]["CategoryCompareData"]
>;
export type FormattedCategoryCompareDetails = Omit<
  RequiredCategoryCompareDetails,
  "categoryID" | "items"
> & {
  categoryId: string;
  items: {
    item: ValidCategoryItem[];
  };
};

export function isCategoryItem(
  maybeCategoryItem: any
): maybeCategoryItem is ValidCategoryItem {
  if (typeof maybeCategoryItem !== "object" || maybeCategoryItem === null) {
    return false;
  }

  const { itemId, itemName } = maybeCategoryItem;

  return typeof itemId === "number" && typeof itemName === "string";
}

export function formatCategoryCompare(
  rawCategoryCompare: any
): FormattedCategoryCompareDetails | undefined {
  const {
    categoryComments,
    categoryDescription,
    categoryID,
    categoryKeywords,
    categoryMarketingName,
    categoryName,
    categoryType,
    doNotShow,
    items,
  } = rawCategoryCompare;
  const { item: rawItems } = items;

  // massage into type-safe structure
  const validItems: ValidCategoryItem[] = rawItems
    ? rawItems.filter((maybeItem: any): maybeItem is ValidCategoryItem => {
        return isCategoryItem(maybeItem);
      })
    : [];
  return {
    categoryComments:
      typeof categoryComments === "string" ? categoryComments : "",
    categoryDescription:
      typeof categoryDescription === "string" ? categoryDescription : "",
    categoryId:
      typeof categoryID === "number" || typeof categoryID === "string"
        ? String(categoryID)
        : "",
    categoryKeywords:
      typeof categoryKeywords === "string" ? categoryKeywords : "",
    categoryMarketingName:
      typeof categoryMarketingName === "string" ? categoryMarketingName : "",
    categoryName: typeof categoryName === "string" ? categoryName : "",
    categoryType: typeof categoryType === "string" ? categoryType : "",
    doNotShow: typeof doNotShow === "string" ? doNotShow : "",
    items: {
      item: validItems,
    },
  };
}

export function createApplyPagination<T>() {
  return function applyPagination(
    documents: T[],
    options: PaginationOptions
  ): T[] {
    const { page, rowsPerPage } = options;
    return documents.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
  };
}

export function createSortByArchiveDateFn<
  T extends { archiveInfo?: miscellaneousComponents["schemas"]["ArchiveInfo"] }
>() {
  return (records: T[], sortOrder: SortOrder) => {
    const defaultDate =
      sortOrder === "desc" ? "2000-01-01 00:00:00" : "2040-01-01 00:00:00";
    return records.sort((a, b) => {
      const dateA = new Date(String(a.archiveInfo?.archiveDate || defaultDate));
      const dateB = new Date(String(b.archiveInfo?.archiveDate || defaultDate));

      if (dateA.getTime() === dateB.getTime()) {
        return 0;
      }

      if (dateA.getTime() > dateB.getTime()) {
        return sortOrder === "asc" ? 1 : -1;
      }

      return sortOrder === "asc" ? -1 : 1;
    });
  };
}

export function createSortByDateFn<T extends object>(field: keyof T) {
  return (records: T[], sortOrder: SortOrder) =>
    records.sort((a, b) => {
      const dateA = new Date(String(a[field]));
      const dateB = new Date(String(b[field]));

      if (dateA.getTime() === dateB.getTime()) {
        return 0;
      }

      if (dateA.getTime() > dateB.getTime()) {
        return sortOrder === "asc" ? 1 : -1;
      }

      return sortOrder === "asc" ? -1 : 1;
    });
}

export function createSortByStringFn<T extends object>(field: keyof T) {
  return (records: T[], sortOrder: SortOrder) =>
    records.sort((a, b) => {
      if (sortOrder === "asc") {
        return String(a[field])
          .trim()
          .localeCompare(String(b[field]).trim(), "en", {
            sensitivity: "base",
          });
      }
      return String(b[field])
        .trim()
        .localeCompare(String(a[field]).trim(), "en", {
          sensitivity: "base",
        });
    });
}

export function createSortByNumberFn<T extends object>(field: keyof T) {
  return (records: T[], sortOrder: SortOrder) =>
    records.sort((a, b) => {
      if (Number(a[field]) === Number(b[field])) {
        return 0;
      }
      if (Number(a[field]) > Number(b[field])) {
        return sortOrder === "asc" ? 1 : -1;
      }
      return sortOrder === "asc" ? -1 : 1;
    });
}

export function createTopNMatchFn<T extends object>(
  fields: (keyof T)[],
  n: number
) {
  return (records: T[], search: string) => {
    const lowerSearch = search.toLowerCase();

    let matches: T[] = [];

    records.forEach((record) => {
      for (const field of fields) {
        const fieldValue = String(record[field]).toLowerCase();
        // Check if field starts with or contains the search term
        if (
          fieldValue.startsWith(lowerSearch) ||
          fieldValue.includes(lowerSearch)
        ) {
          matches.push(record);
          break; // Break to avoid adding the same record for multiple field matches
        }
      }
      if (matches.length >= n) {
        return;
      }
    });

    // Deduplicate matches while preserving the order
    matches = Array.from(new Set(matches));

    // If there are more matches than needed, trim the array
    if (matches.length > n) {
      matches.length = n;
    }

    return matches;
  };
}

// Helper functions to handle localStorage
export const localStorageAvailable = () => {
  try {
    const testKey = "__storage_test__";
    window.localStorage.setItem(testKey, testKey);
    window.localStorage.removeItem(testKey);
    return true;
  } catch (e) {
    return false;
  }
};

export const getLocalStorageValue = (key: string) => {
  try {
    return window.localStorage.getItem(key);
  } catch (err) {
    return null;
  }
};

export const setLocalStorageValue = (
  key: string,
  value: string | object | null
) => {
  try {
    const valueToStore =
      typeof value === "string" ? value : JSON.stringify(value);
    window.localStorage.setItem(key, valueToStore);
  } catch (err) {}
};

// Helper functions to handle cookies
export const getCookieValue = (key: string) => {
  const name = key + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(";");

  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i];
    while (cookie.charAt(0) === " ") {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }

  return null;
};

export const setCookieValue = (key: string, value: string | object | null) => {
  const valueToStore =
    typeof value === "string" ? value : JSON.stringify(value);
  document.cookie = `${key}=${encodeURIComponent(valueToStore)}`;
};

// Helper function to get stored value from localStorage or cookies
export const getStoredValue = (key: string) => {
  if (localStorageAvailable()) {
    return getLocalStorageValue(key);
  } else {
    return getCookieValue(key);
  }
};

export const setStoredValue = (key: string, value: string | object | null) => {
  if (localStorageAvailable()) {
    setLocalStorageValue(key, value);
  } else {
    setCookieValue(key, value);
  }
};
export const convertToUniversalDateTime = (
  dateTimeString: string | undefined
): string => {
  if (!dateTimeString) {
    return "";
  }

  const dateTime = new Date(dateTimeString);
  const year = dateTime.getUTCFullYear();
  const month = (dateTime.getUTCMonth() + 1).toString().padStart(2, "0");
  const day = dateTime.getUTCDate().toString().padStart(2, "0");

  let formattedDate = `${year}-${month}-${day}`;

  if (dateTimeString.includes(":")) {
    const hours = dateTime.getUTCHours().toString().padStart(2, "0");
    const minutes = dateTime.getUTCMinutes().toString().padStart(2, "0");
    const seconds = dateTime.getUTCSeconds().toString().padStart(2, "0");

    formattedDate += ` ${hours}:${minutes}:${seconds}`;
  }

  return formattedDate;
};

function generateRandomString(length: number) {
  var text = "";
  var possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (var i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return text;
}

export function generateCodeVerifier(): string {
  return generateRandomString(64);
}

export async function generateCodeChallenge(
  codeVerifier: string
): Promise<string> {
  const encoder = new TextEncoder();
  const data = encoder.encode(codeVerifier);
  const hash = await crypto.subtle.digest("SHA-256", data);
  const base64String = btoa(String.fromCharCode(...new Uint8Array(hash)));
  return base64String
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "");
}

export const getRandomNumberBetween = (
  min: number = 1000,
  max: number = 10000
): number => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export type ItemData = RequireFields<itemComponents["schemas"]["ItemData"]>;
export type ItemWithCategories = Omit<ItemData, "categories"> & {
  categories: number[];
};
export type CategoryData = RequireFields<
  categoryComponents["schemas"]["CategoriesData"]
>;
export type ItemByCategoryData = RequireFields<
  categoryComponents["schemas"]["CategoryItemData"]
>;
export type ProductData = RequireFields<
  productComponents["schemas"]["Products"]
>;

export function isValidItem(maybeItem: any): maybeItem is ItemData {
  if (typeof maybeItem === "undefined") {
    return false;
  }
  const { itemId, displayOrder, itemName, itemType, status, showEdit, categories } =
    maybeItem;
  return (
    typeof itemId === "number" &&
    typeof displayOrder === "number" &&
    typeof itemName === "string" &&
    typeof itemType === "string" &&
    typeof status === "string" &&
    typeof showEdit === "string" &&
    typeof categories === "string"
  );
}
export function isValidCategoryData(
  maybeCategory: any
): maybeCategory is CategoryData {
  if (typeof maybeCategory === "undefined") {
    return false;
  }
  const { categoryId, categoryName } = maybeCategory;
  return typeof categoryId === "number" && typeof categoryName === "string";
}
export function isValidProduct(maybeProduct: any): maybeProduct is ProductData {
  if (typeof maybeProduct === "undefined") {
    return false;
  }
  const { displayOrder, productId, productName, productStatus, showEdit } =
    maybeProduct;
  return (
    typeof displayOrder === "number" &&
    typeof productId === "number" &&
    typeof productName === "string" &&
    typeof productStatus === "string" &&
    typeof showEdit === "string"
  );
}
export function formatProductsWithDisplayOrder(data: any[]): ProductData[] {
  return data.filter(isValidProduct).sort((a, b) => {
    // sort by displayOrder and fallback to productId
    if (a.displayOrder === b.displayOrder) {
      return a.productId - b.productId;
    }
    return a.displayOrder - b.displayOrder;
  });
}

export function formatMappedCategoryItems(items: any[]): ItemByCategoryData[] {
  const orderedItems: ItemByCategoryData[] = [];
  let displayOrder = 0;

  items.forEach((item) => {
    if (isCategoryItem(item)) {
      orderedItems.push({
        ...item,
        displayOrder: displayOrder++,
      });
    }
  });

  return orderedItems;
}

export function isValidItemByCategoryId(
  maybeItemByCategory: any
): maybeItemByCategory is ItemByCategoryData {
  if (typeof maybeItemByCategory === "undefined") {
    return false;
  }
  const { itemId, itemName, displayOrder } = maybeItemByCategory;
  return (
    typeof itemId === "number" &&
    typeof itemName === "string" &&
    typeof displayOrder === "number"
  );
}

export function formatItemForDataProvider(item: ItemData): ItemWithCategories {
  const { categories, ...otherFields } = item;
  return {
    ...otherFields,
    categories: categories.split(",").map(Number),
  };
}

export type PushLiveDetailData = miscellaneousComponents["schemas"]["DataList"];
export function isPushLiveDetail(
  maybePushLiveDetail: any
): maybePushLiveDetail is PushLiveDetailData {
  if (typeof maybePushLiveDetail !== "object" || maybePushLiveDetail === null) {
    return false;
  }

  const {
    pushLiveEventName,
    status,
    initiatedBy,
    pushLiveEventId,
    scheduledTime,
  } = maybePushLiveDetail;
  return (
    typeof pushLiveEventName === "string" &&
    typeof status === "string" &&
    typeof initiatedBy === "string" &&
    typeof pushLiveEventId === "string" &&
    typeof scheduledTime === "string"
  );
}
export type ApprovedChangesResponse =
  miscellaneousComponents["schemas"]["ApprovedChangesResponse"];
export type Element = RequireFields<
  miscellaneousComponents["schemas"]["Element"]
>;
export type PushLiveChangeset = {
  Categories_and_Meal_Bundles: Element[];
  Menu_Item: Element[];
  Products: Element[];
};
export type PushLiveMetaData = RequireFields<
  miscellaneousComponents["schemas"]["PushLiveMetaData"]
>;
export type AuditSearchElement = RequireFields<
  auditComponents["schemas"]["AuditTrailEntry"]
>;
export type AuditType = RequireFields<auditComponents["schemas"]["AuditType"]>;
export type AuditUser = RequireFields<auditComponents["schemas"]["User"]>;
export function isAuditSearchData(
  maybeAuditSearchData: any
): maybeAuditSearchData is AuditSearchElement {
  if (
    typeof maybeAuditSearchData !== "object" ||
    maybeAuditSearchData === null
  ) {
    return false;
  }
  const {
    auditType,
    auditAction,
    auditSubtype,
    actionStatement,
    username,
    role,
    dateOfAction,
  } = maybeAuditSearchData;
  return (
    typeof auditType === "string" &&
    typeof auditAction === "string" &&
    typeof auditSubtype === "string" &&
    typeof actionStatement === "string" &&
    typeof username === "string" &&
    typeof role === "string" &&
    typeof dateOfAction === "string"
  );
}
export function isAuditTypeList(
  maybeAuditType: any
): maybeAuditType is AuditType {
  return (
    typeof maybeAuditType === "object" &&
    maybeAuditType !== null &&
    (typeof maybeAuditType.auditTypeId === "number" ||
      maybeAuditType.auditTypeId === undefined) &&
    (typeof maybeAuditType.auditTypeName === "string" ||
      maybeAuditType.auditTypeName === undefined)
  );
}
export function isAuditUsersList(
  maybeAuditUser: any
): maybeAuditUser is AuditUser {
  return (
    typeof maybeAuditUser === "object" &&
    maybeAuditUser !== null &&
    (typeof maybeAuditUser.userId === "string" ||
      maybeAuditUser.userId === undefined) &&
    (typeof maybeAuditUser.userName === "string" ||
      maybeAuditUser.userName === undefined)
  );
}

export function isElement(maybeElement: any): maybeElement is Element {
  return (
    typeof maybeElement === "object" &&
    maybeElement !== null &&
    (typeof maybeElement.elementId === "number" ||
      maybeElement.elementId === undefined) &&
    (typeof maybeElement.elementName === "string" ||
      maybeElement.elementName === undefined)
  );
}

export function formatPushLiveChangeset(
  maybePushLiveChangeset: any
): PushLiveChangeset {
  if (typeof maybePushLiveChangeset === "undefined") {
    return {
      Categories_and_Meal_Bundles: [],
      Menu_Item: [],
      Products: [],
    };
  }
  const {
    Categories_and_Meal_Bundles = [],
    Menu_Item = [],
    Products = [],
  } = maybePushLiveChangeset || {};

  return {
    Categories_and_Meal_Bundles: Categories_and_Meal_Bundles.filter(isElement),
    Menu_Item: Menu_Item.filter(isElement),
    Products: Products.filter(isElement),
  };
}

export function formatPushLiveMetaData(
  rawPushLiveMetaData: any
): PushLiveMetaData {
  if (typeof rawPushLiveMetaData === "undefined") {
    return {
      eventType: "",
      timeZoneId: 0,
      scheduleTime: "",
      reason: "",
    };
  }
  const { eventType, timeZoneId, scheduleTime, reason } = rawPushLiveMetaData;
  return {
    eventType: typeof eventType === "string" ? eventType : "",
    timeZoneId: typeof timeZoneId === "number" ? timeZoneId : 0,
    scheduleTime: typeof scheduleTime === "string" ? scheduleTime : "",
    reason: typeof reason === "string" ? reason : "",
  };
}

export type TimeZoneResponse = miscellaneousComponents["schemas"]["TimeZone"];
export function isTimeZone(
  maybeTimeZone: any
): maybeTimeZone is TimeZoneResponse {
  return (
    typeof maybeTimeZone === "object" &&
    maybeTimeZone !== null &&
    (typeof maybeTimeZone.timeZoneId === "number" ||
      maybeTimeZone.timeZoneId === undefined) &&
    (typeof maybeTimeZone.timeZoneName === "string" ||
      maybeTimeZone.timeZoneName === undefined) && 
      (typeof maybeTimeZone.timeZoneDesc === "string" ||
      maybeTimeZone.timeZoneDesc === undefined)
  );
}

function mergeAndSortUnique(array1: Element[], array2: Element[]): Element[] {
  const mergedArray = array1.concat(array2);

  const uniqueArray = mergedArray.filter(
    (element, index, array) =>
      array.findIndex((e) => e.elementId === element.elementId) === index
  );

  return uniqueArray.sort((a, b) => a.elementId - b.elementId);
}

export function combinePushLiveResponses(
  approvedItems: PushLiveChangeset,
  editItems: PushLiveChangeset
): PushLiveChangeset {
  const combinedCategoriesAndMealBundles = mergeAndSortUnique(
    approvedItems.Categories_and_Meal_Bundles,
    editItems.Categories_and_Meal_Bundles
  );
  const combinedMenuItem = mergeAndSortUnique(
    approvedItems.Menu_Item,
    editItems.Menu_Item
  );
  const combinedProducts = mergeAndSortUnique(
    approvedItems.Products,
    editItems.Products
  );
  return {
    Categories_and_Meal_Bundles: combinedCategoriesAndMealBundles,
    Menu_Item: combinedMenuItem,
    Products: combinedProducts,
  };
}
// export type CategoryData = categoryComponents["schemas"]["ItemData"];
export type CategoryDataType = {
  categoryId: number;
  categoryName?: string;
  categoryType?: string;
  status?: string;
  showEdit?: string;
};
export type ValidCategoryAttributeWithValue = RequireFields<
  itemComponents["schemas"]["elementDetails"]
>;
export type ValidCategoryAttribute = Omit<
  ValidCategoryAttributeWithValue,
  "value"
> & {
  value?: string;
};
export function isCategoryMarketingAttribute(
  maybeItemAttribute: any
): maybeItemAttribute is ValidCategoryAttribute {
  if (typeof maybeItemAttribute !== "object" || maybeItemAttribute === null) {
    return false;
  }

  const { id, name, value, keyName } = maybeItemAttribute;

  return (
    typeof id === "number" &&
    typeof name === "string" &&
    typeof keyName === "string" &&
    (typeof value === "string" || typeof value === "undefined")
  );
}
export function isValidCategory(
  maybecategoryAttribute: any
): maybecategoryAttribute is ValidCategory {
  if (
    typeof maybecategoryAttribute !== "object" ||
    maybecategoryAttribute === null
  ) {
    return false;
  }

  const { categoryId, categoryName, status, categoryType, showEdit } =
    maybecategoryAttribute;

  return (
    typeof categoryId === "number" &&
    typeof categoryName === "string" &&
    typeof status === "string" &&
    typeof categoryType === "string" &&
    typeof showEdit === "string"
  );
}
export function formatCategoriesForDisplay(
  categories: ValidCategory[]
): ValidCategoryWithDisplayOrder[] {
  const updatedCategories: ValidCategory[] = [
    {
      displayOrder: 0,
      categoryId: 0,
      categoryType: "",
      categoryName: "Unassigned",
      status: "",
      showEdit: "U",
    },
    ...categories,
  ];

  // Map over the updatedCategories to include the displayOrder
  const categoriesWithDisplayOrder: ValidCategoryWithDisplayOrder[] =
    updatedCategories.map((category, index) => ({
      ...category,
      displayOrder: index, // Assigning the index as displayOrder
    }));

  return categoriesWithDisplayOrder;
}
export type ValidCategoryArchive = RequireFields<
  categoryComponents["schemas"]["archivedData"]
>;
export function isCategoryArchive(
  maybeCategoryArchive: any
): maybeCategoryArchive is ValidCategoryArchive {
  if (
    typeof maybeCategoryArchive !== "object" ||
    maybeCategoryArchive === null
  ) {
    return false;
  }

  const { name, id } = maybeCategoryArchive;

  return typeof name === "string" && typeof id === "number";
}
export type ValidItemArchive = RequireFields<
  itemComponents["schemas"]["itemsData"]
>;
export function isItemArchive(
  maybeItemArchive: any
): maybeItemArchive is ValidItemArchive {
  if (typeof maybeItemArchive !== "object" || maybeItemArchive === null) {
    return false;
  }

  const { name, id } = maybeItemArchive;

  return typeof name === "string" && typeof id === "number";
}

export type ValidUOM = RequireFields<miscellaneousComponents["schemas"]["UOM"]>;
export type ValidRole = RequireFields<
  miscellaneousComponents["schemas"]["RoleType"]
>;
export type ValidUser = RequireFields<
  miscellaneousComponents["schemas"]["UserData"]
>;
export type ValidUserDetails = RequireFields<
  miscellaneousComponents["schemas"]["userData"]
>;
export type ValidSaveUser = RequireFields<
  miscellaneousComponents["schemas"]["UserDetailsRequest"]
>;

export type ValidUOMList = {
  [key: string]: ValidUOM[];
};

export function isUOM(maybeUOM: any): maybeUOM is ValidUOM {
  if (typeof maybeUOM !== "object" || maybeUOM === null) {
    return false;
  }
  const { uom, uomDesc, measurementType, uomId } = maybeUOM;
  return (
    typeof uomId === "number" &&
    typeof uom === "string" &&
    typeof uomDesc === "string" &&
    typeof measurementType === "string"
  );
}
export function isRole(maybeUOM: any): maybeUOM is ValidRole {
  if (typeof maybeUOM !== "object" || maybeUOM === null) {
    return false;
  }
  const { roleId, roleName } = maybeUOM;
  return typeof roleId === "number" && typeof roleName === "string";
}
export function isUserById(maybeUser: any): maybeUser is ValidUserDetails {
  if (typeof maybeUser !== "object" || maybeUser === null) {
    return false;
  }
  const { eid, firstName, lastName, email, roleIds, remarks } = maybeUser;
  return (
    typeof eid === "string" &&
    typeof firstName === "string" &&
    typeof lastName === "string" &&
    typeof email === "string" &&
    typeof roleIds === "string" &&
    (typeof remarks === "string" || typeof remarks === "undefined")
  );
}
// export function isUserById(maybeUser: any): maybeUser is ValidUserDetails {
//   if (typeof maybeUser === "undefined") {
//     return false;
//   }
//   const { eid, firstName, lastName, email, roleIds, remarks } = maybeUser;
//   return (
//     typeof eid === "string" &&
//     typeof firstName === "string" &&
//     typeof lastName === "string" &&
//     typeof email === "string" &&
//     typeof roleIds === "string" &&
//     (typeof remarks === "string" || typeof remarks === "undefined")
//   );
// }
export function isUserData(maybeUser: any): maybeUser is ValidUser {
  if (typeof maybeUser !== "object" || maybeUser === null) {
    return false;
  }
  const { eId, name, email, country } = maybeUser;
  return (
    typeof country === "string" &&
    typeof eId === "string" &&
    typeof name === "string" &&
    typeof email === "string"
  );
}

export function isUOMList(maybeUOMList: any): maybeUOMList is ValidUOMList {
  if (typeof maybeUOMList !== "object" || maybeUOMList === null) {
    return false;
  }

  for (const key in maybeUOMList) {
    if (!Array.isArray(maybeUOMList[key])) {
      return false;
    }

    for (const countryCode of maybeUOMList[key]) {
      if (!isUOM(countryCode)) {
        return false;
      }
    }
  }

  return true;
}
export function filterUOMListByLanguage(
  uomList: ValidUOMList,
  selectedLanguage: string
): ValidUOM[] {
  // Check if the selectedLanguage key exists in the uomList
  if (uomList[selectedLanguage]) {
    // Return the array of UOM objects for the selectedLanguage
    return uomList[selectedLanguage];
  } else {
    // If the selectedLanguage key is not found, return an empty array
    return [];
  }
}

export type MenuListItem = {
  menuName: string;
  subMenuList: MenuListItem[];
  [key: string]: any;
};

export function isMenuListItem(
  maybeMenuListItem: any
): maybeMenuListItem is MenuListItem {
  if (typeof maybeMenuListItem !== "object" || maybeMenuListItem === null) {
    return false;
  }

  if (typeof maybeMenuListItem.menuName !== "string") {
    return false;
  }

  if (!Array.isArray(maybeMenuListItem.subMenuList)) {
    return false;
  }

  return maybeMenuListItem.subMenuList.every(isMenuListItem);
}
export type validUserMenu = RequireFields<navComponents["schemas"]["MenuResponse"]>;
// Function to extract pathList from validUserMenu
export const getPathList = (menuResponse: validUserMenu): string[] | undefined => {
  return menuResponse.pathList;
};

export const extractPaths = (menuList: any[]): string[] => {
  const paths: string[] = [];

  const extract = (menus: any[]) => {
    menus.forEach(menu => {
      if (menu.path) {
        paths.push(menu.path);
      }
      if (menu.subMenuList && menu.subMenuList.length > 0) {
        extract(menu.subMenuList);
      }
    });
  };

  extract(menuList);
  return paths;
};




export type ValidCategory = RequireFields<
  categoryComponents["schemas"]["CategoryData"]
>;
export type ValidCategoryWithDisplayOrder = ValidCategory & {
  displayOrder: number;
};

export type Privilege = miscellaneousComponents["schemas"]["Privilege"];
export type RoleWithPrivileges = miscellaneousComponents["schemas"]["Role"];

export function formatPrivilege(rawPrivilege: any): Privilege | undefined {
  const { privilegeId, privilegeName, isEnable } = rawPrivilege;
  if (!isNaN(Number(privilegeId))) {
    return {
      privilegeId: Number(privilegeId),
      privilegeName: typeof privilegeName === "string" ? privilegeName : "",
      isEnable: typeof isEnable === "string" ? isEnable : "",
    };
  }
}

export function formatRoleWithPrivileges(
  rawRoleWithPrivileges: any
): RoleWithPrivileges | undefined {
  const { roleId, roleName, isEnable, privilegeList } = rawRoleWithPrivileges;

  if (!isNaN(Number(roleId))) {
    return {
      roleId: Number(roleId),
      roleName: typeof roleName === "string" ? roleName : "",
      isEnable: typeof isEnable === "string" ? isEnable : "",
      privilegeList: privilegeList.map(formatPrivilege).filter(isDefined),
    };
  }
}

export type ExceptionReport =
  miscellaneousComponents["schemas"]["ExceptionReport"];


export function formatUpdateDate(updateDate: string): string {
  const monthAbbreviations: Record<string, string> = {
    January: "Jan",
    February: "Feb",
    March: "Mar",
    April: "Apr",
    May: "May",
    June: "Jun",
    July: "Jul",
    August: "Aug",
    September: "Sep",
    October: "Oct",
    November: "Nov",
    December: "Dec",
  };

  const normalizedDateString = updateDate.replace(/,(\S)/, ", $1");
  const [dayOfWeek, day, month, year] = normalizedDateString.split(/[ ,]+/);

  const abbreviatedMonth = monthAbbreviations[month];
  if (!abbreviatedMonth) {
    console.warn(`Unrecognized month: "${month}"`);
    return updateDate;
  }

  return `${dayOfWeek}, ${day} ${abbreviatedMonth} ${year}`;
}
