import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { withLayout } from "../hoc/with-layout";
import {
  Grid,
  IconButton,
  Stack,
  TablePagination,
  Typography,
  Link,
  Tooltip,
  Button,
} from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import SummarizeIcon from "@mui/icons-material/Summarize";
import { Loader } from "../components/loader/Loader";
import { useUrlSearchPageSort } from "../hooks/use-url-search-page-sort";
import AddCircle from "@mui/icons-material/AddCircle";
import { AddRowLink } from "../components/AddRowLink";
import { StyledDashboardTableHead } from "./dashboard";
import { useCallback, useContext, useMemo, useState } from "react";
import { RoleContext } from "../role-provider";
import {
  ApiError,
  ItemWithCategories,
  createSortByNumberFn,
  createSortByStringFn,
} from "../utils";
import { useNavigate } from "react-router-dom";
import { SearchInput } from "../components/SearchInput";
import { DataContext } from "../data-provider";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  SuccessAlertSnackbar,
  ErrorAlertSnackbar,
} from "../components/AlertSnackbar";
import TableSortIcon from "../components/TableSortIcon";
import { ConfirmActionModal } from "../components/ConfirmActionModal";
import { useAxios } from "../axios-provider";
import { softDeleteItem } from "../data/items";
import { AxiosError } from "axios";
import { ResendFormModal } from "../components/ResendFormModal";
import { useForm } from "react-hook-form";
import { saveElementDisplayOrder } from "../data/miscellaneous";
import {
  ArrowDownward,
  ArrowUpward,
  Cancel,
  LowPriority,
  Save,
} from "@mui/icons-material";
import { EmptyState } from "../components/EmptyState";

type SoftDeleteItemFormValues = {
  itemId: number;
  deleteReason: string;
};

const sortByName = createSortByStringFn<ItemWithCategories>("itemName");
const sortByType = createSortByStringFn<ItemWithCategories>("itemType");
const sortById = createSortByNumberFn<ItemWithCategories>("itemId");
const sortByDisplayOrder =
  createSortByNumberFn<ItemWithCategories>("displayOrder");
const sortByStatus = createSortByStringFn<ItemWithCategories>("status");

export const Items = withLayout(() => {
  const navigate = useNavigate();
  const { apiClient } = useAxios();
  const queryClient = useQueryClient();

  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isEditDisplayOrderOn, setIsEditDisplayOrderOn] =
    useState<boolean>(false);
  const [itemToSoftDeleteId, setItemToSoftDeleteId] = useState<number | null>(
    null
  );
  const [deleteReasonMemo, setDeleteReasonMemo] = useState<string>("");
  const [isRetrySoftDeleteModalOpen, setIsRetrySoftDeleteModalOpen] =
    useState<boolean>(false);

  const { isReaderRole, selectedCountry, selectedRole, isLegalRole } =
    useContext(RoleContext);
  const { items, itemsLoading } = useContext(DataContext);

  const { mutate: doSoftDelete, isLoading: isSoftDeleteLoading } = useMutation(
    (data: SoftDeleteItemFormValues) => {
      return softDeleteItem(apiClient)(
        { deleteReason: data.deleteReason },
        {
          itemId: data.itemId,
          countryCode: selectedCountry!,
          roleId: Number(selectedRole!),
        }
      );
    },
    {
      onSuccess: () => {
        setSuccessMessage(`Item was successfully deleted`);
        setItemToSoftDeleteId(null);
        setDeleteReasonMemo("");
        setIsRetrySoftDeleteModalOpen(false);
        queryClient.invalidateQueries(["getItemsList", { selectedCountry }]);
      },
      onError: (error: AxiosError) => {
        if (error?.response?.status === 401) {
          // expired token - parse out "deleteReason" from submitted form data to allow retry
          const formData = JSON.parse(error.config?.data || {});
          const { deleteReason } = formData;
          setDeleteReasonMemo(
            typeof deleteReason === "string" ? deleteReason : ""
          );
          setIsRetrySoftDeleteModalOpen(true);
        } else if (error.response?.data) {
          const errorMessage: ApiError = error.response.data as ApiError;
          setErrorMessage(String(errorMessage.message));
        } else {
          setErrorMessage(String(error));
        }
      },
    }
  );

  const handleOpenEdit = useCallback(() => setIsEditDisplayOrderOn(true), []);
  const handleCancelEdit = useCallback(
    () => setIsEditDisplayOrderOn(false),
    []
  );
  const handleSaveDisplayOrderSuccess = useCallback(() => {
    setSuccessMessage(`Item display order was saved successfully`);
    setIsEditDisplayOrderOn(false);
  }, []);

  return (
    <Stack aria-label="items list">
      <Grid item columns={12}>
        <Stack spacing={3}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="h2">View Items</Typography>
            </Grid>
            {!isReaderRole && !isLegalRole && (
              <AddRowLink
                onClick={() => navigate(`/items/create-item`)}
                data-testid="add-new-item-button"
              >
                <AddCircle aria-label="add new item button" />
                <Typography>Add New Item</Typography>
              </AddRowLink>
            )}
          </Grid>
          <StyledRelativeContainer>
            {itemsLoading ? (
              <Loader />
            ) : (
              <>
                {isEditDisplayOrderOn ? (
                  <ItemsWithEditableDisplayOrderTable
                    items={items}
                    onCancelEdit={handleCancelEdit}
                    onSaveSuccess={handleSaveDisplayOrderSuccess}
                  />
                ) : (
                  <ItemsWithSortAndSearchTable
                    items={items}
                    onEnableDisplayOrderEdit={handleOpenEdit}
                    onClickSoftDeleteItem={(itemId: number) =>
                      setItemToSoftDeleteId(itemId)
                    }
                  />
                )}
              </>
            )}
            {typeof itemToSoftDeleteId === "number" && (
              <ConfirmActionModal
                open={!isRetrySoftDeleteModalOpen}
                loading={isSoftDeleteLoading}
                message="Are you sure you want to delete this item?"
                onConfirm={(reason) => {
                  doSoftDelete({
                    itemId: itemToSoftDeleteId!,
                    deleteReason: reason,
                  });
                }}
                onCancel={() => {
                  setItemToSoftDeleteId(null);
                  setDeleteReasonMemo("");
                }}
                showReason={true}
              />
            )}
            <ResendFormModal
              open={isRetrySoftDeleteModalOpen}
              onResend={() => {
                setIsRetrySoftDeleteModalOpen(false);
                if (typeof itemToSoftDeleteId === "number") {
                  doSoftDelete({
                    itemId: itemToSoftDeleteId!,
                    deleteReason: deleteReasonMemo,
                  });
                }
              }}
              onCancel={() => {
                setItemToSoftDeleteId(null);
                setIsRetrySoftDeleteModalOpen(false);
                setDeleteReasonMemo("");
              }}
              description="An error occurred while deleting this item"
            />
            <SuccessAlertSnackbar
              message={successMessage}
              onClose={() => setSuccessMessage(null)}
            />
            <ErrorAlertSnackbar
              message={errorMessage}
              onClose={() => setErrorMessage(null)}
            />
          </StyledRelativeContainer>
        </Stack>
      </Grid>
    </Stack>
  );
}, "Items");

type ItemsWithSortAndSearchTableProps = {
  items: ItemWithCategories[];
  onEnableDisplayOrderEdit: () => void;
  onClickSoftDeleteItem: (itemId: number) => void;
};

const ItemsWithSortAndSearchTable = ({
  items,
  onEnableDisplayOrderEdit,
  onClickSoftDeleteItem,
}: ItemsWithSortAndSearchTableProps) => {
  const navigate = useNavigate();
  const {
    page,
    pageSize,
    sortField,
    sortOrder,
    search,
    navigateToPage,
    setPageSize,
    setSorting,
    setSearchQuery,
    currentSort,
  } = useUrlSearchPageSort<ItemWithCategories>("items", {
    page: 0,
    pageSize: 25,
    sortField: "displayOrder",
    sortOrder: "asc",
    search: "",
  });

  const { isReaderRole } = useContext(RoleContext);

  const filteredAndSortedItems = useMemo(() => {
    const searchLower = search.toLowerCase();
    const filteredItems =
      search.length > 0
        ? items?.filter((item) => {
            return (
              item.itemName?.toLowerCase().includes(searchLower) ||
              item.itemId.toString().includes(search)
            );
          })
        : items;

    if (sortField === "itemName") {
      return sortByName(filteredItems, sortOrder);
    } else if (sortField === "itemId") {
      return sortById(filteredItems, sortOrder);
    } else if (sortField === "itemType") {
      return sortByType(filteredItems, sortOrder);
    } else if (sortField === "status") {
      return sortByStatus(filteredItems, sortOrder);
    }
    return sortByDisplayOrder(filteredItems, "asc");
  }, [search, items, sortField, sortOrder]);

  const handlePageChange = React.useCallback(
    (_: React.MouseEvent<HTMLButtonElement> | null, value: number) => {
      navigateToPage(value);
    },
    [navigateToPage]
  );
  const handleRowsPerPageChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setPageSize(Number(event.target.value));
    },
    [setPageSize]
  );

  const getRow = React.useCallback(
    (index: number) => {
      if (!filteredAndSortedItems || !filteredAndSortedItems[index]) {
        return (
          <StyledTableRow
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
            }}
            data-testid="items-placeholder-tr"
            role="row"
          >
            <TableCell
              role="cell"
              component="th"
              scope="row"
              data-testid="items-td-name"
            ></TableCell>
            <TableCell role="cell"></TableCell>
            <TableCell role="cell"></TableCell>
            <TableCell role="cell"></TableCell>
            <TableCell role="cell"></TableCell>
            <TableCell role="cell"></TableCell>
          </StyledTableRow>
        );
      }
      const item = filteredAndSortedItems[index];
      return (
        <StyledTableRow
          key={item.itemId}
          sx={{
            "&:last-child td, &:last-child th": { border: 0 },
          }}
          data-testid="item-tr"
        >
          <TableCell
            component="th"
            scope="row"
            role="row"
            data-testid="item-td-name"
          >
            {item.itemName}
          </TableCell>
          <TableCell
            component="th"
            scope="row"
            role="row"
            align="left"
            data-testid="item-td-type"
          >
            {item.itemType}
          </TableCell>
          <TableCell align="center" role="row">
            {item.itemId}
          </TableCell>
          <TableCell align="center" data-testid="item-td-updated-on" role="row">
            {item.status}
          </TableCell>
          <TableCell
            align="right"
            data-testid="item-td-compare-with-live"
            role="cell"
          >
            <StyledBlueLink
              role="link"
              tabIndex={0}
              href={`/items/${item.itemId}/compare-with-live`}
            >
              Compare With Live
            </StyledBlueLink>
          </TableCell>
          <TableCell align="center" role="cell">
            <div
              style={{ display: "flex", justifyContent: "center" }}
              role="group"
            >
              {!isReaderRole && item.showEdit === "Y" && (
                <>
                  <Tooltip title="Edit Item">
                    <IconButton
                      aria-label="edit button"
                      role="button"
                      data-testid="view-item-btn"
                    >
                      <EditIcon
                        aria-label="edit button"
                        onClick={() => {
                          navigate(
                            `/items/${item.itemId}/item-marketing-information`
                          );
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete Item">
                    <IconButton
                      aria-label="delete button"
                      onClick={() => onClickSoftDeleteItem(item.itemId!)}
                      role="button"
                      data-testid="cy-delete-item-button"
                      sx={{ color: "#DA291C" }}
                    >
                      <DeleteForeverIcon />
                    </IconButton>
                  </Tooltip>
                </>
              )}
              {(isReaderRole || item.showEdit === "N") && (
                <Tooltip title="View Item">
                  <IconButton
                    onClick={() => {
                      navigate(
                        `/items/${item.itemId}/item-marketing-information`
                      );
                    }}
                    role="button"
                    aria-label="view button"
                    data-testid="view-item-btn"
                  >
                    <SummarizeIcon />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          </TableCell>
        </StyledTableRow>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filteredAndSortedItems, isReaderRole]
  );
  return (
    <>
      <StyledTableControls>
        <Grid container justifyContent="flex-end" gap={2}>
          <SearchInput
            value={search}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search"
            aria-label="Search items"
            testId="cy-items-table-search"
          />
          {/* {!isReaderRole && (
            <StyledButton
              color="primary"
              variant="contained"
              startIcon={<EditIcon />}
              onClick={onEnableDisplayOrderEdit}
            >
              Edit Display Order
            </StyledButton>
          )} */}
        </Grid>
      </StyledTableControls>
      <Grid marginTop={3}>
        <TableContainer component={Paper}>
          <Table aria-label="items table" data-testid="item-table" role="table">
            <StyledDashboardTableHead>
              <TableRow role="row">
                <TableCell role="columnheader">
                  <TableSortIcon
                    currentSort={currentSort}
                    setSorting={setSorting}
                    field="itemName"
                    label="Name"
                    aria-label="Sort by name"
                    testId="sort-by-item-name"
                  />
                </TableCell>
                <TableCell role="columnheader">
                  <TableSortIcon
                    currentSort={currentSort}
                    setSorting={setSorting}
                    field="itemType"
                    label="Type"
                    aria-label="Sort by item type"
                    testId="sort-by-item-type"
                  />
                </TableCell>
                <TableCell align="right" role="columnheader">
                  <TableSortIcon
                    currentSort={currentSort}
                    setSorting={setSorting}
                    field="itemId"
                    label="Id"
                    aria-label="Sort by id"
                    testId="sort-by-item-id"
                  />
                </TableCell>
                <TableCell align="right" role="columnheader">
                  <TableSortIcon
                    currentSort={currentSort}
                    setSorting={setSorting}
                    field="status"
                    label="Status"
                    aria-label="Sort by status"
                    testId="sort-by-item-status"
                  />
                </TableCell>
                <TableCell align="right" role="columnheader">
                  Compare With Live
                </TableCell>
                <TableCell align="center" role="columnheader">
                  Action
                </TableCell>
              </TableRow>
            </StyledDashboardTableHead>
            <TableBody>
              {Array.from({ length: pageSize }, (_, i) => i).map((i) =>
                getRow(page * pageSize + i)
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <StyledActionPanel container>
          <TablePagination
            component="div"
            count={filteredAndSortedItems.length}
            onPageChange={handlePageChange}
            page={page}
            onRowsPerPageChange={handleRowsPerPageChange}
            rowsPerPage={pageSize}
            aria-label="Table pagination"
            rowsPerPageOptions={[5, 10, 25]}
            data-testid="items-pagination"
            SelectProps={{
              MenuProps: {
                classes: { list: "items-pagination-list" },
              },
            }}
          />
        </StyledActionPanel>
      </Grid>
    </>
  );
};

type ItemsWithEditableDisplayOrderTableProps = {
  items: ItemWithCategories[];
  onCancelEdit: () => void;
  onSaveSuccess: () => void;
};

const ItemsWithEditableDisplayOrderTable = ({
  items,
  onCancelEdit,
  onSaveSuccess,
}: ItemsWithEditableDisplayOrderTableProps) => {
  const { apiClient } = useAxios();
  const queryClient = useQueryClient();
  const { isReaderRole, selectedCountry, selectedRole } =
    useContext(RoleContext);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [isResendModalOpen, setIsResendModalOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const itemsByDisplayOrder = useMemo(() => {
    return sortByDisplayOrder(items, "asc");
  }, [items]);

  const {
    watch,
    setValue,
    formState: { isDirty },
  } = useForm<{
    elementList: ItemWithCategories[];
  }>({
    defaultValues: {
      elementList: itemsByDisplayOrder,
    },
  });
  const itemsWithEditableDisplayOrder = watch("elementList");

  const saveElementDisplayOrderRequest = saveElementDisplayOrder(apiClient);
  const { mutate: doSaveDisplayOrder, isLoading: isDisplayOrderSaving } =
    useMutation(
      () => {
        return saveElementDisplayOrderRequest(
          {
            elementList: itemsWithEditableDisplayOrder.map((item, index) => ({
              displayOrder: index,
              elementId: item.itemId,
            })),
          },
          {
            countryCode: selectedCountry!,
            roleId: selectedRole!,
            elementType: "Item",
          }
        );
      },
      {
        onMutate: () => saveElementDisplayOrderRequest,
        onSuccess: () => {
          queryClient.invalidateQueries(["getItemsList", { selectedCountry }]);
          onSaveSuccess();
        },
        onError: (error: AxiosError) => {
          if (error?.response?.status === 401) {
            setIsResendModalOpen(true);
          } else if (error.response?.data) {
            const errorMessage: ApiError = error.response.data as ApiError;
            setErrorMessage(String(errorMessage.message));
          } else {
            setErrorMessage(String(error));
          }
        },
      }
    );

  const handlePageChange = useCallback(
    (_: React.MouseEvent<HTMLButtonElement> | null, value: number) => {
      setPage(value);
    },
    []
  );
  const handleRowsPerPageChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setPageSize(Number(event.target.value));
    },
    [setPageSize]
  );

  const handleMoveItem = useCallback(
    (index: number, direction: string) => {
      const newIndex = direction === "up" ? index - 1 : index + 1;
      const updatedProducts = [...itemsWithEditableDisplayOrder];
      [updatedProducts[index], updatedProducts[newIndex]] = [
        updatedProducts[newIndex],
        updatedProducts[index],
      ];
      setValue("elementList", updatedProducts, { shouldDirty: true });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [itemsWithEditableDisplayOrder]
  );

  const handleMoveToTop = useCallback(
    (index: number) => {
      const updatedProducts = [...itemsWithEditableDisplayOrder];
      const [productToMove] = updatedProducts.splice(index, 1);
      updatedProducts.unshift(productToMove);
      setValue("elementList", updatedProducts, { shouldDirty: true });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [itemsWithEditableDisplayOrder]
  );

  const handleRetrySaveDisplayOrder = useCallback(() => {
    setIsResendModalOpen(false);
    doSaveDisplayOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancelRetrySaveDisplayOrder = useCallback(
    () => setIsResendModalOpen(false),
    []
  );

  const getEmptyRow = useCallback((index: number) => {
    return (
      <StyledTableRow
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
        }}
        data-testid="items-placeholder-tr"
        role="row"
      >
        <TableCell
          role="cell"
          component="th"
          scope="row"
          data-testid="items-td-name"
        ></TableCell>
        <TableCell role="cell"></TableCell>
        <TableCell role="cell"></TableCell>
        <TableCell role="cell"></TableCell>
        <TableCell role="cell"></TableCell>
        <TableCell role="cell"></TableCell>
      </StyledTableRow>
    );
  }, []);

  const getRow = useCallback(
    (index: number) => {
      if (
        !itemsWithEditableDisplayOrder ||
        !itemsWithEditableDisplayOrder[index]
      ) {
        return getEmptyRow(index);
      }
      const item = itemsWithEditableDisplayOrder[index];

      return (
        <StyledTableRow
          key={item.itemId}
          sx={{
            "&:last-child td, &:last-child th": { border: 0 },
          }}
          data-testid="item-tr"
        >
          <TableCell
            component="th"
            scope="row"
            role="row"
            data-testid="item-td-name"
          >
            {item.itemName}
          </TableCell>
          <TableCell
            component="th"
            scope="row"
            role="row"
            align="left"
            data-testid="item-td-type"
          >
            {item.itemType}
          </TableCell>
          <TableCell align="center" role="row">
            {item.itemId}
          </TableCell>
          <TableCell align="center" data-testid="item-td-updated-on" role="row">
            {item.status}
          </TableCell>
          <TableCell
            align="center"
            data-testid="item-td-display-order"
            role="cell"
          >
            {item.displayOrder}
          </TableCell>
          <TableCell align="center" role="cell">
            {index !== 0 && (
              <IconButton
                color="primary"
                onClick={() => handleMoveItem(index, "up")}
                aria-label={`Move ${item.itemName} up`}
              >
                <ArrowUpward />
              </IconButton>
            )}
            {index !== itemsWithEditableDisplayOrder.length - 1 && (
              <IconButton
                color="primary"
                onClick={() => handleMoveItem(index, "down")}
                aria-label={`Move ${item.itemName} down`}
              >
                <ArrowDownward />
              </IconButton>
            )}
            {index !== 0 && (
              <Tooltip title="Move to Top">
                <IconButton
                  color="primary"
                  onClick={() => handleMoveToTop(index)}
                  aria-label={`Move ${item.itemName} to top`}
                  style={{ transform: "rotate(180deg)" }}
                >
                  <LowPriority />
                </IconButton>
              </Tooltip>
            )}
          </TableCell>
        </StyledTableRow>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [itemsWithEditableDisplayOrder, isReaderRole]
  );
  return (
    <StyledRelativeContainer>
      <StyledTableControls>
        <Grid container justifyContent="flex-end" gap={2}>
          <StyledButton
            color="primary"
            variant="contained"
            startIcon={<Save />}
            onClick={() => doSaveDisplayOrder()}
            disabled={!isDirty || isDisplayOrderSaving}
          >
            Save Display Order
          </StyledButton>
          <Tooltip title="Cancel edit display order">
            <IconButton
              onClick={onCancelEdit}
              aria-label="Cancel edit display order"
              disabled={isDisplayOrderSaving}
            >
              <Cancel color="error" />
            </IconButton>
          </Tooltip>
        </Grid>
      </StyledTableControls>
      {isDisplayOrderSaving && <Loader />}
      <Grid marginTop={3}>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table" role="table">
            <StyledDashboardTableHead>
              <TableRow sx={{ borderBottom: "unset" }} role="row">
                <TableCell role="columnheader">Name</TableCell>
                <TableCell role="columnheader">Type</TableCell>
                <TableCell role="columnheader" align="center">
                  Id
                </TableCell>
                <TableCell role="columnheader" align="center">
                  Status
                </TableCell>
                <TableCell role="columnheader" align="center">
                  Display Order
                </TableCell>
                <TableCell role="columnheader" align="center">
                  Action
                </TableCell>
              </TableRow>
            </StyledDashboardTableHead>
            <TableBody>
              {itemsWithEditableDisplayOrder.length > 0 ? (
                Array.from({ length: pageSize }, (_, i) => i).map((i) =>
                  getRow(page * pageSize + i)
                )
              ) : (
                <EmptyState>No records found</EmptyState>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <StyledActionPanel container>
          <TablePagination
            component="div"
            count={itemsWithEditableDisplayOrder.length}
            onPageChange={handlePageChange}
            page={page}
            onRowsPerPageChange={handleRowsPerPageChange}
            rowsPerPage={pageSize}
            aria-label="Table pagination"
            rowsPerPageOptions={[5, 10, 25]}
            data-testid="items-pagination"
            SelectProps={{
              MenuProps: {
                classes: { list: "items-pagination-list" },
              },
            }}
          />
        </StyledActionPanel>
      </Grid>
      <ErrorAlertSnackbar
        message={errorMessage}
        onClose={() => setErrorMessage(null)}
      />
      <ResendFormModal
        open={isResendModalOpen}
        onResend={handleRetrySaveDisplayOrder}
        onCancel={handleCancelRetrySaveDisplayOrder}
        description={"An error occurred saving the product display order."}
      />
    </StyledRelativeContainer>
  );
};

const StyledRelativeContainer = styled(Grid)({
  display: "flex",
  flexDirection: "column",
  margin: 0,
  position: "relative",
  justifyContent: "center",
});
const StyledTableControls = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  paddingTop: theme.spacing(2),
  paddingRight: 0,
  paddingBottom: theme.spacing(1),
}));
const StyledTableRow = styled(TableRow)({
  borderBottom: "unset",
  backgroundColor: "white",
  height: 48,
});
const StyledActionPanel = styled(Grid)({
  display: "flex",
  flexDirection: "row",
  gap: "10px",
  justifyContent: "flex-end",
  alignItems: "center",
});
const StyledBlueLink = styled(Link)(({ theme }) => ({
  color: theme.typography.button.color,
  textDecorationColor: theme.typography.button.color,
}));
const StyledButton = styled(Button)(({ theme }) => ({
  color: "#000000",
  fontSize: theme.typography.largeBold.fontSize,
  fontFamily: theme.typography.largeBold.fontFamily,
  fontWeight: theme.typography.largeBold.fontWeight,
  textTransform: "none",
}));
