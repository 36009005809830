import { useSearchParams } from "react-router-dom";
import { withLayout } from "../hoc/with-layout";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Typography } from "@mui/material";

export const UnAuthorized = withLayout(() => {
  const [searchParams] = useSearchParams();
  const message =
    searchParams.get("message") || "You're not authorized to access this page";

  return (
    <>
      <StyledCenteredContainer>
        <Typography variant="h2">Oops, 401 UnAuthorized Access</Typography>
      </StyledCenteredContainer>
      <StyledCenteredContainer>
        <Typography variant="h5">{message}</Typography>
      </StyledCenteredContainer>
    </>
  );
}, "Not Found");
const StyledCenteredContainer = styled(Grid)(({ theme }) => ({
  justifyContent: "center",
  display: "flex",
  width: "100%",
  marginBottom: theme.spacing(6),
}));
