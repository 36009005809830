import { MouseEventHandler, useCallback, useContext, useState } from "react";
import { withLayout } from "../hoc/with-layout";
import { useCustomQuery } from "../hooks/use-custom-query";
import { RoleContext } from "../role-provider";
import {
  deleteStatusFlag,
  getViewStatus,
  saveStatusFlag,
} from "../data/miscellaneous";
import { useAxios } from "../axios-provider";
import { Loader } from "../components/loader/Loader";
import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { FixedHeightTableLoader } from "../components/FixedHeightLoader";
import { Add, DeleteForever } from "@mui/icons-material";
import { components } from "../data/miscellaneous.types";
import { EmptyState } from "../components/EmptyState";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ApiError } from "../utils";
import { AxiosError } from "axios";
import {
  ErrorAlertSnackbar,
  SuccessAlertSnackbar,
} from "../components/AlertSnackbar";
import { ResendFormModal } from "../components/ResendFormModal";
import {
  AddStatusFormDataType,
  AddStatusFormModal,
} from "../components/AddStatusFormModal";
import { ConfirmActionModal } from "../components/ConfirmActionModal";

export const ViewStatus = withLayout(() => {
  const { selectedCountry, selectedRole } = useContext(RoleContext);
  const { apiClient } = useAxios();
  const queryClient = useQueryClient();

  // Success and error messages
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const onCloseSuccessMessage = useCallback(() => {
    setSuccessMessage(null);
  }, []);
  const onCloseErrorMessage = useCallback(() => {
    setErrorMessage(null);
  }, []);

  // Get statuses data
  const { data: statusesData, isLoading } = useCustomQuery(
    ["getViewStatus", { countryCode: selectedCountry }],
    () =>
      getViewStatus(apiClient)({
        countryCode: selectedCountry!,
      })
  );

  // Add Status
  const { mutate: saveStatusMutation } = useMutation(
    (statusData: AddStatusFormDataType) =>
      saveStatusFlag(apiClient)(
        { statusFlagName: statusData.statusFlagName! },
        {
          countryCode: selectedCountry!,
          roleId: selectedRole!,
        }
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "getViewStatus",
          { countryCode: selectedCountry },
        ]);
        setSuccessMessage("Status was saved successfully");
      },
      onError: (error: AxiosError, statusData) => {
        if (error?.response?.status === 401) {
          setIsResendModalOpen(true);
          setResendModalAction("save");
          setResendData(statusData);
        } else if (error.response?.data) {
          const errorMessage: ApiError = error.response.data as ApiError;
          setErrorMessage(String(errorMessage.message));
        } else {
          setErrorMessage(String(error));
        }
      },
    }
  );

  const [isFormOpen, setIsFormOpen] = useState(false);
  const onClickAddStatus = useCallback(() => setIsFormOpen(true), []);
  const onCancelAddStatus = useCallback(() => setIsFormOpen(false), []);
  const onSaveAddForm = useCallback(
    (status: AddStatusFormDataType) => {
      setIsFormOpen(false);
      saveStatusMutation(status);
    },
    [saveStatusMutation]
  );

  // Delete
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
  const [statusToDelete, setStatusIdToDelete] = useState<number>();
  const { mutate: deleteMutation, isLoading: isDeleteLoading } = useMutation(
    (statusFlagId: components["schemas"]["FlagStatus"]["id"]) =>
      deleteStatusFlag(apiClient)({
        countryCode: selectedCountry!,
        statusFlagId,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "getViewStatus",
          { countryCode: selectedCountry },
        ]);
        setSuccessMessage("Status was deleted successfully");
        setResendModalAction(undefined);
        setResendData(undefined);
      },
      onError: (error: AxiosError, statusId) => {
        if (error?.response?.status === 401) {
          setIsResendModalOpen(true);
          setResendModalAction("delete");
          setResendData(statusId);
        } else if (error.response?.data) {
          const errorMessage: ApiError = error.response.data as ApiError;
          setErrorMessage(String(errorMessage.message));
        } else {
          setErrorMessage(String(error));
        }
      },
    }
  );
  const onClickDeleteStatus: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      const statusId = Number(e.currentTarget.dataset.statusid);
      if (statusId && !isNaN(statusId)) {
        setStatusIdToDelete(statusId);
        setIsConfirmDeleteOpen(true);
      }
    },
    []
  );
  const confirmDeleteStatus = useCallback(() => {
    typeof statusToDelete === "number" && deleteMutation(statusToDelete);
    setStatusIdToDelete(undefined);
    setIsConfirmDeleteOpen(false);
    setResendModalAction(undefined);
  }, [statusToDelete, deleteMutation]);
  const closeConfirmDialog = useCallback(() => {
    setIsConfirmDeleteOpen(false);
    setStatusIdToDelete(undefined);
    setResendModalAction(undefined);
  }, []);

  const [resendModalAction, setResendModalAction] = useState<
    "save" | "delete"
  >();
  const [resendData, setResendData] = useState<
    AddStatusFormDataType | components["schemas"]["FlagStatus"]["id"]
  >();
  const [isResendModalOpen, setIsResendModalOpen] = useState(false);
  const onCancelResend = useCallback(() => {
    setIsResendModalOpen(false);
    setResendModalAction(undefined);
    setResendData(undefined);
  }, []);
  const onResendClick = useCallback(() => {
    switch (resendModalAction) {
      case "save":
        if (typeof resendData === "object") {
          saveStatusMutation(resendData);
        }
        break;
      case "delete":
        if (typeof resendData === "number") {
          deleteMutation(resendData);
        }
        break;
    }

    setIsResendModalOpen(false);
    setResendModalAction(undefined);
    setResendData(undefined);
  }, [resendModalAction, resendData, saveStatusMutation, deleteMutation]);

  if (isLoading) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ minHeight: 360 }}
      >
        <Loader />
      </Grid>
    );
  }

  return (
    <Grid container gap={2} direction={"column"}>
      <SuccessAlertSnackbar
        message={successMessage}
        onClose={onCloseSuccessMessage}
      />
      <ErrorAlertSnackbar
        message={errorMessage}
        onClose={onCloseErrorMessage}
      />
      <ResendFormModal
        open={isResendModalOpen}
        onCancel={onCancelResend}
        onResend={onResendClick}
      />
      <AddStatusFormModal
        isOpen={isFormOpen}
        onCancel={onCancelAddStatus}
        onSave={onSaveAddForm}
      />
      <Grid container justifyContent={"space-between"}>
        <Grid>
          <Typography variant="h1">View Status</Typography>
        </Grid>
        <Grid>
          <Button
            variant="contained"
            color="primary"
            onClick={onClickAddStatus}
            startIcon={<Add />}
            sx={(theme) => ({ color: theme.palette.text.primary })}
            disableRipple
          >
            Add Status
          </Button>
        </Grid>
      </Grid>
      <Grid container>
        <Grid mobile={6}>
          <ConfirmActionModal
            loading={isDeleteLoading}
            message="Are you sure you want to delete this status?"
            open={isConfirmDeleteOpen}
            onConfirm={confirmDeleteStatus}
            onCancel={closeConfirmDialog}
          />
          <FixedHeightTableLoader pageSize={10} withActionPanel={true}>
            {isLoading && <Loader />}

            <TableContainer component={Paper}>
              <Table>
                <StyledTableHead>
                  <TableRow>
                    <TableCell>Status Flag Name</TableCell>
                    <TableCell width={80}>Action</TableCell>
                  </TableRow>
                </StyledTableHead>
                <TableBody>
                  {statusesData?.data.dataList.length === 0 && (
                    <EmptyState>
                      <Typography variant="h6">No data found</Typography>
                    </EmptyState>
                  )}
                  {statusesData?.data.dataList?.map((status) => (
                    <TableRow key={status.id}>
                      <TableCell>{status.value}</TableCell>
                      <TableCell>
                        <IconButton
                          data-statusid={status.id}
                          onClick={onClickDeleteStatus}
                          disableRipple
                        >
                          <DeleteForever color="error" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </FixedHeightTableLoader>
        </Grid>
      </Grid>
    </Grid>
  );
}, "View Status");

export const StyledTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  ...theme.typography.normalBold,
}));
