import { Navigate, useOutlet, useLocation } from "react-router-dom";
import { AuthContext } from "../auth-provider";
import { useContext } from "react";
import { RoleContext } from "../role-provider";

export const ProtectedRoute = () => {
  const { accessToken, setRedirectDestination } = useContext(AuthContext);
  const { selectedCountry, selectedRole } = useContext(RoleContext);

  const outlet = useOutlet();
  const location = useLocation();
  const currentPath = location.pathname;
  const accessPaths = JSON.parse(localStorage.getItem('userMenuList') || '[]');
  // Define checkPermissions inside ProtectedRoute
 const checkPermissions = (path: string): boolean => {

  // Check if the user has access to the specific path
  const hasAccess = accessPaths.some((accessPath: string) => {
    const regex = new RegExp(`^${accessPath.replace(/:\w+/g, '\\w+')}$`);
    return regex.test(path);
  });

  return hasAccess;
};

  if (!accessToken) {
    setRedirectDestination(`${location.pathname}${location.search}`);
    // user is not authenticated
    return <Navigate to="/login" />;
  }
  if (!selectedCountry || !selectedRole) {
    // user has not selected country/role
    return <Navigate to="/switch-role" />;
  }
  if (accessPaths.length === 0) {
    // Wait until access paths are loaded
    return null;
  }
  if (!checkPermissions(currentPath)) {
    // user does not have permission for this route
    return <Navigate to="/unauthorized" />;
  } 
  return <>{outlet}</>;
};
