import {
  ChangeEvent,
  MouseEventHandler,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { withLayout } from "../hoc/with-layout";
import { useCustomQuery } from "../hooks/use-custom-query";
import { RoleContext } from "../role-provider";
import {
  deleteLogo,
  getViewLogo,
  saveLogoDetails,
} from "../data/miscellaneous";
import { useAxios } from "../axios-provider";
import { Loader } from "../components/loader/Loader";
import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { FixedHeightTableLoader } from "../components/FixedHeightLoader";
import { Add, DeleteForever, Edit } from "@mui/icons-material";
import { SearchInput } from "../components/SearchInput";
import { components } from "../data/miscellaneous.types";
import { EmptyState } from "../components/EmptyState";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ConfirmActionModal } from "../components/ConfirmActionModal";
import { ApiError } from "../utils";
import { AxiosError } from "axios";
import {
  ErrorAlertSnackbar,
  SuccessAlertSnackbar,
} from "../components/AlertSnackbar";
import { ResendFormModal } from "../components/ResendFormModal";
import {
  AddEditLogoFormDataType,
  AddEditLogoFormModal,
} from "../components/AddEditLogoFormModal";

export const ViewLogo = withLayout(() => {
  const { selectedCountry, selectedRole } = useContext(RoleContext);
  const { apiClient } = useAxios();
  const queryClient = useQueryClient();

  // Success and error messages
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const onCloseSuccessMessage = useCallback(() => {
    setSuccessMessage(null);
  }, []);
  const onCloseErrorMessage = useCallback(() => {
    setErrorMessage(null);
  }, []);

  // Get logos data
  const {
    data: logosData,
    isLoading,
    dataUpdatedAt: logoListUpdatedAt,
  } = useCustomQuery(["getViewLogo", { countryCode: selectedCountry }], () =>
    getViewLogo(apiClient)({
      countryCode: selectedCountry!,
    })
  );

  // Add/Edit Logo
  const { mutate: saveLogoMutation } = useMutation(
    (logoData: AddEditLogoFormDataType) => {
      const { logoId, ...rest } = logoData;
      if (isNaN(Number(logoId)) || Number(logoId) < 1) {
        return saveLogoDetails(apiClient)(rest, {
          countryCode: selectedCountry!,
          roleId: selectedRole!,
        });
      }
      return saveLogoDetails(apiClient)(rest, {
        countryCode: selectedCountry!,
        roleId: selectedRole!,
        logoId,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "getViewLogo",
          { countryCode: selectedCountry },
        ]);
        setSuccessMessage("Logo was saved successfully");
      },
      onError: (error: AxiosError, logoData) => {
        if (error?.response?.status === 401) {
          setIsResendModalOpen(true);
          setResendModalAction("save");
          setResendData(logoData);
        } else if (error.response?.data) {
          const errorMessage: ApiError = error.response.data as ApiError;
          setErrorMessage(String(errorMessage.message));
        } else {
          setErrorMessage(String(error));
        }
      },
    }
  );

  const [isFormOpen, setIsFormOpen] = useState(false);
  const [editingLogo, setEditingLogo] =
    useState<components["schemas"]["Logo"]>();
  const onClickAddLogo = useCallback(() => {
    setEditingLogo(undefined);
    setIsFormOpen(true);
  }, []);
  const onCancelAddEditLogo = useCallback(() => {
    setEditingLogo(undefined);
    setIsFormOpen(false);
  }, []);
  const onSaveAddEditForm = useCallback(
    (logo: AddEditLogoFormDataType) => {
      setIsFormOpen(false);
      saveLogoMutation(logo);
      setEditingLogo(undefined);
    },
    [saveLogoMutation]
  );

  // Delete
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
  const [logoToDelete, setLogoToDelete] = useState<number>();
  const { isLoading: isDeleteLoading, mutate: deleteMutation } = useMutation(
    (logoId: components["schemas"]["Logo"]["logoId"]) =>
      deleteLogo(apiClient)({
        countryCode: selectedCountry!,
        logoId: logoId,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "getViewLogo",
          { countryCode: selectedCountry },
        ]);
        setSuccessMessage("Logo was deleted successfully");
      },
      onError: (error: AxiosError, logoId) => {
        if (error?.response?.status === 401) {
          setIsResendModalOpen(true);
          setResendModalAction("delete");
          setResendData(logoId);
        } else if (error.response?.data) {
          const errorMessage: ApiError = error.response.data as ApiError;
          setErrorMessage(String(errorMessage.message));
        } else {
          setErrorMessage(String(error));
        }
      },
    }
  );
  const onClickDeleteLogo: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      const logoId = Number(e.currentTarget.dataset.logoid);
      if (logoId && !isNaN(logoId)) {
        setLogoToDelete(logoId);
        setIsConfirmDeleteOpen(true);
      }
    },
    []
  );
  const confirmDeleteLogo = useCallback(() => {
    typeof logoToDelete === "number" && deleteMutation(logoToDelete);
    setLogoToDelete(undefined);
    setIsConfirmDeleteOpen(false);
  }, [logoToDelete, deleteMutation]);
  const closeConfirmDialog = useCallback(
    () => setIsConfirmDeleteOpen(false),
    []
  );

  const [resendModalAction, setResendModalAction] = useState<
    "save" | "delete"
  >();
  const [resendData, setResendData] = useState<
    AddEditLogoFormDataType | components["schemas"]["Logo"]["logoId"]
  >();
  const [isResendModalOpen, setIsResendModalOpen] = useState(false);
  const onCancelResend = useCallback(() => {
    setIsResendModalOpen(false);
    setResendModalAction(undefined);
    setResendData(undefined);
  }, []);
  const onResendClick = useCallback(() => {
    switch (resendModalAction) {
      case "save":
        if (typeof resendData === "object") {
          saveLogoMutation(resendData);
        }
        break;
      case "delete":
        if (typeof resendData === "number") {
          deleteMutation(resendData);
        }
        break;
    }

    setIsResendModalOpen(false);
    setResendModalAction(undefined);
    setResendData(undefined);
  }, [resendModalAction, resendData, saveLogoMutation, deleteMutation]);

  // Search
  const [search, setSearch] = useState("");
  const onSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  }, []);

  const filteredData = useMemo(() => {
    if (!logosData) {
      return [];
    }
    if (!search) {
      return logosData.data.viewLogo;
    }
    return logosData.data.viewLogo.filter((logo) => {
      const searchLower = search.toLowerCase();
      return (
        logo.logoName.toLowerCase().includes(searchLower) ||
        logo.logoImage.toLowerCase().includes(searchLower)
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logoListUpdatedAt, search]);

  const onClickEditLogo = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      const logoId = Number(e.currentTarget.dataset.logoid);
      if (isNaN(logoId)) return;

      const logo = filteredData.find((logo) => logo.logoId === logoId);
      if (!logo) return;

      setIsFormOpen(true);
      setEditingLogo(logo);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [logoListUpdatedAt]
  );

  if (isLoading) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ minHeight: 360 }}
      >
        <Loader />
      </Grid>
    );
  }

  return (
    <Grid container gap={2} direction={"column"}>
      <SuccessAlertSnackbar
        message={successMessage}
        onClose={onCloseSuccessMessage}
      />
      <ErrorAlertSnackbar
        message={errorMessage}
        onClose={onCloseErrorMessage}
      />
      <ResendFormModal
        open={isResendModalOpen}
        onCancel={onCancelResend}
        onResend={onResendClick}
      />
      <Grid container justifyContent={"space-between"}>
        <Grid>
          <Typography variant="h1">View Logo</Typography>
        </Grid>
        <Grid>
          <Button
            variant="contained"
            color="primary"
            onClick={onClickAddLogo}
            startIcon={<Add />}
            sx={(theme) => ({ color: theme.palette.text.primary })}
            disableRipple
          >
            Add Logo
          </Button>
          <AddEditLogoFormModal
            logo={editingLogo}
            isOpen={isFormOpen}
            onCancel={onCancelAddEditLogo}
            onSave={onSaveAddEditForm}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="flex-end">
        <Grid>
          <SearchInput
            placeholder="Search"
            value={search}
            onChange={onSearchChange}
            testId="search-input"
          />
        </Grid>
      </Grid>
      <Grid>
        <FixedHeightTableLoader pageSize={10} withActionPanel={true}>
          {isLoading && <Loader />}

          <TableContainer component={Paper}>
            <Table>
              <StyledTableHead>
                <TableRow>
                  <TableCell>Logo Name</TableCell>
                  <TableCell>Logo Image</TableCell>
                  <TableCell>URL</TableCell>
                  <TableCell>Alt Text</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </StyledTableHead>
              <TableBody>
                {filteredData.length === 0 && (
                  <EmptyState>
                    <Typography variant="h6">No data found</Typography>
                  </EmptyState>
                )}
                {filteredData?.map((logo) => (
                  <TableRow key={logo.logoId}>
                    <TableCell>{logo.logoName}</TableCell>
                    <TableCell>{logo.logoImage}</TableCell>
                    <TableCell>{logo.url}</TableCell>
                    <TableCell>{logo.altText}</TableCell>
                    <TableCell>{logo.description}</TableCell>
                    <TableCell>
                      <IconButton
                        data-logoid={logo.logoId}
                        onClick={onClickEditLogo}
                      >
                        <Edit />
                      </IconButton>
                      <IconButton
                        data-logoid={logo.logoId}
                        onClick={onClickDeleteLogo}
                      >
                        <DeleteForever color="error" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </FixedHeightTableLoader>
      </Grid>
      <ConfirmActionModal
        loading={isDeleteLoading}
        message="Are you sure you want to delete this logo?"
        onConfirm={confirmDeleteLogo}
        open={isConfirmDeleteOpen}
        onCancel={closeConfirmDialog}
      />
    </Grid>
  );
}, "View Logo");

export const StyledTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  ...theme.typography.normalBold,
}));
