import { components } from "../data/uom.types";
import { isDefined } from "../utils";
import { localizedNumStringOrEmpty } from "./number-localization";

export type RoundingRule = components["schemas"]["RoundingRule"];
export type RoundingRuleDataMapping =
  components["schemas"]["RoundingRuleDataMapping"];
export type NutrientRoundingRuleMappingFormData =
  components["schemas"]["NutrientRoundingRuleMappingFormData"];
export type NutrientRoundingRuleMappingConfig =
  components["schemas"]["NutrientRoundingRuleMappingConfig"];
export type RoundingRuleMappingValue =
  components["schemas"]["RoundingRuleMappingValue"];

export type RoundingRuleForEdit = {
  description: string;
  roundingConstant: string;
  roundingModeId: number;
  roundingRuleName: string;
};

export type NutrientRoundingRuleMappingForEdit = {
  roundingRuleMappingId?: number;
  nutrientFactId: number;
  roundingRuleId: number | null;
  roundingRuleTypeId: number | null;
  minValue: string;
  maxValue: string;
};

export function formatRoundingRule(
  rawRoundingRule: any,
  marketCode: string
): RoundingRule | undefined {
  const { roundingRuleId, roundingRuleName, roundingMode, roundingConstant } =
    rawRoundingRule;
  if (!isNaN(Number(roundingRuleId))) {
    return {
      roundingRuleId: Number(roundingRuleId),
      roundingRuleName:
        typeof roundingRuleName === "string" ? roundingRuleName : "",
      roundingMode: typeof roundingMode === "string" ? roundingMode : "",
      roundingConstant:
        typeof roundingConstant === "string"
          ? localizedNumStringOrEmpty(roundingConstant, marketCode)
          : "",
    };
  }
}

export function formatRoundingRuleForEdit(
  rawRoundingRule: any,
  marketCode: string
): RoundingRuleForEdit | undefined {
  const { description, roundingRuleName, roundingModeId, increment } =
    rawRoundingRule;
  if (!isNaN(Number(roundingModeId))) {
    return {
      roundingModeId: Number(roundingModeId),
      roundingRuleName:
        typeof roundingRuleName === "string" ? roundingRuleName : "",
      description: typeof description === "string" ? description : "",
      roundingConstant:
        typeof increment === "string"
          ? localizedNumStringOrEmpty(increment, marketCode!)
          : "",
    };
  }
}

export function formatRoundingRuleMappingValue(
  rawRoundingRuleMappingValue: any
): RoundingRuleMappingValue | undefined {
  const { roundingRuleMappingId, ruleName, minVal, maxVal } =
    rawRoundingRuleMappingValue;
  if (!isNaN(Number(roundingRuleMappingId))) {
    return {
      roundingRuleMappingId: Number(roundingRuleMappingId),
      ruleName: typeof ruleName === "string" ? ruleName : "",
      minVal: typeof minVal === "string" ? minVal : "",
      maxVal: typeof maxVal === "string" ? maxVal : "",
    };
  }
}

export function formatNutrientRoundingRuleMappingConfig(
  rawNutrientRoundingRuleMapping: any
): NutrientRoundingRuleMappingConfig | undefined {
  const {
    nutrientFactId,
    nutrientFactName,
    roundingRuleValue,
    roundingRuleDailyValue,
  } = rawNutrientRoundingRuleMapping;
  if (!isNaN(Number(nutrientFactId))) {
    return {
      nutrientFactId: Number(nutrientFactId),
      nutrientFactName:
        typeof nutrientFactName === "string" ? nutrientFactName : "",
      roundingRuleValue: roundingRuleValue
        .map(formatRoundingRuleMappingValue)
        .filter(isDefined),
      roundingRuleDailyValue: roundingRuleDailyValue
        .map(formatRoundingRuleMappingValue)
        .filter(isDefined),
    };
  }
}

export function formatRoundingRuleDataMapping(
  rawRoundingRuleDataMapping: any
): RoundingRuleDataMapping | undefined {
  const { id, value } = rawRoundingRuleDataMapping;
  if (!isNaN(Number(id))) {
    return {
      id: Number(id),
      value: typeof value === "string" ? value : "",
    };
  }
}

export function formatNutrientRoundingRuleMappingFormData(
  rawNutrientRoundingRuleMappingFormData: any
): NutrientRoundingRuleMappingFormData {
  const { nutrient, roundingRuleType, roundingRule } =
    rawNutrientRoundingRuleMappingFormData;
  return {
    nutrient: nutrient.map(formatRoundingRuleDataMapping).filter(isDefined),
    roundingRuleType: roundingRuleType
      .map(formatRoundingRuleDataMapping)
      .filter(isDefined),
    roundingRule: roundingRule
      .map(formatRoundingRuleDataMapping)
      .filter(isDefined),
  };
}
