import { AxiosHeaders } from "axios";
import products from "./json/products.json";
import {
  realGetCompareCurrent,
  realGetCompareLive,
  realGetProductDetails,
  realGetProductNutrients,
  realGetProducts,
  realGetSearchableProducts,
  realProductGetReviewAndSubmit,
  realProductSaveReviewAndSubmit,
  realSaveProductDetails,
  realSaveProductNutrients,
} from "../api/products";
import { delay } from ".";
export type ElementDetail = {
  id?: number;
  name?: string;
  value?: string;
  keyName?: string;
  displayOrder?: number;
};

export const mockGetProducts: ReturnType<typeof realGetProducts> = async ({
  countryCode = "US",
  languageCode = "EN",
  roleId = 2,
}) => {
  await delay(1.5);
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful",
      dataList: products.map((product, index) => ({
        productId: Number(product.productId),
        productName: product.productName,
        productStatus: "Active",
        showEdit: "Y",
        displayOrder: index,
      })),
      totalRecordCount: products.length,
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};
export const mockGetSearchableProducts: ReturnType<
  typeof realGetSearchableProducts
> = async ({ countryCode = "US" }) => {
  await delay(1.5);
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful",
      dataList: [
        {
          productId: 300000,
          importId: "10",
          productName: "Regular Bun",
          unit: "100 g ( )",
        },
        {
          productId: 300001,
          importId: "2",
          productName: "Beef 10:1 Patty",
          unit: "100 g ( )",
        },
        {
          productId: 300002,
          importId: "3",
          productName: "Mustard",
          unit: "100 g ( )",
        },
        {
          productId: 300003,
          importId: "4",
          productName: "Ketchup",
          unit: "100 g ( )",
        },
        {
          productId: 300004,
          importId: "5",
          productName: "Onion",
          unit: "100 g ( )",
        },
        {
          productId: 300005,
          importId: "6",
          productName: "Pickle",
          unit: "100 g ( )",
        },
        {
          productId: 300006,
          importId: "20",
          productName: "Big Mac Bun",
          unit: "100 g ( )",
        },
        {
          productId: 300007,
          importId: "21",
          productName: "Big Mac Sauce",
          unit: "100 g ( )",
        },
        {
          productId: 300008,
          importId: "7",
          productName: "Lettuce",
          unit: "100 g ( )",
        },
        {
          productId: 300009,
          importId: "8",
          productName: "Cheddar Cheese",
          unit: "100 g ( )",
        },
        {
          productId: 300010,
          importId: "30",
          productName: '"5" Bun',
          unit: "100 g ( )",
        },
        {
          productId: 300011,
          importId: "22",
          productName: "Big Tasty Sauce",
          unit: "100 g ( )",
        },
        {
          productId: 300012,
          importId: "33",
          productName: "Beef 3:1 Patty",
          unit: "100 g ( )",
        },
        {
          productId: 300013,
          importId: "51",
          productName: "Slivered Onion",
          unit: "100 g ( )",
        },
        {
          productId: 300014,
          importId: "99",
          productName: "Tomato",
          unit: "100 g ( )",
        },
        {
          productId: 300015,
          importId: "90",
          productName: "Emmental Cheese",
          unit: "100 g ( )",
        },
        {
          productId: 300016,
          importId: "44",
          productName: "Value Chicken Patty",
          unit: "100 g ( )",
        },
        {
          productId: 300017,
          importId: "15",
          productName: "Mayonnaise",
          unit: "100 g ( )",
        },
        {
          productId: 300018,
          importId: "60",
          productName: "Chicken Burger",
          unit: "100 g ( )",
        },
        {
          productId: 300019,
          importId: "701",
          productName: "Chicken Nuggets  - 4pcs",
          unit: "100 g ( )",
        },
        {
          productId: 300020,
          importId: "702",
          productName: "Chicken Nuggets",
          unit: "100 g ( )",
        },
        {
          productId: 300021,
          importId: "703",
          productName: "Chicken Nuggets - 9 pcs",
          unit: "100 g ( )",
        },
        {
          productId: 300022,
          importId: "995621545",
          productName: "Reg Buns (Fish)",
          unit: "100 g ( )",
        },
        {
          productId: 300023,
          importId: "45",
          productName: "Fish Portion",
          unit: "100 g ( )",
        },
        {
          productId: 300024,
          importId: "49",
          productName: "Tartar Sauce",
          unit: "100 g ( )",
        },
        {
          productId: 300025,
          importId: "111",
          productName: "Water Split Bun",
          unit: "100 g ( )",
        },
        {
          productId: 300026,
          importId: "112",
          productName: "Grand Chicken Patty",
          unit: "100 g ( )",
        },
        {
          productId: 300027,
          importId: "113",
          productName: "Garlic Mayonniase",
          unit: "100 g ( )",
        },
        {
          productId: 300028,
          importId: "88888888",
          productName: "Shredded Lettuce",
          unit: "100 g ( )",
        },
        {
          productId: 300029,
          importId: "115",
          productName: "Mustard-Mayo Sauce",
          unit: "100 g ( )",
        },
        {
          productId: 300030,
          importId: "200",
          productName: "Chicken Roll",
          unit: "100 g ( )",
        },
        {
          productId: 300031,
          importId: "201",
          productName: "Dijon Mustard Sauce",
          unit: "100 g ( )",
        },
        {
          productId: 300032,
          importId: "202",
          productName: "Rocket Leaves",
          unit: "100 g ( )",
        },
        {
          productId: 300033,
          importId: "112112",
          productName: "Spicy Grand Chicken Patty",
          unit: "100 g ( )",
        },
        {
          productId: 300034,
          importId: "300",
          productName: "Flat Bread (McArabia Chicken)",
          unit: "100 g ( )",
        },
        {
          productId: 300035,
          importId: "301",
          productName: "Grilled Chicken Patty",
          unit: "100 g ( )",
        },
        {
          productId: 300036,
          importId: "350",
          productName: '4" Bun',
          unit: "100 g ( )",
        },
        {
          productId: 300037,
          importId: "360",
          productName: "Chicken Portion",
          unit: "100 g ( )",
        },
        {
          productId: 300038,
          importId: "370",
          productName: "Beef 4:1 Patty",
          unit: "100 g ( )",
        },
        {
          productId: 300039,
          importId: "400",
          productName: "Quarter Pounder Bun",
          unit: "100 g ( )",
        },
        {
          productId: 300040,
          importId: "700",
          productName: "Spicy Chicken",
          unit: "100 g ( )",
        },
        {
          productId: 300041,
          importId: "36",
          productName: "English Muffin",
          unit: "100 g ( )",
        },
        {
          productId: 300042,
          importId: "46",
          productName: "Lurpak Butter",
          unit: "100 g ( )",
        },
        {
          productId: 300043,
          importId: "47",
          productName: "Halloumi Cheese",
          unit: "100 g ( )",
        },
        {
          productId: 300044,
          importId: "48",
          productName: "Olive Paste",
          unit: "100 g ( )",
        },
        {
          productId: 300045,
          importId: "56",
          productName: "Round Egg",
          unit: "100 g ( )",
        },
        {
          productId: 300046,
          importId: "57",
          productName: "Chicken Sausage",
          unit: "100 g ( )",
        },
        {
          productId: 300047,
          importId: "72",
          productName: "Chicken Roll",
          unit: "100 g ( )",
        },
        {
          productId: 300048,
          importId: "801",
          productName: "Hotcake",
          unit: "100 g ( )",
        },
        {
          productId: 300049,
          importId: "802",
          productName: "Hotcake Syrup",
          unit: "100 g ( )",
        },
        {
          productId: 300050,
          importId: "58",
          productName: "Scrambled Egg",
          unit: "100 g ( )",
        },
        {
          productId: 300051,
          importId: "699",
          productName: "Hash Brown",
          unit: "100 g ( )",
        },
        {
          productId: 300052,
          importId: "8340123",
          productName: "Sundae Mix",
          unit: "100 g ( )",
        },
        {
          productId: 300053,
          importId: "8341",
          productName: "Oreo Toppings",
          unit: "100 g ( )",
        },
        {
          productId: 300054,
          importId: "8340",
          productName: "Sundae Mix",
          unit: "100 g ( )",
        },
        {
          productId: 300055,
          importId: "841",
          productName: "Hot Fudge Topping",
          unit: "100 g ( )",
        },
        {
          productId: 300056,
          importId: "8342",
          productName: "Caramel Topping",
          unit: "100 g ( )",
        },
        {
          productId: 300057,
          importId: "844",
          productName: "Strawberry  Topping",
          unit: "100 g ( )",
        },
        {
          productId: 300058,
          importId: "8444",
          productName: "Cone",
          unit: "100 g ( )",
        },
        {
          productId: 300059,
          importId: "66667",
          productName: "kitkat topping",
          unit: "100 g ( )",
        },
        {
          productId: 300060,
          importId: "",
          productName: "Brown Tortilla",
          unit: "100 g ( )",
        },
        {
          productId: 300061,
          importId: "",
          productName: "American cheddar Cheese",
          unit: "100 g ( )",
        },
        {
          productId: 300062,
          importId: "",
          productName: "Mini McCrispy",
          unit: "100 g ( )",
        },
        {
          productId: 300063,
          importId: "",
          productName: "Sausage",
          unit: "100 g ( )",
        },
        {
          productId: 300064,
          importId: "",
          productName: "Butter",
          unit: "100 g ( )",
        },
        {
          productId: 300065,
          importId: "",
          productName: "McMuffin egg",
          unit: "100.0 g ( )",
        },
        {
          productId: 300066,
          importId: "",
          productName: "Light Mayonnaise",
          unit: "100 g ( )",
        },
        {
          productId: 300067,
          importId: "",
          productName: "Brioche bun",
          unit: "100 g ( )",
        },
        {
          productId: 300068,
          importId: "",
          productName: "Hot tomato sauce",
          unit: "100 g ( )",
        },
        {
          productId: 300069,
          importId: "",
          productName: "Crispy onion",
          unit: "100 g ( )",
        },
        {
          productId: 300070,
          importId: "",
          productName: "Jalapenos",
          unit: "100 g ( )",
        },
        {
          productId: 300071,
          importId: "",
          productName: "Boston lettuce",
          unit: "100 g ( )",
        },
        {
          productId: 300072,
          importId: "",
          productName: "4:1 Angus patty",
          unit: "100 g ( )",
        },
        {
          productId: 300073,
          importId: "",
          productName: "Double water split bun",
          unit: "100 g ( )",
        },
        {
          productId: 300074,
          importId: "",
          productName: "Creamy tomato sauce",
          unit: "100 g ( )",
        },
        {
          productId: 300075,
          importId: "",
          productName: "Buttermilk chicken patty",
          unit: "100 g ( )",
        },
        {
          productId: 300076,
          importId: "",
          productName: "Smoky BBQ sauce",
          unit: "100 g ( )",
        },
        {
          productId: 300077,
          importId: "",
          productName: "Crispy chicken bacon",
          unit: "100 g ( )",
        },
        {
          productId: 300078,
          importId: "",
          productName: "Whole wheat bun",
          unit: "100 g ( )",
        },
        {
          productId: 300079,
          importId: "",
          productName: "Chargrill sauce",
          unit: "100 g ( )",
        },
        {
          productId: 300080,
          importId: "",
          productName: "Fresh slivered onion",
          unit: "100 g ( )",
        },
        {
          productId: 300081,
          importId: "",
          productName: "Rocca leaves",
          unit: "100 g ( )",
        },
        {
          productId: 300082,
          importId: "",
          productName: "Whole muscle grilled chicken",
          unit: "100 g ( )",
        },
        {
          productId: 300083,
          importId: "",
          productName: "Dehydrated Onion",
          unit: "100 g ( )",
        },
        {
          productId: 300084,
          importId: "",
          productName: "Salad Mustard",
          unit: "100 g ( )",
        },
        {
          productId: 300085,
          importId: "",
          productName: "White Onion",
          unit: "2.0 g ( )",
        },
        {
          productId: 300086,
          importId: "",
          productName: "FOF portion",
          unit: "100 g ( )",
        },
        {
          productId: 300087,
          importId: "",
          productName: "McChicken patty",
          unit: "100 g ( )",
        },
      ],
      totalRecordCount: 88,
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};
export const mockGetProductDetails: ReturnType<
  typeof realGetProductDetails
> = async ({ productId, countryCode = "US", languageCode = "EN" }) => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "",
      typeList: [
        {
          value: "Core Product",
          id: 40,
        },
        {
          value: "Promotional Product",
          id: 101,
        },
      ],
      attributeList: [
        {
          name: "Product Type",
          value: "Core Product",
          id: 251,
          keyName: "product_type",
        },
        {
          name: "Product Name",
          value: "Galaxy Chocolate Sauce",
          id: 252,
          keyName: "product_name",
        },
        {
          name: "Product Marketing Name",
          value: "Galaxy Chocolate Sauce",
          id: 253,
          keyName: "product_marketing_name",
        },
        {
          name: "Genesis Product Name",
          value: "Galaxy Chocolate Sauce",
          id: 791,
          keyName: "imported_product_name",
        },
        {
          name: "Product ID",
          value: "300002",
          id: 255,
          keyName: "product_id",
        },
        {
          name: "Product No.",
          value: "3",
          id: 256,
          keyName: "product_external_id",
        },
        {
          name: "Genesis Id",
          value: "5033605-002",
          id: 790,
          keyName: "import_id",
        },
        {
          name: "Ingredient Statement",
          value:
            'Milk Chocolate (36%) (Sugar, Cocoa Butter, <span class="offscreen">Allergen Ingredient: </span> Skimmed <strong>MILK</strong> Powder, Cocoa Mass, Vegetable Fat (Palm), Lactose (<span class="offscreen">Allergen Ingredient: </span><strong>MILK</strong>), Whey Powder (<span class="offscreen">Allergen Ingredient: </span> <strong>MILK</strong>), <span class="offscreen">Allergen Ingredient: </span> <strong>MILK</strong> Fat, Whey Solids (<span class="offscreen">Allergen Ingredient: </span><strong>MILK</strong>), Emulsifier (<span class="offscreen">Allergen Ingredient: </span><strong>SOYA</strong> Lecithin), Natural Vanilla Flavouring), Glucose-Fructose Syrup, <span class="offscreen">Allergen Ingredient: </span> Sweetened Condensed <strong>MILK</strong> (<span class="offscreen">Allergen Ingredient: </span><strong>MILK</strong>, Sugar), Water, Thickener (Modified Starch).',
          id: 254,
          keyName: "ingredient_statement",
        },
        {
          name: "PIM ID",
          value: "",
          id: 1429,
          keyName: "pim_id",
        },
        {
          name: "PIM Product Name",
          value: "",
          id: 1430,
          keyName: "pim_product_name",
        },
        {
          name: "Product Allergen",
          value: "",
          id: 259,
          keyName: "product_allergen",
        },
        {
          name: "Additional Allergen",
          value: "",
          id: 265,
          keyName: "product_additional_allergen",
        },
        {
          name: "Product Comments",
          value: "",
          id: 260,
          keyName: "product_comments",
        },
        {
          name: "Additional Text For Ingredient Statement",
          value: "",
          id: 262,
          keyName: "product_additional_text_ingredient_statement",
        },
        {
          name: "Product Keywords",
          value: "",
          id: 263,
          keyName: "product_keywords",
        },
        {
          name: "Thumbnail Image",
          value: "",
          id: 264,
          keyName: "attach_product_thumbnail_image",
        },
      ],
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};
export const mockGetProductNutrients: ReturnType<
  typeof realGetProductNutrients
> = async ({ countryCode = "US", productId }) => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful",
      nutritionList: [
        {
          nutrientFactId: 1,
          nutrientFactName: "Energy kcal",
          nutrientValue: "352.5",
          uomName: "kcal",
          dvValue: "N/A",
          hundredGramPerProduct: "N/A",
        },
        {
          nutrientFactId: 9,
          nutrientFactName: "Energy kJ",
          nutrientValue: "1480.7",
          uomName: "kJ",
          dvValue: "N/A",
          hundredGramPerProduct: "N/A",
        },
        {
          nutrientFactId: 2,
          nutrientFactName: "protein",
          nutrientValue: "3.7",
          uomName: "g",
          dvValue: "N/A",
          hundredGramPerProduct: "N/A",
        },
        {
          nutrientFactId: 3,
          nutrientFactName: "carbohydrates",
          nutrientValue: "53.3",
          uomName: "g",
          dvValue: "N/A",
          hundredGramPerProduct: "N/A",
        },
        {
          nutrientFactId: 4,
          nutrientFactName: "sugars",
          nutrientValue: "51.8",
          uomName: "g",
          dvValue: "N/A",
          hundredGramPerProduct: "N/A",
        },
        {
          nutrientFactId: 5,
          nutrientFactName: "fat",
          nutrientValue: "13.7",
          uomName: "g",
          dvValue: "N/A",
          hundredGramPerProduct: "N/A",
        },
        {
          nutrientFactId: 6,
          nutrientFactName: "saturated fat",
          nutrientValue: "8.07",
          uomName: "g",
          dvValue: "N/A",
          hundredGramPerProduct: "N/A",
        },
        {
          nutrientFactId: 7,
          nutrientFactName: "fibre",
          nutrientValue: "0.6",
          uomName: "g",
          dvValue: "N/A",
          hundredGramPerProduct: "N/A",
        },
        {
          nutrientFactId: 8,
          nutrientFactName: "salt",
          nutrientValue: "0.15",
          uomName: "g",
          dvValue: "N/A",
          hundredGramPerProduct: "N/A",
        },
        {
          nutrientFactId: 10,
          nutrientFactName: "Serving Size",
          nutrientValue: "100",
          uomName: "g",
          dvValue: "N/A",
          hundredGramPerProduct: "N/A",
        },
      ],
    },

    headers: {},
    status: 200,
    statusText: "OK",
  };
};
export const mockGetCompareCurrent: ReturnType<
  typeof realGetCompareCurrent
> = async ({ countryCode = "US", productId, languageCode = "EN" }) => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful",
      component: {
        productComments: "",
        productAllergen: "Milk, Soy.",
        ingredientStatement:
          "Tomaattipyree (66 %; vastaa 184 g tomaatteja / 100 g ketsuppia), glukoosi-fruktoosisiirappi, väkiviinaetikka, suola, mausteuutteet.",
        productKeywords: "",
        productNo: "1187",
        genesisProductName:
          "Annosketsuppi (Develey Senf & Feinkost GmbH, Saksa)",
        pimId: "3105-40014",
        importId: "3105-40014",
        genesisId: "3105-40014",
        unit: "",
        servingSizeMetric: "",
        productWirnNo: "WRIN 06008-009",
        productId: "300000",
        productMarketingName:
          "Annosketsuppi (Develey Senf & Feinkost GmbH, Saksa)",
        productName: "Annosketsuppi (Develey Senf & Feinkost GmbH, Saksa)",
        productAdditionalTextIngredientStatement: "",
        productAdditionalAllergen: "",
        nutrientFacts: {
          nutrient: [
            {
              hundredGPerProduct: "",
              uom: "kJ",
              name: "Energiasisältö (kJ)",
              id: 3,
              value: "529",
              uomDescription: "Energia",
            },
            {
              hundredGPerProduct: "",
              uom: "kcal",
              name: "Energiasisältö (kcal)",
              id: 4,
              value: "125",
              uomDescription: "Energia",
            },
            {
              hundredGPerProduct: "",
              uom: "g",
              name: "Annoskoko (g)",
              id: 12,
              value: "10",
              uomDescription: "gramma (all other nutrients)",
            },
          ],
        },
        productType: "Core Product",
        id: "300000",
        attachProductThumbnailImage: {
          imageName: "lowfat_smoothie_yogurt.png",
        },
      },
    },

    headers: {},
    status: 200,
    statusText: "OK",
  };
};
export const mockGetCompareLive: ReturnType<
  typeof realGetCompareLive
> = async ({ countryCode = "US", productId, languageCode = "EN" }) => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful",
      component: {
        productComments: "",
        productAllergen: "Milk, Soy.",
        ingredientStatement:
          "Tomaattipyree (66 %; vastaa 184 g tomaatteja / 100 g ketsuppia), glukoosi-fruktoosisiirappi, väkiviinaetikka, suola, mausteuutteet.",
        productKeywords: "",
        productNo: "1187",
        genesisProductName:
          "Annosketsuppi (Develey Senf & Feinkost GmbH, Saksa)",
        pimId: "3105-40014",
        importId: "3105-40014",
        genesisId: "3105-40014",
        unit: "",
        servingSizeMetric: "",
        productWirnNo: "WRIN 06008-009",
        productId: 300000,
        productMarketingName:
          "Annosketsuppi (Develey Senf & Feinkost GmbH, Saksa)",
        productName: "Annosketsuppi (Develey Senf & Feinkost GmbH, Saksa)",
        productAdditionalTextIngredientStatement: "",
        productAdditionalAllergen: "",
        nutrientFacts: {
          nutrient: [
            {
              hundredGPerProduct: "",
              uom: "kJ",
              name: "Energiasisältö (kJ)",
              id: 3,
              value: "N/A",
              uomDescription: "Energia",
            },
            {
              hundredGPerProduct: "",
              uom: "kcal",
              name: "Energiasisältö (kcal)",
              id: 4,
              value: "125.89",
              uomDescription: "Energia",
            },
            {
              hundredGPerProduct: "",
              uom: "g",
              name: "Annoskoko (g)",
              id: 12,
              value: "10.0",
              uomDescription: "gramma (all other nutrients)",
            },
          ],
        },
        productType: "Core Product",
        id: 300000,
        attachProductThumbnailImage: {
          imageName: "lowfat_smoothie_yogurt.png",
          altText: "attach_item_thumbnail_image",
          description: "attach_item_thumbnail_image",
          url: "http://s3.amazonaws.com/us-east-prod-dep-new-share-s3/dna/images",
          imageDisclaimer: "",
        },
      },
    },

    headers: {},
    status: 200,
    statusText: "OK",
  };
};
export const mockSaveProductDetails: ReturnType<
  typeof realSaveProductDetails
> = async (
  body: { id?: number; name?: string; value?: string; keyName?: string }[]
) => {
  await delay(2);
  return {
    config: { headers: new AxiosHeaders() },
    data: { status: "200", message: "Food Promotion was saved successfully" },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockSaveProductNutrients: ReturnType<
  typeof realSaveProductNutrients
> = async (body) => {
  await delay(2);
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      status: "200",
      productName: "Diet Coke",
      nutritionList: [
        {
          nutrientFactId: 18,
          nutrientFactName: "Secondary Serving Size",
          nutrientValue: "100",
          uomName: "g",
          dvValue: "N/A",
          hundredGramPerProduct: "N/A",
        },
        {
          nutrientFactId: 17,
          nutrientFactName: "Primary Serving Size",
          nutrientValue: "3.5273961980687005",
          uomName: "oz",
          dvValue: "N/A",
          hundredGramPerProduct: "N/A",
        },
        {
          nutrientFactId: 2,
          nutrientFactName: "Calories",
          nutrientValue: "0.269",
          uomName: " Cal.",
          dvValue: "N/A",
          hundredGramPerProduct: "N/A",
        },
        {
          nutrientFactId: 23,
          nutrientFactName: "Calories From Fat",
          nutrientValue: "0",
          uomName: " Cal.",
          dvValue: "N/A",
          hundredGramPerProduct: "N/A",
        },
        {
          nutrientFactId: 30,
          nutrientFactName: "Energy kJ",
          nutrientValue: "1.1255",
          uomName: "kJ",
          dvValue: "N/A",
          hundredGramPerProduct: "N/A",
        },
        {
          nutrientFactId: 11,
          nutrientFactName: "Protein",
          nutrientValue: "0.009",
          uomName: "g",
          dvValue: "N/A",
          hundredGramPerProduct: "N/A",
        },
        {
          nutrientFactId: 22,
          nutrientFactName: "Carbohydrates",
          nutrientValue: "0.084",
          uomName: "g",
          dvValue: "N/A",
          hundredGramPerProduct: "N/A",
        },
        {
          nutrientFactId: 9,
          nutrientFactName: "Dietary Fiber 2015",
          nutrientValue: "0.023",
          uomName: "g",
          dvValue: "N/A",
          hundredGramPerProduct: "N/A",
        },
        {
          nutrientFactId: 10,
          nutrientFactName: "Total Sugars",
          nutrientValue: "0.006",
          uomName: "g",
          dvValue: "N/A",
          hundredGramPerProduct: "N/A",
        },
        {
          nutrientFactId: 21,
          nutrientFactName: "Total Fat",
          nutrientValue: "0",
          uomName: "g",
          dvValue: "N/A",
          hundredGramPerProduct: "N/A",
        },
        {
          nutrientFactId: 31,
          nutrientFactName: "Added Sugars",
          nutrientValue: "0.006",
          uomName: "g",
          dvValue: "N/A",
          hundredGramPerProduct: "N/A",
        },
        {
          nutrientFactId: 4,
          nutrientFactName: "Saturated Fat",
          nutrientValue: "0",
          uomName: "g",
          dvValue: "N/A",
          hundredGramPerProduct: "N/A",
        },
        {
          nutrientFactId: 29,
          nutrientFactName: "Trans Fat",
          nutrientValue: "0",
          uomName: "g",
          dvValue: "N/A",
          hundredGramPerProduct: "N/A",
        },
        {
          nutrientFactId: 6,
          nutrientFactName: "Cholesterol",
          nutrientValue: "0",
          uomName: "mg",
          dvValue: "N/A",
          hundredGramPerProduct: "N/A",
        },
        {
          nutrientFactId: 28,
          nutrientFactName: "Vitamin B6",
          nutrientValue: "N/A",
          uomName: "mg",
          dvValue: "N/A",
          hundredGramPerProduct: "N/A",
        },
        {
          nutrientFactId: 27,
          nutrientFactName: "Vitamin D",
          nutrientValue: "0",
          uomName: "mcg",
          dvValue: "N/A",
          hundredGramPerProduct: "N/A",
        },
        {
          nutrientFactId: 14,
          nutrientFactName: "Calcium",
          nutrientValue: "0",
          uomName: "mg",
          dvValue: "N/A",
          hundredGramPerProduct: "N/A",
        },
        {
          nutrientFactId: 15,
          nutrientFactName: "Iron",
          nutrientValue: "0",
          uomName: "mg",
          dvValue: "N/A",
          hundredGramPerProduct: "N/A",
        },
        {
          nutrientFactId: 24,
          nutrientFactName: "Phosphorus",
          nutrientValue: "N/A",
          uomName: "mg",
          dvValue: "N/A",
          hundredGramPerProduct: "N/A",
        },
        {
          nutrientFactId: 25,
          nutrientFactName: "Potassium",
          nutrientValue: "12.239",
          uomName: "mg",
          dvValue: "N/A",
          hundredGramPerProduct: "N/A",
        },
        {
          nutrientFactId: 7,
          nutrientFactName: "Sodium",
          nutrientValue: "13.983",
          uomName: "mg",
          dvValue: "N/A",
          hundredGramPerProduct: "N/A",
        },
        {
          nutrientFactId: 26,
          nutrientFactName: "Caffeine",
          nutrientValue: "N/A",
          uomName: "mg",
          dvValue: "N/A",
          hundredGramPerProduct: "N/A",
        },
        {
          nutrientFactId: 32,
          nutrientFactName: "Dietary Fiber",
          nutrientValue: "0.023",
          uomName: "g",
          dvValue: "N/A",
          hundredGramPerProduct: "N/A",
        },
      ],
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockProductGetReviewAndSubmit: ReturnType<
  typeof realProductGetReviewAndSubmit
> = async ({ countryCode = "AE", productId }) => {
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "The call was successful.",
      appliedWorkFlowId: 85,
      currentStep: "Nutritionist",
      previousStep: "Marketing",
      nextStep: "",
      productName: "Chicken Nuggets - 6pcs",
      previousComments: [
        {
          userName: "User,Test",
          date: "11/09/2023",
          comments: "Auto Approved",
          role: "Marketing",
        },
        {
          userName: "User,Test",
          date: "11/09/2023",
          comments: "Submitted for approval by Country Administrator",
          role: "Marketing",
        },
      ],
      workFlowList: [
        {
          id: 84,
          name: "No Workflow",
          description: "No Workflow for Product",
        },
        {
          id: 85,
          name: "All Team Workflow",
          description: "All step for Product",
        },
      ],
      checkListAndAckList: [
        {
          checkList: "Products & Ingredients",
          ack: "Products & Ingredients has been validated.",
        },
        {
          checkList: "Nutrition Information",
          ack: "Nutrition Information has been validated.",
        },
      ],
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};

export const mockProductSaveReviewAndSubmit: ReturnType<
  typeof realProductSaveReviewAndSubmit
> = async (body) => {
  await delay(2);
  return {
    config: { headers: new AxiosHeaders() },
    data: {
      message: "Data has been updated successfully.",
    },
    headers: {},
    status: 200,
    statusText: "OK",
  };
};
