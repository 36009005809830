import React, { useCallback, createContext, useMemo, useState } from "react";
import { useNavigate, useOutlet } from "react-router-dom";
import { useStorage } from "./hooks/use-storage";

interface AuthProps {
  accessToken: string | null;
  refreshToken: string | null;
  eId: string | null;
  redirectDestination: string | null;
  pkceCodeVerifier: string | null;
  setAccessToken: (token: string | null) => void;
  setRefreshToken: (token: string | null) => void;
  setEid: (eId: string | null) => void;
  setRedirectDestination: (destination: string) => void;
  setPkceCodeVerifier: (codeVerifier: string) => void;
  logout: (error?: string) => void;
  invalidateAccessToken: () => void;
  setDomain: (domain: string | null) => void;
  domain?: string | null;
}

export const AuthContext = createContext<AuthProps>({
  accessToken: null,
  refreshToken: null,
  eId: null,
  redirectDestination: null,
  pkceCodeVerifier: null,
  setAccessToken: () => {},
  setRefreshToken: () => {},
  setEid: () => {},
  setRedirectDestination: () => {},
  setPkceCodeVerifier: () => {},
  logout: (error?: string) => {},
  invalidateAccessToken: () => {},
  setDomain: () => {},
  domain: null,
});

function stripFirstTwoChars(inputString: string): string {
  // Check if the input string has at least two characters
  if (inputString.length > 1) {
    // Return the string without the first two characters
    return inputString.substring(2);
  } else {
    // Return an empty string if the input string is shorter than two characters
    return "";
  }
}

export const AuthProvider = () => {
  const [eid, setEid] = useStorage("eId", null);
  const [accessToken, setAccessToken] = useStorage("accessToken", null);
  const [refreshToken, setRefreshToken] = useStorage("refreshToken", null);
  const [pkceCodeVerifier, setPkceCodeVerifier] = useStorage(
    "pkceCodeVerifier",
    null
  );
  const [redirectDestination, setRedirectDestination] = useState<string | null>(
    null
  );
  const [domain, setDomain] = useState<string | null>(null);
  const navigate = useNavigate();
  const outlet = useOutlet();

  const logout = useCallback(
    (error?: string) => {
      setEid(null);
      setAccessToken(null);
      setRefreshToken(null);
      setPkceCodeVerifier(null);
      navigate({
        pathname: "/login",
        search: error ? `?error=${error}` : undefined,
      });
    },
    [setEid, setAccessToken, setRefreshToken, setPkceCodeVerifier, navigate]
  );

  // Alters the current accessToken in order to trigger 401 response from API (for debug only)
  const invalidateAccessToken = useCallback(() => {
    if (typeof accessToken === "string" && accessToken.length > 0) {
      setAccessToken(stripFirstTwoChars(accessToken));
    }
  }, [accessToken, setAccessToken]);

  const value = useMemo(
    () => ({
      accessToken,
      refreshToken,
      eId: eid,
      redirectDestination,
      pkceCodeVerifier,
      logout,
      setAccessToken,
      setRefreshToken,
      setEid,
      setRedirectDestination,
      setPkceCodeVerifier,
      invalidateAccessToken,
      domain,
      setDomain,
    }),
    [
      logout,
      accessToken,
      refreshToken,
      eid,
      redirectDestination,
      pkceCodeVerifier,
      setAccessToken,
      setRefreshToken,
      setEid,
      setRedirectDestination,
      setPkceCodeVerifier,
      invalidateAccessToken,
      domain,
      setDomain,
    ]
  );

  return <AuthContext.Provider value={value}>{outlet}</AuthContext.Provider>;
};
