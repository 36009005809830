import * as React from "react";
import {
  Typography,
  Grid,
  Paper,
  TableHead,
  IconButton,
  Button,
  Box,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useCallback, useContext, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { RoleContext } from "../role-provider";
import { useAxios } from "../axios-provider";
import { AddRowLink } from "../components/AddRowLink";
import { AddCircle, DeleteForever } from "@mui/icons-material";
import { Loader } from "../components/loader/Loader";
import { deleteRelationship, saveRelationship } from "../data/miscellaneous";
import { AxiosError } from "axios";
import { ApiError, SANITIZE_OPTS_NO_TAGS } from "../utils";
import {
  ErrorAlertSnackbar,
  SuccessAlertSnackbar,
} from "../components/AlertSnackbar";
import { ResendFormModal } from "../components/ResendFormModal";
import {
  RelationshipVariant,
  RelationshipVariantLanguageData,
} from "../util/relationships";
import { Controller, useForm } from "react-hook-form";
import DOMPurify from "dompurify";
import { StyledSecondaryButton } from "./ItemMarketingForm";
import { ConfirmActionModal } from "./ConfirmActionModal";
import { useNavigate } from "react-router-dom";

type UnsavedRelationshipVariant = Omit<RelationshipVariant, "relationId"> & {
  relationId?: number;
};

type RelationshipTypeForm = {
  relationTypeId?: number;
  relationTypeName: string;
  variants: UnsavedRelationshipVariant[];
};

type VariantToDelete = {
  relationshipTypeId: number;
  relationId: number;
};

type AddEditRelationshipProps = {
  data: RelationshipTypeForm;
  onSaveSuccess: () => void;
  isEdit: boolean;
};

export const AddEditRelationship = ({
  data,
  onSaveSuccess,
  isEdit,
}: AddEditRelationshipProps) => {
  const { relationTypeId, relationTypeName, variants } = data;
  const { apiClient } = useAxios();
  const queryClient = useQueryClient();
  const { selectedCountry, selectedRole, selectableLanguages } =
    useContext(RoleContext);
    const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [variantToDelete, setVariantToDelete] =
    useState<VariantToDelete | null>(null);
  const [isRetrySaveModalOpen, setIsRetrySaveModalOpen] =
    useState<boolean>(false);
  const [isRetryDeleteModalOpen, setIsRetryDeleteModalOpen] =
    useState<boolean>(false);

  const {
    handleSubmit,
    register,
    control,
    watch,
    reset,
    setValue,
    getValues,
    formState: { errors, isDirty },
  } = useForm<RelationshipTypeForm>({
    defaultValues: {
      relationTypeId,
      relationTypeName,
      variants,
    },
  });
  const editableVariants = watch("variants");
  
  const saveRelationshipRequest = saveRelationship(apiClient);
  const { mutate: doSaveRelationship, isLoading: isSavingRelationship } =
    useMutation(
      (data: RelationshipTypeForm) => {
        const body = {
          relationshipTypeName: data.relationTypeName,
          dataList: data.variants,
        };
        const meta = {
          ...(typeof relationTypeId !== "undefined"
            ? { relationshipTypeId: relationTypeId }
            : {}),
          countryCode: selectedCountry!,
          roleId: String(selectedRole)!,
        };
        return saveRelationshipRequest(body, meta);
      },
      {
        onMutate: () => saveRelationshipRequest,
        onSuccess: () => {
          queryClient.invalidateQueries(["getEditRelationshipDetails"]);
          if (isEdit) {
            onSaveSuccess();  
          }
          else {
            setTimeout(() => {
              navigate("/relationships");
            }, 2000); 
          setSuccessMessage("Relationship was added successfully");
          }
        },
        onError: (error: AxiosError) => {
          if (error?.response?.status === 401) {
            setIsRetrySaveModalOpen(true);
          } else if (error.response?.data) {
            const errorMessage: ApiError = error.response.data as ApiError;
            setErrorMessage(String(errorMessage.message));
          } else {
            setErrorMessage(String(error));
          }
        },
      }
    );

  const onClickSubmit = useCallback(
    (data: RelationshipTypeForm) => doSaveRelationship(data),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleDeleteVariantSuccess = useCallback(() => {
    setSuccessMessage(`Variant was successfully deleted`);
    setVariantToDelete(null);
    setIsRetryDeleteModalOpen(false);
    queryClient.invalidateQueries(["getEditRelationshipDetails"]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleDeleteVariantError = useCallback((error: AxiosError) => {
    setVariantToDelete(null);
    if (error?.response?.status === 401) {
      setIsRetryDeleteModalOpen(true);
    } else if (error.response?.data) {
      const errorMessage: ApiError = error.response.data as ApiError;
      setErrorMessage(String(errorMessage.message));
    } else {
      setErrorMessage(String(error));
    }
  }, []);

  const deleteVariantRequest = deleteRelationship(apiClient);
  const { mutate: doDeleteVariant, isLoading: isDeletingVariant } = useMutation(
    (data: VariantToDelete) => {
      const { relationshipTypeId, relationId } = data;
      return deleteVariantRequest({
        countryCode: selectedCountry!,
        roleId: selectedRole!,
        relationshipTypeId,
        relationId,
      });
    },
    {
      onSuccess: handleDeleteVariantSuccess,
      onError: handleDeleteVariantError,
    }
  );

  const handleAddEmptyVariant = useCallback(() => {
    const languageData: RelationshipVariantLanguageData = {};
    for (const language of selectableLanguages) {
      languageData[language.languageCode] = {
        value: "",
        abbreviationValue: "",
      };
    }
    setValue("variants", [...editableVariants, { languageData }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editableVariants]);

  const handleDeleteVariantClick = useCallback(
    (variant: UnsavedRelationshipVariant) => {
      if (
        typeof relationTypeId === "number" &&
        typeof variant.relationId === "number"
      ) {
        // variant has a relationId so it's been saved before
        // must be deleted on through API
        setVariantToDelete({
          relationshipTypeId: relationTypeId,
          relationId: variant.relationId,
        });
      } else {
        // variant was just added
        // can be removed from UI without calling API
        const updatedVariants = editableVariants.filter((ev) => ev !== variant);
        setValue("variants", updatedVariants);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [relationTypeId, editableVariants]
  );

  const handleRetrySaveConfirm = useCallback(() => {
    const formValues = getValues();
    doSaveRelationship(formValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleRetrySaveCancel = useCallback(
    () => setIsRetrySaveModalOpen(false),
    []
  );
  const handleRetryDeleteConfirm = useCallback(() => {
    doDeleteVariant(variantToDelete!);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variantToDelete]);
  const handleRetryDeleteCancel = useCallback(() => {
    setIsRetryDeleteModalOpen(false);
    setVariantToDelete(null);
  }, []);
  const handleSuccessSnackbarClose = useCallback(() => {
    setSuccessMessage(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledRelativeContainer>
      {isSavingRelationship && <Loader />}
      <form
        onSubmit={handleSubmit(onClickSubmit)}
        data-testid="edit-relation-type"
      >
        <Grid mobile={12} container sx={{ mb: 6 }}>
          <StyledTextField
            fullWidth
            disabled={typeof relationTypeId !== "undefined"}
            label={"Relationship-Type Name*"}
            InputLabelProps={{
              shrink: true,
              htmlFor: "edit-relation-type-name",
            }}
            id="edit-relation-type-name"
            {...register("relationTypeName", {
              required: true,  
            })}
            onBlur={(event) =>
              setValue(
                "relationTypeName",
                DOMPurify.sanitize(event.target.value, SANITIZE_OPTS_NO_TAGS)
                  .toString()
                  .trim()
              )
            }
            error={!!errors["relationTypeName"]}
            helperText={
              errors["relationTypeName"] && "Relationship-Type Name is required"
            }
            data-testid="cy-edit-relation-type-name"
          />
        </Grid>
        <Grid
          mobile={12}
          container
          marginBottom={3}
          sx={{ justifyContent: "space-between" }}
        >
          <Typography variant="h4">Relationship Variants</Typography>
          <AddRowLink
            onClick={handleAddEmptyVariant}
            data-testid="add-new-relationship-variant-button"
          >
            <AddCircle aria-label="add new relationship variant button" />
            <Typography>Add Variant</Typography>
          </AddRowLink>
        </Grid>
        <Grid container mobile={12} marginBottom={3}>
          {editableVariants.map((variant, index) => (
            <StyledVariantContainer>
              <Grid mobile={12} container>
                <Grid mobile={10} container>
                  {selectableLanguages.map((language) => {
                    return (
                      <StyledHorizontalField container mobile={12} key={index}>
                        <Grid mobile={2} paddingLeft={3}>
                          <Typography variant="normalBold">
                            {language.languageName}:
                          </Typography>
                        </Grid>
                        <Grid mobile={4}>
                          <Controller
                            name={`variants.${index}.languageData.${language.languageCode}.value`}
                            control={control}
                            rules={{ required: "Value is required" }}
                            render={({ field }) => (
                              <StyledTextField
                                fullWidth
                                {...field}
                                label={"Value*"}
                                InputLabelProps={{
                                  shrink: true,
                                  htmlFor: `relationship-variant-${variant.relationId}-value`,
                                }}
                                id={`relationship-variant-${variant.relationId}-value`}
                                error={Boolean(
                                  errors.variants?.[index]?.languageData?.[
                                    language.languageCode
                                  ]?.value
                                )}
                                helperText={
                                  errors.variants?.[index]?.languageData?.[
                                    language.languageCode
                                  ]?.value?.message
                                }
                                {...(typeof variant.relationId === "undefined"
                                  ? { autoFocus: true }
                                  : {})}
                              />
                            )}
                          />
                        </Grid>
                        <Grid mobile={4}>
                          <Controller
                            name={`variants.${index}.languageData.${language.languageCode}.abbreviationValue`}
                            control={control}
                            render={({ field }) => (
                              <StyledTextField
                                {...field}
                                label={"Abbreviated Value"}
                                InputLabelProps={{
                                  shrink: true,
                                  htmlFor: `relationship-variant-${variant.relationId}-abbreviated-value`,
                                }}
                                id={`relationship-variant-${variant.relationId}-abbreviated-value`}
                              />
                            )}
                          />
                        </Grid>
                      </StyledHorizontalField>
                    );
                  })}
                </Grid>
                <Grid mobile={2} container justifyContent="center">
                  <IconButton
                    sx={{ color: "#DA291C" }}
                    onClick={() => handleDeleteVariantClick(variant)}
                    aria-label={`Delete relationship variant`}
                  >
                    <DeleteForever />
                  </IconButton>
                </Grid>
              </Grid>
            </StyledVariantContainer>
          ))}
          {editableVariants.length === 0 && (
            <StyledEmptyBox>
              <Typography variant="h6">{`No variants added yet`}</Typography>
            </StyledEmptyBox>
          )}
        </Grid>
        <Grid
          mobile={12}
          sx={{
            p: 0,
            my: 4,
            justifyContent: "space-between",
            display: "flex",
          }}
        >
          <StyledSecondaryButton
            variant="contained"
            size="large"
            aria-label="Reset"
            disabled={!isDirty}
            onClick={() => reset()}
          >
            Reset
          </StyledSecondaryButton>
          <StyledSubmitButton type="submit" variant="contained" size="large">
            Submit
          </StyledSubmitButton>
        </Grid>
      </form>
      <ResendFormModal
        open={isRetrySaveModalOpen}
        onResend={handleRetrySaveConfirm}
        onCancel={handleRetrySaveCancel}
        description="An error occurred while saving the relationship"
      />
      {variantToDelete !== null && (
        <>
          <ConfirmActionModal
            open={!isRetryDeleteModalOpen}
            loading={isDeletingVariant}
            message="Are you sure you want to delete this variant?"
            onConfirm={() => doDeleteVariant(variantToDelete)}
            onCancel={() => setVariantToDelete(null)}
          />
          <ResendFormModal
            open={isRetryDeleteModalOpen}
            onResend={handleRetryDeleteConfirm}
            onCancel={handleRetryDeleteCancel}
            description="An error occurred while deleting the variant"
          />
        </>
      )}
      <SuccessAlertSnackbar
        message={successMessage}
        onClose={handleSuccessSnackbarClose}
        // onClose={() => setSuccessMessage(null)}
      />
      <ErrorAlertSnackbar
        message={errorMessage}
        onClose={() => setErrorMessage(null)}
      />
    </StyledRelativeContainer>
  );
};

const StyledRelativeContainer = styled(Grid)({
  margin: 0,
  position: "relative",
});
const StyledEmptyBox = styled(Box)(({ theme }) => ({
  display: "flex",
  height: 88,
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: theme.palette.secondary.main,
}));
export const StyledDashboardTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  fontSize: theme.typography.small.fontSize,
}));
const StyledHorizontalField = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}));
const StyledVariantContainer = styled(Paper)(({ theme }) => ({
  width: "100%",
  marginBottom: theme.spacing(6),
}));
const StyledTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: "white",
  "& .MuiInputLabel-shrink": {
    color: "#000000",
    fontSize: theme.typography.large.fontSize,
    fontFamily: theme.typography.large.fontFamily,
    fontWeight: theme.typography.large.fontWeight,
    lineHeight: theme.typography.large.lineHeight,
  },
  "& .MuiOutlinedInput-root legend": {
    fontSize: "0.85em",
  },
}));
const StyledSubmitButton = styled(Button)(({ theme }) => ({
  color: "#000000",
  fontSize: theme.typography.largeBold.fontSize,
  fontFamily: theme.typography.largeBold.fontFamily,
  fontWeight: theme.typography.largeBold.fontWeight,
  textTransform: "none",
}));
